import { memo, useEffect, useState } from "react";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import MainHead from "../layout/MainHead";
import { Tabs, Row, Col, message, AutoComplete } from "antd";
import { Icon } from "@iconify/react";
import AssetSpendList from "./AssetSpendList";
import AssetSpendTopListChart from "./AssetSpendTopListChart";
import AssetSpendListOthers from "./AssetSpendListOthers";
import OldRecords from "./OldRecords";
import Kubernetes_CostDemaze from "../assetandspend/KubernetesCostDemaze/Kubernetes_CostDemaze";
import AssetSpendDBMain from "../assetandspend/AssetSpendDB/AssetSpendDBMain";
import {
  downloads3file,
  searchresource,
  resourceassetspend,
} from "../../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";
import ResourceList from "./ResourceList";

const AssetSpendLayout = () => {
  const [topchartstatus, setTopChartStatus] = useState(false);
  const [records, setRecords] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [infrochart, setInfraChartDetails] = useState({
    categories: [],
    vmlist: [],
    dblist: [],
    others: [],
  });
  const [spendchart, setSpendChartDetails] = useState({
    categories: [],
    value: [],
  });
  const [chartspend, setChartAllSpend] = useState([]);
  const [chartcount, setChartAllCount] = useState([]);
  const [resourceName, setResourceName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [select, setonSelect] = useState(false);
  const [selectedvalueData, setSelectedValueData] = useState();
  const [resourceNameselected, setSelectResource] = useState("");
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [activeTab, setActiveTab] = useState("1");
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(records);
    // console.log(applicationlist);
    var records_list_count = [];
    var reacords_list_spend = [];
    applicationlist.map((application) => {
      // if(application.toLowerCase() === )
      const list_records = records?.filter((datas) => {
        return datas.application === application.toLowerCase();
      });
      // console.log(list_records);
      const spendtotal = list_records.reduce((a, v) => (a = +a + +v.spend), 0);
      // console.log(spendtotal);

      records_list_count.push({
        label: application,
        value: list_records.length,
      });
      reacords_list_spend.push({
        label: application,
        value: Math.round(spendtotal),
      });
    });
    const records_list = records_list_count.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    const records_list_spend = reacords_list_spend.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    // console.log(records_list_spend);
    var cate_list = [];
    var vmcount = [];
    records_list.map((data, index) => {
      if (index < 5) {
        cate_list.push(data.label);
        vmcount.push(data.value);
      }
    });
    setInfraChartDetails({
      categories: cate_list,
      vmlist: vmcount,
      dblist: [0, 0, 0, 0, 0],
      others: [0, 0, 0, 0, 0],
    });

    var spend_cate_list = [];
    var spend_list = [];
    records_list_spend.map((data, index) => {
      if (index < 5) {
        spend_cate_list.push(data.label);
        spend_list.push(data.value);
      }
    });
    setSpendChartDetails({ categories: spend_cate_list, value: spend_list });
    // console.log({ categories: spend_cate_list, value: spend_list });

    // console.log({
    //   categories: cate_list,
    //   vmlist: vmcount,
    //   dblist: [0, 0, 0, 0, 0],
    // others: [0, 0, 0, 0, 0],
    // });
  }, [records]);

  const items = [
    {
      key: "1",
      label: `VM`,
      children: (
        <div className="assetspendslm">
          {select ? (
            <ResourceList
              resourceNames={resourceNameselected}
              searchValue={searchValue}
            />
          ) : (
            <AssetSpendList
              type="vm"
              setRecords={setRecords}
              setApplicationList={setApplicationList}
              setViewStatus={setViewStatus}
              viewStatus={viewStatus}
            />
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: `DBaas`,
      children: (
        <div className="assetspendslm">
          {" "}
          <AssetSpendDBMain
            setChartAllSpend={setChartAllSpend}
            setChartAllCount={setChartAllCount}
            type="db"
          />
        </div>
      ),
    },
    {
      key: "3",
      label: `Kubernetes`,
      children: (
        <div className="assetspendslm">
          <Kubernetes_CostDemaze className="slm" />
        </div>
      ),
    },
    {
      key: "4",
      label: `Others`,
      children: (
        <AssetSpendListOthers
          setChartAllSpend={setChartAllSpend}
          setChartAllCount={setChartAllCount}
          type="others"
        />
      ),
    },
  ];
  const downloadExcel = () => {
    let now = new Date();
    // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate()));

    // const prevMonth = new Date(selectedTopdate).toLocaleDateString("en-US", {
    //   month: "long",
    // });
    const prevMonth = new Date(selectedTopdate).toLocaleDateString("en-US", {
      month: "long",
    });
    const yearvalue = new Date(selectedTopdate).toLocaleDateString("en-US", {
      year: "numeric",
    });
    console.log(prevMonth, "clickmonth");

    dispatch(downloads3file({ prevMonth, yearvalue, companyName }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "datafordownloading");

        if (data.application_grouping_report === "Report Not Yet Generated") {
          message.info("Report not yet generated");
        } else if (data && data.application_grouping_report) {
          const apidata = data.application_grouping_report.replace(
            /^"|"$/g,
            ""
          );
          console.log(apidata);

          const link = document.createElement("a");
          link.href = apidata;
          link.download = "grouping.pdf";
          link.click();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSearch = (value) => {
    dispatch(searchresource({ customer, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        const resourcelist = data.assetspend_resourcelist.map((datas) => {
          return {
            resourcename: datas.resourcename,
          };
        });

        setResourceName(resourcelist);
      })
      .catch((err) => {
        console.log(err);
      });

    setSearchValue(value);
    console.log(value);
    console.log(searchValue);
    if (value === "") {
      setonSelect(false);
    }
  };

  const handleSelect = (value) => {
    // setHandleSelect(value)
    console.log(value);
    setSelectedValueData(value);
    if (!value) {
      setonSelect(false);
      setSelectResource([]);
    } else {
      dispatch(
        resourceassetspend({ customer, selectedTopdate, searchValue: value })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const resourceselect = data.assetspend_resource_search.map(
            (datas) => {
              return {
                account_name: datas.account_name,
                environment: datas.environment,
                application: datas.application,
                cloud: datas.cloud,
                resourceid: datas.resourceid,
                ram: datas.ram,
                core: datas.core,
                instancetype: datas.instancetype,
                storage: datas.storage,
                billingperiodstartdate: datas.billingperiodstartdate,
                spend: datas.spend,
                recommendation: datas.recommendation,
                savings_opportunity: datas.savings_opportunity,
                second_month_spend: datas.second_month_spend,
                third_month_spend: datas.third_month_spend,
                first_month_spend: datas.first_month_spend,
              };
            }
          );
          console.log(resourceselect, "resource select");
          setSelectResource(resourceselect);
          setonSelect(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log(searchValue);
  let dataSource = [];

  if (resourceName && !Array.isArray(resourceName)) {
    dataSource = Object.values(resourceName).map((item) => item.resourcename);
  } else if (Array.isArray(resourceName)) {
    dataSource = resourceName.map((item) => item.resourcename);
  } else {
    console.error("resourceName is not an array or an object");
  }

  // Ensure dataSource is an array even if resourceName is empty or not provided
  if (!Array.isArray(dataSource)) {
    dataSource = [];
  }
  const filteredSuggestions = dataSource.filter((item) =>
    item.toLowerCase().includes(searchValue.toLowerCase())
  );
  const showSuggestions =
    searchValue.length > 0 && filteredSuggestions.length > 0;
  const autoCompleteDataSource = showSuggestions ? filteredSuggestions : [];

  return (
    <>
      {topchartstatus && (
        <AssetSpendTopListChart
          topchartstatus={topchartstatus}
          setTopChartStatus={setTopChartStatus}
          infrochart={infrochart}
          spendchart={spendchart}
          chartspend={chartspend}
          chartcount={chartcount}
        />
      )}

      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <OldRecords
          setChartAllSpend={setChartAllSpend}
          setChartAllCount={setChartAllCount}
        />
        <CostimizeSidebar />
        <MainHead title="Asset/Spend" />
        <div className="costimize-body-screen">
          <Row style={{ display: "block" }}>
            <Col span={24} className="spend-asset-body">
             
                <div className="spend-search">
                {activeTab === "1" && (
                  <div className="searchOption">
                    <AutoComplete
                      style={{ width: 200 }}
                      value={searchValue}
                      onChange={(value) => handleSearch(value)}
                      onSelect={handleSelect}
                      dataSource={autoCompleteDataSource}
                      placeholder="Enter Resource Name"
                      allowClear
                    />
                  </div> )}

                  <div
                    className="plat-icon-bg"
                    onClick={() => setTopChartStatus(true)}
                  >
                    <Icon
                      icon="mdi:chart-bar-stacked"
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                  <div
                    className="plat-icon-bg cursor-pointer"
                    onClick={downloadExcel}
                  >
                    <Icon icon="file-icons:microsoft-excel" />
                  </div>

                  {/* <Input
                  placeholder="Search"
                  style={{
                    width: "191px",
                    height: "27px",
                    boxShadow: "0px 4px 4px rgba(217, 217, 217, 0.6)",
                  }}
                /> */}
                </div>
             

              <div className="spend-tab">
                <Tabs
                  type="line"
                  onChange={(key) => setActiveTab(key)}
                  defaultActiveKey="1"
                  items={items}
                  className="assetspend-costimize-table-tab"
                />
                {console.log("keyset", activeTab)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default memo(AssetSpendLayout);
