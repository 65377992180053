import { Button, DatePicker, Row, Select, Spin, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import export_logo from "./../../assets/images/cams/excel_logo.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getReportData,
  getReportsPages,
} from "../action/graphql/cams/cams_reducers";
import { saveAs } from "file-saver";

const CamsReport = (customer) => {
  const options = [
    {
      label: "SA Processed",
      value: "cams_processed",
    },
    {
      label: "All Alerts",
      value: "all_alerts",
    },
  ];
  const tempDate = new Date();
  const [selectedDate, setSelectedDate] = useState();
  const dispatch = useDispatch();
  const [tableDisplayData, setTableDisplayData] = useState([]);
  const [readyForExport, setReadyForExport] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  }

  function downloadCSV(excelrecords, date, type) {
    const data = excelrecords;
    const filename = `${customer.customer}_${date}_${type}.csv`;
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
    message.success("Report Downloaded");
  }

  const { RangePicker } = DatePicker;

  // const handleDateChange = (dateRange) => {
  //   if (dateRange && dateRange.length === 2) {
  //     setStartDate(dateRange[0].format("YYYY-MM-DD"));
  //     setEndDate(dateRange[1].format("YYYY-MM-DD"));
  //   }
  // };
  const handleDateChange = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings.map((dateString) =>
      moment(dateString).format("YYYY-MM-DD")
    );
    setSelectedDate({ startDate, endDate });
    console.log({ startDate, endDate });
  };

  var tempDataArray = [];

  const getTableData = (startDate, endDate, currentPage, totalPages) => {
    let currTemp = currentPage + 1;
    let totalTemp = totalPages;

    dispatch(
      getReportData({
        company_name: customer.customer,
        start_date: startDate,
        end_date: endDate,
        page_size: 100,
        alert_type: type,
        page_count: currentPage,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data, "alertdetails");
        tempDataArray.push(...data.cams_report_tab_main_table);
        console.log(tempDataArray);
        currentPage = currentPage + 1;
        setTableDisplayData(tempDataArray);
        setReadyForExport(true);
        setLoading(false);
        setShowSpinner(false);
      });
  };

  const generateReports = () => {
    if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
      const { startDate, endDate } = selectedDate;

      setLoading(true);
      setTableDisplayData([]);
      setShowSpinner(true);
      tempDataArray = [];
      dispatch(
        getReportsPages({
          company_name: customer.customer,
          start_date: startDate,
          end_date: endDate,
          count: 100,
          alert_type: type,
        })
      )
        .unwrap()
        .then(({ data }) => {
          var totalPages = Number(
            data.cams_report_tab_table_pagesize[0].total_pages
          );
          var currentPage = 1;
          getTableData(startDate, endDate, currentPage, totalPages);
        });
    } else {
      message.error("Please Select Date Range");
    }
  };

  const [type, setType] = useState("all_alerts");
  const [reportData, setReportData] = useState([]);

  const columns = [
    {
      title: "Alert Type",
      key: "alert_type",
      dataIndex: "alert_type",
      align: "left",
    },

    {
      title: "From Address",
      key: "from_address",
      dataIndex: "from_address",
      className: "devops-table-header",
      align: "left",
    },
    {
      title: "Server Name",
      key: "server_name",
      dataIndex: "server_name",
      align: "left",
    },
    {
      title: "Severity Level",
      key: "severity",
      dataIndex: "severity",
      align: "left",
    },
    {
      title: "Subject",
      key: "subject",
      dataIndex: "subject",
      align: "left",
    },
    {
      title: "To Address",
      key: "to_address",
      dataIndex: "to_address",
      align: "left",
    },
    {
      title: "Date",
      key: "date_column",
      dataIndex: "date_column",
      align: "left",
    },
    {
      title: "Time",
      key: "time",
      dataIndex: "time",
      align: "left",
    },
  ];

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const downloadReport = () => {
    if (tableDisplayData.length > 0) {
      downloadCSV(tableDisplayData, selectedDate, type);
    } else {
      message.error("No data to export");
    }
  };
  return (
    <>
      <div className="h-100 cams-content  mb-5">
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="d-flex justify-content-end align-items-center mr-3 ">
              <div className="cams-date mt-2">
                <RangePicker
                  className="datepicker"
                  onChange={handleDateChange}
                />
              </div>
              <Select
                defaultValue={type}
                options={options}
                dropdownMatchSelectWidth={false}
                onChange={(val) => {
                  setType(val);
                }}
              />

              <Button
                className="reports-submit"
                onClick={!loading ? generateReports : undefined}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="alert-detail-table-reports reports-table d-block">
            {showSpinner ? (
              <Spin className="reports-spinner" />
            ) : (
              <>
                <div
                  className=" export d-flex justify-content-end"
                  onClick={downloadReport}
                >
                  <span>
                    <img
                      className="ml-2 cursor-pointer"
                      alt="Export"
                      src={export_logo}
                    />
                    Export
                  </span>
                </div>
                <div className="alert-detail-table2-height">
                  <Table
                    className="slm"
                    dataSource={tableDisplayData}
                    columns={columns}
                    bordered={false}
                    noDataIndication="No Records"
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};
export default CamsReport;
