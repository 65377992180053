import React from "react";
import { memo, useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { capitalizeFirst } from "../../../custom_hook/CustomHook";
import {
  addUpdateForm,
  listDevopsAccount,
  listDevopscategory,
} from "../../../action/graphql/devops/DevOpsAction";
import {
  getConfigurationData,
  saveBasicDetails,
  setAccountInfo,
} from "../../../slice/devopsSlice";

function BasicDetails(props) {
  const navigateFn = useNavigate();
  const [tablerecords, setTableRecords] = useState([]);
  const [categoryrecords, setcategoryrecords] = useState([]);
  const [categoryoptions, setcategoryoptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectaccountOptions, setSelectaccountOptions] = useState([]);
  const [selectCloudoption, setselectCloudoption] = useState([]);
  const [selectCategoryoption, setselectCategoryoption] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const devopsStore = useSelector((state) => state.devops);
  const getcloudDetails = () => {
    dispatch(listDevopsAccount({ customer }))
      .unwrap()
      .then(({ data }) => {
        const records = data.list_platform_devops_accounts.map((datas) => {
          return {
            account_id: datas.account_id,
            account_name: datas.account_name,
            company: datas.company,
            cloud_platform: datas.cloud_platform,
            customer_approval_email: datas.customer_approval_email,
            external_id: datas.external_id,
            och_approval_email: datas.och_approval_email,
            status: datas.status,
          };
        });
        dispatch(setAccountInfo(records));
        setTableRecords(records);
        const options = records.reduce((acc, record) => {
          const foundOption = acc.find(
            (option) => option.value === record.cloud_platform
          );
          if (!foundOption) {
            acc.push({
              label: record.cloud_platform,
              value: record.cloud_platform,
            });
          }
          return acc;
        }, []);
        setSelectOptions(options);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getCloudCategory = (cloud_platform) => {
    dispatch(listDevopscategory({ cloud_platform }))
      .unwrap()
      .then(({ data }) => {
        const records = data.devops_config.map((datas) => {
          return {
            build_category: datas.build_category,
            build_sub_category: datas.build_sub_category,
            cloud_platform: datas.cloud_platform,
            configurations: datas.configurations,
            logs: datas.logs,
            status: datas.status,
          };
        });

        dispatch(getConfigurationData(data.devops_config));
        setcategoryrecords(records);
        console.log({ records });
        const options = records.reduce((acc, record) => {
          const foundOption = acc.find(
            (option) => option.value === record.build_category
          );
          if (!foundOption) {
            acc.push({
              label: record.build_category,
              value: record.build_category,
            });
          }
          return acc;
        }, []);
        setcategoryoptions(options);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getcloudDetails();
    console.log(devopsStore);
    if (devopsStore.requestAction === "add") {
      form.resetFields();
      setTableRecords([]);
    } else if (devopsStore.requestAction === "edit") {
      form.setFieldsValue(devopsStore.requestorTableData);
    }
  }, [customer]);
  const handleCloudChange = (selectedCloud) => {
    setselectCloudoption(selectedCloud);
    const accountOptions = tablerecords
      .filter((record) => record.cloud_platform === selectedCloud)
      .map((record) => ({
        label: record.account_name,
        value: record.account_name,
      }));
    setSelectaccountOptions(accountOptions);
    getCloudCategory(selectedCloud);
  };

  const handleCategoryChange = (selectedCate) => {
    const accountOptions = categoryrecords
      .filter((record) => record.build_category === selectedCate)
      .map((record) => ({
        label: record.build_sub_category,
        value: record.build_sub_category,
      }));
    setselectCategoryoption(accountOptions);
  };
  useEffect(() => {
    var records;
    form.setFieldsValue(records);
  }, []);
  const handleSubmit = (values) => {
    values["status"] = "draft";
    values["event_type"] = "insert";
    console.log({ values });
    dispatch(saveBasicDetails(values));

    props.setCurrent(props.current + 1);
  };
  return (
    <>
      {" "}
      <Form onFinish={handleSubmit} form={form}>
        <div className="basic-details">
          <Row gutter={[16, 16]} className="d-flex justify-content-center">
            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
              {/* <Row gutter={[16, 16]} className="d-flex justify-content-center"> */}
              {/* <Col> */}
              {/* <Form.Item name="cloud" label="Cloud"> */}
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  Cloud
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item name="cloud_platform">
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Cloud"
                      options={selectOptions}
                      onChange={handleCloudChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  Account Name
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item name="account_name">
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Account Name"
                      options={selectaccountOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                  Select Launch Category
                </Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item name="service_name">
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Launch Category"
                      options={categoryoptions}
                      onChange={handleCategoryChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  Select Configuration
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item name="operation">
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Configuration"
                      options={selectCategoryoption}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  Select CR Type
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item name="activity_type">
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select CR Type"
                      options={[
                        {
                          label: "General",
                          value: "general",
                        },
                        {
                          label: "Standard",
                          value: "standard",
                        },
                        {
                          label: "Emergency",
                          value: "emergency",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <br />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item>
              <Button
                htmlType="button"
                onClick={() => navigateFn("/devops-requestor")}
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit" className="form-button">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default BasicDetails;
