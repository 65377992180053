// userAction.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../apploClient";
import client_new from "./client/dashboardapplonewClinet";
import { useErrorNavigation } from "../custom_hook/CustomHook";
import {
  ACCOUNT_LIST,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_UPDATE,
  ACCOUNT_STATUS_UPDATE,
  ACCOUNT_LIST_NEW,
  ACCOUNT_ENVIRIONMENT_LIST,
  ACCOUNT_ONBOARDING,
  ACCOUNT_ONBOARDING_INSERT,
  TOKEN_VERIFIED,
  ROLE_DROPDOWN,
  ROLE_GET_BY_ID,
  ROLE_INSERT,
  ALL_ROLE_LIST,
  DELETE_ROLE,
  UPDATE_ROLE,
  DOCUMENT_POLICY,
  COMPANY_BASED_ACCOUNT,
  COMPANY_DISTINICT_NAME,
} from "../Graphql";
import { useNavigate } from "react-router-dom";

export const listAccount = createAsyncThunk(
  // action type string
  "account/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const accList = await client.query({
        query: ACCOUNT_LIST,
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAccountNew = createAsyncThunk(
  // action type string
  "account/new_list",
  // callback function
  async ({}, { rejectWithValue }) => {
    try {
      const accList = await client.query({
        query: ACCOUNT_LIST_NEW,
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const accountOnboarding = createAsyncThunk(
  // action type string
  "account/create",
  // callback function
  async (
    {
      usage_account_id,
      account_name,
      bucket_name,
      iam_arn_role,
      prefix_path,
      report_name,
      environment,
      status,
      cloud_platform,
      customer,
      ec2_regions_list,
      environment_tag,
      application_name_column,
      payer_account_id,
      auto_tag_update,
      event_type,
      external_id,
      files,
    },
    { rejectWithValue }
  ) => {
    // console.log({  account_id, account_name, bucket_name, iam_arn_role, prefix_path, report_name, environment, status, cloud_type, company_name, ec2_region_list, environment_tag, application_tag, payer_account_id, auto_tag_update,event_type, files });
    console.log(event_type);
    var query;
    if (event_type === "validate") {
      query = ACCOUNT_ONBOARDING;
    } else {
      query = ACCOUNT_ONBOARDING_INSERT;
    }
    try {
      const account = await client.mutate({
        mutation: query,
        variables: {
          usage_account_id,
          account_name,
          bucket_name,
          iam_arn_role,
          prefix_path,
          report_name,
          environment,
          status: "active",
          cloud_platform,
          customer,
          ec2_regions_list,
          environment_tag,
          application_name_column,
          payer_account_id,
          auto_tag_update,
          event_type,
          external_id,
          files,
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createAccount = createAsyncThunk(
  // action type string
  "account/create",
  // callback function
  async (
    {
      account_id,
      account_name,
      bucket_name,
      iam_arn_role,
      prefix_path,
      report_name,
      environment,
      status,
      cloud_type,
      company_name,
      ec2_region_list,
      environment_tag,
      application_tag,
      payer_account_id,
      auto_tag_update,
    },
    { rejectWithValue }
  ) => {
    try {
      const account = await client.mutate({
        mutation: CREATE_ACCOUNT,
        variables: {
          createaccountconfiginput: {
            account_id,
            account_name,
            bucket_name,
            iam_arn_role,
            prefix_path,
            report_name,
            environment,
            status: "active",
            cloud_type,
            company_name,
            ec2_region_list,
            environment_tag,
            application_tag,
            payer_account_id,
            auto_tag_update,
          },
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAccount = createAsyncThunk(
  // action type string
  "account/delete",
  // callback function
  async ({ account_id, account_name }, { rejectWithValue }) => {
    try {
      const account = await client.mutate({
        mutation: DELETE_ACCOUNT,
        variables: {
          account_id,
          account_name,
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAccountDetails = createAsyncThunk(
  // action type string
  "account/update",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const account = await client.mutate({
        mutation: ACCOUNT_UPDATE,
        variables: {
          account_id: data.account_id,
          account_name: data.account_name,
          bucket_name: data.bucket_name,
          environment: data.environment,
          iam_arn_role: data.iam_arn_role,
          prefix_path: data.prefix_path,
          report_name: data.report_name,
          ec2_region_list: data.ec2_region_list,
          environment_tag: data.environment_tag,
          application_tag: data.application_tag,
          payer_account_id: data.payer_account_id,
          auto_tag_update: data.auto_tag_update,
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAccountStatus = createAsyncThunk(
  // action type string
  "account/status_update",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const account = await client.mutate({
        mutation: ACCOUNT_STATUS_UPDATE,
        variables: {
          account_id: data.account_id,
          account_name: data.account_name,
          status: data.status_value,
        },
      });
      return account;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const accountEnvirionmentList = createAsyncThunk(
  // action type string
  "account/envirionment_list",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const account = await client.mutate({
        mutation: ACCOUNT_ENVIRIONMENT_LIST,
        variables: {},
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifiedJWTToken = createAsyncThunk(
  // action type string
  "token/verified",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client.query({
        query: TOKEN_VERIFIED,
        variables: {
          token: data.userToken,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const roleDropdown = createAsyncThunk(
  // action type string
  "get/roledropdown",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.mutate({
        mutation: ROLE_DROPDOWN,
        variables: {
          company: data.company_name,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const roleGetByID = createAsyncThunk(
  // action type string
  "get/roledropdown",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: ROLE_GET_BY_ID,
        variables: {
          company: data.company_name,
          role_id: data.role_id,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const newRoleInsert = createAsyncThunk(
  // action type string
  "role/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: ROLE_INSERT,
        variables: {
          customer: data.input.customer,
          role_category: data.input.role_category,
          role_name: data.input.role_name,
          user_id: data.input.user_id,
          event_type: data.input.event_type,
          offering: data.input.offering,
          resources: data.input.resources,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAllRole = createAsyncThunk(
  // action type string
  "role/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.mutate({
        mutation: ALL_ROLE_LIST,
        variables: {
          company_name: data.company_name,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletedCustomRole = createAsyncThunk(
  // action type string
  "role/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: DELETE_ROLE,
        variables: {
          customer: data.input.customer,
          role_id: data.input.role_id,
          user_id: data.input.user_id,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateRole = createAsyncThunk(
  // action type string
  "role/update",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_new.query({
        query: UPDATE_ROLE,
        variables: {
          input_new: data.input_new,
          input_old: data.input_old,
          user_id: data.user_id,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const distinictlistAccount = createAsyncThunk(
  // action type string
  "account/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const accList = await client.mutate({
        mutation: COMPANY_DISTINICT_NAME,
        variables: {
          cloud: data.cloud,
          event_type: data.event_type,
        },
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const companybasedlistAccount = createAsyncThunk(
  // action type string
  "account/list",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const accList = await client.mutate({
        mutation: COMPANY_BASED_ACCOUNT,
        variables: {
          company: data.toggleText,
          event_type: data.event_type_company,
        },
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const rolePolicyDocument = createAsyncThunk(
  // action type string
  "accountonboard/document",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client.mutate({
        mutation: DOCUMENT_POLICY,
        variables: {
          cloud: data.cloud,
          event_type: data.event_type,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
