import React, { useState } from "react";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { Row, Col } from "antd";
import { Icon } from "@iconify/react";
import { numberFormat, capitalizeFirst } from "../../../custom_hook/CustomHook";
import AssetResourceDB from "./AssetResourceDB";

function AssetApplicationDB(props) {
  const [applicationclicked, setApplicationClicked] = useState();
  const [isShowApplication, setIsShowApplication] = useState(0);

  const handleApplicationClick = (application) => {

    setApplicationClicked(application);
  };

  const handleOnClick = (indx, application) => {
    setIsShowApplication(indx);
  };

  return (
    <>
      {props.applicationList.map((applicationlist, index) => {
        const ran_num_app = Math.random();
        return (
          <ul className="cd-accordion__sub cd-accordion__sub--l3">
            <li className="cd-accordion__item cd-accordion__item--has-children">
              {/* <p className="text-white">
                {" "}
                clicked index-{isShowApplication}index-{index}
              </p> */}
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={"sub-group-level3-db" + index + "" + ran_num_app}
                id={"sub-group-level3-db" + index + "" + ran_num_app}
                // defaultChecked
              />
              <label
                className="cd-accordion__label"
                htmlFor={"sub-group-level3-db" + index + "" + ran_num_app}
                onClick={() => handleOnClick(index, applicationlist[0])}
              >
                <span
                  className="accordion-full-width"
                  onClick={() => handleApplicationClick(applicationlist[0])}p
                >
                  <ToggleButtonV2
                    status={true}
                    newclass="application-list-icon"
                    //   onClick={() =>
                    //     setIsRowClicked(!isRowClicked)
                    //   }
                  >
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-left-title cloud-list-title asset-spend-title-vs"
                      >
                        {capitalizeFirst(applicationlist[0])}(
                        {applicationlist[1].count})
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        ${numberFormat(applicationlist[1].spend)}
                        {/* {applicationlist[2][0][0]} */}
                        <Icon
                          className={
                            "me-1 " +
                            (applicationlist[1].spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            applicationlist[1].spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                    </Row>
                    <div className="asset-spend-border-bottom-vs"></div>
                  </ToggleButtonV2>
                </span>
              </label>

              {isShowApplication == index ? (
                <AssetResourceDB
                  index={index}
                  isShowApplication={isShowApplication}
                  applicationclicked={applicationclicked}
                />
              ) : (
                ""
              )}
            </li>
          </ul>
        );
      })}
    </>
  );
}
export default AssetApplicationDB;
