import { Icon } from '@iconify/react'
import { Col, Row } from 'antd'
import React from 'react'

function TicketInformationPopup({zohoData}) {
  return (
<>
<div className='zoho-info'>
<div>     
<Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
     <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"pajamas:assignee"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Asignee
    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].assignee ? zohoData[0].assignee : "-"}
    </p> 
    </Col>
</Row><Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"bxs:category-alt"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Category
    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].category ? zohoData[0].category : "-"}
    </p>
    </Col>
</Row><Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"pajamas:time-out"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Closed Time
    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].closed_time ? zohoData[0].closed_time : "-"}
    </p>
    </Col>
</Row>
<Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"material-symbols:more-time"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Created Time
    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].created_time ? zohoData[0].created_time : "-"}
    </p>
    </Col>
</Row>
<Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"mdi:user-clock"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Customer Response Time    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].customerResponseTime ? zohoData[0].customerResponseTime : "-"}
    </p>
    </Col>
</Row><Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"tabler:calendar-due"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Due Date    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].dueDate ? zohoData[0].dueDate : "-"}
    </p>
    </Col>
</Row><Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"material-symbols:priority-high"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Priority    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
   {zohoData[0].priority ? zohoData[0].priority : "-"}
    </p>
    </Col>
</Row><Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"fluent:calendar-chat-20-filled"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Response Due Date    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].responseDueDate ? zohoData[0].responseDueDate : "-"}
    </p>
    </Col>
</Row><Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"pajamas:status"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Status    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
   {zohoData[0].status ?  zohoData[0].status : "-"}
    </p>
    </Col>
</Row>
<Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon 
          icon={"heroicons-outline:ticket"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Ticket Number    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].ticketNumber ? zohoData[0].ticketNumber : "-"}
    </p>
    </Col>
</Row>
<Row>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <div className='d-flex'> 
       <div className='icon-div'>  <Icon className='icon-shadow'
          icon={"material-symbols:topic-rounded"}
          style={{ fontSize: "20px" }}/>
          </div>
    <strong>
Subject    </strong>
 </div>
    </Col>
    <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
    <p>
    {zohoData[0].subject ? zohoData[0].subject : "-"}
    </p>
    </Col>
</Row>
</div>
     </div>
</>  )
}

export default TicketInformationPopup