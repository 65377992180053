import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Select, message, Row, Col } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import DrawerPlat from "./../properties/Drawer/DrawerPlat";
import {
  Addbillingclient,
  Updatebillingclient,
  Deletebillingclient,
} from "../action/costimizev2DashboardAction";
import "../spp/spp.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Icon } from "@iconify/react";
import AWS from "aws-sdk";
import DatePicker from "react-datepicker";

dayjs.extend(customParseFormat);
AWS.config.update({
  accessKeyId: "AKIAUNMDL3MXCBFVA75Z",
  secretAccessKey: "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",
});
const s3 = new AWS.S3();
const Billingform = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonloading, setButtonLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [selectedFileName, setSelectedFileName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [billDate, setBillDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    var records = {
      bill_id: "",
      billing_currency: "",
      billingperiod: "",
      billtype: "",
      due_date: "",
      invoiceid: "",
      date: "",
      platform_cost: "",
      status: "",
      tax_cost: "",
      total_invoice_amount: "",
      usage_cost: "",
      vendor: "",
      s3file: "",
    };
    if (props.filePathURL) {
      console.log(props.filePathURL);
    }
    if (props.oldrecords) {
      console.log(props.oldrecords);
      records = {
        bill_id: props.oldrecords.bill_id,
        billing_currency: props.oldrecords.billing_currency,
        date: props.oldrecords.issued_date,
        billingperiod: props.oldrecords.billingperiod,
        due_date: props.oldrecords.due_date,
        billtype: props.oldrecords.billtype,
        invoiceid: props.oldrecords.invoiceid,
        platform_cost: props.oldrecords.platform_cost,
        status: props.oldrecords.status,
        tax_cost: props.oldrecords.tax,
        total_invoice_amount: props.oldrecords.total_invoice_amount,
        usage_cost: props.oldrecords.usage,
        vendor: props.oldrecords.vendor,
        s3file: props.oldrecords.s3_file_name,
      };
      setInitialFormValues(records);
      setSelectedFileName(props.oldrecords.s3_file_name);
      console.log(records);
    }
    form.setFieldsValue(records);
    console.log(form.getFieldsValue());
  }, [props]);
  console.log(initialFormValues);
  const onFinish = async (value) => {
    console.log("Form values:", value);
    setButtonLoading(true);
    if (file) {
      console.log(file);
      console.log(file.name);
      const currentDate = new Date();
      const randomNum = Math.floor(Math.random() * 10000);
      const sanitizeFileName = (fileName) => {
        return fileName.replace(/[^a-zA-Z0-9-]/g, "");
      };
      let fileName = `${randomNum}${currentDate.getFullYear()}${
        currentDate.getMonth() + 1
      }${currentDate.getDate()}.pdf`;
      fileName = sanitizeFileName(fileName);
      const directoryPath = `${customer}/`;
      const params = {
        Bucket: "spp-billing-invoice",
        Key: `${directoryPath}${fileName}`,
        Body: file,
      };
      console.log(params);
      console.log(fileName);
      const { Location } = await s3.upload(params).promise();
      console.log("uploading to s3", Location);
      message.success("File uploaded");
    }
    console.log(value);
    let input = {
      customer: customer,
      issued_date: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-${new Date(value.date).getDate()}`,
      invoiceid: value.invoiceid,
      billtype: value.billtype,
      billing_currency: value.billing_currency,
      // vendor: value.vendor,
      vendor: "1CH",
      billingperiod: `${new Date(value.billingperiod).getFullYear()}-${
        new Date(value.billingperiod).getMonth() + 1
      }-1`,
      due_date: `${new Date(value.due_date).getFullYear()}-${
        new Date(value.due_date).getMonth() + 1
      }-${new Date(value.due_date).getDate()}`,
      platform_cost: value.platform_cost,
      usage_cost: value.usage_cost,
      tax_cost: value.tax_cost,
      total_invoice_amount: value.total_invoice_amount,
      status: value.status,
      s3_file_name: selectedFileName,
    };
    console.log(input);

    if (props.deletestatus) {
      const bill_id = props.oldrecords.bill_id;

      dispatch(Updatebillingclient({ input, bill_id }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          message.success("Record Updated");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setButtonLoading(false);
          message.info("Record Not Updated");
        });
    } else {
      dispatch(Addbillingclient({ input }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          message.success("New Records Added");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setButtonLoading(false);
          message.info("Record not inserted");
        });
    }
  };
  const rowDelete = () => {
    setConfirmLoading(true);
    const bill_id = props.oldrecords.bill_id;
    dispatch(Deletebillingclient({ bill_id, customer }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        props.setUpdateStatus(!props.updatestatus);
        props.setDrawerStatus(false);
        setButtonLoading(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setButtonLoading(false);
        message.info("Record not deleted");
      });
  };
  const [file, setFile] = useState();
  const [previousFile, setPreviousFile] = useState("");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file ? file.name : "";
    setSelectedFileName(fileName);
    console.log(e.target.files[0].name);
    setFile(e.target.files[0]);
    setPreviousFile(file);
  };
  console.log(file);
  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerstatus}
        changeDrawerStatus={props.changeDrawerStatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        deletestatus={props.deletestatus}
        confirmLoading={confirmLoading}
        title="Billing "
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialFormValues}
        >
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="date"
                label="Issued Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="invoiceid"
                label="Invoice No"
                rules={[
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="billtype"
                label="Bill Type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="billing_currency"
                label="Billing Currency"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "USD",
                      label: "USD",
                    },
                    {
                      value: "INR",
                      label: "INR",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {/* <Form.Item
                                name="vendor"
                                label="Vendor"
                                initialValue="1CH"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "",
                                //     },
                                // ]}
                            > */}
              {/* <Input value="1CH" readOnly /> */}
              {/* <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: "1CH",
                                            label: "1CH",
                                        },
                                        // {
                                        //     value: "AWS",
                                        //     label: "AWS",
                                        // },
                                    ]}

                                />
                            </Form.Item> */}
            </Col>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="billingperiod"
                label="Billing Period"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={billDate}
                  onChange={(date) => setBillDate(date)}
                  showMonthYearPicker
                />

                {/* <input type="hidden" value={selectedMonth} /> 
                                <DatePicker
                                    // value={dayjs(selectedMonth, monthFormat)}
                                    format={monthFormat}
                                    // onChange={handleDateChange}
                                    className='spp-date'
                                    picker="month"

                                /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="due_date"
                label="Due Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="platform_cost"
                label="Platform Cost"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="usage_cost"
                label="Usage Cost"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="tax_cost"
                label="Tax"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="total_invoice_amount"
                label="Total Invoice Amount"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Yet to Invoice",
                      label: "Yet to Invoice",
                    },
                    {
                      value: "Generated",
                      label: "Generated",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="s3file" label="Report">
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  defaultValue={selectedFileName}
                />
                {/* <span>{selectedFileName}</span> */}
                {props.deletestatus && (
                  <a
                    href={props.filePathURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    download=""
                    className="view-text"
                  >
                    view a file{" "}
                    <Icon icon="bi:file-pdf-fill" className="download-btn" />
                  </a>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => props.setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {" "}
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};
export default Billingform;
