import { Spin } from "antd";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

function SummaryViewChart({ dateLabel, allAlertsTrend, camsTrend, loading }) {
  useEffect(() => {}, [dateLabel, allAlertsTrend, camsTrend]);
  const xData = [
    "01 Apr",
    "02 Apr",
    "03 Apr",
    "04 Apr",
    "05 Apr",
    "06 Apr",
    "07 Apr",
    "08 Apr",
    "09 Apr",
    "10 Apr",
    "11 Apr",
    "12 Apr",
    "13 Apr",
    "14 Apr",
    "15 Apr",
    "16 Apr",
    "17 Apr",
    "18 Apr",
    "19 Apr",
    "20 Apr",
    "21 Apr",
    "22 Apr",
    "23 Apr",
    "24 Apr",
    "25 Apr",
  ];
  const series = [
    {
      name: "Total Alerts",
      data: allAlertsTrend,
      color: "#E9ED2B",
    },
    {
      name: "Smart Alert Processed",
      data: camsTrend,
      color: "#30929F",
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#E9ED2B"],
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: dateLabel,
      labels: {
        rotateAlways: true,
      },
    },
  };

  return (
    <div>
      {loading ? (
        <Spin className="d-flex justify-content-center" />
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      )}
    </div>
  );
}

export default SummaryViewChart;
