import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import SignedUpCustomer from "./components/SignedUpCustomer";
import StepperData from "./components/StepperData";
import CustomerForm from "./components/admin/useronboard/CustomerForm";
import AccountOnboarding from "./components/AccountOnboarding";
import Chart from "./components/Chart";
import War from "./components/War";
import Footer from "./components/Footer";
import AdminPanel from "./components/admin/Index";
import BasicCard from "./components/Card";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import SignedUpCustomerGroup from "./components/SignedUpCustomerGroup";
import Consolidated from "./components/Consolidated";
import HomePage from "./components/HomePage";
import AdminDashboard from "./components/AdminDashboard";
import AdminActions from "./components/AdminActions";
import UserManagement from "./components/UserMng";
import RoleMng from "./components/RoleMng";
import AccountOffering from "./components/Accountoffering";
import AllAWS from "./components/All-aws";
import AllAZure from "./components/All-azure";
import ForgotPassword from "./components/ForgotPassword";
import CostimizeTab from "./components/costimize/costimizeTab";
import "./App.css";
// import CostimizeAssestsTab from './components/costimize/costimizeAssetstab';

import "@fontsource/poppins";
import Dashboardv2 from "./components/dashboard-v2/Dashboardv2";
import Header from "./components/header/Header";
// import Header from './components/Header';
import Signgroup from "./components/signup/Signgroup";
import PlatOperation from "./components/operation/PlatOperation";
import Operation from "./components/operation/Operation";

import { useDispatch, useSelector } from "react-redux";
import AppPDF from "./components/properties/PDF/AppPDF";
import ProtectedRoute from "./ProtectedRoute";
import QuickGlanceLayout from "./components/costimize_v2/quick_glance/QuickGlanceLayout";
import CAMS from "./components/cams";
import SavingsModelLayout from "./components/costimize_v2/savings_model/SavingsModelLayout";
import AssetSpendLayout from "./components/costimize_v2/assetandspend/AssetSpendLayout";
import InstanceOverview from "./components/costimize_v2/InstanceOverView/instanceOverview";
import BudgetPlanning from "./components/costimize_v2/BudgetPlanning/budgetPlanning";
import SLM_Main from "./components/slm_v2/slm_subcomponents/AssetsSpend";
import SLM_AssetManaged from "./components/slm_v2/slm_subcomponents/AssetManaged";

import SLM_ActivityTracker from "./components/slm_v2/slm_subcomponents/ActivityTracker/ActivityTracker";
import LandingHomePage from "./components/landing_home_page/LandingPage";
import Spp from "./components/spp/spp";
import SppInvoiceDocument from "./components/spp/Sppviewpdf";
import SLM_POActivity from "./components/slm_v2/slm_subcomponents/PO_Activity/PO_Activity/POActivity";
import SLM_Compliance from "./components/slm_v2/slm_subcomponents/Compliance";
import RoleManagement from "./components/admin/role/RoleManagement";
import PeemsIndex from "./components/peems/PeemsIndex";
import MyRoleAccount from "./components/admin/role/MyRoleAccount";
import SLM_Quickglance from "./components/slm_v2/SLM_Quickglance";
import ReactGA from "react-ga4";
import DevOpsMain from "./components/devops/DevOpsMain";
import ConfigurationForm from "./components/devops/configurationForm/ConfigurationForm";
import ChatBot from "./components/costimize_v2/ChartBot/ChatBot";
import AzureAuth from "./components/auth/AzureAuth";
import { useLocale } from "antd/es/locale";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [location]);

  return <>{props.children}</>;
};
const router = [
  {
    path: "/signin_old",
    element: <Signin />,
  },
  {
    path: "signedup-customer",
    element: <SignedUpCustomer />,
  },
  {
    path: "signedup-customer-group",
    element: <SignedUpCustomerGroup />,
  },
  // {
  //   path: "register-new-customer",
  //   element: <StepperData />,
  // },
  {
    path: "register-new-customer",
    element: <CustomerForm />,
  },
  {
    path: "role-management",
    element: <RoleManagement />,
  },
  {
    path: "account-onboarding",
    element: <AccountOnboarding />,
  },
  {
    path: "chart",
    element: <Chart />,
  },
  {
    path: "war",
    element: <War />,
  },
  {
    path: "admin",
    element: <AdminPanel />,
  },
  {
    path: "card",
    element: <BasicCard />,
  },
  {
    path: "consolidated-view",
    element: <Consolidated />,
  },
  {
    path: "admin-dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "admin-actions",
    element: <AdminActions />,
  },
  {
    path: "user-mng",
    element: <UserManagement />,
  },
  {
    path: "role-mng",
    element: <RoleMng />,
  },
  {
    path: "account-offering",
    element: <AccountOffering />,
  },
  {
    path: "admin-user-mng",
    element: <UserManagement />,
  },
  {
    path: "all-aws",
    element: <AllAWS />,
  },
  {
    path: "all-azure",
    element: <AllAZure />,
  },
  {
    path: "all-azure",
    element: <Consolidated />,
  },
  {
    path: "forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "costimizeAssests",
    element: <CostimizeTab />,
  },
  {
    path: "costimize_dashboard",
    element: <Dashboardv2 />,
  },
  {
    path: "old_operation",
    element: <PlatOperation />,
  },
  {
    path: "operation",
    element: <Operation />,
  },
  {
    path: "costdemaze/quick_glance",
    element: <QuickGlanceLayout />,
  },
  {
    path: "cams",
    element: <CAMS />,
  },
  {
    path: "/costdemaze/savings_model",
    element: <SavingsModelLayout />,
  },
  {
    path: "/costdemaze/assent_spend",
    element: <AssetSpendLayout />,
  },
  {
    path: "/costdemaze/instance_overview",
    element: <InstanceOverview />,
  },
  {
    path: "/costdemaze/budget_planning",
    element: <BudgetPlanning />,
  },
  {
    path: "/slm/dashboard",
    element: <SLM_Quickglance />,
  },
  {
    path: "/slm/assets-spend",
    element: <SLM_Main />,
  },
  {
    path: "/slm/asset-managed",
    element: <SLM_AssetManaged />,
  },
  {
    path: "/slm/compliance",
    element: <SLM_Compliance />,
  },
  {
    path: "/slm/planned-opened-activity",
    element: <SLM_POActivity />,
  },
  {
    path: "/slm/activity-tracker",
    element: <SLM_ActivityTracker />,
  },
  {
    path: "billing",
    element: <Spp />,
  },
  {
    path: "/billing/invoice/:id",
    element: <SppInvoiceDocument />,
  },
  {
    path: "/peems",
    element: <PeemsIndex />,
  },
  {
    path: "/role",
    element: <MyRoleAccount />,
  },
  {
    path: "/devops-requestor",
    element: <DevOpsMain />,
  },
  {
    path: "/devops-config",
    element: <ConfigurationForm />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
];

function App() {
  const { chatbotstatus } = useSelector((state) => state.user);
  // useEffect(() => {
  //   console.log("chatbot action");
  // }, [chatbotstatus]);
  // console.log(chatbotstatus);
  const records = {
    user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
    _token: localStorage.getItem("_token"),
  };
  return (
    <div className="body-width">
      <BrowserRouter>
        <Header />
        <div>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingHomePage />} />
            {records.user_id && records._token ? (
              <>
                <Route
                  path="/signup/:code"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signup"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signin"
                  element={<Navigate to="/role" replace />}
                />
              </>
            ) : (
              <>
                <Route
                  path="/signup/:code"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signup"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signin"
                  element={<Signgroup page_type="signin" />}
                />
              </>
            )}

            {/* <Route path="/signup" element={<ProtectedRoute>
                      <Signgroup page_type='signup' />
                    </ProtectedRoute>} />
            <Route path="/signin" element={<ProtectedRoute>
                      <Signgroup page_type='signin' />
                    </ProtectedRoute>} /> */}
            {router.map((val) => (
              <Route
                path={val.path}
                key={val.path}
                element={val.element}
                // element={<ProtectedRoute>{val.element}</ProtectedRoute>}
              />
            ))}

            <Route path="/pdfview" element={<AppPDF />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
          {/* {userInfo.first_name &&  (
              router.map(val =>
                <Route
                  path={val.path}
                  key={val.path}
                  element={val.element}
                />
              )
            )} */}
        </div>
        {/* {chatbotstatus && <ChatBot />} */}
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
