import { memo, useState, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown, Spin, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import TopApplicationChartPopup from "./TopApplicationChartPopup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { listApplicationSpend } from "../../action/costimizeV2Active";

const TopApplicationChart = () => {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //state
  const [preloader, setPreloader] = useState(true);
  const [open, setOpen] = useState(false);
  const [monthlist, setMonthList] = useState(0);
  const [chartvalue, setChartValue] = useState([]);
  const [chartdate, setChartDate] = useState([]);
  const [environmentcount, setEnvironmentCount] = useState(0);
  const [environmentstatus, setEnvironmentStatus] = useState(false);
  const changeMonth = useCallback(
    (month) => {
      setOpen(true);
      setMonthList(month);
    },
    [open]
  );

  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];

  useEffect(() => {
    setPreloader(true);
    //days
    const datelist = [];
    const month_list = [];
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
    }
    setChartDate(month_list);
    //inputs
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: datelist.toString(),
    };
    if (selectedEnvironment.toString() === "all") {
      setEnvironmentStatus(false);
    } else {
      setEnvironmentStatus(true);
    }
    setEnvironmentCount(selectedEnvironment.length);
    dispatch(listApplicationSpend({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        const applicationlist = data.newfunctionquery16.map(
          (v) => v.application
        );
        var application = new Set([...applicationlist]);
        application = [...application];

        var chartvalue = application.map((applicationname) => {
          return {
            name: applicationname,
            data: [],
          };
        });
        datelist.map((date) => {
          const environmentvmfiltered = data.newfunctionquery16.filter(
            (datas) => {
              return (
                new Date(datas.report_month).getMonth() + 1 ===
                new Date(date).getMonth() + 1
              );
            }
          );

          chartvalue.map((application_data) => {
            const application_spend = environmentvmfiltered.filter(
              (app_datas) => {
                return app_datas.application === application_data.name;
              }
            );
            var spend = 0;
            if (application_spend.length > 0) {
              spend = Math.round(application_spend[0].spend);
            }
            application_data.data.push(spend);
          });
          setChartValue(chartvalue);
        });
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloader(false);
      });
  }, [
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    selectedTopdate,
  ]);

  const series = chartvalue;
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 0,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    // colors: [
    //   "#E3818A",
    //   "#BFBFE4",
    //   "#11104E",
    //   "#FCAF04",
    //   "#7CC4E6",
    //   "#CEE05C",
    //   "#E64A19",
    //   "#00ACC1",
    //   "#757575",
    // ],
    colors: [
      "#34495E",
      "#11104E",
      "#F7CB15",
      "#7CC4E6",
      "#CC8EE2",
      "#97DB4F",
      "#3b2a22",
      "#00ACC1",
      "#757575",
    ],
    xaxis: {
      categories: chartdate,
      labels: {
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    tooltip: {
      y: {
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
    },
    responsive: [
      {
        breakpoint: 450,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div style={{ position: "relative" }}>
      <Spin spinning={preloader} wrapperClassName="costimize-loader">
        {open && (
          <TopApplicationChartPopup
            open={open}
            setOpen={setOpen}
            monthlist={monthlist}
            setMonthList={setMonthList}
          />
        )}
        <h1 className="spend-chart-title">
          Top 5 Application Spend{" "}
          {environmentstatus && (
            <>
              <Tooltip
                placement="bottom"
                title={selectedEnvironment.map((data, index) => (
                  <span>
                    {data}
                    <br></br>
                  </span>
                ))}
              >
                ({environmentcount})
              </Tooltip>
            </>
          )}
        </h1>
        {/* {chartvalue.length === 0 ? (
          <div className="chart-no-data"></div>
        ) : ( */}
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={200}
        />
        {/* )} */}

        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <a onClick={(e) => e.preventDefault()} className="chart-filter2">
            <Icon icon="material-symbols:filter-alt-outline-sharp" />
          </a>
        </Dropdown>
      </Spin>
    </div>
  );
};
export default memo(TopApplicationChart);
