import React, { useState } from "react";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { Row, Col } from "antd";
import { Icon } from "@iconify/react";
import awsicon from "../../../../assets/images/aws.svg";

import { numberFormat, capitalizeFirst } from "../../../custom_hook/CustomHook";
import AssetApplicationDB from "./AssetApplicationDB";

const CloudList = (props) => {

  return (
    <>
      {props.cloudList.map((cloudlist, index) => {
        const ran_num = Math.random();
        return (
          <ul className="cd-accordion__sub cd-accordion__sub--l2">
            <li className="cd-accordion__item cd-accordion__item--has-children">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={`sub-group-level-db${index}${ran_num}`}
                id={`sub-group-level-db${index}${ran_num}`}
                // defaultChecked
              />
              <label
                className="cd-accordion__label"
                htmlFor={`sub-group-level-db${index}${ran_num}`}
                // onClick={() => handleOnClick()}
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2 status={true} newclass="cloud-list-icon">
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-left-title cloud-list-title asset-spend-title-vs"
                      >
                        {cloudlist[0].toLowerCase() === "aws" ? (
                          <>
                            <img src={awsicon} /> AWS({cloudlist[1].count})
                          </>
                        ) : cloudlist[0].toLowerCase() === "azure" ? (
                          <>
                            <Icon
                              icon="vscode-icons:file-type-azure"
                              style={{ marginRight: "4px" }}
                            />{" "}
                            Azure({cloudlist[1].count})
                          </>
                        ) : cloudlist[0].toLowerCase() === "oracle" ? (
                          <>
                            <Icon
                              icon="simple-icons:oracle"
                              style={{ color: "#FF0000", marginRight: "4px" }}
                            />{" "}
                            Oracle({cloudlist[1].count})
                          </>
                        ) : (
                          <>
                            <Icon
                              icon="skill-icons:gcp-light"
                              style={{ marginRight: "4px" }}
                            />{" "}
                            GCP({cloudlist[1].count})
                          </>
                        )}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        ${numberFormat(cloudlist[1].spend)}
                        <Icon
                          className={`me-1 ${
                            cloudlist[1].spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom"
                          }`}
                          icon={
                            cloudlist[1].spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                    </Row>
                    <div className="asset-spend-border-bottom-vs"></div>
                  </ToggleButtonV2>
                </span>
              </label>
              
                <AssetApplicationDB applicationList={cloudlist[2][0]} />
             
            </li>
          </ul>
        );
      })}
    </>
  );
};

export default CloudList;
