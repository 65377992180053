import { Row, Col, Container } from "react-bootstrap";
import Capture from "../../assets/images/Capture.png";
import loginImg from "../../assets/images/login-image.png";
import signupInfo from "../../assets/images/signup-info.svg";
import yellowBg from "../../assets/images/yellow-bg-new.png";
import signup from "../../assets/images/signup.png";
import "./signgroup.css";
import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import home1 from "../../assets/images/home1.svg";
import home2 from "../../assets/images/login.svg";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Login from "../Login";
import Register from "../Register";
import { useDispatch, useSelector } from "react-redux";
import { enableRegisterFlag } from "../slice/userSlice";
import CustimizeFooter from "../dashboard-v2/CustimizeFooter";
import Header from "../header/Header";

const Signgroup = (props) => {
  const [basicActive, setBasicActive] = useState("tab1");
  const { isRegisterFlag } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleClose = (event) => {
    dispatch(enableRegisterFlag(false));
  };

  useEffect(() => {
    if (props.page_type === "signin") {
      setBasicActive("tab1");
    } else {
      setBasicActive("tab2");
    }
  }, [props]);
  const tabChange = () => {
    if (basicActive === "tab2") {
      setBasicActive("tab1");
    } else {
      setBasicActive("tab2");
    }
  };
  return (
    <>
      {isRegisterFlag ? (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully register user
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="login-bg">
        <div>
          <img src={loginImg} alt="left-img" className="left-img" />

          <div className="left-text">
            <p className="para-text-1">
              Making your multi cloud operation more
            </p>
            <p className="para-text-2">
              <span>visible, efficient and simple.</span>
            </p>
          </div>
        </div>
        <div>
          <img src={yellowBg} alt="yellow-bg" className="yellow-background" />
          <Container>
            <div className="plat-home-page">
              <div className="plat-home-logo">
                {/* <img height= "40px" width="160px" src={Capture} alt="Capture" /> */}
              </div>
              <Row>
                <Col lg="8" md="7">
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="d-flex justify-content-center align-items-start"
                    ></Col>
                    {/* <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <p className="homepage-content">
                                <span className='content-blue'> Cloud Shifu </span> is yours comprehensive platform that provides a 360-degree view of your infrastructure's assets, covering all aspects of cloud infrastructure management, including <span className='content-green'>SysOps</span>, <span className='content-maroon'>FinOps</span>, <span className='content-purple'>SecOps</span>, and <span className='content-blue2'>DevOps</span>.
                                </p>
                                <p className="homepage-content plat-margin-top-6">
                                With Cloud Shifu, you can manage your entire cloud infrastructure, including servers, networks, and applications, and gain complete control over your systems and servers.
                                </p>
                                <p className="homepage-content plat-margin-top-6">
                                Its <span className='content-green'>SysOps</span> capabilities offer comprehensive patch management and event management features, ensuring your systems are always up-to-date, secure, and running smoothly. 
                                </p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <img src={home2} alt="home2" className='home2-img'/>
                            </Col> */}
                  </Row>

                  {/* <div className='plat-sign-img-group'>
                            <div className='plat-signup-main-image'>
                                <img className='plat-signup-image' src={signup} />
                            </div>
                            <div className='plat-signup-main-image-info'>
                                <img  className='plat-signup-image-info' src={signupInfo} />
                            </div>
                        </div>  */}
                </Col>
                <Col lg="4" md="5" style={{ "z-index": "1" }}>
                  <div className="plat-sign-login-tap mt-2 pt-5">
                    {/* <MDBTabs className="mb-3 plat-sign-tabs">
                      <MDBTabsItem className="plat-sign-item">
                        <MDBTabsLink
                          className="plat-sign-link signin"
                          onClick={() => setBasicActive("tab1")}
                          active={basicActive === "tab1"}
                        >
                          Sign In
                        </MDBTabsLink>
                      </MDBTabsItem>
                      <MDBTabsItem className="plat-sign-item">
                        <MDBTabsLink
                          className="plat-sign-link signup"
                          onClick={() => setBasicActive("tab2")}
                          active={basicActive === "tab2"}
                        >
                          Sign Up
                        </MDBTabsLink>
                      </MDBTabsItem>
                    </MDBTabs> */}
                    <MDBTabsContent>
                      <MDBTabsPane show={basicActive === "tab1"}>
                        <Login onSelectTab={tabChange} />
                      </MDBTabsPane>
                      <MDBTabsPane show={basicActive === "tab2"}>
                        <Register onSelectTab={tabChange} />
                      </MDBTabsPane>
                    </MDBTabsContent>
                  </div>
                </Col>
              </Row>
              {/* <Row style={{marginTop:40}} >
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <img src={home1} alt="home1"/>
                    </Col>
                    <Col>
                        <p className="homepage-content">
                        Additionally, the platform's <span className='content-maroon'> FinOps </span> features enable you to monitor your financial operations, optimize your spending, and identify cost savings opportunities..
                        </p>
                        <p className="homepage-content plat-margin-top-6">
                        With <span className='content-purple'>SecOps</span> capabilities, you can track security events, detect and respond to threats, and maintain compliance with industry standards. 
                        </p>
                        <p className="homepage-content plat-margin-top-6">
                        Finally, Cloud Shifuo's <span className='content-blue2'>DevOps</span> tools help you automate and streamline your processes, enabling you to rapidly deploy and scale your applications with ease. 
                        </p>
                        <p className="homepage-content plat-margin-top-6">
                        With Cloud Shifu, you can confidently manage your cloud infrastructure from one central location, saving time and resources while achieving operational excellence, so that you can focus on growing your business and achieving your goals.
                        </p>
                    </Col>
                </Row> */}
            </div>
          </Container>
        </div>
      </div>
      <CustimizeFooter />
    </>
  );
};

export default Signgroup;
