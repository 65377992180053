import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Spin,
  message,
} from "antd";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import {
  getServiceRequestData,
  addServiceRequestTime,
  updateServiceRequestTime,
  deleteServiceRequestTime,
} from "../../../action/graphql/slm/SLM_reducers";
import { Icon } from "@iconify/react";
const ServiceRequestTable = (props) => {
  console.log(props)
  const tablerecords=props.tablerecords
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [serviceRequestTableData, setServiceRequestTableData] = useState([]);
  const [oldRecords, setOldRecords] = useState();
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(selectedTopdate);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const date = selectedMonth;
  useEffect(() => {
    dispatch(getServiceRequestData({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery27.map((datas) => {
          return {
            company: datas.company,
            completed_on_time: datas.completed_on_time,
            date: datas.month,
            request: datas.request,
            request_status: datas.request_status,
            sr_request_id: datas.sr_request_id,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => handleEditClick(datas)}
              />
            ),
            type: "data",
          };
        });
        console.log({ records });
        setServiceRequestTableData(records);
        setShowSpinner(false);
      });
  }, [updatestatus, selectedTopdate]);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const requestStatusOption = [
    {
      label: "Post completion",
      value: "post_completion",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
    {
      label: "Failed",
      value: "failed",
    },
  ];
  const columns = [
    {
      title: "Request",
      dataIndex: "request",
      key: "request",
    },
    {
      title: "Completed on Time",
      dataIndex: "completed_on_time",
      key: "completed_on_time",
    },
    {
      title: "Cancelled",
      dataIndex: "Cancelled",
      key: "request_status",
    },
    {
      title: "Request Status",
      dataIndex: "request_status",
      key: "request_status",
    },
    {
      title: "Request Status",
      dataIndex: "request_status",
      key: "request_status",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    // },
    // {
    //   title: " ",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (record, index) => {
    //     <>
    //       <Dropdown.ItemText
    //         className="plat-dropdown-item-text"
    //       onClick={() => handleEditClick(index)}
    //       >
    //         <span className="plat-box-item-title list-expand-left">Edit</span>
    //         <span className="plat-box-item-title list-expand-right">
    //           <Icon
    //             icon="material-symbols:edit-outline"
    //             class="table-add-records-icon"
    //           />
    //         </span>
    //       </Dropdown.ItemText>
    //     </>;
    //     return (
    //       <>
    //         <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
    //           <Dropdown.Toggle
    //             className="plat-three-dort plat-tap-menu plat-box-three-dort"
    //             id="dropdown-button-dark-example1"
    //             variant="secondary"
    //           >
    //             <Icon
    //               icon="material-symbols:edit-outline"
    //               class="plat-expandalt-icon-view"
    //             />
    //           </Dropdown.Toggle>
    //           {/* <Dropdown.Menu className="plat-box-menu-list more-details">
    //           <Dropdown.ItemText
    //           className="plat-dropdown-item-text"
    //           //  onClick={() => editInstanceDrawerStatus(index)}
    //         >
    //           <span className="plat-box-item-title list-expand-left">Edit</span>
    //           <span className="plat-box-item-title list-expand-right">
    //             <Icon
    //               icon="ph:dots-three-bold"
    //               class="table-add-records-icon"
    //             />
    //           </span>
    //         </Dropdown.ItemText>
    //           </Dropdown.Menu> */}
    //         </Dropdown>
    //       </>
    //     );
    //   },
    // },
  ];
  const tableProps = {};
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    const input = {
      request: values.request,
      company_name: companyName,
      completed_on_time: values.completed_on_time,
      request_status: values.request_status,
      report_month: selectedTopdate,
    };
    if (deletestatus) {
      const sr_request_id = oldRecords.sr_request_id;
      dispatch(updateServiceRequestTime({ input, sr_request_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record updated");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(addServiceRequestTime({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record added");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const handleAddClick = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
    const records = {
      request: "",
      completed_on_time: "",
      request_status: "",
    };
    form.setFieldsValue(records);
  };

  const handleEditClick = (data) => {
    setDrawerStatus(true);
    setDeleteStatus(true);
    setOldRecords(data);
    const records = {
      request: data.request,
      completed_on_time: data.completed_on_time,
      request_status: data.request_status,
    };
    form.setFieldsValue(records);
  };
  const rowDelete = () => {
    const sr_request_id = oldRecords.sr_request_id;
    dispatch(deleteServiceRequestTime({ sr_request_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        setDrawerStatus(false);
        setUpdateStatus(!updatestatus);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {/* <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title="Service Request"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="request" label="Request Type">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={[
                    {
                      value: "SR",
                      label: "SR",
                    },
                    {
                      value: "CR",
                      label: "CR",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="completed_on_time" label="Completed on Time">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="request_status" label="Request Status">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={requestStatusOption}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div onClick={() => handleAddClick()}>
        <button className="table-add-button float-right">
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
      </div> */}

      <Table
        {...tableProps}
        className="slm"
        style={{ overflow: "auto", height: "19.0rem" }}
        loading={{ indicator: <Spin />, spinning: showSpinner }}
        columns={columns}
        // dataSource={serviceRequestTableData}
        dataSoure={tablerecords}
        pagination={false}
      />
    </>
  );
};
export default ServiceRequestTable;
