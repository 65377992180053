import { memo, useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { listApplicationSpend } from "../../action/costimizeV2Active";

const TopApplicationChartPopup = (props) => {
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  //state
  const [chart, setChart] = useState({
    chartoption: {},
    chartvalue: [],
  });
  const [preloader, setPreloader] = useState(true);
  const handleCancel = () => {
    props.setOpen(false);
    props.setMonthList(0);
  };
  useEffect(() => {
    setPreloader(true);
    if (props.monthlist > 0) {
      let currentDate = new Date(selectedTopdate);
      let lastThreeMonthsDate = new Date(selectedTopdate);
      lastThreeMonthsDate.setMonth(
        currentDate.getMonth() - (props.monthlist - 1)
      );
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month_list = [];
      var datelist = [];
      for (let i = 0; i < props.monthlist; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        month_list.push(
          `${monthNamesShort[date.getMonth()]}-${(
            "" + date.getFullYear()
          ).substr(2)}`
        );
        datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
      }
      //inputs
      var apivalue = {
        environment: selectedEnvironment.toString(),
        account: selectedAccount.toString(),
        cloud: selectedCloud.toString(),
        application: selectedApplication.toString(),
        services: selectedservices.toString(),
        os: selectedos.toString(),
        infra: selectedinfra.toString(),
        db: selecteddb.toString(),
        date: datelist.toString(),
      };
      dispatch(listApplicationSpend({ companyName, apivalue }))
        .unwrap()
        .then(({ data }) => {
          const applicationlist = data.newfunctionquery16.map(
            (v) => v.application
          );
          var application = new Set([...applicationlist]);
          application = [...application];

          var chartvalue = application.map((applicationname) => {
            return {
              name: applicationname,
              data: [],
            };
          });
          datelist.map((date) => {
            const environmentvmfiltered = data.newfunctionquery16.filter(
              (datas) => {
                return (
                  new Date(datas.report_month).getMonth() + 1 ===
                  new Date(date).getMonth() + 1
                );
              }
            );

            chartvalue.map((application_data) => {
              const application_spend = environmentvmfiltered.filter(
                (app_datas) => {
                  return app_datas.application === application_data.name;
                }
              );
              var spend = 0;
              if (application_spend.length > 0) {
                spend = Math.round(application_spend[0].spend);
              }
              application_data.data.push(spend);
            });
          });

          setChart({
            chartoption: {
              chart: {
                type: "bar",
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  borderRadius: 0,
                },
              },
              stroke: {
                width: 1,
                colors: ["#fff"],
              },
              colors: [
                "#E3818A",
                "#BFBFE4",
                "#11104E",
                "#FCAF04",
                "#7CC4E6",
                "#CEE05C",
              ],
              xaxis: {
                categories: month_list,
                labels: {
                  formatter: (num) => {
                    return Math.abs(num) > 999
                      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                      : Math.sign(num) * Math.abs(num);
                  },
                },
              },
              yaxis: {
                title: {
                  text: undefined,
                },
              },
              tooltip: {
                y: {
                  formatter: (num) => {
                    return Math.abs(num) > 999
                      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                      : Math.sign(num) * Math.abs(num);
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                position: "right",
                horizontalAlign: "right",
                offsetX: 0,
                markers: {
                  radius: 12,
                },
                itemMargin: {
                  horizontal: 0,
                  vertical: 0,
                },
              },
              responsive: [
                {
                  breakpoint: 450,
                  options: {
                    legend: {
                      position: "bottom",
                      horizontalAlign: "left",
                    },
                  },
                },
              ],
            },
            chartvalue: chartvalue,
          });
          setPreloader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props]);

  return (
    <>
      <Modal
        title="Top 5 Application"
        open={props.open}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width="auto"
        maxWidth={800}
      >
        <Spin spinning={preloader} wrapperClassName="costimize-loader">
          <ReactApexChart
            options={chart.chartoption}
            series={chart.chartvalue}
            type="bar"
            height={300}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default memo(TopApplicationChartPopup);
