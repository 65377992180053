import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";

import { getK8Applications } from "../../../action/costimizeV2Active";
import { capitalizeFirst, numberFormat } from "../../../custom_hook/CustomHook";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import K8_ResourceName from "./k8_ResourceName";

function K8_Application(props) {
  console.log(props);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const dispatch = useDispatch();
  const [allapplicationlist, setAllApplicationList] = useState([]);
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var first_month = month_list[0];
    var second_month = month_list[1];
    var thired_month = month_list[2];

    var apivalue = {
      environment: props.environment,
      account: selectedAccount.toString(),
      cloud: props.cloud,
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    dispatch(
      getK8Applications({
        companyName,
        selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data.newfunctionquery38, "32");
        if (data.newfunctionquery38.length > 0) {
          const application = data.newfunctionquery38?.map(
            (v) => v.application
          );
          var applicationlist = new Set([...application]);
          applicationlist = [...applicationlist];
          const cloud_all_list = [];
          applicationlist.map((data_map) => {
            const cloud_data_list = data.newfunctionquery38?.filter((datas) => {
              return datas.application.toLowerCase() === data_map.toLowerCase();
            });
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list,
              first_month,
              second_month,
              thired_month
            );
            // console.log(return_environment);
            cloud_all_list.push(return_environment);
          });
          // console.log(cloud_all_list);
          setAllApplicationList(cloud_all_list);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce(
      (a, v) => (a = +a + +v.count),
      0
    );

    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",

      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      count: totalcount,
    };
  };
  const handleClick = () => {
    setIsRowClicked(!isRowClicked);
  };
  useEffect(() => {}, [isRowClicked]);
  const [isShowResourceName, setIsShowResourceName] = useState(false);

  const handleOnClick = () => {
    setIsShowResourceName(!isShowResourceName);
  };
  return (
    <div>
      <>
        {allapplicationlist.map((applicationlist, indexa) => {
          const ran_num_cloud = Math.random();
          return (
            <div className="p-3">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={"sub-group-level3-" + indexa + "" + ran_num_cloud}
                id={"sub-group-level3-" + indexa + "" + ran_num_cloud}
                // defaultChecked
              />
              <label
                className="cd-accordion__label"
                htmlFor={"sub-group-level3-" + indexa + "" + ran_num_cloud}
                onClick={handleOnClick}
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2
                    status={true}
                    newclass="application-list-icon-new"
                  >
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="asset-left-title app-list-title asset-spend-title-vs"
                      >
                        {capitalizeFirst(applicationlist.title)}(
                        {applicationlist.count})
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        ${numberFormat(applicationlist.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (applicationlist.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            applicationlist.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {applicationlist.name}
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {applicationlist.size}
                      </Col>
                    </Row>
                    <div className="asset-spend-border-bottom-vs"></div>
                  </ToggleButtonV2>
                </span>
              </label>
              {/* resorces list */}
              {console.log(applicationlist.title)}
              {console.log(props.cloud)}

              {isShowResourceName && (
                <K8_ResourceName
                  environment={props.environment}
                  cloud={props.cloud}
                  application={applicationlist.title}
                  resname={props.resname}
                />
              )}
            </div>
          );
        })}
      </>
    </div>
  );
}

export default K8_Application;
