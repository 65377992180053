import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "./client/dashbaordapploClient";
import client_new from "./client/dashboardapplonewClinet";
import { useNavigate } from "react-router-dom";
import { useErrorNavigation } from "../custom_hook/CustomHook";

import {
  ADD_SAVINGS_MODEL,
  GET_PAGINATION_COUNT,
  GET_TAGGING_DOWNLOAD_LIST,
  ADD_ANNUAL_BUDGET,
  DELETE_INVOICE_TABLE,
  LIST_ANNUAL_BUDGET,
  DELETE_ANNUAL_BUDGET,
  ADD_APPLICATION_BUDGET,
  UPDATED_APPLICATION_BUDGET,
  DELETE_APPLICATION_BUDGET,
  UPDATE_ANNUAL_BUDGET,
  LIST_APPLICATION_BUDGET,
  LIST_SAVINGS_MODEL,
  UPDATE_SAVINGS_MODEL,
  DELETE_SAVINGS_MODEL,
  LIST_INSTANCE_TRACKING,
  LIST_SAVINGS_REALIZATION_CHART,
  LIST_ASSET_MANAGED,
  LIST_INSTANCE_RUNTIME,
  CHART_INSTANCE_RUNTIME,
  LIST_APPLICATION_SPEND,
  UNDATE_INSTANCERUN_TIME,
  LIST_PLANNED_AND_ACTIVITY,
  ADD_PLANNED_AND_ACTIVITY,
  UPDATE_PLANNED_AND_ACTIVITY,
  DELETED_PLANNED_AND_ACTIVITY,
  ADD_RTO_RPO,
  LIST_RTO_RPO,
  UPDATE_RTO_RPO,
  DELETED_RTO_RPO,
  ADD_ACTION_ITEM,
  LIST_ACTION_ITEM,
  UPDATE_ACTION_ITEM,
  DELETE_ACTION_ITEM,
  ADD_PROJECT_STATUS,
  LIST_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
  DELETE_PROJECT_STATUS,
  ADD_KEY_ACTIVITES,
  LIST_KEY_ACTIVITES,
  UPDATE_KEY_ACTIVITES,
  DELETE_KEY_ACTIVITES,
  LIST_INVOICE_TABLE,
  UPDATE_INVOICE_TABLE,
  ADD_INVOICE_TABLE,
  LIST_INVOICE_DOCUMENT,
  LIST_CLIENT_INVOICE,
  DELETE_CLIENT_INVOICE,
  UPDATE_CLIENT_INVOICE,
  ADD_CLIENT_INVOICE,
} from "./graphql/Costimizev2DashboardGraphql";

export const getPaginationCount = createAsyncThunk(
  // action type string
  "tagging/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: GET_PAGINATION_COUNT,
        variables: {
          tagging_name: data.tagging_name,
          service_name: data.service_name,
          company_name: data.companyName,
          date: data.date,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTagginglist = createAsyncThunk(
  // action type string
  "tagging/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tagging_list = await client_new.mutate({
        mutation: GET_TAGGING_DOWNLOAD_LIST,
        variables: {
          tagging_name: data.tagging_name,
          service_name: data.service_name,
          company_name: data.companyName,
          date: data.date,
          page: data.page,
        },
      });
      return tagging_list;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const insertRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ADD_SAVINGS_MODEL,
        variables: {
          application: data.inputs.application,
          environment: data.inputs.environment,
          service: data.inputs.service,
          account: data.inputs.account,
          company_name: data.inputs.company_name,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realised_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: UPDATE_SAVINGS_MODEL,
        variables: {
          application: data.inputs.application,
          environment: data.inputs.environment,
          service: data.inputs.service,
          account: data.inputs.account,
          company_name: data.inputs.company_name,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realised_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const deleteSavings = await client_new.mutate({
        mutation: DELETE_SAVINGS_MODEL,
        variables: {
          companyName: data.companyName,
          deleteid: data.deleteid,
        },
      });
      return deleteSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_SAVINGS_MODEL,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listApplicationSpend = createAsyncThunk(
  // action type string
  "dashboard/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listapplication = await client_new.mutate({
        mutation: LIST_APPLICATION_SPEND,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listapplication;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateInstanceRuntime = createAsyncThunk(
  // action type string
  "dashboard/instanceruntime/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateinstance = await client_new.mutate({
        mutation: UNDATE_INSTANCERUN_TIME,
        variables: {
          application: data.inputs.application,
          company_name: data.inputs.company_name,
          environment: data.inputs.environment,
          planned_runtime: data.inputs.planned_runtime,
          planning_date: data.inputs.planning_date,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
        },
      });
      return updateinstance;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAnnualBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: UPDATE_ANNUAL_BUDGET,
        variables: {
          budget_amount: data.inputs.budget_amount,
          budget_month: data.inputs.budget_month,
          budget_year: data.inputs.budget_year,
          company_name: data.inputs.company_name,
          budget_id: data.budget_id,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addAnnualBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ADD_ANNUAL_BUDGET,
        variables: {
          budget_amount: data.inputs.budget_amount,
          budget_month: data.inputs.budget_month,
          budget_year: data.inputs.budget_year,
          company_name: data.inputs.company_name,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAnnualBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_ANNUAL_BUDGET,
        variables: {
          company_name: data.companyName,
          cloud: data.cloud,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAssetManaged = createAsyncThunk(
  // action type string
  "dashboard/assetmanaged/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_ASSET_MANAGED,
        variables: {
          account_name: data.account,
          application: data.application,
          cloud: data.cloud,
          company_name: data.compnay_name,
          environment: data.environment,
          month: data.date,
          os: data.os,
          selectedservices: data.selectedservices,
          selecteddb: data.selecteddb,
          selectedinfra: data.selectedinfra,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInstanceTracking = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_INSTANCE_TRACKING,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const chartInstanceRuntime = createAsyncThunk(
  // action type string
  "dashboard/instance/runtime/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const addSavings = await client_new.mutate({
        mutation: CHART_INSTANCE_RUNTIME,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
          resourcename: data.apivalue.resourcename,
          resourceid: data.apivalue.resourceid,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInstanceRuntime = createAsyncThunk(
  // action type string
  "dashboard/instance/runtime/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_INSTANCE_RUNTIME,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRealizationChart = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_SAVINGS_REALIZATION_CHART,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
          db: data.apivalue.db,
          infra: data.apivalue.infra,
          service: data.apivalue.service,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listApplicationBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_APPLICATION_BUDGET,
        variables: {
          company_name: data.input.company_name,
          account_name: data.input.account_name,
          application: data.input.application,
          budget_year: data.input.budget_year,
          cloud: data.input.cloud,
          environment: data.input.environment,
        },
      });
      return addSavings;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAnnualBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_budget = await client_new.mutate({
        mutation: DELETE_ANNUAL_BUDGET,
        variables: {
          budget_id: data.budget_id,
          compnay_name: data.company_name,
        },
      });
      return delete_budget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteApplicationBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/application/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_budget = await client_new.mutate({
        mutation: DELETE_APPLICATION_BUDGET,
        variables: {
          budget_id: data.budget_id,
          companyName: data.companyName,
        },
      });
      return delete_budget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addApplicationBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/application/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_budget = await client_new.mutate({
        mutation: ADD_APPLICATION_BUDGET,
        variables: {
          account_name: data.inputs.account,
          application: data.inputs.application,
          budget_amount: data.inputs.budget_amount,
          budget_month: data.inputs.budget_month,
          budget_year: data.inputs.budget_year,
          company_name: data.inputs.company_name,
          environment: data.inputs.environment,
        },
      });
      return delete_budget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateApplicationBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/application/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_budget = await client_new.mutate({
        mutation: UPDATED_APPLICATION_BUDGET,
        variables: {
          account_name: data.inputs.account,
          application: data.inputs.application,
          budget_amount: data.inputs.budget_amount,
          budget_month: data.inputs.budget_month,
          budget_year: data.inputs.budget_year,
          company_name: data.inputs.company_name,
          environment: data.inputs.environment,
          key: data.key,
        },
      });
      return delete_budget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listPlannedActivity = createAsyncThunk(
  // action type string
  "slm/plannedandactivity/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_budget = await client_new.mutate({
        mutation: LIST_PLANNED_AND_ACTIVITY,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return delete_budget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addPlannedActivity = createAsyncThunk(
  // action type string
  "slm/plannedandactivity/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const add_planned = await client_new.mutate({
        mutation: ADD_PLANNED_AND_ACTIVITY,
        variables: {
          accountability: data.input.accountability,
          company_name: data.input.company_name,
          usagedate: data.input.usagedate,
          report_month: data.input.report_month,
          next_target_date: data.input.next_target_date,
          pending_activity: data.input.pending_activity,
          priority: data.input.priority,
          project: data.input.project,
          project_phase: data.input.project_phase,
          remarks: data.input.remarks,
          sow_status: data.input.sow_status,
        },
      });
      return add_planned;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePlannedActivity = createAsyncThunk(
  // action type string
  "slm/plannedandactivity/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const update_planned = await client_new.mutate({
        mutation: UPDATE_PLANNED_AND_ACTIVITY,
        variables: {
          accountability: data.input.accountability,
          company_name: data.input.company_name,
          usagedate: data.input.usagedate,
          report_month: data.input.report_month,
          next_target_date: data.input.next_target_date,
          pending_activity: data.input.pending_activity,
          priority: data.input.priority,
          project: data.input.project,
          project_phase: data.input.project_phase,
          remarks: data.input.remarks,
          sow_status: data.input.sow_status,
          activity_id: data.activity_id,
        },
      });
      return update_planned;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletePlannedActivity = createAsyncThunk(
  // action type string
  "slm/plannedandactivity/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_planned = await client_new.mutate({
        mutation: DELETED_PLANNED_AND_ACTIVITY,
        variables: {
          activity_id: data.activity_id,
        },
      });
      return delete_planned;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addRtoRop = createAsyncThunk(
  // action type string
  "slm/rtorpo/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const add_rtorpo = await client_new.mutate({
        mutation: ADD_RTO_RPO,
        variables: {
          application: data.input.application,
          company_name: data.input.company_name,
          environment: data.input.environment,
          instance_name: data.input.instance_name,
          report_month: data.input.report_month,
          rpo: data.input.rpo,
          rto: data.input.rto,
        },
      });
      return add_rtorpo;
    } catch (error) {
      useErrorNavigation(error);
      // console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRtoRpo = createAsyncThunk(
  // action type string
  "slm/rtorpo/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_rtorpo = await client_new.mutate({
        mutation: LIST_RTO_RPO,
        variables: {
          date: data.date,
          company_name: data.companyName,
        },
      });
      return list_rtorpo;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const updateRtoRop = createAsyncThunk(
  // action type string
  "slm/rtorpo/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const update_rtorpo = await client_new.mutate({
        mutation: UPDATE_RTO_RPO,
        variables: {
          application: data.input.application,
          company_name: data.input.company_name,
          environment: data.input.environment,
          instance_name: data.input.instance_name,
          report_month: data.input.report_month,
          rpo: data.input.rpo,
          rto: data.input.rto,
          key: data.key,
        },
      });
      return update_rtorpo;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteRtoRpo = createAsyncThunk(
  // action type string
  "slm/rtorpo/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_rtorpo = await client_new.mutate({
        mutation: DELETED_RTO_RPO,
        variables: {
          key: data.key,
          company_name: data.companyName,
        },
      });
      return delete_rtorpo;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const AddcreateActionItem = createAsyncThunk(
  "slm/actionitem/add",
  async (data, { rejectWithValue }) => {
    try {
      const add_actionItem = await client_new.mutate({
        mutation: ADD_ACTION_ITEM,
        variables: {
          company_name: data.input.company_name,
          category: data.input.category,
          owner: data.input.owner,
          points_discussed: data.input.points_discussed,
          remarks: data.input.remarks,
          status: data.input.status,
          report_month: data.input.report_month,
          usagedate: data.input.usagedate,
        },
      });
      console.log("AddcreateActionItem");

      return add_actionItem;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listActionItem = createAsyncThunk(
  // action type string
  "slm/actionitem/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_actionItem = await client_new.mutate({
        mutation: LIST_ACTION_ITEM,
        variables: {
          date: data.date,
          company_name: data.companyName,
        },
      });
      console.log("AddcreateActionItemlist");

      return list_actionItem;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateaddActionitem = createAsyncThunk(
  // action type string
  "slm/actionitem/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateActionItem = await client_new.mutate({
        mutation: UPDATE_ACTION_ITEM,
        variables: {
          company_name: data.input.company_name,
          category: data.input.category,
          owner: data.input.owner,
          points_discussed: data.input.points_discussed,
          remarks: data.input.remarks,
          status: data.input.status,
          report_month: data.input.report_month,
          usagedate: data.input.usagedate,
          action_item_id: data.action_item_id,
        },
      });
      return updateActionItem;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//deleteActionItem
export const deleteActionItem = createAsyncThunk(
  // action type string
  "slm/actionitem/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_actionItem = await client_new.mutate({
        mutation: DELETE_ACTION_ITEM,
        variables: {
          action_item_id: data.action_item_id,
          company_name: data.companyName,
        },
      });
      return delete_actionItem;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const AddcreateProjectstatus = createAsyncThunk(
  "slm/projectstatus/add",
  async (data, { rejectWithValue }) => {
    try {
      const add_projectstatus = await client_new.mutate({
        mutation: ADD_PROJECT_STATUS,
        variables: {
          company_name: data.input.company_name,
          next_milestone: data.input.next_milestone,
          progress: data.input.progress,
          project_name: data.input.project_name,
          project_phase: data.input.project_phase,
          project_target_date: data.input.project_target_date,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          start_date: data.input.start_date,
          status: data.input.status,
          status_id: data.input.status_id,
        },
      });
      console.log("AddcreateProjectstatus");

      return add_projectstatus;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listProjectStatus = createAsyncThunk(
  // action type string
  "slm/projectstatus/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_projectstatus = await client_new.mutate({
        mutation: LIST_PROJECT_STATUS,
        variables: {
          date: data.date,
          company_name: data.companyName,
        },
      });
      console.log("listProjectStatus");

      return list_projectstatus;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateaddActionProjectstatus = createAsyncThunk(
  // action type string
  "slm/projectstatus/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateActionItem = await client_new.mutate({
        mutation: UPDATE_PROJECT_STATUS,
        variables: {
          company_name: data.input.company_name,
          next_milestone: data.input.next_milestone,
          progress: data.input.progress,
          project_name: data.input.project_name,
          project_phase: data.input.project_phase,
          project_target_date: data.input.project_target_date,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          start_date: data.input.start_date,
          status: data.input.status,
          status_id: data.status_id,
        },
      });
      return updateActionItem;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteProjectstatus = createAsyncThunk(
  // action type string
  "slm/projectstatus/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const delete_projectstatus = await client_new.mutate({
        mutation: DELETE_PROJECT_STATUS,
        variables: {
          status_id: data.status_id,
          company_name: data.companyName,
        },
      });
      return delete_projectstatus;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const AddKeyActivities = createAsyncThunk(
  "slm/keyactivites/add",
  async (data, { rejectWithValue }) => {
    try {
      const add_keyactivities = await client_new.mutate({
        mutation: ADD_KEY_ACTIVITES,
        variables: {
          company_name: data.input.company_name,
          action_token: data.input.action_token,
          application: data.input.application,
          category: data.input.category,
          cost_impact: data.input.cost_impact,
          description: data.input.description,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          severity: data.input.severity,
          key_id: data.input.key_id,
        },
      });
      console.log("AddcreateProjectstatus");

      return add_keyactivities;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listKeyActivies = createAsyncThunk(
  // action type string
  "slm/keyactivites/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_keyactivies = await client_new.mutate({
        mutation: LIST_KEY_ACTIVITES,
        variables: {
          date: data.date,
          company_name: data.companyName,
        },
      });
      console.log("listKeyActivies");

      return list_keyactivies;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateKeyActivities = createAsyncThunk(
  // action type string
  "slm/keyactivites/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateKeyActivities = await client_new.mutate({
        mutation: UPDATE_KEY_ACTIVITES,
        variables: {
          company_name: data.input.company_name,
          action_token: data.input.action_token,
          application: data.input.application,
          category: data.input.category,
          cost_impact: data.input.cost_impact,
          description: data.input.description,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          severity: data.input.severity,
          key_id: data.key_id,
        },
      });
      return updateKeyActivities;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//deleteKeyActivities
export const deleteKeyActivities = createAsyncThunk(
  // action type string
  "slm/keyactivites/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const deleteKeyActivities = await client_new.mutate({
        mutation: DELETE_KEY_ACTIVITES,
        variables: {
          key_id: data.key_id,
          company_name: data.companyName,
        },
      });
      return deleteKeyActivities;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInvoicetable_old = createAsyncThunk(
  "spp/invoicetable/list",

  async (data, { rejectWithValue }) => {
    try {
      const list_invoice = await client_new.mutate({
        mutation: LIST_INVOICE_TABLE,
        variables: {
          date: data.date,
          company_name: data.companyName,
        },
      });

      return list_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateInvoicetable_old = createAsyncThunk(
  "spp/invoicetable/update",

  async (data, { rejectWithValue }) => {
    try {
      const update_invoice = await client_new.mutate({
        mutation: UPDATE_INVOICE_TABLE,
        variables: {
          company_name: data.input.company_name,
          billing_currency: data.input.billing_currency,
          client: data.input.client,
          discount: data.input.discount,
          month: data.month,
          och_billing_cost: data.input.och_billing_cost,
          och_invoiceid: data.input.och_invoiceid,
          och_platform_cost: data.input.och_platform_cost,
          och_tax: data.input.och_tax,
          status: data.input.status,
          invoiceid: data.invoiceid,
          account_name: data.account_name,
        },
      });

      return update_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const AddInvoiceTable = createAsyncThunk(
  "slm/keyactivites/add",
  async (data, { rejectWithValue }) => {
    try {
      const add_invoice = await client_new.mutate({
        mutation: ADD_INVOICE_TABLE,
        variables: {
          //company_name: data.input.company_name,
          customer: data.input.client,
          report_month: data.input.report_month,
          billing_currency: data.input.billing_currency,
          billing_entity: data.input.billing_entity,
          cloud: data.input.cloud,
          cloud_invoice_no: data.input.cloud_invoice_no,
          discount: data.input.discount,
          invoice_no: data.input.och_invoiceid,
          och_tax: data.input.och_tax,
          platform_cost: data.input.och_platform_cost,
          resell_partner: data.input.resell_partner,
          status: data.input.status,
          total_invoice_amount: data.input.total_invoice_amount,
          total_usage_amount: data.input.total_usage_amount,
          usage: data.input.invoice_usage_cost,
          address_1: data.input.address_1,
          address_2: data.input.address_2,
          city: data.input.city,
          state: data.input.state,
          pincode: data.input.pincode,
          city: data.input.city,
          country: data.input.country,
        },
      });
      return add_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInvoicetable = createAsyncThunk(
  "spp/invoicetable/list",

  async (data, { rejectWithValue }) => {
    try {
      const list_invoice = await client_new.mutate({
        mutation: LIST_INVOICE_TABLE,
        variables: {
          date: data.date,
          //company_name: data.companyName,
        },
      });

      return list_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateInvoicetable = createAsyncThunk(
  "spp/invoicetable/update",

  async (data, { rejectWithValue }) => {
    try {
      const update_invoice = await client_new.mutate({
        mutation: UPDATE_INVOICE_TABLE,
        variables: {
          //company_name: data.input.company_name,
          customer: data.input.client,
          report_month: data.input.report_month,
          billing_currency: data.input.billing_currency,
          billing_entity: data.input.billing_entity,
          cloud: data.input.cloud,
          cloud_invoice_no: data.input.cloud_invoice_no,
          discount: data.input.discount,
          invoice_no: data.input.och_invoiceid,
          och_tax: data.input.och_tax,
          platform_cost: data.input.och_platform_cost,
          resell_partner: data.input.resell_partner,
          status: data.input.status,
          total_invoice_amount: data.input.total_invoice_amount,
          total_usage_amount: data.input.total_usage_amount,
          bill_id: data.bill_id,
          usage: data.input.invoice_usage_cost,
          address_1: data.input.address_1,
          address_2: data.input.address_2,
          city: data.input.city,
          state: data.input.state,
          pincode: data.input.pincode,
          city: data.input.city,
          country: data.input.country,
        },
      });

      return update_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteInvoice = createAsyncThunk(
  "spp/invoice/delete",

  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const deleteinvoicetable = await client_new.mutate({
        mutation: DELETE_INVOICE_TABLE,
        variables: {
          bill_id: data.bill_id,
          //company_name: data.companyName,
        },
      });
      console.log(data);
      return deleteinvoicetable;
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInvoicedocumet = createAsyncThunk(
  "spp/invoicetable/list",

  async (data, { rejectWithValue }) => {
    try {
      const list_invoice_doc = await client_new.mutate({
        mutation: LIST_INVOICE_DOCUMENT,
        variables: {
          bill_id: data.bill_id,
        },
      });

      return list_invoice_doc;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const Addbillingclient = createAsyncThunk(
  "slm/keyactivites/add",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const add_invoice = await client_new.mutate({
        mutation: ADD_CLIENT_INVOICE,
        variables: {
          customer: data.input.customer,
          billing_currency: data.input.billing_currency,
          due_date: data.input.due_date,
          invoiceid: data.input.invoiceid,
          issued_date: data.input.issued_date,
          month: data.input.billingperiod,
          platform_cost: data.input.platform_cost,
          status: data.input.status,
          tax_cost: data.input.tax_cost,
          total_invoice_amount: data.input.total_invoice_amount,
          usage_cost: data.input.usage_cost,
          vendor: data.input.vendor,
          billtype: data.input.billtype,
          s3_file_name: data.input.s3_file_name,
        },
      });
      return add_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const Updatebillingclient = createAsyncThunk(
  "spp/invoicetable/update",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const update_invoice = await client_new.mutate({
        mutation: UPDATE_CLIENT_INVOICE,
        variables: {
          customer: data.input.customer,
          billing_currency: data.input.billing_currency,
          due_date: data.input.due_date,
          invoiceid: data.input.invoiceid,
          issued_date: data.input.issued_date,
          month: data.input.billingperiod,
          platform_cost: data.input.platform_cost,
          status: data.input.status,
          tax_cost: data.input.tax_cost,
          total_invoice_amount: data.input.total_invoice_amount,
          usage_cost: data.input.usage_cost,
          vendor: data.input.vendor,
          billtype: data.input.billtype,
          bill_id: data.bill_id,
          s3_file_name: data.input.s3_file_name,
        },
      });
      console.log(data);

      return update_invoice;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//Deletebillingclient,
export const Deletebillingclient = createAsyncThunk(
  "spp/invoice/delete",

  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const deleteinvoicetable = await client_new.mutate({
        mutation: DELETE_CLIENT_INVOICE,
        variables: {
          bill_id: data.bill_id,
          customer: data.customer,
          //company_name: data.companyName,
        },
      });
      console.log(data);
      return deleteinvoicetable;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listbillingclient = createAsyncThunk(
  "sppclient/invoicetable/list",

  async (data, { rejectWithValue }) => {
    try {
      const list_invoice_client = await client_new.mutate({
        mutation: LIST_CLIENT_INVOICE,
        variables: {
          date: data.date,
          customer: data.customer,
        },
      });
      return list_invoice_client;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
