import React from "react";
import { Row, Col, Card } from "antd";
import ServiceRequestTable from "./ServiceRequestTable";
import ServiceRequestChart from "./ServiceRequestChart";

const ServiceRequest = (tablerecords) => {
  return (
    <>
      <div>
        <Row gutter={[16, 16]}>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Card>
              <ServiceRequestChart tablerecords={tablerecords} />
            </Card>
          </Col>
          <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24} className="m-2">
            <div>
              <h6> Resolution Time </h6>
            </div>
            <ServiceRequestTable tablerecords={tablerecords}/>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServiceRequest;
