import { Icon } from "@iconify/react";
import { Button, Col, Form, Input, Row, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import {
  createIdentifier,
  listEventIdentifier,
  updateIdentifier,
} from "../../../action/graphql/peems/PeemsAPI";
import { useDispatch } from "react-redux";

function EventIdentifierHome() {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const columns = [
    {
      key: 1,
      name: "event_id",
      title: "Event Id ",
      dataIndex: "rule_id",
      fixed: "left",
    },

    {
      key: 2,
      name: "key",
      title: "Key",
      dataIndex: "key",
      fixed: "left",
    },
    {
      key: 3,
      name: "contains",
      title: "Contains",
      dataIndex: "condition",
      fixed: "left",
    },
    {
      key: 4,
      name: "value",
      title: "Value",
      dataIndex: "value",
      fixed: "left",
    },

    {
      key: 6,
      name: "action",
      title: "",
      dataIndex: "action",
      fixed: "left",
      render: (text, record, index) => {
        console.log(record);
        return (
          <>
            <Icon
              icon="material-symbols:edit"
              onClick={() => handleEditClick(record)}
            />
          </>
        );
      },
    },
  ];
  const handleAddClick = () => {
    form.resetFields();
    setEditStatus(false);
    setDrawerStatus(true);
  };
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    setIsShowSpinner(true);
    console.log(values);
    if (editStatus) {
      dispatch(updateIdentifier({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          // let response = data.listPEEMSRules.items;
          // setTableData(response);
          setTableData([]);
          getEventIdentifier();
          setIsShowSpinner(false);
          setDrawerStatus(false);
          setEditStatus(false);
        });
    } else {
      dispatch(createIdentifier({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          // let response = data.listPEEMSRules.items;
          // setTableData(response);
          setTableData([]);

          getEventIdentifier();
          setIsShowSpinner(false);
          setDrawerStatus(false);
        });
    }
  };
  const rowDelete = () => {};
  const [tableData, setTableData] = useState([]);

  const [drawerStatus, setDrawerStatus] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState(false);
  const handleEditClick = (record) => {
    form.resetFields();
    console.log(record);
    setSelectedRow(record);
    setEditStatus(true);
    form.setFieldsValue(record);
    setDrawerStatus(true);
  };
  const [selectedRow, setSelectedRow] = useState("");
  const getEventIdentifier = () => {
    dispatch(listEventIdentifier({ companyName }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let response = data.listPEEMSRules.items;
        setTableData(response);
        setIsShowSpinner(false);
      });
  };
  useEffect(() => {
    getEventIdentifier();
  }, []);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title=" Event Identifier"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="rule_id" label="Event Id">
                <Input
                  style={{
                    width: "100%",
                  }}
                  disabled={editStatus}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="key" label="Key">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="condition" label="Condition">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={[
                    {
                      label: "contains",
                      value: "contains",
                    },
                    {
                      label: "equals",
                      value: "equals",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="value" label="Value">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div onClick={() => handleAddClick()}>
        <button className="table-add-button float-right">
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
      </div>
      <Table
        className="peems"
        columns={columns}
        pagination={true}
        loading={{ indicator: <Spin />, spinning: isShowSpinner }}
        dataSource={tableData}
      />
    </>
  );
}

export default EventIdentifierHome;
