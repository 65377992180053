import Options from "./Options";

const OptionList = (props) => {
  const botName = "Cost Whisperer";
  const options = [
    {
      name: "Give me top 3 application by its cost",
      handler: props.actionProvider.handleCustom,
      id: 1,
      botName: botName,
    },
    {
      name: "Give me the operating systems for Amazon EC2",
      handler: props.actionProvider.handleCustom,
      id: 2,
      botName: botName,
    },
    // {
    //   name: "What was the sum of cost for Amazon EC2 as productcode and instance category in billing period of June 2023",
    //   handler: props.actionProvider.handleCustom,
    //   id: 3,
    //   botName: botName,
    // },
    {
      name: "What are the total cost of productcode Amazon EC2 by its categories and sort by highest to lowest",
      handler: props.actionProvider.handleCustom,
      id: 4,
      botName: botName,
    },
  ];
  return <Options options={options} title="Options" {...props} />;
};

export default OptionList;
