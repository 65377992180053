import { Icon } from "@iconify/react";
import { Button, Col, Form, Input, Row, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import {
  createIdentifier,
  listRuleIdentifier,
  updateIdentifier,
} from "../../../action/graphql/peems/PeemsAPI";
import { useDispatch } from "react-redux";
import TextArea from "antd/es/input/TextArea";

function RuleIdentifierHome() {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const handleEditClick = (record) => {
    form.resetFields();
    console.log(record);
    setSelectedRow(record);
    setEditStatus(true);
    form.setFieldsValue(record);
    setDrawerStatus(true);
  };
  const columns = [
    {
      key: 1,
      name: "rule_id",
      title: "Rule Id ",
      dataIndex: "rule_id",
      fixed: "left",
    },

    {
      key: 2,
      name: "key name",
      title: "Key Name",
      dataIndex: "key",
      fixed: "left",
    },
    {
      key: 3,
      name: "contains",
      title: "Contains",
      dataIndex: "condition",
      fixed: "left",
    },
    {
      key: 4,
      name: "value",
      title: "Value",
      dataIndex: "value",
      fixed: "left",
    },
    {
      key: 4,
      name: "action",
      title: "Action",
      dataIndex: "action",
      fixed: "left",
    },
    {
      key: 4,
      name: "to_Address",
      title: "To Address",
      dataIndex: "to_Address",
      fixed: "left",
    },
    {
      key: 4,
      name: "subject",
      title: "Subject",
      dataIndex: "subject",
      fixed: "left",
    },
    {
      key: 4,
      name: "contact_number",
      title: "Contact Number",
      dataIndex: "contact_number",
      fixed: "left",
    },
    {
      key: 7,
      name: "action",
      title: "",
      dataIndex: "action",
      fixed: "left",
      render: (text, record, index) => {
        console.log(record);
        return (
          <>
            <Icon
              icon="material-symbols:edit"
              onClick={() => handleEditClick(record)}
            />
          </>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const handleSubmit = (values) => {
    setIsShowSpinner(true);
    console.log(values);
    if (editStatus) {
      dispatch(updateIdentifier({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          // let response = data.listPEEMSRules.items;
          // setTableData(response);
          setTableData([]);
          getRuleIdentifier();
          setIsShowSpinner(false);
          setDrawerStatus(false);
          setEditStatus(false);
        });
    } else {
      dispatch(createIdentifier({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          // let response = data.listPEEMSRules.items;
          // setTableData(response);
          setTableData([]);

          getRuleIdentifier();
          setIsShowSpinner(false);
          setDrawerStatus(false);
        });
    }
  };
  const getRuleIdentifier = () => {
    dispatch(listRuleIdentifier({ companyName }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let response = data.listPEEMSRules.items;
        setTableData(response);
        setIsShowSpinner(false);
      });
  };
  useEffect(() => {
    getRuleIdentifier();
  }, []);
  const handleAddClick = () => {
    form.resetFields();
    setEditStatus(false);
    setDrawerStatus(true);
  };
  const [form] = Form.useForm();
  const rowDelete = () => {};
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const [isShowContact, setIsShowContact] = useState(false);
  const [isShowMail, setIsShowMail] = useState(false);

  const handleActionChange = (e) => {
    console.log(e);
    if (e == "sms") {
      setIsShowMail(false);
      setIsShowContact(true);
    } else if (e == "email") {
      setIsShowContact(false);
      setIsShowMail(true);
    } else {
      setIsShowContact(false);
      setIsShowMail(false);
    }
  };
  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title=" Rule Identifier"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="rule_id" label="Rule Id">
                <Input
                  style={{
                    width: "100%",
                  }}
                  disabled={editStatus}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="key" label="Key">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="condition" label="Condition">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={[
                    {
                      label: "contains",
                      value: "contains",
                    },
                    {
                      label: "equals",
                      value: "equals",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="value" label="Value">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="action" label="Action">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  onChange={handleActionChange}
                  placeholder="Select"
                  options={[
                    {
                      label: "SMS",
                      value: "sms",
                    },
                    {
                      label: "Email",
                      value: "email",
                    },
                    {
                      label: "Do Nothing",
                      value: "do_nothing",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {isShowContact && (
            <>
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="contact_number" label="Contact Number">
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {isShowMail && (
            <>
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="to_Address" label="To Address">
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="cc_name" label="CC">
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="subject" label="Subject">
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="body" label="Body">
                    <TextArea
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div onClick={() => handleAddClick()}>
        <button className="table-add-button float-right">
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
      </div>
      <Table
        className="peems"
        columns={columns}
        pagination={true}
        loading={{ indicator: <Spin />, spinning: isShowSpinner }}
        dataSource={tableData}
      />
    </>
  );
}

export default RuleIdentifierHome;
