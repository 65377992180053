import React, { useEffect, useState } from "react";
import PeemsStatusCards from "../PeemsStatusCards";
import ReactApexChart from "react-apexcharts";
import { Spin, Table } from "antd";
import PeemsJobsSchedule from "../PeemsOverview/PeemsJobsSchedule";
import { useDispatch, useSelector } from "react-redux";
import { listChart, listReport } from "../../action/graphql/peems/PeemsAPI";
import moment from "moment";

function PeemsReport() {
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const columns = [
    Table.EXPAND_COLUMN,

    {
      key: 1,
      name: "scheduled_date",
      title: "Scheduled Date",
      dataIndex: "schedule_date",
      fixed: "left",
      width: 200,
    },

    {
      key: 2,
      name: "job_name",
      title: "Job Name",
      dataIndex: "event_identifier",
      fixed: "left",
      width: 300,
    },

    {
      key: 6,
      name: "Completed",
      title: "Completed Jobs",
      dataIndex: "completed_count",
      fixed: "left",
      width: 200,
    },

    {
      key: 7,
      name: "Not Triggered Jobs",
      title: "Not Triggered Jobs",
      dataIndex: "not_triggered_count",
      fixed: "left",
      width: 200,
    },
    {
      key: 7,
      name: "Failed Jobs",
      title: "Failed Jobs",
      dataIndex: "failed_count",
      fixed: "left",
      width: 150,
    },
  ];
  const [yAxisData, setYAxisData] = useState({
    completedCount: [],
    failedCount: [],
    notTrigerredCount: [],
  });

  const dispatch = useDispatch();
  const { selectedTopdate } = useSelector((state) => state.peems);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [endDate, setEndDate] = useState();
  useEffect(() => {
    setIsShowSpinner(true);
    getReportDetails();
  }, [selectedTopdate, isShowSpinner]);
  const [statusCounts, setStatusCounts] = useState({
    successJobs: [],
    failureJobs: [],
    totalJobs: [],
    unTrigerredJobs: [],
    scheduleJobs: [],
  });

  const [responseData, setResponseData] = useState([]);
  const [data, setData] = useState([]);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  const [xAxisData, setXAxisData] = useState([]);
  const getReportDetails = () => {
    setIsShowSpinner(true);
    var dt = new Date();
    let currentDate = new Date(selectedTopdate);
    let monthEndDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    let formattedDate = moment(monthEndDate).format("YYYY/MM/DD");
    setEndDate(formattedDate);
    dispatch(listReport({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.getjobcount_athena_monthly);
        response = response.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });

        setResponseData(response);
        setData(response);
        let succssCount,
          unTrigerredCount,
          failedJobsCount,
          scheduledJobsCount = [];

        response.map((item, index) => {
          return {};
        });
      });
    let no_Of_Days = getDaysInMonth(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );

    let xAxisArr = [];
    var completed__Count = [];
    var failed__Count = [];
    var unTrigerred__Count = [];
    for (var i = 1; i <= no_Of_Days; i++) {
      if (i < 10) {
        xAxisArr.push("0" + i);
      } else {
        xAxisArr.push(i.toString());
      }
      completed__Count.push(0);
      failed__Count.push(0);
      unTrigerred__Count.push(0);
    }
    setXAxisData(xAxisArr);
    setYAxisData({
      completedCount: completed__Count,
      failedCount: failed__Count,
      notTrigerredCount: unTrigerred__Count,
    });
    dispatch(listChart({ companyName, selectedTopdate, formattedDate }))
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.getcount_athena_monthly);

        response.forEach((element, index) => {
          if (xAxisArr.includes(element.day)) {
            if (Object.keys(element)[1] == "completed_count") {
              completed__Count[parseInt(element.day)] = element.completed_count;
            }
            if (Object.keys(element)[2] == "failed_count") {
              failed__Count[parseInt(element.day)] = element.failed_count;
            }
            if (Object.keys(element)[3] == "not_triggered_count") {
              unTrigerred__Count[parseInt(element.day)] =
                element.not_triggered_count;
            }
          }
        });
        let successCount = 0;
        let failedCount = 0;
        let notTrigerredCount = 0;
        response.forEach((element, index) => {
          successCount = successCount + parseInt(element.completed_count);
          failedCount = failedCount + parseInt(element.failed_count);
          notTrigerredCount =
            notTrigerredCount + parseInt(element.not_triggered_count);
        });
        let totalJobCount = successCount + failedCount + notTrigerredCount;
        setStatusCounts({
          successJobs: successCount,
          totalJobs: totalJobCount,
          unTrigerredJobs: notTrigerredCount,
          failureJobs: failedCount,
        });
        setIsShowSpinner(false);
      });
  };
  useEffect(() => {}, [yAxisData]);
  const series = [
    {
      name: "Not Trigerred",
      data: yAxisData.notTrigerredCount,
    },
    {
      name: "Success Jobs",
      data: yAxisData.completedCount,
    },
    {
      name: "Failed Jobs",
      data: yAxisData.failedCount,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: xAxisData,
      labels: {
        show: true,
        rotate: -70,
        trim: true,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: "bottom",
      offsetX: 100,
      offsetY: 0,
    },

    colors: ["#FF1A2F", "#3D9D1B", "#FAE741"],
  };
  const getStatusCounts = (data, key) => {
    let successJobs = data.filter(function (item) {
      return item.status == key;
    });
    return successJobs;
  };

  const uniqByKeepLast = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };
  return (
    <>
      <div className="Peems-overview">
        <div className="d-flex job-cards justify-content-between">
          <PeemsStatusCards
            statusCounts={statusCounts && statusCounts}
            isShowSpinner={isShowSpinner}
            type={"report"}
          />
        </div>
      </div>
      <br />
      <div className="peems-graph">
        {isShowSpinner ? (
          <Spin />
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={400}
          />
        )}
      </div>
      <div className="peems-report-table">
        <Table
          className="peems"
          columns={columns}
          pagination={true}
          loading={{ indicator: <Spin />, spinning: isShowSpinner }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <>
                  <PeemsJobsSchedule
                    event_identifier={record.event_identifier}
                    startDate={record.schedule_date}
                    type="report"
                  />
                </>
              );
            },
          }}
          dataSource={data}
        />
      </div>
    </>
  );
}

export default PeemsReport;
