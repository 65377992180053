import QuickView from "../QuickView";
import Tapmenu from "../Tapmenu";
import { useEffect, useState } from "react";
import { Table } from "antd";
import AddInstanceRuntime from "./AddInstanceRuntime";
import TrackingRuntime from "./TrackingRuntime";
import Dropdown from "react-bootstrap/Dropdown";
import CustimizeFooter from "../CustimizeFooter";
import InstanceRuntimeChart from "./InstanceRuntimeChart";
import { useDispatch, useSelector } from "react-redux";
import {
  listInstanceTracking,
  listInstanceRuntime,
} from "../../action/costimizev2DashboardAction";
import { Tooltip } from "antd";
import { Icon } from "@iconify/react";

const InstanceRuntime = () => {
  const [trackingrecords, setTrackingRecords] = useState([]);
  const [runtimerecords, setRuntimeRecords] = useState([]);
  const [addrecordsstatus, addRecordsStatus] = useState(false);
  const [instancedrawerstatus, setInstanceDrawerStatus] = useState(false);
  const [trackingdrawerstatus, setTrackingDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [instancerecords, setInstanceRecords] = useState();
  const [monthdaycount, setMonthDayCount] = useState([]);
  const [datachange, setDatachange] = useState(false);
  const dispatch = useDispatch();
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
  } = useSelector((state) => state.constimize);
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const columns = [
    {
      title: "Month/Year",
      dataIndex: "date",
      key: "date",
      align:"left",
      sorter: (a, b) => (a.date > b.date ? -1 : 1),
      render: (record, index) => {
        return `${monthNamesShort[new Date(record).getMonth()]}/${new Date(
          record
        ).getFullYear()}`;
      },
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align:"left",
      sorter: (a, b) => (a.application > b.application ? -1 : 1),
    },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      align:"left",
      sorter: (a, b) => (a.environment > b.environment ? -1 : 1),
    },
    {
      title: "Resource ID",
      dataIndex: "resourceid",
      key: "resourceid",
      align:"right",
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
      key: "instance_name",
      align:"left",
      sorter: (a, b) => (a.instance_name > b.instance_name ? -1 : 1),
      render: (record, index) => {
        if (record.length > 50) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 50)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Planned Runtime",
      dataIndex: "planned_runtime",
      key: "planned_runtime",
      align:"right",
      sorter: (a, b) => (a.planned_runtime > b.planned_runtime ? -1 : 1),
      render: (record) => {
        if (record) {
          return `${record} hr`;
        } else {
          return 0;
        }
      },
    },
    {
      title: "Actual Runtime",
      dataIndex: "actual_runtime",
      key: "actual_runtime",
      align:"right",
      sorter: (a, b) => (a.actual_runtime > b.actual_runtime ? -1 : 1),
      render: (record) => {
        return `${Math.round(record)} hr`;
      },
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      render: (record, index) => {
        var editscreen;
        var addscreen;
        editscreen = (
          <Dropdown.ItemText
            className="plat-dropdown-item-text"
            onClick={() => editInstanceDrawerStatus(index)}
          >
            <span className="plat-box-item-title list-expand-left">Edit</span>
            <span className="plat-box-item-title list-expand-right">
              <Icon
                icon="material-symbols:edit-outline"
                class="table-add-records-icon"
              />
            </span>
          </Dropdown.ItemText>
        );
        return (
          <>
            <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
              <Dropdown.Toggle
                className="plat-three-dort plat-tap-menu plat-box-three-dort"
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <Icon
                  icon="ph:dots-three-bold"
                  class="plat-expandalt-icon-view"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="plat-box-menu-list more-details">
                {editscreen}
                {addscreen}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const tableProps = {};

  const history_columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => (a.date > b.date ? -1 : 1),
      render: (record, index) => {
        return `${monthNamesShort[new Date(record).getMonth()]}/${new Date(
          record
        ).getFullYear()}`;
      },
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      sorter: (a, b) => (a.application > b.application ? -1 : 1),
    },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      sorter: (a, b) => (a.environment > b.environment ? -1 : 1),
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
      key: "instance_name",
      sorter: (a, b) => (a.instance_name > b.instance_name ? -1 : 1),
      render: (record, index) => {
        if (record) {
          if (record.length > 80) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 80)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        }
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.status > b.status ? -1 : 1),
      render: (record, index) => {
        return record.toUpperCase();
      },
    },
  ];

  const changeInstancesDrawerStatus = () => {
    setInstanceDrawerStatus(false);
  };
  const changeTrackingDrawerStatus = () => {
    setTrackingDrawerStatus(false);
  };

  const editInstanceDrawerStatus = (data) => {
    setInstanceDrawerStatus(true);
    setDeleteStatus(true);
    setInstanceRecords(data);
  };
  const addInstanceDrawerStatus = () => {
    setInstanceDrawerStatus(true);
    setDeleteStatus(false);
    setInstanceRecords();
  };

  useEffect(() => {
    //days count
    const today = new Date(selectedTopdate);
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const currentDateCount = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const day_list = [];
    for (let index = 0; index < currentDateCount; index++) {
      day_list.push(`Day ${+index + +1}`);
    }
    setMonthDayCount(day_list);

    //instance tracking
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
    };

    console.log(apivalue);

    //instance runtime
    dispatch(listInstanceRuntime({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        const list = data.newfunctionquery12.map((list_data, index) => {
          return {
            key: index,
            application: list_data.application,
            environment: list_data.environment,
            instance_name: list_data.resourcename,
            planned_runtime: list_data.planned_runtime,
            actual_runtime: list_data.actual_runtime,
            date: list_data.usagestartdate,
            fulldate: list_data.report_month,
            resourceid: list_data.resourceid,
          };
        });
        setRuntimeRecords(list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
    datachange,
  ]);

  useState(() => {
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
    };

    dispatch(listInstanceTracking({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        const list = data.newfunctionquery15.map((list_data, index) => {
          var status = list_data.status;
          if (list_data.status === "CREATED") {
            status = "LAUNCHED";
          }
          return {
            key: index,
            application: list_data.application,
            environment: list_data.environment,
            instance_name: list_data.resourcename,
            date: list_data.report_month,
            status: status,
            changes_occured: list_data.changes_occured,
            region: list_data.region,
            root_device_type: list_data.root_device_type,
            instance_type: list_data.instance_type,
            ebs_volume: list_data.ebs_volume,
          };
        });
        console.log(list);
        setTrackingRecords(list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
  ]);

  return (
    <>
      <AddInstanceRuntime
        instancedrawerstatus={instancedrawerstatus}
        changeInstancesDrawerStatus={changeInstancesDrawerStatus}
        setInstanceDrawerStatus={setInstanceDrawerStatus}
        deletestatus={deletestatus}
        instancerecords={instancerecords}
        setDatachange={setDatachange}
        datachange={datachange}
      />

      <TrackingRuntime
        trackingdrawerstatus={trackingdrawerstatus}
        changeTrackingDrawerStatus={changeTrackingDrawerStatus}
        setTrackingDrawerStatus={setTrackingDrawerStatus}
      />
      <div className="plat-dashboard-body">
        <div className="plat-dashboard-quickview">
          <QuickView />
        </div>

        <div className="plat-dashboard-tabs">
          <Tapmenu
            excalname="savings models"
            type="savingsmodel"
            osstatus={false}
            dbstatus={false}
            title="Instance Overview"
          />

          {/* <div className='plat-main-title'>
                        <h1 className='top-Plat-quick-title'>Instance Runtime cognito</h1> 
                    </div> */}
          <div className="budget-allocated-table">
            <h1 className="plat-table-title">Instance Runtime</h1>
            <div className="table-width">
              <div className="table-planned-actual">
                <Table
                  {...tableProps}
                  className="savings_new_table"
                  columns={columns}
                  expandable={{
                    expandedRowRender: (record) => (
                      <InstanceRuntimeChart
                        record={record}
                        monthdaycount={monthdaycount}
                      />
                    ),
                  }}
                  dataSource={runtimerecords}
                  pagination={true}
                  rowClassName={(record, index) =>
                    record.type === "footer" ? "budget-table-footer" : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="budget-allocated-table">
            <h1 className="plat-table-title">Instance Tracking</h1>
            <div className="table-width">
              <div className="table-planned-actual">
                <Table
                  {...tableProps}
                  className="savings_new_table"
                  columns={history_columns}
                  expandable={{
                    expandedRowRender: (record) => (
                      <>
                        <div className="instance_text ">
                          {record && record.changes_occured && (
                            <li>Changes_occured : {record.changes_occured}</li>
                          )}

                          {record && record.region && (
                            <li>Region:{record.region}</li>
                          )}

                          {record && record.root_device_type && (
                            <li>Device Type : {record.root_device_type}</li>
                          )}

                          {record && record.instance_type && (
                            <li>Instance Type : {record.instance_type}</li>
                          )}

                          {record && record.ebs_volume && (
                            <li>EBS Volume : {record.ebs_volume}</li>
                          )}
                        </div>
                      </>
                    ),
                  }}
                  dataSource={trackingrecords}
                  pagination={true}
                  showSizeChanger={false}
                  rowClassName={(record, index) => {
                    if (record.status === "LAUNCHED") {
                      return "added-row";
                    } else if (record.status === "running") {
                      return "added-row";
                    } else if (record.status === "stopped") {
                      return "deleted-row";
                    } else if (record.status === "deletted") {
                      return "deleted-row";
                    } else if (record.status === "modified") {
                      return "modified-row";
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustimizeFooter />
    </>
  );
};

export default InstanceRuntime;
