import React from "react";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Collapse, Row } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { capitalizeFirst, numberFormat } from "../../../custom_hook/CustomHook";
import AssetSpendListCloud from "../AssetSpendListCloud";
import K8_SpendCloud from './k8_SpendCloud';
const K8_EnvironmentList = (props) => {
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [allenvironmentlist, setAllEnvironmentList] = useState([]);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [openstatus, setOpenStatus] = useState(false);
  useEffect(() => {
    if (props.allenvironmentlist?.length > 0) {
      let currentDate = new Date(selectedTopdate);
      let lastThreeMonthsDate = new Date(selectedTopdate);
      lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
      var month_list = [];
      for (let i = 0; i < 3; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      }

      var first_month = month_list[0];
      var second_month = month_list[1];
      var thired_month = month_list[2];

      const enviroment = props.allenvironmentlist?.map((v) => v.environment);
      var enviromentlist = new Set([...enviroment]);
      enviromentlist = [...enviromentlist];
      //environment list
      const environment_all_list = [];
      enviromentlist.map((data_map) => {
        const environment_data_list = props.allenvironmentlist?.filter(
          (datas) => {
            return datas.environment.toLowerCase() === data_map.toLowerCase();
          }
        );
        const return_environment = getEnvironmentList(
          data_map,
          environment_data_list,
          first_month,
          second_month,
          thired_month,
        );
        environment_all_list.push(return_environment);
        setAllEnvironmentList(environment_all_list);
        // console.log(environment_all_list)
      });
    }
  }, [props]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce(
      (a, v) => (a = +a + +v.count),
      0
    );


    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );

    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      count: totalcount,
    };
  };
  //   console.log(allenvironmentlist);
  var titles = allenvironmentlist.map((env) => env.title);
  // console.log(titles)
  var cloud = "all";
  var application = "all";
  var resname = "all";

  const handleClick = () => {
    setOpenStatus(!openstatus);
    setIsRowClicked(!isRowClicked);
  };
  useEffect(() => {}, [isRowClicked]);
  const [isShowCloud, setIsShowCloud] = useState(false);

  const handleOnClick = () => {
    setIsShowCloud(!isShowCloud);
  };
  return (
    <div>
      <>
        {allenvironmentlist.map((environmentlist, index) => {
          return (
            <>
              <div className="p-3">
                <input
                  className="cd-accordion__input"
                  type="checkbox"
                  id={"sub-group-k8" + index}
                  // defaultChecked
                />
                <label
                  className="cd-accordion__label"
                  htmlFor={"sub-group-k8" + index}
                  onClick={() => handleOnClick()}
                >
                  <span className="accordion-full-width">
                    <ToggleButtonV2
                      status={true}
                      newclass="environment-list-icon"
                    >
                      <Row className="plat-table-assets-spend-thead">
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className=" asset-left-title environment-list-title asset-spend-title-vs"
                        >
                          {capitalizeFirst(environmentlist.title)}(
                          {environmentlist.count})
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-spend-values-vs justify-content-center"
                        >
                          ${numberFormat(environmentlist.spend)}
                          <Icon
                            className={
                              "me-1 " +
                              (environmentlist.spend > 0
                                ? "text-danger-icon-custom"
                                : "text-success-icon-custom")
                            }
                            icon={
                              environmentlist.spend > 0
                                ? "material-symbols:arrow-drop-up"
                                : "material-symbols:arrow-drop-down"
                            }
                            style={{ fontSize: "20px" }}
                          />
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-spend-values-vs justify-content-center"
                        >
                          {environmentlist.name}
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-spend-values-vs plat-chart-center"
                        >
                          $ {numberFormat(environmentlist.savings_opportunity)}
                        </Col>
                      </Row>
                    </ToggleButtonV2>
                  </span>
                </label>
                {isShowCloud ? (
                  <K8_SpendCloud
                    environmentdetails={environmentlist}
                    cloud={cloud}
                    application={application}
                    resname={resname}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          );
        })}
      </>
    </div>
  );
};

export default memo(K8_EnvironmentList);
