import { memo, useEffect, useState } from "react";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import MainHead from "../layout/MainHead";
import { Col, Row, Card, Spin } from "antd";
import { Icon } from "@iconify/react";
import RealisedChart from "./RealisedChart";
import SavingsRealizedChart from "./SavingsRealizedChart";
import { Tabs } from "antd";
import UnrealizedLayout from "./UnrealizedLayout";
import RealizedLayout from "./RealizedLayout";
import { useDispatch, useSelector } from "react-redux";
import { listRecommendation } from "../../action/costimizeV2Active";
import { numberFormat } from "../../custom_hook/CustomHook";

const SavingsModelLayout = () => {
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();

  //state
  const [toprecords, setTopRecords] = useState({
    potential_savings: 0,
    realised_savings: 0,
    pending_action: "0/0",
    color_code: "#000",
  });
  const [recordsudpate, setRecordsUpdate] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [rolestatus, seRoleStatus] = useState(true);

  //loader
  const [lsavingsstatus, setLSavingsStatus] = useState(true);

  useEffect(() => {
    //role
    const role = JSON.parse(
      localStorage.getItem("CognitoIdentityServiceProvider.role")
    );
    if (role) {
      if (role[0]["savings_model"] === "Read") {
        seRoleStatus(false);
      }
    }

    setLSavingsStatus(true);
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: selectedTopdate,
    };
    dispatch(listRecommendation({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const shortdata = data.newfunctionquery10.sort((a, b) =>
          a.account_names > b.account_names
            ? 1
            : b.account_names > a.account_names
            ? -1
            : 0
        );
        // console.log(shortdata);
        setTableRecords(shortdata);
        var potential_list = [];
        var realised_list = [];
        shortdata.map((map_data) => {
          if (
            map_data.realised_savings === null ||
            Math.round(map_data.realised_savings) === 0
          ) {
            potential_list.push(map_data);
          } else {
            realised_list.push(map_data);
          }
        });
        const pending_action = shortdata.length - realised_list.length;
        var pending_percentage = (pending_action * 100) / shortdata.length;
        var color_code = "#000";
        if (pending_percentage >= 50) {
          color_code = "#be1e2d";
        } else if (pending_percentage >= 30) {
          color_code = "#FFA500";
        } else {
          color_code = "#188f00";
        }

        setTopRecords({
          potential_savings: potential_list?.reduce(
            (a, v) => (a = +a + +v.potential_savings),
            0
          ),
          realised_savings: realised_list?.reduce(
            (a, v) => (a = +a + +v.realised_savings),
            0
          ),
          pending_action: `${shortdata.length - realised_list.length}/${
            shortdata.length
          }`,
          color_code: color_code,
        });
        setLSavingsStatus(false);
      })
      .catch((err) => {
        setLSavingsStatus(false);
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    companyName,
    selecteddb,
    selectedinfra,
    selectedos,
    recordsudpate,
  ]);

  const items = [
    {
      key: "1",
      label: `Unrealised`,
      children: (
        <UnrealizedLayout
          records={tablerecords}
          recordsudpate={recordsudpate}
          setRecordsUpdate={setRecordsUpdate}
          lsavingsstatus={lsavingsstatus}
          rolestatus={rolestatus}
        />
      ),
    },
    {
      key: "2",
      label: `Realised`,
      children: (
        <RealizedLayout
          records={tablerecords}
          recordsudpate={recordsudpate}
          setRecordsUpdate={setRecordsUpdate}
          lsavingsstatus={lsavingsstatus}
          rolestatus={rolestatus}
        />
      ),
    },
  ];

  return (
    <>
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <CostimizeSidebar />
        <MainHead title="Savings Opportunity" />
        <div className="costimize-body-screen">
          <Row gutter={[16, 16]}>
            <Col xxl={4} xl={4} lg={4} md={8} sm={24} xs={24}>
              <Card className="plat-card-1">
                <Spin
                  spinning={lsavingsstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div className="mt-3">
                    <h1 className="card-1-title-2 site-color">
                      Potential Annual Savings
                    </h1>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Icon
                      icon="mdi:wallet"
                      className="costimize-center-icon site-color"
                    />
                  </div>
                  <div className="mb-3">
                    <h1 className="card-1-value-2 site-color">
                      ${numberFormat(toprecords.potential_savings)}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={8} sm={24} xs={24}>
              <Card className="plat-card-1">
                <Spin
                  spinning={lsavingsstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div className="mt-3">
                    <h1 className="card-1-title-2 site-color">
                      Realised Annual Savings
                    </h1>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Icon
                      icon="fluent:wallet-credit-card-20-filled"
                      className="costimize-center-icon site-color"
                    />
                  </div>
                  <div className="mb-3">
                    <h1 className="card-1-value-2 site-color">
                      ${numberFormat(toprecords.realised_savings)}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={8} sm={24} xs={24}>
              <Card className="plat-card-1">
                <Spin
                  spinning={lsavingsstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div className="mt-3">
                    <h1 className="card-1-title-2 site-color">
                      Pending Action
                    </h1>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Icon
                      icon="ic:outline-pending-actions"
                      className="costimize-center-icon site-color"
                    />
                  </div>
                  <div className="mb-3">
                    <h1
                      className="card-1-value-2 site-color"
                      style={{ color: toprecords.color_code }}
                    >
                      {toprecords.pending_action}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={24} sm={24} xs={24}>
              <Card className="plat-card-3">
                <RealisedChart
                  records={tablerecords}
                  lsavingsstatus={lsavingsstatus}
                />
              </Card>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={24} sm={24} xs={24}>
              <Card className="plat-card-3">
                <SavingsRealizedChart />
              </Card>
            </Col>
          </Row>
          <Row className="mt-4" style={{ display: "block" }}>
            <Col span={24}>
              <Tabs
                type="line"
                defaultActiveKey="1"
                items={items}
                className="costimize-table-tab"
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default memo(SavingsModelLayout);
