// import { Icon } from "@iconify/react";
import { Card, Col, message, Row, Select } from "antd";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import InitialResponseTimeTable from "./InitialResponseTimeTable";
import ResolutionTimeTable from "./ResolutionTimeTable";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Table, Form, Button, Input } from "antd";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addIncidentTrend,
  deleteIncidentTrend,
  getReqResponseTime,
  listIncidentTrends,
  updateIncidentTrend,
} from "../../../action/graphql/slm/SLM_reducers";
import moment from "moment";
import { DatePicker } from "antd";
const { Option } = Select;

function IncidentTrends(props) {
  console.log(props)
  const tablerecords=props.tablerecords;
  const [tableDrawerStatus, setTableDrawerStatus] = useState(false);
  const [formDrawerStatus, setFormDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [tableRecords, setTableRecords] = useState([]);
  const monthFormat = "YYYY-MM-01";
  const [priorityCounts, setPriorityCounts] = useState({
    p1: [],
    p2: [],
    p3: [],
  });
  const [monthLabelList, setMonthLabelList] = useState([]);

  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);

  const [xAxisData, setxAxisData] = useState([]);
  const [yAxisData, setyAxisData] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState("");
  const [drawerDeletestatus, setDrawerDeleteStatus] = useState(false);
  const onEditClick = (data) => {
    console.log("edit data", data);
    setFormDrawerStatus(true);
    setEditStatus(true);
    setSelectedTicketId(data.ticket_id);
    setDeleteStatus(true);
    const records = {
      ptype: data.priority_type,
      date: moment(data.month),
    };
    console.log(records);
    form.setFieldsValue(records);
  };
  var month_list = [];
  useEffect(() => {
    // month_list = [];
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      if (
        !month_list.includes(`${date.getFullYear()}-${date.getMonth() + 1}-01`)
      )
        month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    incident_trends_chart();
    initialResponseTimeTableAPI();
  }, [selectedTopdate]);

  useEffect(() => {}, [tableRecords]);

  const handleCancel = () => {
    setFormDrawerStatus(false);
    form.resetFields();
    setSelectedTicketId("");
    setDeleteStatus(false);
  };
  const incident_trends_chart = () => {
    dispatch(listIncidentTrends({ companyName, selectedTopdate: month_list }))
      .unwrap()
      .then(({ data }) => {
        let priorityData = data.newfunctionquery25;
        let priorityCountsObj = {
          p1: [],
          p2: [],
          p3: [],
        };
        let labelArrayTemp = [];

        month_list.forEach((month) => {
          let p1Count = 0;
          let p2Count = 0;
          let p3Count = 0;

          const checkMonth = moment(month, "YYYY-MM-DD").toDate();
          labelArrayTemp.push(moment(month, "YYYY-MM-DD").format("MMM YY"));

          priorityData.forEach((data) => {
            const dataMonth = moment(data.month, "YYYY-MM-DD").toDate();

            if (checkMonth.getMonth() === dataMonth.getMonth()) {
              data.priority_type.toLowerCase() === "p1 critical"
                ? p1Count++
                : data.priority_type.toLowerCase() === "p2 moderate"
                ? p2Count++
                : p3Count++;
            }
          });
          priorityCountsObj.p1.push(p1Count);
          priorityCountsObj.p2.push(p2Count);
          priorityCountsObj.p3.push(p3Count);
        });
        setPriorityCounts(priorityCountsObj);
        setMonthLabelList(labelArrayTemp);

        const tableRecordTemp = priorityData.map((item, index) => {
          return {
            year: moment(item.month, "YYYY-MM-DD").format("YYYY"),
            month: moment(item.month, "YYYY-MM-DD").format("MMMM"),
            ticketNo: item.ticket_id,
            priorityType: item.priority_type,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => onEditClick(item)}
              />
            ),
          };
        });
        setTableRecords(tableRecordTemp);
      });
  };

  useEffect(() => {}, [priorityCounts]);
  const [tableData, setTableData] = useState([]);
  const initialResponseTimeTableAPI = () => {
    let lastThreeMonths = [];
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );

      lastThreeMonths.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    dispatch(
      getReqResponseTime({ companyName, selectedTopdate: lastThreeMonths })
    )
      .unwrap()
      .then(({ data }) => {
        setShowSpinner(false);
        setTableData(data.newfunctionquery24);
      });
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val;
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    // dataLabels: {
    //   enabled: false
    // },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: monthLabelList,
    },
    yaxis: {
      decimalsInFloat: 0, // set decimalsInFloat to 0 to remove decimal values
      labels: {
        formatter: function (value) {
          return parseInt(value); // parse the y-axis value to remove decimal values
        },
      },
      min: 0,
      max: 3,
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "P1 Critical",
      data: priorityCounts.p1,
    },
    {
      name: "P2 Moderate",
      data: priorityCounts.p2,
    },
    {
      name: "P3 Low",
      data: priorityCounts.p3,
    },
  ];

  const handleIncidentIconClick = () => {
    setTableDrawerStatus(true);
    setFormDrawerStatus(false);
  };
  const changeIncidentIconDrawerStatus = () => {
    setTableDrawerStatus(false);
    form.resetFields();
    setSelectedTicketId("");
  };

  const changeIncidentFormIconDrawerStatus = () => {
    setFormDrawerStatus(false);
    form.resetFields();
    setSelectedTicketId("");
    setDeleteStatus(false);
  };

  const handleEditIconClick = () => {
    setFormDrawerStatus(true);
    setTableDrawerStatus(false);
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Ticket No.",
      dataIndex: "ticketNo",
      key: "ticketNo",
    },
    {
      title: "Priority Type",
      dataIndex: "priorityType",
      key: "priorityType",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const data = tableRecords;
  const dropdownOptions = [
    { label: "P1 Critical", value: "P1 Critical" },
    { label: "P2 Moderate", value: "P2 Moderate" },
    { label: "P3 Low", value: "P3 Low" },
  ];
  const tableProps = {};
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log({ values });
    let report_month = `${new Date(values.date).getFullYear()}-${
      new Date(values.date).getMonth() + 1
    }-01`;
    console.log(report_month);
    const input = {
      priority_type: values.ptype,
      ticket_id: `t-${Math.floor(100000 + Math.random() * 900000)}`,
      company_name: companyName,
      report_month: report_month,
    };

    if (editStatus === true) {
      console.log("first");
      const editInput = {
        priority_type: values.ptype,
        ticket_id: selectedTicketId,
        company_name: companyName,
        report_month: report_month,
      };
      console.log(editInput);
      dispatch(updateIncidentTrend({ editInput }))
        .unwrap()
        .then(({ data }) => {
          setFormDrawerStatus(false);
          incident_trends_chart();
          setEditStatus(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(addIncidentTrend({ input }))
        .unwrap()
        .then(({ data }) => {
          setFormDrawerStatus(false);
          console.log(month_list);
          incident_trends_chart();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteIncident = () => {
    // console.log("first");
    const input = {
      ticket_id: selectedTicketId,
      company_name: companyName,
    };

    dispatch(deleteIncidentTrend({ input }))
      .unwrap()
      .then(({ data }) => {
        message.success("Deleted");
        setFormDrawerStatus(false);
        setDeleteStatus(false);
        incident_trends_chart();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(tablerecords)
  return (
    <>
      <DrawerPlat
        drawerstatus={tableDrawerStatus}
        changeDrawerStatus={changeIncidentIconDrawerStatus}
        // deletestatus={deletestatus}
        title="Incident Trends"
      >
        <div onClick={() => setFormDrawerStatus(true)}>
          <button className="table-add-button" style={{ float: "right" }}>
            {/* <Icon icon="ion:add-circle-outline" /> */}
            <Icon
              className="cursor-pointer"
              icon="material-symbols:add-circle-outline"
            />
            Add
          </button>
        </div>
        {tableDrawerStatus && (
          <Table
            {...tableProps}
            className="savings_new_table "
            style={{ overflowX: "scroll" }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        )}
      </DrawerPlat>

      <DrawerPlat
        drawerstatus={formDrawerStatus}
        changeDrawerStatus={changeIncidentFormIconDrawerStatus}
        deletestatus={deletestatus}
        rowDelete={deleteIncident}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        title="Incident Trends"
      >
        {formDrawerStatus && (
          <div>
            {" "}
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              // initialValues={initialValues}
              form={form}
            >
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        required: true,

                        message: "",
                      },
                    ]}
                  >
                    <DatePicker
                      format={monthFormat}
                      className="antd-date-picker w-100 form-control"
                      picker="month"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item
                    name="ptype"
                    label="Priority Type"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select>
                      {dropdownOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {/* <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="ticket_no" label="Ticket Number">
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item name="ptype" label="Priority Type">
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col> */}
              </Row>

              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item>
                    <Button htmlType="button" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="p-1"
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        background: "#1b5f9d",
                        color: "#fff",
                        float: "right",
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>{" "}
          </div>
        )}
      </DrawerPlat>

      <Row gutter={[16, { xs: 8, sm: 16, md: 16, lg: 32 }]}>
        <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
          <Card className="plat-card-1">
            <div className="d-flex p-3">
              <h6
                className="card-title card-header"
                // onClick={() => setDrawerStatus(true)}
              >
                Incident Trends
              </h6>
              <div className="card-options">
                <span
                  className="card-filter-icon"
                  onClick={handleIncidentIconClick}
                >
                  <Icon
                    className="cursor-pointer"
                    icon="material-symbols:edit-note-sharp"
                  />
                </span>
              </div>
            </div>

            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={235}
            />
          </Card>
        </Col>
        <Col xxl={14} xl={14} lg={14} md={14} sm={24} xs={24}>
          <Card className="plat-card-1">
            <InitialResponseTimeTable
            tablerecords={tablerecords}
              tableData={tableData}
              monthList={month_list}
              showSpinner={showSpinner}
              initialResponseTimeTableAPI={initialResponseTimeTableAPI}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ResolutionTimeTable
            tableData={tableData}
            tablerecords={tablerecords}
            monthList={month_list}
            showSpinner={showSpinner}
            initialResponseTimeTableAPI={initialResponseTimeTableAPI}

          />
        </Col>
      </Row>
    </>
  );
}

export default IncidentTrends;
