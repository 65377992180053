import { gql } from "@apollo/client";

const LIST_DEVOPS_ACCOUNT = gql`
  query MyQuery($customer: String!) {
    list_platform_devops_accounts(customer: $customer) {
      account_id
      account_name
      company
      cloud_platform
      customer_approval_email
      external_id
      och_approval_email
      status
    }
  }
`;
const LIST_DEVOPS_CATEGORY = gql`
  query MyQuery($cloud_platform: String!) {
    devops_config(cloud_platform: $cloud_platform) {
      build_category
      build_sub_category
      cloud_platform
      configurations
      logs
      status
    }
  }
`;

const LIST_REQUEST_TABLE_ALL = gql`
  query MyQuery($customer_name: String!) {
    list_platform_devops_accounts_activities_all(
      customer_name: $customer_name
    ) {
      id
      account_name
      activity_type
      approved_time
      build_number
      cloud_platform
      completed_time
      configurations
      created_by
      created_time
      customer_name
      cx_approved_by
      cx_comments
      job_name
      och_approved_by
      och_comments
      operation
      rule_name
      service_name
      status
      rejected_by
      is_admin_approved
      is_och_approved
    }
  }
`;

const LIST_REQUEST_TABLE_USER = gql`
  query MyQuery($customer_name: String!, $created_by: String!) {
    list_platform_devops_accounts_activities_user(
      customer_name: $customer_name
      created_by: $created_by
    ) {
      id
      account_name
      activity_type
      approved_time
      build_number
      cloud_platform
      completed_time
      configurations
      created_by
      created_time
      customer_name
      cx_approved_by
      cx_comments
      job_name
      och_approved_by
      och_comments
      operation
      rule_name
      service_name
      status
      rejected_by
      is_admin_approved
      is_och_approved
    }
  }
`;

const REJECT_QUERY_1CH_ADMIN = gql`
  query MyQuery(
    $event_type: String
    $och_comments: String
    $id: String
    $och_approval_email: String
    $created_by: String
    $cx_approval_email: String
    $is_admin_approved: String
    $is_och_approved: String
    $cx_comments: String
    $rejected_by: String
  ) {
    devops_workflow(
      event_type: $event_type
      och_comments: $och_comments
      id: $id
      och_approval_email: $och_approval_email
      created_by: $created_by
      cx_approval_email: $cx_approval_email
      is_admin_approved: $is_admin_approved
      is_och_approved: $is_och_approved
      cx_comments: $cx_comments
      rejected_by: $rejected_by
    )
  }
`;

const BASIC_CONFIG_ADD_UPDATE = gql`
  query MyQuery(
    $event_type: String!
    $account_name: String!
    $activity_type: String!
    $configurations: String!
    $created_by: String!
    $customer_name: String!
    $cx_approval_email: String!
    $cx_comments: String!
    $job_name: String!
    $och_approval_email: String!
    $och_comments: String!
    $operation: String!
    $status: String!
    $service_name: String!
    $cloud_platform: String!
  ) {
    devops_workflow(
      event_type: $event_type
      account_name: $account_name
      activity_type: $activity_type
      configurations: $configurations
      created_by: $created_by
      customer_name: $customer_name
      cx_approval_email: $cx_approval_email
      cx_comments: $cx_comments
      job_name: $job_name
      och_approval_email: $och_approval_email
      och_comments: $och_comments
      operation: $operation
      status: $status
      service_name: $service_name
      cloud_platform: $cloud_platform
    )
  }
`;
export {
  LIST_DEVOPS_ACCOUNT,
  LIST_DEVOPS_CATEGORY,
  REJECT_QUERY_1CH_ADMIN,
  BASIC_CONFIG_ADD_UPDATE,
  LIST_REQUEST_TABLE_ALL,
  LIST_REQUEST_TABLE_USER,
};
