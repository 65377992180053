import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import navHeader from "./../../assets/images/cams/cams_header_logo.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { loginUser } from "../action/userAction";
import { signOut } from "../amplify/auth-config";
import { useDispatch, useSelector } from "react-redux";
function HomeLandingHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdetails, setUserDetails] = useState([]);
  const records = {
    user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
    _token: localStorage.getItem("_token"),
  };
  useEffect(() => {
    var path = window.location.pathname;
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        setUserDetails(data.getPlatformUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.pathname]);
  const userLogout = () => {
    dispatch(signOut())
      .unwrap()
      .then(() => {
        localStorage.removeItem("_token");
        localStorage.removeItem("CognitoIdentityServiceProvider.auth");
        navigate("/signin", { replace: true });
        window.location.href = "/signin";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Navbar className="navHeader" expand="lg">
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img src={navHeader} alt="nav-logo" height={35} />
          <span className="nav-header-logo-text">CloudShifu</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="d-flex justify-content-between w-100">
            <Nav></Nav>

            {records.user_id && records._token ? (
              <Nav className="">
                {/* <Nav.Link
                   className="navlink-signin d-flex align-items-center"
                   onClick={() => {
                    navigate("/admin-actions")}}
                 >
                   <span className="signin"> Admin</span>
                 </Nav.Link>
                 <Nav.Link
                   onClick={() => {
                     navigate("/signup");
                   }}
                 >
                   <span>
                     <Button
                       className="signup-button"
                       onClick={() => navigate("/signup")}
                     >
                       Sign UP
                     </Button>{" "}
                   </span>
                 </Nav.Link> */}
                <NavDropdown
                  title={
                    <Avatar size="large" className="header-avatar">
                      {userdetails.first_name && (
                        <>{Array.from(userdetails.first_name)[0]}</>
                      )}
                    </Avatar>
                  }
                  id="navbarScrollingDropdown"
                  className="navbar-top-menu costimize-navbar-top-menu"
                >
                  {userdetails.first_name ? (
                    <>
                      <label className="menu-topicon-username">
                        {userdetails.first_name}
                      </label>
                      <NavDropdown.Divider />
                    </>
                  ) : (
                    <></>
                  )}

                  {localStorage
                    .getItem("CognitoIdentityServiceProvider.offering")
                    ?.toLowerCase() === "master" ||
                  localStorage
                    .getItem("CognitoIdentityServiceProvider.offering")
                    ?.toLowerCase() === "admin" ? (
                    <>
                      {localStorage
                        .getItem("CognitoIdentityServiceProvider.offering")
                        ?.toLowerCase() === "master" &&
                      window.location.pathname !== "/role" ? (
                        <>
                          <NavDropdown.Item
                            className="navbar-top-submenu"
                            onClick={() => navigate("/admin-dashboard")}
                          >
                            Dashboard
                          </NavDropdown.Item>
                        </>
                      ) : (
                        " "
                      )}
                      {/* {adminpanelpermission &&
                        window.location.pathname !== "/role" ? (
                          <NavDropdown.Item
                            className="navbar-top-submenu"
                            onClick={() => navigate("/admin-actions")}
                          >
                            Admin Panel
                          </NavDropdown.Item>
                        ) : (
                          ""
                        )} */}
                    </>
                  ) : (
                    <></>
                  )}
                  <NavDropdown.Item
                    className="navbar-top-submenu"
                    onClick={() => navigate("/role")}
                  >
                    My Role
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="navbar-top-submenu"
                    onClick={userLogout}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            ) : (
              <>
                <Nav className="">
                  <Nav.Link
                    className="navlink-signin d-flex align-items-center"
                    onClick={() => {
                      navigate("/signin");
                    }}
                  >
                    <span className="signin"> Sign In</span>
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    <span>
                      <Button
                        className="signup-button"
                        onClick={() => navigate("/signup")}
                      >
                        Sign Up
                      </Button>{" "}
                    </span>
                  </Nav.Link>
                </Nav>
              </>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default HomeLandingHeader;
