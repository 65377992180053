import React from "react";
import { Col, Row, Select } from "antd";
import logo from "../../assets/images/company_logo.png";
import cmacgm_logo from "../../assets/images/cmacgm_logo_new.png";
import { headercustomerlogo } from "../action/header_logo";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useCallback } from "react";
import CostimizeFilter from "../costimize_v2/layout/CostimizeFilter";
import "./cams.css";
import { useEffect } from "react";

function HeaderSection(props) {
  const dispatch = useDispatch();
  const [shortmonth, setShortMonth] = useState("current_month");
  const [company_logo, setCompanyLogo] = useState();
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const company_name = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    const demo_type = localStorage.getItem(
      "CognitoIdentityServiceProvider.demo"
    );
    if (demo_type == "true") {
      setCompanyLogo();
    } else {
      if (company_name === "cmacgm") {
        setCompanyLogo(cmacgm_logo);
      } else if (company_name === "fivestar") {
        setCompanyLogo(logo);
      }
    }
  }, [props]);
  const handleMultiMonthChange = useCallback(
    (event) => {
      console.log(event);
    },
    [shortmonth]
  );
  return (
    <div>
      {" "}
      <div className="cams-header-body">
        <Row className="mt-2">
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="header-title header-color"
          >
            {props.title}
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="header-logo-block">
              {company_logo && (
                <img className="costimize_header_logo" src={company_logo} />
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="header-filter-block">
              {/* <div>
                <ul className="header-filter-list">
                  <li
                    className="active"
                    onClick={() => handleMultiMonthChange("current_month")}
                  >
                    This Month
                  </li>
                  <li onClick={() => handleMultiMonthChange("last_month")}>
                    Last Month
                  </li>
                  <li>
                    <CostimizeFilter />
                  </li>
                </ul>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HeaderSection;
