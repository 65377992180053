import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../components/slice/userSlice";
import warReducer from "../components/slice/warSlice";
import accountReducer from "../components/slice/accountSlice";
import costimizeReducer from "../components/slice/costimizeSlice";
import peemsSlice from "./slice/peemsSlice";
import costimizespendRenducer from "./slice/costimizespendSlice";
import devopsSlice from "./slice/devopsSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    war: warReducer,
    account: accountReducer,
    constimize: costimizeReducer,
    peems: peemsSlice,
    costimizespend: costimizespendRenducer,
    devops: devopsSlice,
    devTools: true,
  },
});

export default store;
