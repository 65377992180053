import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Space,
  Table,
  Col,
  Row,
  Menu,
  Button,
  message,
  Tabs,
  Tag,
  Modal,
  Progress,
  Input,
  Spin,
} from "antd";
import RequestorDocument from "../../../assets/images/RequestorDocument.png";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  listRequestTable,
  listRequestTableforAll,
} from "../../action/graphql/devops/DevOpsAction";
import { reintialize } from "../../action/graphql/devops/DevOpsAction";
import {
  getApprovalTableData,
  setRequestAction,
  setRequestorTableData,
} from "../../slice/devopsSlice";
import moment from "moment";
import { loginUser } from "../../action/userAction";

function RequestorTable() {
  const [tagModalOpen, settagModalOpen] = useState(false);
  const [refreshModalOpen, setrefreshModalOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(50);
  const [titletag, setTitletag] = useState("Unknown Log");
  const [showSpinner, setShowSpinner] = useState(true);
  const [adminApproved, setAdminApproved] = useState(true);
  // const [reintialize,setReintialize] =  useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const userRole = localStorage.getItem(
    "CognitoIdentityServiceProvider.offering"
  );
  const [tableData, setTableData] = useState([]);
  function sortById() {
    return function (elem1, elem2) {
      if (elem1.id < elem2.id) {
        return -1;
      } else if (elem1.id > elem2.id) {
        return 1;
      } else {
        return 0;
      }
    };
  }
  const getRequestTableDetails = () => {
    if (userRole === "master") {
      dispatch(listRequestTableforAll({ companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log("logging data in requestTableDetails", data);
          setShowSpinner(false);
          let tableDataBeforeStart = [];

          tableDataBeforeStart =
            data.list_platform_devops_accounts_activities_all;
          tableDataBeforeStart = tableDataBeforeStart.reverse();
          setTableData(tableDataBeforeStart);
          // setTableData([]);
          dispatch(
            getApprovalTableData(
              data.list_platform_devops_accounts_activities_all
            )
          );
        });
    } else {
      // to get createdby user
      let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
      let token = localStorage.getItem("_token");
      let created_by_user;
      const records = {
        user_id: user_id,
        _token: token,
      };
      dispatch(loginUser(records))
        .unwrap()
        .then(({ data }) => {
          console.log(data.getUsers.company_contact_no);
          created_by_user = data.getUsers.email_id;
          dispatch(listRequestTable({ companyName, created_by_user }))
            .unwrap()
            .then(({ data }) => {
              console.log("data data", data);
              if (data.is_admin_approved == 0) {
                setAdminApproved(false);
              }
              setShowSpinner(false);
              let tableDataBeforeStart = [];
              tableDataBeforeStart =
                data.list_platform_devops_accounts_activities_user;
              tableDataBeforeStart = tableDataBeforeStart.reverse();
              setTableData(tableDataBeforeStart);
              // setTableData([]);
              dispatch(
                getApprovalTableData(
                  data.list_platform_devops_accounts_activities_user
                )
              );
            });
        });
      // const userDetails = localStorage.getItem(
      //   "CognitoIdentityServiceProvider.47d6lvj9jj8ca117snjv8du3i5.3113db53-3957-48a1-813b-2ed29d18bdf5.userData"
      // );
      // console.log(JSON.parse(userDetails));
      // let user_json_formatted = JSON.parse(userDetails);
      // let created_by_user;
      // console.log(user_json_formatted?.UserAttributes[8]);
      // if (!user_json_formatted?.UserAttributes[8]) {
      //   created_by_user = user_json_formatted.UserAttributes[8].Value;
      // } else {
      //   created_by_user = user_json_formatted.UserAttributes[6].Value;
      // }

      // console.log(created_by_user);
    }
  };
  useEffect(() => {
    getRequestTableDetails();
  }, []);
  const handleCreateRequest = () => {
    dispatch(setRequestAction("addNew"));
    navigate("/devops-config");
  };
  const handleRefreshClick = (record) => {
    console.log("records", record);

    dispatch(setRequestAction("edit"));
    dispatch(setRequestorTableData(record));
    setTitletag(record);
    setrefreshModalOpen(true);
    console.log("refresh");
  };
  const tagClick = (record) => {
    console.log(record);
    setTitletag(record);
    settagModalOpen(true);
  };
  const handleCancel = () => {
    settagModalOpen(false);
    setrefreshModalOpen(false);
    setShowSpinner(false);
  };
  const handleAddClick = () => {
    dispatch(setRequestAction("addNew"));
    navigate("/devops-config");
  };
  const handleEditClick = (record) => {
    dispatch(setRequestAction("edit"));
    dispatch(setRequestorTableData(record));
    navigate("/devops-config");

    // navigate("/devops-config");
  };
  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: "CR ID",
      dataIndex: "id",
      align: "center",
      sorter: (a, b) => a.id - b.id,
      className: "devops-table-header",
      render: (a, b, c) => {
        return b.activity_type + b.id;
      },
      defaultSortOrder: "ascend",
      // width: 80,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => (a.status > b.status ? -1 : 1),
      className: "devops-table-header",
      render: (text, record, index) => {
        if (record.status === "Approved") {
          return (
            <div
              className="status-box approved"
              onClick={() => tagClick(record)}
            >
              Approved
            </div>
          );
        } else if (record.status === "Build Failed") {
          return (
            <div className="status-box failed" onClick={() => tagClick(record)}>
              Failed
            </div>
          );
        } else if (record.status === "Draft") {
          return (
            <div className="status-box draft" onClick={() => tagClick(record)}>
              Draft
            </div>
          );
        } else if (record.status === "Build in-Progress") {
          return (
            <div className="status-box draft" onClick={() => tagClick(record)}>
              Build InProgress
            </div>
          );
        } else if (record.status === "Approval Pending") {
          return (
            <div
              className="status-box approval-pending"
              onClick={() => tagClick(record)}
            >
              Approval Pending
            </div>
          );
        } else if (record.status === "OCH Approval Pending") {
          return (
            <div
              className="status-box approval-pending"
              onClick={() => tagClick(record)}
            >
              OCH Approval Pending
            </div>
          );
        } else if (record.status === "Build Completed") {
          return (
            <div
              className="status-box completed"
              onClick={() => tagClick(record)}
            >
              Completed
            </div>
          );
        } else if (record.status === "Rejected") {
          return (
            <div className="status-box failed" onClick={() => tagClick(record)}>
              Rejected
            </div>
          );
        } else {
          return record.status;
        }
      },
      // width: 170,
    },
    {
      title: "Company Name",
      dataIndex: "customer_name",
      align: "center",
      sorter: (a, b) => (a.customer_name > b.customer_name ? -1 : 1),
      className: "devops-table-header",
      // width: 140,
    },

    {
      title: "Account",
      dataIndex: "account_name",
      align: "center",
      sorter: (a, b) => (a.account_name > b.account_name ? -1 : 1),
      className: "devops-table-header",
      // width: 140,
    },

    {
      title: "Cloud",
      dataIndex: "cloud_platform",
      align: "center",
      sorter: (a, b) => (a.cloud_platform > b.cloud_platform ? -1 : 1),
      className: "devops-table-header",
      // width: 90,
    },
    {
      title: "Service",
      dataIndex: "service_name",
      align: "center",
      sorter: (a, b) => (a.service_name > b.service_name ? -1 : 1),
      className: "devops-table-header",
      // ellipsis: true,
    },
    {
      title: "Launch Category",
      dataIndex: "operation",
      align: "center",
      sorter: (a, b) => (a.operation > b.operation ? -1 : 1),
      className: "devops-table-header",
      // ellipsis: true,
    },

    {
      title: "Requested Time",
      dataIndex: "created_time",
      render: (text, record, index) =>
        moment(record.created_time).format("DD-MMM-YYYY, hh:mm:ss A"),
      // ellipsis: true,
    },
    {
      title: "Completed Time",
      dataIndex: "completed_time",
      render: (text, record, index) => {
        if (record.completed_time) {
          let record_text = moment(record.completed_time).format(
            "DD-MMM-YYYY, hh:mm:ss A"
          );
          return record_text;
        } else {
          return "-";
        }
      },
      align: "center",
      // ellipsis: true,
    },

    {
      title: "Approved By",
      render: (text, record, index) => {
        if (record.och_approved_by && userRole == "1ch_admin") {
          return record.och_approved_by + "(OCH Admin)";
        } else if (record.cx_approved_by && userRole == "admin") {
          return record.cx_approved_by + "(Admin)";
        } else {
          <>-</>;
        }
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record, index) => {
        let actionIcon = null;

        if (record.status === "Rejected") {
          actionIcon = (
            <Icon
              className="modal-icon cursor-pointer"
              icon="jam:refresh"
              onClick={() => handleRefreshClick(record)}
            />
          );
        } else if (record.status === "Draft") {
          actionIcon = (
            <Icon
              className="modal-icon cursor-pointer"
              icon="ph:note-pencil"
              onClick={() => handleEditClick(record)}
            />
          );
        } else if (
          record.status === "Approval Pending" ||
          record.status === "OCH Approval Pending" ||
          record.status === "Build in-Progress"
        ) {
          actionIcon = <>-</>;
        } else {
          actionIcon = <>-</>;
        }

        return actionIcon;
      },
      align: "center",
    },
  ];

  // const data = [];
  const items = [
    {
      key: "1",
      label: "CR",
    },
    {
      key: "2",
      label: "SR",
    },
  ];

  const handleClickReinitiate = () => {
    // console.log('record', record)
    dispatch(setRequestAction("edit"));
    dispatch(setRequestorTableData(titletag));
    console.log(titletag);
    setrefreshModalOpen(false);
    // console.log("API data",data)
    let obj = {};
    console.log("object", obj);
    let cx_comments,
      id,
      och_approval_email,
      och_comments,
      is_och_approved,
      cx_approval_email,
      is_admin_approved,
      rejected_by;

    obj.cx_comments = titletag.cx_comments;
    obj.id = titletag.id;
    obj.rejected_by = titletag.rejected_by;
    console.log(titletag.och_comments, "<<");
    obj.och_approval_email = titletag.och_approved_by;
    console.log(och_approval_email);
    obj.och_comments = titletag.och_comments;
    obj.is_admin_approved = titletag.is_admin_approved;
    obj.is_och_approved = titletag.is_och_approved;
    obj.cx_approval_email = titletag.cx_approved_by;

    navigate("/devops-config");
    obj.event_type = "reinitialize";
    console.log(id);
    dispatch(reintialize({ obj }));
  };
  return (
    <>
      {" "}
      <>
        {showSpinner ? (
          <>
            <div className="d-flex justify-content-center">
              <Spin />
            </div>
          </>
        ) : (
          <>
            {tableData.length !== 0 ? (
              <>
                <div>
                  <div className="title-btn-row">
                    <Button
                      className="title-btn"
                      onClick={() => handleAddClick()}
                    >
                      <Space>
                        <Icon icon="material-symbols:add-circle-outline" />
                        Add
                      </Space>
                    </Button>
                  </div>

                  <div>
                    <Table
                      className="devops_Table"
                      columns={columns}
                      dataSource={tableData}
                      pagination={{ pageSize: 5 }}
                      showSizeChanger={false}
                    />
                  </div>
                  <Modal
                    centered
                    title={titletag.status}
                    open={tagModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    className="modal-tag devops-status"
                    expandable={{
                      expandedRowRender: (record) => {
                        return <></>;
                      },
                    }}
                  >
                    <div>
                      <br />
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="fluent-mdl2:message-friend-request"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Request created:{" "}
                          {moment(titletag.created_time).format(
                            "DD/MMM/YYYY hh:mm:ss A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="ant-design:reload-time-outline"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Sent for apporival date :{" "}
                          {moment(titletag.created_time).format(
                            "DD/MMM/YYYY hh:mm:ss A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="material-symbols:deployed-code-history-outline-sharp"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Initiated deployment :{" "}
                          {titletag.approved_time
                            ? moment(titletag.approved_time).format(
                                "DD/MMM/YYYY hh:mm:ss A"
                              )
                            : " -- "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="material-symbols:deployed-code-outline-sharp"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Deployment completed :
                          {titletag.completed_time
                            ? moment(titletag.completed_time).format(
                                "DD/MMM/YYYY hh:mm:ss A"
                              )
                            : " -- "}
                        </p>
                      </div>
                    </div>

                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon icon="mdi:user" className="modal-icon" />
                        </button>
                        <p className="total-modal-text">
                          Admin Approved by :{" "}
                          {titletag.cx_approved_by
                            ? titletag.cx_approved_by
                            : "--"}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon icon="mdi:user" className="modal-icon" />
                        </button>
                        <p className="total-modal-text">
                          OCH Approved by :{" "}
                          {titletag.och_approved_by
                            ? titletag.och_approved_by
                            : "--"}
                        </p>
                      </div>
                    </div>
                    {titletag.status === "Rejected" && (
                      <>
                        <div>
                          <div>
                            <div className="total-modal">
                              <button className="modal-btn">
                                <Icon icon="mdi:user" className="modal-icon" />
                              </button>
                              <p className="total-modal-text">
                                Rejected By : &nbsp;
                                {titletag.rejected_by}
                              </p>
                            </div>
                          </div>
                          <div className="total-modal">
                            <button className="modal-btn">
                              <Icon
                                icon="material-symbols:comment-outline"
                                className="modal-icon"
                              />
                            </button>
                            <p className="total-modal-text">
                              Comment : &nbsp; {titletag.och_comments}
                              {titletag.cx_comments}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </Modal>
                  <Modal
                    centered
                    okButtonProps={{
                      style: {
                        background:
                          "linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)",
                        color: "white",
                      },
                    }}
                    className="devops-modal "
                    open={refreshModalOpen}
                    onCancel={handleCancel}
                    cancelButtonProps={{
                      style: {
                        color: "#0f0f0f",
                      },
                    }}
                    footer={null}
                    width={400}
                  >
                    <div className="p-4">
                      <Row>
                        <Col>
                          <span className="text-white">
                            Are you sure you want to reinitiate ?
                          </span>
                        </Col>
                      </Row>
                      <br />
                      <Row justify="center" gutter={8}>
                        <Col
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="p-1"
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="yes-button"
                            onClick={() => handleClickReinitiate()}
                          >
                            Yes
                          </Button>
                        </Col>
                        <Col
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="p-1"
                        >
                          <Button
                            htmlType="button"
                            className="no-btn"
                            onClick={() => setrefreshModalOpen(false)}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  marginTop: "3rem",
                }}
              >
                <div>
                  <div>
                    <img
                      src={RequestorDocument}
                      alt="RequestorDocument"
                      className="center-image"
                    />
                  </div>

                  <br />
                  <div className="d-flex justify-content-center">
                    <div>
                      <div className="center-text">
                        <h4>Your Dashboard is empty</h4>
                      </div>
                      <div className="center-text mt-3">
                        <h4>
                          To create a request{" "}
                          <a
                            onClick={handleCreateRequest}
                            className="click-here-text"
                          >
                            Click here
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default RequestorTable;
