import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ModalPopUp from "./ModalPopUp";
// import TicketInformationTable from "./TicketInformationTable";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getAllAlertsDetails,
  getCamsAlertsDetails,
} from "../action/graphql/cams/cams_reducers";
import TicketInformationTableAlerts from "./TicketInformationTableAlerts";
import { Spin } from "antd";
// import TicketInformationPopup from "./TicketInformationPopup";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTablePagination from "./BootstrapTablePagination";

const AlertDetailsTableTwo = ({ customer, dateLabel, severityWeeklyData }) => {
  const [serverDayWise, setServerDayWise] = useState({});
  const [camsDetails, setCamsDetails] = useState([]);
  const [convertedDateArr, setConvertedDateArr] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  //pagination
  const [page_number, setPageNumber] = useState(1);
  const [totalrecordscount, setTotalRecordsCount] = useState(0);

  const company_name =
    localStorage.getItem("CognitoIdentityServiceProvider.company") !==
    "Internal1CH"
      ? localStorage.getItem("CognitoIdentityServiceProvider.company")
      : customer;
  useEffect(() => {
    let tempDateObj = {};

    for (const i in dateLabel) {
      const str = dateLabel[i];
      const convertedDate = moment(str, "MMM D, YYYY").format("YYYY-MM-DD");
      convertedDateArr.push(convertedDate);
      tempDateObj[convertedDate] = { total: { totalCams: 0, totalAll: 0 } };
    }
    setServerDayWise(tempDateObj);
    segregateData();

    let formattedData = [
      {
        serverName: "",
        alertType: "",
        severityLevel: "",
        alerts: "",
        date1: dateLabel[0],
        date2: dateLabel[1],
        date3: dateLabel[2],
        date4: dateLabel[3],
        date5: dateLabel[4],
        date6: dateLabel[5],
        total: "Total",
        popUpTrue: false,
      },
      {
        serverName: "",
        alertType: "",
        severityLevel: "",
        alerts: "",
        date1A: "A",
        date1CP: "SAP",
        date2A: "A",
        date2CP: "SAP",
        date3A: "A",
        date3CP: "SAP",
        date4A: "A",
        date4CP: "SAP",
        date5A: "A",
        date5CP: "SAP",
        date6A: "A",
        date6CP: "SAP",
        totalA: "A",
        totalCP: "SAP",
        popUpTrue: false,
      },
    ];
    severityWeeklyData.forEach((data) => {
      let dataObj = {};
      dataObj.serverName = data.server_name;
      dataObj.alertType = data.alert_type;
      dataObj.severityLevel = data.severity;
      dataObj.alerts = data.subject;
      let subject = data.subject;
      let date = "";
      date = data.date;
      let server = data.server_name;
      let total = 0;
      let cams = 0;
      dataObj.date = date;
      total =
        serverDayWise[convertedDateArr[0]] &&
        serverDayWise[convertedDateArr[0]][server] &&
        serverDayWise[convertedDateArr[0]][server][subject]
          ? total + serverDayWise[convertedDateArr[0]][server][subject].total
          : total + 0;
      total =
        serverDayWise[convertedDateArr[1]] &&
        serverDayWise[convertedDateArr[1]][server] &&
        serverDayWise[convertedDateArr[1]][server][subject]
          ? total + serverDayWise[convertedDateArr[1]][server][subject].total
          : total + 0;
      total =
        serverDayWise[convertedDateArr[2]] &&
        serverDayWise[convertedDateArr[2]][server] &&
        serverDayWise[convertedDateArr[2]][server][subject]
          ? total + serverDayWise[convertedDateArr[2]][server][subject].total
          : total + 0;
      total =
        serverDayWise[convertedDateArr[3]] &&
        serverDayWise[convertedDateArr[3]][server] &&
        serverDayWise[convertedDateArr[3]][server][subject]
          ? total + serverDayWise[convertedDateArr[3]][server][subject].total
          : total + 0;
      total =
        serverDayWise[convertedDateArr[4]] &&
        serverDayWise[convertedDateArr[4]][server] &&
        serverDayWise[convertedDateArr[4]][server][subject]
          ? total + serverDayWise[convertedDateArr[4]][server][subject].total
          : total + 0;
      total =
        serverDayWise[convertedDateArr[5]] &&
        serverDayWise[convertedDateArr[5]][server] &&
        serverDayWise[convertedDateArr[5]][server][subject]
          ? total + serverDayWise[convertedDateArr[5]][server][subject].total
          : total + 0;

      cams =
        serverDayWise[convertedDateArr[0]] &&
        serverDayWise[convertedDateArr[0]][server] &&
        serverDayWise[convertedDateArr[0]][server][subject]
          ? cams + serverDayWise[convertedDateArr[0]][server][subject].cams
          : cams + 0;
      cams =
        serverDayWise[convertedDateArr[1]] &&
        serverDayWise[convertedDateArr[1]][server] &&
        serverDayWise[convertedDateArr[1]][server][subject]
          ? cams + serverDayWise[convertedDateArr[1]][server][subject].cams
          : cams + 0;
      cams =
        serverDayWise[convertedDateArr[2]] &&
        serverDayWise[convertedDateArr[2]][server] &&
        serverDayWise[convertedDateArr[2]][server][subject]
          ? cams + serverDayWise[convertedDateArr[2]][server][subject].cams
          : cams + 0;
      cams =
        serverDayWise[convertedDateArr[3]] &&
        serverDayWise[convertedDateArr[3]][server] &&
        serverDayWise[convertedDateArr[3]][server][subject]
          ? cams + serverDayWise[convertedDateArr[3]][server][subject].cams
          : cams + 0;
      cams =
        serverDayWise[convertedDateArr[4]] &&
        serverDayWise[convertedDateArr[4]][server] &&
        serverDayWise[convertedDateArr[4]][server][subject]
          ? cams + serverDayWise[convertedDateArr[4]][server][subject].cams
          : cams + 0;
      cams =
        serverDayWise[convertedDateArr[5]] &&
        serverDayWise[convertedDateArr[5]][server] &&
        serverDayWise[convertedDateArr[5]][server][subject]
          ? cams + serverDayWise[convertedDateArr[5]][server][subject].cams
          : cams + 0;

      dataObj.date1A =
        serverDayWise[convertedDateArr[0]] &&
        serverDayWise[convertedDateArr[0]][server] &&
        serverDayWise[convertedDateArr[0]][server][subject]
          ? serverDayWise[convertedDateArr[0]][server][subject].total
          : "0";
      dataObj.date1CP =
        serverDayWise[convertedDateArr[0]] &&
        serverDayWise[convertedDateArr[0]][server] &&
        serverDayWise[convertedDateArr[0]][server][subject]
          ? serverDayWise[convertedDateArr[0]][server][subject].cams
          : "0";
      dataObj.date2A =
        serverDayWise[convertedDateArr[1]] &&
        serverDayWise[convertedDateArr[1]][server] &&
        serverDayWise[convertedDateArr[1]][server][subject]
          ? serverDayWise[convertedDateArr[1]][server][subject].total
          : "0";
      dataObj.date2CP =
        serverDayWise[convertedDateArr[1]] &&
        serverDayWise[convertedDateArr[1]][server] &&
        serverDayWise[convertedDateArr[1]][server][subject]
          ? serverDayWise[convertedDateArr[1]][server][subject].cams
          : "0";
      dataObj.date3A =
        serverDayWise[convertedDateArr[2]] &&
        serverDayWise[convertedDateArr[2]][server] &&
        serverDayWise[convertedDateArr[2]][server][subject]
          ? serverDayWise[convertedDateArr[2]][server][subject].total
          : "0";
      dataObj.date3CP =
        serverDayWise[convertedDateArr[2]] &&
        serverDayWise[convertedDateArr[2]][server] &&
        serverDayWise[convertedDateArr[2]][server][subject]
          ? serverDayWise[convertedDateArr[2]][server][subject].cams
          : "0";
      dataObj.date4A =
        serverDayWise[convertedDateArr[3]] &&
        serverDayWise[convertedDateArr[3]][server] &&
        serverDayWise[convertedDateArr[3]][server][subject]
          ? serverDayWise[convertedDateArr[3]][server][subject].total
          : "0";
      dataObj.date4CP =
        serverDayWise[convertedDateArr[3]] &&
        serverDayWise[convertedDateArr[3]][server] &&
        serverDayWise[convertedDateArr[3]][server][subject]
          ? serverDayWise[convertedDateArr[3]][server][subject].cams
          : "0";
      dataObj.date5A =
        serverDayWise[convertedDateArr[4]] &&
        serverDayWise[convertedDateArr[4]][server] &&
        serverDayWise[convertedDateArr[4]][server][subject]
          ? serverDayWise[convertedDateArr[4]][server][subject].total
          : "0";
      dataObj.date5CP =
        serverDayWise[convertedDateArr[4]] &&
        serverDayWise[convertedDateArr[4]][server] &&
        serverDayWise[convertedDateArr[4]][server][subject]
          ? serverDayWise[convertedDateArr[4]][server][subject].cams
          : "0";
      dataObj.date6A =
        serverDayWise[convertedDateArr[5]] &&
        serverDayWise[convertedDateArr[5]][server] &&
        serverDayWise[convertedDateArr[5]][server][subject]
          ? serverDayWise[convertedDateArr[5]][server][subject].total
          : "0";
      dataObj.date6CP =
        serverDayWise[convertedDateArr[5]] &&
        serverDayWise[convertedDateArr[5]][server] &&
        serverDayWise[convertedDateArr[5]][server][subject]
          ? serverDayWise[convertedDateArr[5]][server][subject].cams
          : "0";
      dataObj.totalA = total > 0 ? total.toString() : "0";
      dataObj.totalCP = cams > 0 ? cams.toString() : "0";

      formattedData.push(dataObj);
    });
    // console.log("formattedData");
    // console.log(formattedData);
    const start_count = page_number * 10 - 10;
    const end_count = page_number * 10;
    const firstTenRecords = formattedData.slice(start_count, end_count);
    setTotalRecordsCount(formattedData.length);
    // console.log(firstTenRecords);
    // console.log(formattedData);
    setTableData(formattedData);
    setLoading(false);
  }, [severityWeeklyData, dateLabel, page_number]);

  // useEffect(() => {}, [camsDetails]);

  const segregateData = () => {
    severityWeeklyData.forEach((data) => {
      let tempServCount = {};
      tempServCount["cams"] =
        data.cams_processed && data.cams_processed !== null
          ? parseInt(data.cams_processed)
          : 0;
      tempServCount["total"] =
        data.alert_count && data.alert_count !== null
          ? parseInt(data.alert_count)
          : 0;

      if (!serverDayWise[data.date]) {
        serverDayWise[data.date] = {};
      }
      if (!serverDayWise[data.date][data.server_name]) {
        serverDayWise[data.date][data.server_name] = {};
      }
      if (!serverDayWise[data.date][data.server_name][data.subject]) {
        serverDayWise[data.date][data.server_name][data.subject] = {};
      }

      serverDayWise[data.date][data.server_name][data.subject] = tempServCount;
    });
  };

  const dispatch = useDispatch();

  const [isShow, setIsShow] = useState(false);
  const handleCancel = () => {
    setIsShow(false);
  };
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const camsValueClick = (item, type) => {
    setType("cams");

    let originalString = item.alerts;
    let modifiedString = originalString.replace(/"/g, "\\$&");
    dispatch(
      getCamsAlertsDetails({
        companyName: company_name,
        selectedTopdate: convertedDateArr[type],
        alert_type: item.alertType,
        server_name: item.serverName,
        severity: item.severityLevel,
        subject: modifiedString,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setCamsDetails(data.cams_severity_camsprocessed_table);
        setIsShow(true);
      });
  };

  const allAlertsClick = (item, type) => {
    setType("all");
    dispatch(
      getAllAlertsDetails({
        companyName: company_name,
        selectedTopdate: convertedDateArr[type],
        alert_type: item.alertType,
        server_name: item.serverName,
        severity: item.severityLevel,
        subject: item.alerts,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setCamsDetails(data.cams_severity_alert_table);
        setIsShow(true);
      });
  };
  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      console.log("sorted");
      console.log(sorted);
      setTableData(sorted);
    }
  };
  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
    <>
      <div className="alert-table-two">
        <div className="alert-detail-table">
          <div className="alert-detail-table2-height">
            <Spin spinning={loading}>
              <Table className="table responsive">
                <thead>
                  <tr>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSortingChange("serverName")}
                    >
                      Server Name &nbsp; <Icon icon={"carbon:caret-sort"} />
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSortingChange("alertType")}
                    >
                      Alert Type &nbsp; <Icon icon={"carbon:caret-sort"} />
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSortingChange("severityLevel")}
                    >
                      Severtiy Level &nbsp; <Icon icon={"carbon:caret-sort"} />
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSortingChange("alerts")}
                    >
                      Alert &nbsp; <Icon icon={"carbon:caret-sort"} />
                    </th>
                    <th style={{ textAlign: "center" }} colSpan={7}>
                      Alerts/Smart Alert Processed &nbsp;{" "}
                      <Icon icon={"carbon:caret-sort"} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item) => {
                    return (
                      <tr>
                        <td>{item.serverName}</td>
                        <td>{item.alertType}</td>
                        <td>{item.severityLevel}</td>
                        <td>{item.alerts}</td>
                        {item.popUpTrue ? (
                          <>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.date1}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.date2}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.date3}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.date4}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.date5}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.date6}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsShow(true);
                              }}
                            >
                              {item.total}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              {item.date1A ? (
                                <>
                                  {" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => allAlertsClick(item, 0)}
                                  >
                                    {item.date1A}{" "}
                                  </span>{" "}
                                  |{" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => camsValueClick(item, 0)}
                                  >
                                    {" "}
                                    {item.date1CP}{" "}
                                  </span>
                                </>
                              ) : (
                                <> {item.date1}</>
                              )}
                            </td>
                            <td>
                              {item.date2A ? (
                                <>
                                  {" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => allAlertsClick(item, 1)}
                                  >
                                    {item.date2A}{" "}
                                  </span>{" "}
                                  |{" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => camsValueClick(item, 1)}
                                  >
                                    {" "}
                                    {item.date2CP}{" "}
                                  </span>
                                </>
                              ) : (
                                <> {item.date2}</>
                              )}
                            </td>
                            <td>
                              {item.date3A ? (
                                <>
                                  {" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => allAlertsClick(item, 2)}
                                  >
                                    {item.date3A}{" "}
                                  </span>{" "}
                                  |{" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => camsValueClick(item, 2)}
                                  >
                                    {" "}
                                    {item.date3CP}{" "}
                                  </span>
                                </>
                              ) : (
                                <> {item.date3}</>
                              )}
                            </td>
                            <td>
                              {item.date4A ? (
                                <>
                                  {" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => allAlertsClick(item, 3)}
                                  >
                                    {item.date4A}{" "}
                                  </span>{" "}
                                  |{" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => camsValueClick(item, 3)}
                                  >
                                    {" "}
                                    {item.date4CP}{" "}
                                  </span>
                                </>
                              ) : (
                                <> {item.date4}</>
                              )}
                            </td>
                            <td>
                              {item.date5A ? (
                                <>
                                  {" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => allAlertsClick(item, 4)}
                                  >
                                    {item.date5A}{" "}
                                  </span>{" "}
                                  |{" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => camsValueClick(item, 4)}
                                  >
                                    {" "}
                                    {item.date5CP}{" "}
                                  </span>
                                </>
                              ) : (
                                <> {item.date5}</>
                              )}
                            </td>
                            <td>
                              {item.date6A ? (
                                <>
                                  {" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => allAlertsClick(item, 5)}
                                  >
                                    {item.date6A}{" "}
                                  </span>{" "}
                                  |{" "}
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => camsValueClick(item, 5)}
                                  >
                                    {" "}
                                    {item.date6CP}{" "}
                                  </span>
                                </>
                              ) : (
                                <> {item.date6}</>
                              )}
                            </td>
                            <td>
                              {item.totalA ? (
                                <>
                                  {" "}
                                  <span>{item.totalA} </span> |{" "}
                                  <span> {item.totalCP}</span>
                                </>
                              ) : (
                                <> {item.total}</>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {/* <BootstrapTablePagination
                page_number={page_number}
                setPageNumber={setPageNumber}
                totalrecordscount={totalrecordscount}
              /> */}
            </Spin>
          </div>
        </div>
      </div>
      {isShow && (
        <>
          <ModalPopUp
            title="Ticket Information"
            open={isShow}
            handleCancel={handleCancel}
            data={
              <TicketInformationTableAlerts
                type={type}
                camsAllDetailsData={camsDetails}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default AlertDetailsTableTwo;
