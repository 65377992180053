import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CostimizeLogo from "../../assets/images/costimize_logo.svg";
import SiteLogo from "../../assets/images/header_logo.svg";
import OperationLogo from "../../assets/images/costimize_logo.svg";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import { userInfoMethod } from "../slice/userSlice";
import { FaBell } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";
import { TbGridDots } from "react-icons/tb";
import { Avatar } from "antd";

import logo2 from "../../assets/images/logo2.svg";
import { signOut } from "../amplify/auth-config";
import { loginUser } from "../action/userAction";
import { Icon } from "@iconify/react";

const GeneralHeader = () => {
  const navigate = useNavigate();
  const [platformlogo, setPlatformLogo] = useState(SiteLogo);
  // const [showBasic, setShowBasic] = useState(false);
  // const [path, setPath] = useState(null);
  const { userInfo } = useSelector((state) => state.user);
  const [userinfostatus, setUserInfoStatus] = useState(false);
  const [userdetails, setUserDetails] = useState([]);
  const dispatch = useDispatch();
  const userLogout = () => {
    //   dispatch(userInfoMethod({}));
    //   // navigate('/signin')
    //   navigate('/signin', { replace: true });
    //   window.location.reload();
    dispatch(signOut())
      .unwrap()
      .then(() => {
        localStorage.removeItem("_token");
        localStorage.removeItem("CognitoIdentityServiceProvider.auth");
        window.location.href = "/signin";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    var path = window.location.pathname;
    const path_array = path.split("/");
    if (path_array[1] === "costimize") {
      setPlatformLogo(logo2);
    } else if (path_array[1] === "operation") {
      setPlatformLogo(OperationLogo);
    } else {
      setPlatformLogo(SiteLogo);
    }

    //useinfo
    var token = localStorage.getItem("_token");
    var auth_user = localStorage.getItem("CognitoIdentityServiceProvider.auth");

    if (token) {
      setUserInfoStatus(true);
    } else {
      setUserInfoStatus(false);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const records = {
      user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
      _token: localStorage.getItem("_token"),
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        // console.log("data");
        // console.log(data.getPlatformUsers);
        // console.log(data.getPlatformUsers.email_id)
        // console.log(data.getPlatformUsers);
        setUserDetails(data.getPlatformUsers);
        // console.log(Array.from(userdetails.first_name)[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.pathname]);

  const customer_image =
    "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img(31).webp";
  return (
    <>
      <Navbar expand="lg" className="plat-navbar" sticky="top">
        <Container fluid className="plat-container">
          <Navbar.Brand
            onClick={() => navigate("/")}
            className="plat-navbar-brand"
          >
            {" "}
            <img
              height="80px"
              width="80px"
              src={platformlogo}
              alt="Capture"
            />{" "}
            &nbsp; <span className="nav-header-logo-text">CloudShifu</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="plat-navbar-collapse">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {/* <NavDropdown title="Solutions" id="navbarScrollingDropdown" className='navbar-top-menu'>
                            <NavDropdown.Item href="#"  className='navbar-top-submenu'>-</NavDropdown.Item>
                        </NavDropdown> */}
            </Nav>
            <Form className="d-flex">
              {userinfostatus ? (
                <>
                  {/* <Icon icon="mdi:dots-grid"  class='menu-icon-without-bg'></Icon>
                            <TbGridDots className='menu-icon-without-bg'/> */}
                  {/* <NavDropdown
                    title={<TbGridDots className="menu-icon-without-bg" />}
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu plat-navbar-top-menu"
                  ></NavDropdown>

                  <NavDropdown
                    title={<IoIosSearch className="menu-icon-with-bg" />}
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu plat-navbar-top-menu"
                  ></NavDropdown> */}
                  {userdetails.role !== "user" && (
                    <Button
                      variant="outline-success menu-admin-panel"
                      onClick={() => navigate("/admin-actions")}
                    >
                      <Icon icon="clarity:administrator-solid" /> Admin
                    </Button>
                  )}
                  {/* <NavDropdown
                    title={<RiSettings3Fill className="menu-icon-with-bg" />}
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu plat-navbar-top-menu"
                  ></NavDropdown>

                  <NavDropdown
                    title={<FaBell className="menu-icon-with-bg" />}
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu plat-navbar-top-menu"
                  ></NavDropdown> */}

                  <NavDropdown
                    title={
                      <Avatar
                        style={{
                          backgroundColor: "#1b5f9d",
                          color: "#fff",
                        }}
                      >
                        {userdetails.first_name && (
                          <>{Array.from(userdetails.first_name)[0]}</>
                        )}
                        {/* {Array.from(userdetails.first_name)[0]} */}
                      </Avatar>
                    }
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu plat-navbar-top-menu"
                  >
                    {userdetails.first_name ? (
                      <>
                        <label className="menu-topicon-username">
                          {userdetails.first_name}
                        </label>
                        <NavDropdown.Divider />
                      </>
                    ) : (
                      <></>
                    )}

                    {userdetails.role === "master" ||
                    userdetails.role === "admin" ? (
                      <>
                        {userdetails.role === "master" && (
                          <>
                            <NavDropdown.Item
                              className="navbar-top-submenu"
                              onClick={() => navigate("/admin-dashboard")}
                            >
                              Dashboard
                            </NavDropdown.Item>
                          </>
                        )}
                        <NavDropdown.Item
                          className="navbar-top-submenu"
                          onClick={() => navigate("/admin-actions")}
                        >
                          Admin
                        </NavDropdown.Item>
                      </>
                    ) : (
                      <></>
                    )}
                    <NavDropdown.Item
                      className="navbar-top-submenu"
                      onClick={userLogout}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <Button
                    variant="outline-success"
                    color="link"
                    className="active-btn ms-2"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </Button>
                  <Button
                    variant="outline-success"
                    color="link"
                    className="active-btn ms-2"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default GeneralHeader;
