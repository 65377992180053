import { Col, Collapse, Row, Spin } from "antd";
import React from "react";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { Icon } from "@iconify/react";
import { capitalizeFirst, numberFormat } from "../../../custom_hook/CustomHook";
// import awsicon from "../../../../assets/images/aws.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterAllKubernetesList } from "../../../action/costimizedashboardAction";
import AssetSpendListEnvironment from "../AssetSpendListEnvironment";
import K8_EnvironmentList from "./k8_EnvironmentList";

function Kubernetes_CostDemaze(props) {
  const [preloader, setPreloader] = useState(true);
  const {
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    environmentList,
    dummydatastatus,
  } = useSelector((state) => state.constimize);

  const [environmentdetails, setEnvironmentDetails] = useState({
    title: "All Environment",
    spend: "-",
    name: "-",
    size: "-",
    ram: "-",
    core: "-",
    storage: "-",
    savings_opportunity: "-",
    spend_trend: "",
    count: 0,
  });
  const [listenvironmentdetails, setListEnvironmentDetails] = useState([]);
  const [allenvironmentlist, setAllEnvironmentList] = useState([]);
  const [isRowClicked, setIsRowClicked] = useState(false);

  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {}, [
    companyName,
    selectedTopdate,
    environmentList,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    isRowClicked,
  ]);
  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: "EKS",
      db: selecteddb.toString(),
    };

    dispatch(
      filterAllKubernetesList({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        if (data.newfunctionquery36.length > 0) {
          console.log(data.newfunctionquery36,"datafunction")
          setAllEnvironmentList(data.newfunctionquery36);
          var first_month = month_list[0];
          var second_month = month_list[1];
          var thired_month = month_list[2];
          //enviroment list
          const enviroment = data.newfunctionquery36.map((v) =>
            v.environment?.toLowerCase()
          );
          const resourcename = data.newfunctionquery36.map((v) =>
            v.resourcename?.toLowerCase()
          );
          var enviromentlist = new Set([...enviroment]);
          enviromentlist = [...enviromentlist];
          const spendtotal = data.newfunctionquery36.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //count
          const totalcount = data.newfunctionquery36.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );

          console.log(totalcount,"totalcountvalue")

          const savings_opportunity = data.newfunctionquery36.reduce(
            (a, v) => (a = +a + +v.savings_opportunity),
            0
          );

          //environment total
          setEnvironmentDetails({
            title: "All Environment",
            resourcename: resourcename,
            spend: Math.round(spendtotal),
            name: "-",
            savings_opportunity: !savings_opportunity
              ? 0
              : Math.round(savings_opportunity),
            count: totalcount,
          });
        }
        // console.log("in");
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
  }, [
    companyName,
    selectedTopdate,
    environmentList,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    props,
  ]);
  var titles = "all";
  var cloud = "all";
  var application = "all";
  var resname = "all";
  // const handleCheckCheckBoxClick = (e) => {
  //   console.log(e.target.checked);
  // };

  const [isShowEnvironment, setIsShowEnvironment] = useState(false);

  const handleOnClick = () => {
    setIsShowEnvironment(!isShowEnvironment);
  };
  return (
    <>
      <Spin spinning={preloader} wrapperClassName="costimize-loader">
        <div>
          <Row
            onClick={() => handleOnClick("cancelAll")}
            className={
              props.classType == "slm "
                ? "assent-spend-head slm-bg"
                : "assent-spend-head"
            }
          >
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="color-orange plat-text-center"
            >
              Environment
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="color-orange plat-text-center"
            >
              Spend
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="color-orange plat-text-center"
            >
              Name
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="color-orange plat-text-center"
            >
              Savings Oppurtunity
            </Col>
          </Row>
          <div className="">
            {/* <input
              className="cd-accordion__inputs"
              type="checkbox"
              name="group-k8"
              id="group-k8-id"
              // id="group-k8"
              // defaultChecked
              onChange={handleCheckCheckBoxClick}
            /> */}
            <label
              className="cd-accordion__label plat-asset-spend-body-vs"
              // htmlFor="group-k8"
              style={{ margin: "10px 0px" }}
              onClick={handleOnClick}
            >
              <span className="accordion-full-width ">
                <ToggleButtonV2 status={true} newclass="all-environment-icon">
                  <Row className="plat-table-assets-spend-thead p-3 ">
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className="asset-spend-title-vs"
                    >
                      All Environment(
                      {environmentdetails.count
                        ? environmentdetails.count
                         : 0}
                        {/* {environmentdetails.count} */}
                      )
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className="asset-spend-values-vs justify-content-center"
                    >
                      $
                      {numberFormat(environmentdetails.spend) == "NaN"
                        ? 0
                        : numberFormat(environmentdetails.spend)}
                      <Icon
                        className={
                          "me-1 " +
                          (environmentdetails.spend > 0
                            ? "text-danger-icon-custom"
                            : "text-success-icon-custom")
                        }
                        icon={
                          environmentdetails.spend > 0
                            ? "material-symbols:arrow-drop-up"
                            : "material-symbols:arrow-drop-down"
                        }
                        style={{ fontSize: "20px" }}
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className="asset-spend-values-vs justify-content-center"
                    >
                      -
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className="asset-spend-values-vs justify-content-center"
                    >
                      {numberFormat(environmentdetails.savings_opportunity) ==
                      "NaN"
                        ? 0
                        : numberFormat(environmentdetails.savings_opportunity)}
                    </Col>
                  </Row>
                </ToggleButtonV2>
                <div className="asset-spend-border-bottom-vs"></div>
              </span>
            </label>
            {console.log(allenvironmentlist)}
            {isShowEnvironment && (
              <>
                <K8_EnvironmentList allenvironmentlist={allenvironmentlist} />
              </>
            )}
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Kubernetes_CostDemaze;
