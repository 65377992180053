import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col, Row, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import {
  getAssetSpendVMResorcesRecords,
  getK8_ResourcenameRecords,
} from "../../../action/costimizeV2Active";
import { numberFormat } from "../../../custom_hook/CustomHook";
import DetailsView from "../DetailsView";

function K8_ResourceName(props) {
  console.log(props, "res");
  const [allresorceslist, setAllResorcesList] = useState([]);
  const [detailsviewstatus, setDetailsViewStatus] = useState(false);
  const [popupdetails, setPopupDetails] = useState([]);
  const dispatch = useDispatch();
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var first_month = month_list[0];
    var second_month = month_list[1];
    var thired_month = month_list[2];

    var apivalue = {
      environment: props.environment,
      account: selectedAccount.toString(),
      cloud: props.cloud,
      application: props.application,
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    dispatch(
      getK8_ResourcenameRecords({
        companyName,
        selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        // console.log(data)
        if (data.newfunctionquery35.length > 0) {
          const application = data.newfunctionquery35?.map(
            (v) => v.resourcename
          );
          // console.log(application)
          var applicationlist = new Set([...application]);
          applicationlist = [...applicationlist];
          const cloud_all_list = [];
          applicationlist.map((data_map) => {
            const cloud_data_list = data.newfunctionquery35?.filter((datas) => {
              return (
                datas.resourcename.toLowerCase() === data_map.toLowerCase()
              );
            });
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list,
              first_month,
              second_month,
              thired_month
            );
            // console.log(return_environment);
            cloud_all_list.push(return_environment);
          });
          // console.log(cloud_all_list);
          setAllResorcesList(cloud_all_list);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);

    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: data_list[0].resourcename,
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      count: data_list.length,
      index: data_list[0],
    };
  };

  const openPopupDetails = (data) => {
    setPopupDetails(data);
    setDetailsViewStatus(true);
  };

  return (
    <div>
      {/* {detailsviewstatus && (
        <DetailsView
          detailsviewstatus={detailsviewstatus}
          setDetailsViewStatus={setDetailsViewStatus}
          details={popupdetails}
        />
      )} */}
      {allresorceslist.map((resourcelist) => (
        <div className="p-3">
          {/* <a
            className="cd-accordion__label cd-accordion__label--icon-img"
            href="javascript:void(0)"
          > */}
          <span className="accordion-full-width">
            <Row className="plat-table-assets-spend-thead">
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="asset-left-title cloud-list-title asset-spend-title-vs"
              >
                {resourcelist.title}
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="asset-spend-values-vs plat-text-right"
              >
                ${resourcelist.spend}
                <Icon
                  className={
                    "me-1 " +
                    (resourcelist.spend > 0
                      ? "text-danger-icon-custom"
                      : "text-success-icon-custom")
                  }
                  icon={
                    resourcelist.spend > 0
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="asset-spend-values-vs justify-content-center"
              >
                <span onClick={() => openPopupDetails(resourcelist.index)}>
                  {resourcelist.name.length > 10 ? (
                    <>
                      <Tooltip placement="bottom" title={resourcelist.name}>
                        {resourcelist.name.substring(0, 10)}
                        ...
                      </Tooltip>
                    </>
                  ) : (
                    resourcelist.name
                  )}
                </span>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="asset-spend-values-vs justify-content-center"
              >
                ${numberFormat(resourcelist.savings_opportunity)}
              </Col>
            </Row>
            <div className="asset-spend-border-bottom-vs"></div>
          </span>
          {/* </a> */}
        </div>
      ))}
    </div>
  );
}

export default K8_ResourceName;
