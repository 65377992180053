import { memo, useEffect, useState } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Icon } from "@iconify/react";
import UnrealizedForm from "./UnrealizedForm";
import { Table, Typography, Tooltip, Spin } from "antd";
import {
  numberFormat,
  checkRolePermission,
} from "../../custom_hook/CustomHook";
import Dropdown from "react-bootstrap/Dropdown";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useSelector, useDispatch } from "react-redux";
import MyDocument from "../../dashboard-v2/NewSavingsModel/MyDocument";
import { headercustomerlogo } from "../../action/header_logo";

const { Text } = Typography;

const UnrealizedLayout = (props) => {
  //state
  const dispatch = useDispatch();
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [recommendationlist, setRecommendationList] = useState([]);
  const [formType, setFormType] = useState("");
  const [oldUpdateRecords, setOldUpdateRecords] = useState({});
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [company_logo, setCompanyLogo] = useState();

  const [permission, setPermission] = useState({
    savingswrite: checkRolePermission("savings_model")
      ? checkRolePermission("savings_model").write
      : 0,
  });
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    var value_list = [];
    var keyvalue = 1;
    const tablelist = props.records.map((data, index) => {
      if (
        data.realised_savings === null ||
        Math.round(data.realised_savings) === 0
      ) {
        value_list.push({
          key: keyvalue,
          date: data.report_month,
          account: data.account_names,
          application: data.applications,
          environment: data.environments,
          services: data.services,
          resource_sub_category: data.resource_sub_category,
          recommendation_type: data.recommendation_rule,
          resource_id: data.resource_id,
          resource_name: data.resource_name,
          current_configuration: data.current_configuration,
          recommended_configuration: data.recommended_configuration,
          commitment: data.commitment,
          potential_savings: Number(data.potential_savings),
          realized_savings: data.realised_savings,
          lost_opportunity: data.lost_opportunity,
          action: "",
          type: "data",
        });
        keyvalue++;
      }
    });
    //recommendation type
    const recommendation_list = value_list.map((v) => v.recommendation_type);
    const recommendation_set = new Set([...recommendation_list]);
    const recommendation = [...recommendation_set];
    var recom_list = [];
    recommendation.map((value) => {
      recom_list.push({
        text: value,
        value: value,
      });
    });
    setRecommendationList(recom_list);
    const potential = value_list.reduce(
      (a, v) => (a = +a + +v?.potential_savings),
      0
    );
    const realised = value_list.reduce(
      (a, v) => (a = +a + +v?.realized_savings),
      0
    );
    setTableRecords(value_list);

    setColumns([
      // {
      //   title: "Month",
      //   dataIndex: "date",
      //   key: "date",
      //   sorter: (a, b) => {
      //     return a.date > b.date ? -1 : 1;
      //   },
      //   render: (record) => {
      //     return record;
      //   },
      // },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        sorter: (a, b) => {
          return a.account > b.account ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Application",
        dataIndex: "application",
        key: "application",
        fixed: "left",
        sorter: (a, b) => {
          return a.application > b.application ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Environment",
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => {
          return a.environment > b.environment ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Services",
        dataIndex: "services",
        key: "services",
        sorter: (a, b) => {
          return a.services > b.services ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Resource Name",
        key: "resource_name",
        dataIndex: "resource_name",
        sorter: (a, b) => {
          return a.resource_name > b.resource_name ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Current Configuration",
        dataIndex: "current_configuration",
        key: "current_configuration",
        align:"left",
        sorter: (a, b) => {
          return a.current_configuration > b.current_configuration ? -1 : 1;
        },
        render: (record) => {
          if (record.length > 16) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 16)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }

          // if (isJSON(record)) {
          //   console.log("in2");
          //   console.log(JSON.parse(record));
          // } else {
          //   console.log("out");
          //   console.log(record);
          // }
        },
      },
      {
        title: "Recommended Configuration",
        dataIndex: "recommended_configuration",
        key: "recommended_configuration",
        align:"left",
        sorter: (a, b) => {
          return a.environment > b.environment ? -1 : 1;
        },
        render: (record) => {
          if (record.length > 16) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 16)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Recommendation Type",
        dataIndex: "recommendation_type",
        key: "recommendation_type",
        filters: recom_list,
        onFilter: (value, record) =>
          record.recommendation_type.indexOf(value) === 0,
        sorter: (a, b) => {
          return a.recommendation_type > b.recommendation_type ? -1 : 1;
        },
      },
      {
        title: "Commitment",
        dataIndex: "commitment",
        key: "commitment",
        sorter: (a, b) => {
          return a.commitment > b.commitment ? -1 : 1;
        },
        render: (record) => {
          if (record) {
            return <>{record}</>;
          } else {
            return <>-</>;
          }
        },
      },
      {
        title: "Potential Savings",
        dataIndex: "potential_savings",
        key: "potential_savings",
        sorter: (a, b) => {
          return a.potential_savings > b.potential_savings ? -1 : 1;
        },
        render: (record) => {
          return (
            <>
              <div className="number_right">${numberFormat(record)}</div>
            </>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (record, index) => {
          if (props.rolestatus) {
            if (permission.savingswrite) {
              return (
                <>
                  <div
                    onClick={() => showEditRow(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon icon="material-symbols:edit" />
                  </div>
                </>
              );
            } else {
              return <></>;
            }
          } else {
            return <></>;
          }
        },
      },
    ]);
  }, [props]);

  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.png_logo;
        var testimg = `data:image/png;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const tableProps = {};

  const showEditRow = (data) => {
    setFormType("update");
    setOldUpdateRecords(data);
    setDrawerStatus(true);
  };
  const showAddRow = () => {
    setDrawerStatus(true);
    setFormType("add");
    setOldUpdateRecords({});
  };
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handlePageChange = (page) => {
    console.log(page);
    // setCurrentPage(page);
    // Fetch data for the new page
    // fetchData(page);
  };
  console.log(tablerecords)
  return (
    <>
      <UnrealizedForm
        drawerstatus={drawerstatus}
        setDrawerStatus={setDrawerStatus}
        oldUpdateRecords={oldUpdateRecords}
        formType={formType}
        recordsudpate={props.recordsudpate}
        setRecordsUpdate={props.setRecordsUpdate}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {company_name !== "gmmco" && (
          <>
            {tablerecords.length > 0 && (
              <div style={{ margin: "10px 0px" }}>
                <Dropdown className="plat-costimize-dropdown">
                  <Dropdown.Toggle
                    className="plat-three-dort plat-tap-menu plat-box-three-dort"
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    {/* <Icon
                      icon="bi:file-earmark-pdf-fill"
                      style={{ fontSize: "24px", color: "#b70a00" }}
                    /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="plat-box-menu-list more-details">
                    <Dropdown.ItemText className="plat-dropdown-item-text">
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            date={selectedTopdate}
                            recommendationlist={recommendationlist}
                            records={tablerecords}
                            company_logo={company_logo}
                          />
                        }
                        fileName={`Cost_Savings_${Math.random()
                          .toString(16)
                          .slice(2)}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <span className="plat-box-item-title list-expand-left">
                              Loading...
                            </span>
                          ) : (
                            <span className="plat-box-item-title list-expand-left">
                              Cost Savings
                            </span>
                          )
                        }
                      </PDFDownloadLink>
                    </Dropdown.ItemText>
                    {/* <Dropdown.ItemText className="plat-dropdown-item-text">
                <span className="plat-box-item-title list-expand-left">
                  Licence Savings
                </span>
              </Dropdown.ItemText> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </>
        )}

        {props.rolestatus && permission.savingswrite ? (
          <>
            <button className="table-add-button" onClick={() => showAddRow()}>
              <Icon icon="ion:add-circle-outline" />
              Add
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="costimize-table-width">
        <Spin
          spinning={props.lsavingsstatus}
          wrapperClassName="costimize-loader"
        >
          <Table
            {...tableProps}
            className="costimize-bootstrap-table"
            columns={columns}
            dataSource={tablerecords}
            pagination={true}
            // pagination={{
            //   current: 1,
            //   total: 100,
            //   onChange: handlePageChange,
            // }}
            // pagination={{
            //   showSizeChanger: true,
            //   showQuickJumper: true,
            //   defaultPageSize: 25,
            //   pageSizeOptions: ["25", "50", "100"],
            //   position: ["bottomLeft"],
            //   size: "default test",
            // }}
            summary={(pageData) => {
              const potential = pageData.reduce(
                (a, v) => (a = +a + +v?.potential_savings),
                0
              );
              return (
                <>
                  <Table.Summary.Row className="costimize-savings-table-footer">
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                      <Text className="number_right white-color">
                        ${numberFormat(potential)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Spin>
      </div>
    </>
  );
};

export default memo(UnrealizedLayout);
