import { memo, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  message,
  Switch,
  Checkbox,
} from "antd";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { updateRole } from "../../action/accountAction";
import { useDispatch } from "react-redux";

const RoleEditForm = (props) => {
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [form] = Form.useForm();
  const [switchstatus, setSwitchStatus] = useState(true);
  const [compnaylist, setCompanyList] = useState([]);
  const [buttonloading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();

  //block
  const [costimizestatus, setCostimizeStatus] = useState(false);

  useEffect(() => {
    setCompanyList([
      {
        label: props.selectedrecords.customer,
        value: props.selectedrecords.customer,
      },
    ]);
    setSwitchStatus(
      props.selectedrecords.role_category === "Custom" ? true : false
    );
    if (props.selectedrecords.offering) {
      if (props.selectedrecords.offering.indexOf("costimize") !== -1) {
        setCostimizeStatus(true);
      }
    }

    const user_management = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "user_management"
    );
    const role_management = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "role_management"
    );
    const account_onboarding = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "account_onboarding"
    );
    const quick_glance = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "quick_glance"
    );
    const budget_spend = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "budget_spend"
    );
    const cost_savings = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "cost_savings"
    );
    const compliance = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "compliance"
    );
    const asset_spend = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "asset_spend"
    );
    const savings_model = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "savings_model"
    );
    const budget_planning = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "budget_planning"
    );
    const instance_runtime = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "instance_runtime"
    );

    const records = {
      role_name: props.selectedrecords.role_name,
      role: props.selectedrecords.role_name,
      offering: props.selectedrecords.offering,
      company: props.selectedrecords.customer,
      user_manager_read:
        user_management.length > 0 ? user_management[0].read : 0,
      user_manager_write:
        user_management.length > 0 ? user_management[0].write : 0,
      role_management_read:
        role_management.length > 0 ? role_management[0].read : 0,
      role_management_write:
        role_management.length > 0 ? role_management[0].write : 0,
      account_onboarding_read:
        account_onboarding.length > 0 ? account_onboarding[0].read : 0,
      account_onboarding_write:
        account_onboarding.length > 0 ? account_onboarding[0].write : 0,
      quick_glance_access: quick_glance.length > 0 ? quick_glance[0].access : 0,
      savings_model_access:
        savings_model.length > 0 ? savings_model[0].access : 0,
      budget_planning_access:
        budget_planning.length > 0 ? budget_planning[0].access : 0,
      instance_runtime_access:
        instance_runtime.length > 0 ? instance_runtime[0].access : 0,
      asset_spend_access: asset_spend.length > 0 ? asset_spend[0].access : 0,
      budget_spend_access: budget_spend.length > 0 ? budget_spend[0].access : 0,
      cost_savings_access: cost_savings.length > 0 ? cost_savings[0].access : 0,
      compliance_access: compliance.length > 0 ? compliance[0].access : 0,
      quick_glance_read: quick_glance.length > 0 ? quick_glance[0].read : 0,
      quick_glance_write: quick_glance.length > 0 ? quick_glance[0].write : 0,
      assets_spend_read: asset_spend.length > 0 ? asset_spend[0].read : 0,
      assets_spend_write: asset_spend.length > 0 ? asset_spend[0].write : 0,
      savings_model_read: savings_model.length > 0 ? savings_model[0].read : 0,
      savings_model_write:
        savings_model.length > 0 ? savings_model[0].write : 0,
      budget_planning_read:
        budget_planning.length > 0 ? budget_planning[0].read : 0,
      budget_planning_write:
        budget_planning.length > 0 ? budget_planning[0].write : 0,
      instance_runtime_read:
        instance_runtime.length > 0 ? instance_runtime[0].read : 0,
      instance_runtime_write:
        instance_runtime.length > 0 ? instance_runtime[0].write : 0,
    };
    form.setFieldsValue(records);
  }, [props]);
  const changeDrawerStatus = () => {
    props.setDrawerEditStatus(false);
  };
  const rowDelete = () => {};

  const handleSubmit = (values) => {
    setButtonLoading(true);
    var role_status = "Default";
    if (values.role_checked_status) {
      role_status = "Custom";
    }
    var admin_status = false;
    if (
      values.account_onboarding_read &&
      values.user_manager_read &&
      values.role_management_read
    ) {
      admin_status = true;
    }
    const resources_records = [
      {
        offering: "admin_panel",
        pages: "admin_panel",
        blocks: "admin_panel",
        access: admin_status ? 1 : 0,
        read: admin_status ? 1 : 0,
        write: admin_status ? 1 : 0,
      },
      {
        offering: "admin_panel",
        pages: "user_management",
        blocks: "user_management",
        access: values.user_manager_read ? 1 : 0,
        read: values.user_manager_read ? 1 : 0,
        write: values.user_manager_read ? 1 : 0,
      },
      {
        offering: "admin_panel",
        pages: "role_management",
        blocks: "role_management",
        access: values.role_management_read ? 1 : 0,
        read: values.role_management_read ? 1 : 0,
        write: values.role_management_write ? 1 : 0,
      },
      {
        offering: "admin_panel",
        pages: "account_onboarding",
        blocks: "account_onboarding",
        access: values.account_onboarding_read ? 1 : 0,
        read: values.account_onboarding_read ? 1 : 0,
        write: values.account_onboarding_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "quick_glance",
        blocks: "quick_glance",
        access: values.quick_glance_access ? 1 : 0,
        read: values.quick_glance_read ? 1 : 0,
        write: values.quick_glance_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "quick_glance",
        blocks: "budget_spend",
        access: values.budget_spend_access ? 1 : 0,
        read: values.quick_glance_read ? 1 : 0,
        write: values.quick_glance_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "quick_glance",
        blocks: "cost_savings",
        access: values.cost_savings_access ? 1 : 0,
        read: values.quick_glance_read ? 1 : 0,
        write: values.quick_glance_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "quick_glance",
        blocks: "compliance",
        access: values.compliance_access ? 1 : 0,
        read: values.quick_glance_read ? 1 : 0,
        write: values.quick_glance_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "asset_spend",
        blocks: "asset_spend",
        access: values.asset_spend_access ? 1 : 0,
        read: values.assets_spend_read ? 1 : 0,
        write: values.assets_spend_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "savings_model",
        blocks: "savings_model",
        access: values.savings_model_access ? 1 : 0,
        read: values.savings_model_read ? 1 : 0,
        write: values.savings_model_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "budget_planning",
        blocks: "budget_planning",
        access: values.budget_planning_access ? 1 : 0,
        read: values.budget_planning_read ? 1 : 0,
        write: values.budget_planning_write ? 1 : 0,
      },
      {
        offering: "costimize",
        pages: "instance_runtime",
        blocks: "instance_runtime",
        access: values.instance_runtime_access ? 1 : 0,
        read: values.instance_runtime_read ? 1 : 0,
        write: values.instance_runtime_write ? 1 : 0,
      },
    ];
    const input_new = JSON.stringify([
      {
        customer: values.company,
        role_category: role_status,
        role_name: values.role_name,
        offering: values.offering.toString(),
        resources: resources_records,
        role_id: props.selectedrecords.role_id,
      },
    ]);

    const input_old = JSON.stringify([
      {
        role_name: props.selectedrecords.role_name,
        role_id: props.selectedrecords.role_id,
        customer: props.selectedrecords.customer,
        role_category: props.selectedrecords.role_category,
        resources: props.selectedrecords.resources,
      },
    ]);
    console.log({
      role_name: props.selectedrecords.role_name,
      role_id: props.selectedrecords.role_id,
      customer: props.selectedrecords.customer,
      role_category: props.selectedrecords.role_category,
      resources: props.selectedrecords.resources,
    });
    const user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    dispatch(updateRole({ input_new, input_old, user_id }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        const records = JSON.parse(data.usermanagementupdate);
        if (records.data.message === "Role Already exist") {
          message.warning("Role Already exist");
        } else {
          message.success("Role Updated Successfully Done");
          props.setUpdateStatus(!props.updatestats);
          props.setDrawerEditStatus(false);
        }
        setButtonLoading(false);
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log(err.message);
      });
  };

  const switchChange = (change) => {
    setSwitchStatus(change);
  };

  const handleCompanyChange = (value, event) => {
    setCostimizeStatus(false);
    if (value.indexOf("costimize") !== -1) {
      setCostimizeStatus(true);
    }
  };
  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawereditstatus}
        changeDrawerStatus={changeDrawerStatus}
        drawerdeletestatus={drawerdeletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        rowDelete={rowDelete}
        deletestatus={deletestatus}
        title="Add New Role"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          {/* <Row gutter={[16, 16]}>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label="Add new role ?"
                valuePropName="checked"
                className="role-head-title"
                name="role_checked_status"
              >
                <Switch defaultChecked={switchstatus} onChange={switchChange} />
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={[16, 16]}>
            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
              {switchstatus ? (
                <Form.Item
                  name="role_name"
                  label="Role Name"
                  rules={[{ required: true, message: "" }]}
                  placeholder="Enter Role Name"
                  className="role-head-title"
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="role"
                  label="Role"
                  className="role-head-title"
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Role"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      // { label: "Admin", value: "Admin" },
                      { label: "CTO", value: "CTO" },
                      { label: "Developer", value: "Developer" },
                    ]}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                name="offering"
                label="Offering"
                className="role-head-title"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onChange={(value, event) => handleCompanyChange(value, event)}
                  placeholder="Select Offering"
                  options={[{ label: "Costimize", value: "costimize" }]}
                />
              </Form.Item>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                name="company"
                label="Company"
                className="role-head-title"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  placeholder="Select a Company"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={compnaylist}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <lable className="role-head-title">Specify Permissions</lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              <lable className="role-permission-head-title">Admin Panel</lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">Read</lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access ">Write</lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-4">
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              User Management
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                <Form.Item name="user_manager_read" valuePropName="checked">
                  <Checkbox className="role-checkbox"></Checkbox>
                </Form.Item>
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                <Form.Item name="user_manager_write" valuePropName="checked">
                  <Checkbox className="role-checkbox"></Checkbox>
                </Form.Item>
              </lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              Role Management
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                <Form.Item name="role_management_read" valuePropName="checked">
                  <Checkbox className="role-checkbox"></Checkbox>
                </Form.Item>
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                <Form.Item name="role_management_write" valuePropName="checked">
                  <Checkbox className="role-checkbox"></Checkbox>
                </Form.Item>
              </lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              Account Onboarding
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                <Form.Item
                  name="account_onboarding_read"
                  valuePropName="checked"
                >
                  <Checkbox className="role-checkbox"></Checkbox>
                </Form.Item>
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                <Form.Item
                  name="account_onboarding_write"
                  valuePropName="checked"
                >
                  <Checkbox className="role-checkbox"></Checkbox>
                </Form.Item>
              </lable>
            </Col>
          </Row>
          {costimizestatus && (
            <div>
              <Row gutter={[16, 16]} className="mt-3">
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <lable className="role-permission-head-title">
                    Costimize
                  </lable>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-3">
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Form.Item name="quick_glance_access" valuePropName="checked">
                    <Checkbox className="role-checkbox">Quick Glance</Checkbox>
                  </Form.Item>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Form.Item name="asset_spend_access" valuePropName="checked">
                    <Checkbox className="role-checkbox">
                      Assets & Spend
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Form.Item
                    name="savings_model_access"
                    valuePropName="checked"
                  >
                    <Checkbox className="role-checkbox">Savings Model</Checkbox>
                  </Form.Item>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}></Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Form.Item
                    name="budget_planning_access"
                    valuePropName="checked"
                  >
                    <Checkbox className="role-checkbox">
                      Budget Planning
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Form.Item
                    name="instance_runtime_access"
                    valuePropName="checked"
                  >
                    <Checkbox className="role-checkbox">
                      Instance Runtime
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-3">
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <lable className="role-offering-head-title">
                    Quick Glance
                  </lable>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Form.Item name="budget_spend_access" valuePropName="checked">
                    <Checkbox className="role-checkbox">
                      <label className="role-offering-list-title">
                        Budget/Spend
                      </label>
                    </Checkbox>
                  </Form.Item>

                  {/* <Checkbox className="role-checkbox" name="myCheckbox">
                    <label className="role-offering-list-title">
                      Budget/Spend
                    </label>
                  </Checkbox> */}
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Form.Item name="cost_savings_access" valuePropName="checked">
                    <Checkbox className="role-checkbox">
                      <label className="role-offering-list-title">
                        Cost Savings
                      </label>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Form.Item name="compliance_access" valuePropName="checked">
                    <Checkbox className="role-checkbox">
                      <label className="role-offering-list-title">
                        Compliance
                      </label>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item name="quick_glance_read" valuePropName="checked">
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="quick_glance_write"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
              </Row>
              {/* <Row gutter={[16, 16]} className="mt-3">
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <lable className="role-offering-head-title">
                    Assets & Spend
                  </lable>
                </Col>
              </Row> */}
              <Row gutter={[16, 16]} className="mt-2">
                <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
                  {/* <Checkbox className="role-checkbox" name="myCheckbox"> */}
                  <label className="role-offering-list-title">
                    Assets & Spend
                  </label>
                  {/* </Checkbox> */}
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item name="assets_spend_read" valuePropName="checked">
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="assets_spend_write"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
                  <label className="role-offering-list-title">
                    Savings Model
                  </label>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="savings_model_read"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="savings_model_write"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
                  <label className="role-offering-list-title">
                    Budget Planning
                  </label>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="budget_planning_read"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="budget_planning_write"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
                  <label className="role-offering-list-title">
                    Instance Runtime
                  </label>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="instance_runtime_read"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="text-center"
                >
                  <lable className="role-permission-access">
                    <Form.Item
                      name="instance_runtime_write"
                      valuePropName="checked"
                    >
                      <Checkbox className="role-checkbox"></Checkbox>
                    </Form.Item>
                  </lable>
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={[16, 16]} className="mt-5">
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => props.setDrawerEditStatus(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};

export default memo(RoleEditForm);
