import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { chartInstanceRuntime } from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from "react-redux";

var colors = ["#1b5f9d", "#8B4513"];

const InstanceRuntimeChart = (props) => {
  // console.log(props);
  const dispatch = useDispatch();
  const [chartvalue, setChartValue] = useState({
    datalist: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ],
    datalist2: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ],
    category: props.monthdaycount,
  });
  const [discrete, setDiscrete] = useState([]);

  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
  } = useSelector((state) => state.constimize);
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    //instance runtime
    //   console.log(props.record.resourceid);
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: props.record.fulldate,
      os: selectedos.toString(),
      resourcename: props.record.instance_name,
      resourceid: props.record.resourceid,
    };
    //   console.log(apivalue);
    dispatch(chartInstanceRuntime({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log("chart");
        const short_date = [...data.newfunctionquery14].sort((a, b) =>
          a.usagestartdate < b.usagestartdate ? -1 : 1
        );
        var actual_runtime = [];
        var planned_runtime = [];

        for (let index = 0; index < props.monthdaycount.length; index++) {
          const actual_spend = short_date?.filter(
            (datas) => new Date(datas.usagestartdate).getDate() === +index + +1
          );
          // new Date(datas.usagestartdate).getDate() === +index + +1
          if (actual_spend.length > 0) {
            actual_runtime.push(Math.round(actual_spend[0].actual_runtime));
          } else {
            actual_runtime.push(0);
          }
          if (actual_spend.length > 0) {
            planned_runtime.push(Math.round(actual_spend[0].planned_runtime));
          } else {
            planned_runtime.push(0);
          }
        }
        setChartValue({
          datalist: actual_runtime,
          datalist2: planned_runtime,
          category: props.monthdaycount,
        });
        //color
        const discrete_records = [];
        const planned_value = planned_runtime;
        const spend_value = actual_runtime;
        spend_value.map((data, index) => {
          var color;
          if (data <= planned_value[index]) {
            color = "#3D9D1B";
          } else {
            color = "#BE1E2D";
          }
          discrete_records.push({
            seriesIndex: 0,
            dataPointIndex: index,
            fillColor: color,
            strokeColor: color,
            size: 3,
          });
        });
        setDiscrete(discrete_records);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props]);

  const options = {
    chart: {
      type: "line",
      height: 160,
      offsetY: 5,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
    },
    stroke: {
      show: true,
      curve: "smooth",
      // lineCap: 'butt',
      // colors: "#000",
      width: 2,
      // dashArray: 0,
    },
    markers: {
      size: [3, 3],
      strokeColors: "#fff",
      strokeWidth: 0,
      strokeOpacity: 0,
      strokeDashArray: 0,
      fillOpacity: 0,
      //   discrete: discrete,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    grid: {
      show: false,
    },
    colors: colors,
    xaxis: {
      categories: chartvalue.category,
      //   categories: ["jan","feb","mar","apr","may","jun"],
      tickPlacement: "on",
      axisBorder: {
        show: true,
        color: "#78909C",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        offsetX: 0,
        offsetY: -6,
        style: {
          fontSize: "7px",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },

      labels: {
        style: {
          fontSize: "8px",
          fontWeight: 400,
        },
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };
  const series = [
    {
      name: "Actual",
      data: chartvalue.datalist,
      // data: [5,8,1,29,3,6]
    },
    {
      name: "Planned",
      data: chartvalue.datalist2,
      // data: [5,8,1,29,3,6]
    },
  ];

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={135}
        className="trendchartv2"
      />
    </>
  );
};

export default InstanceRuntimeChart;
