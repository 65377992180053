import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import InstanceRuntimeChart from "./InstanceRuntimeChart";
import InstanceForm from "./instanceForm";
import "./instanceOverview.css";
import { Table, Tooltip, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { listInstanceRuntime } from "../../action/costimizeV2Active";
import { Icon } from "@iconify/react";
import AddInstanceRuntime from "../../dashboard-v2/InstanceRuntime/AddInstanceRuntime";
import { checkRolePermission } from "../../custom_hook/CustomHook";

const InstanceRuntimeTable = (props) => {
  const dispatch = useDispatch();
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
  } = useSelector((state) => state.constimize);
  const [monthdaycount, setMonthDayCount] = useState([]);
  const [environmentfilter, setEnvironmentFilter] = useState([]);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [permission, setPermission] = useState({
    instancewrite: checkRolePermission("instance_runtime")
      ? checkRolePermission("instance_runtime").write
      : 0,
  });
  const [runtimerecords, setRuntimeRecords] = useState([]);
  const [datachange, setDatachange] = useState(false);
  const [instancedrawerstatus, setInstanceDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [instancerecords, setInstanceRecords] = useState();
  //loader
  const [loader, setLoader] = useState(true);
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const columns = [
    {
      title: "Month/Year",
      dataIndex: "date",
      key: "date",
      align: "left",
      sorter: (a, b) => (a.date > b.date ? -1 : 1),
      render: (record, index) => {
        return `${monthNamesShort[new Date(record).getMonth()]}/${new Date(
          record
        ).getFullYear()}`;
      },
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align: "left",
      // sorter: (a, b) => (a.application > b.application ? -1 : 1),
    },
    // {
    //   title: "Environment",
    //   dataIndex: "environment",
    //   key: "environment",
    //   align: "left",
    //   filters: environmentfilter,
    //   onFilter: (value, record) => record.environment.indexOf(value) === 0,
    //   sorter: (a, b) => (a.environment > b.environment ? -1 : 1),
    // },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      align: "left",
      sorter: (a, b) => {
        // Define a custom order for the environments
        const order = ["Production", "Non-Production", "Untagged"];

        // Get the index of the environments in the custom order
        const indexA = order.indexOf(a.environment);
        const indexB = order.indexOf(b.environment);

        // Compare the indexes to determine the sorting order
        return indexA - indexB;
      },
    },
    {
      title: "Resource ID",
      dataIndex: "resourceid",
      key: "resourceid",
      align: "left",
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
      key: "instance_name",
      align: "left",
      sorter: (a, b) => (a.instance_name > b.instance_name ? -1 : 1),
      render: (record, index) => {
        if (record) {
          if (record.length > 50) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 50)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Planned Runtime (hrs)",
      dataIndex: "planned_runtime",
      key: "planned_runtime",
      align: "right",
      sorter: (a, b) => (a.planned_runtime > b.planned_runtime ? -1 : 1),
      render: (record) => {
        if (record) {
          return `${record}`;
        } else {
          return 0;
        }
      },
    },
    {
      title: "Actual Runtime (hrs)",
      dataIndex: "actual_runtime",
      key: "actual_runtime",
      align: "right",
      sorter: (a, b) => (a.actual_runtime > b.actual_runtime ? -1 : 1),
      render: (record) => {
        return `${Math.round(record)}`;
      },
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      render: (record, index) => {
        const planned_runtime = Math.round(Number(index.planned_runtime));
        const actual_runtime = Math.round(Number(index.actual_runtime));
        var indicate = "";
        if (planned_runtime > 0) {
          if (planned_runtime >= actual_runtime) {
            indicate = <div class="green-round"></div>;
          } else {
            indicate = <div class="yellow-round"></div>;
          }
        } else {
          indicate = <div class="lite-red-round"></div>;
        }

        if (props.rolestatus && permission.instancewrite) {
          return (
            <>
              <div className="d-flex">
                <div>
                  <Icon
                    icon="material-symbols:edit"
                    onClick={() => editInstanceDrawerStatus(index)}
                  />
                </div>
                <div>{indicate}</div>
              </div>
            </>
          );
        } else {
          return <>{indicate}</>;
          return <></>;
        }
      },
    },
  ];

  const editInstanceDrawerStatus = (data) => {
    setInstanceDrawerStatus(true);
    setDeleteStatus(true);
    setInstanceRecords(data);
  };
  useEffect(() => {
    setLoader(true);
    props.setLoader(true);
    //days count
    const today = new Date(selectedTopdate);
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const currentDateCount = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const day_list = [];
    for (let index = 0; index < currentDateCount; index++) {
      day_list.push(`Day ${+index + +1}`);
    }
    setMonthDayCount(day_list);

    //instance tracking
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
    };

    //instance runtime
    dispatch(listInstanceRuntime({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data)
        props.setLoader(false);

        const list = data.newfunctionquery12.map((list_data, index) => {
          return {
            key: index,
            application: list_data.application,
            environment: list_data.environment,
            instance_name: list_data.resourcename,
            planned_runtime: list_data.planned_runtime,
            actual_runtime: list_data.actual_runtime,
            date: list_data.usagestartdate,
            fulldate: list_data.report_month,
            resourceid: list_data.resourceid,
          };
        });
        props.setInstanceRuntimeRecords(list);
        const environment_list = list.map((v) => v.environment);
        const environment_set = new Set([...environment_list]);
        const allenvironment = [...environment_set];
        var env_list = [];
        allenvironment.map((value) => {
          env_list.push({
            text: value,
            value: value,
          });
        });
        setEnvironmentFilter(env_list);
        setRuntimeRecords(list);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
    datachange,
  ]);
  const tableDataArray = Object.values(runtimerecords);
  tableDataArray.sort((a, b) => {
    return columns.find((column) => column.key === "environment").sorter(a, b);
  });
  const tableProps = {};

  const expandRow = {
    renderer: (record) => (
      <div>
        <p>
          <InstanceRuntimeChart record={record} monthdaycount={monthdaycount} />
        </p>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
  };
  const changeInstancesDrawerStatus = () => {
    setInstanceDrawerStatus(false);
  };
  return (
    <>
      <AddInstanceRuntime
        instancedrawerstatus={instancedrawerstatus}
        changeInstancesDrawerStatus={changeInstancesDrawerStatus}
        setInstanceDrawerStatus={setInstanceDrawerStatus}
        deletestatus={deletestatus}
        instancerecords={instancerecords}
        setDatachange={setDatachange}
        datachange={datachange}
      />

      {/* <InstanceForm
        instancedrawerstatus={instancedrawerstatus}
        changeInstancesDrawerStatus={changeInstancesDrawerStatus}
        setInstanceDrawerStatus={setInstanceDrawerStatus}
        deletestatus={deletestatus}
        instancerecords={instancerecords}
        setDatachange={setDatachange}
        datachange={datachange}
      /> */}

      <div className="costimize-table-width">
        <div className="instanceRuntimeTable">
          <Spin spinning={loader} wrapperClassName="costimize-loader">
            <Table
              {...tableProps}
              className="costimize-bootstrap-table"
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <InstanceRuntimeChart
                    record={record}
                    monthdaycount={monthdaycount}
                  />
                ),
              }}
              dataSource={tableDataArray}
              // dataSource={runtimerecords}
              pagination={true}
              rowClassName={(record, index) =>
                record.type === "footer" ? "budget-table-footer" : ""
              }
            />
          </Spin>
        </div>
      </div>
    </>
  );
};

export default InstanceRuntimeTable;
