import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { smallCase } from "../../custom_hook/CustomHook";
import { Spin } from "antd";

const SavingsCastChart = (props) => {
  const [chartdetails, setChartDetails] = useState({
    options: [],
    series: [],
  });
  useEffect(() => {
    // console.log(props.savingslist);
    if (props.savingslist.length > 0) {
      const recommendation_list = props.savingslist.map(
        (v) => v.recommendation_rule
      );
      const recommendation_set = new Set([...recommendation_list]);
      const recommendation = [...recommendation_set];
      var recommendation_count = [];
      recommendation.map((recom_data) => {
        let list = props.savingslist?.filter(
          (v, index) => v.recommendation_rule === recom_data
        );
        recommendation_count.push(list.length);
      });
      const newlist = recommendation.map((recom_data) => smallCase(recom_data));
      // console.log(newlist);
      setChartDetails({
        options: newlist,
        series: recommendation_count,
      });
    } else {
      setChartDetails({
        options: [],
        series: [],
      });
    }
  }, [props]);
  const options = {
    chart: {
      type: "donut",
      offsetY: 10,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              formatter: function (val) {
                var num = val.config.series.reduce((a, b) => a + b, 0);
                var total_value =
                  Math.abs(num) > 999
                    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                    : Math.sign(num) * Math.abs(num);
                return total_value;
              },
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style: {
        fontSize: "9px",
        fontWeight: 500,
        colors: ["#000"],
      },
    },
    labels: chartdetails.options,

    responsive: [
      {
        breakpoint: 2561,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "right",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "right",
          },
        },
      },
      {
        breakpoint: 1100,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const series = chartdetails.series;

  return (
    <>
      <Spin spinning={props.lsavigsstatus} wrapperClassName="costimize-loader">
        <h1 className="spend-chart-title">Savings Opportunity</h1>
        {props.savingslist.length > 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactApexChart options={options} series={series} type="donut" />
          </div>
        ) : (
          <h5 style={{ textAlign: "center", marginTop: "15%" }}>
            Not generated
          </h5>
        )}
      </Spin>
    </>
  );
};

export default memo(SavingsCastChart);
