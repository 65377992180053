import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

function AlertDetailsBarChart({serverLabels,warnCount,criticalCount}) {

  useEffect(()=>{
  },[serverLabels,warnCount,criticalCount])
  const xData = [
    "AsterDM-PROD-AZVMAXH2H",
    "SEAAZUSGWVD-0-PROD",
    "SYDAWOPWDIBM708-ANL-NONPROD",
    "AsterDM-PROD-AZVMAXH2H",
    "AsterDM-PROD-UAEAZPCCRSDB01",
    "AsterDM-PROD-AZVMAXH2H",
    "AsterDM-PROD-UAESSCWVDP4-4",
    "WEBAPPS2-ANL-PROD",
    "USWLBAZANAPP1-OPTIM-PROD",
    "AsterDM-PROD-UAEAZPCCRSDB01",
  ];

  
  const series = [{
    name: 'Warning',
    data: warnCount
  }, {
    name: 'Critical',
    data: criticalCount,
  }];
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    xaxis: {
      categories: serverLabels,
      labels: {
        rotate: 270,
        // wrap: true,
        maxWidth: 1000,
        trim:true
      },
    },
    colors: ['#FCAF04', '#BE1E2D'],
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
      />
    </div>
  );
}

export default AlertDetailsBarChart;
