import { memo, useEffect, useState } from "react";
import {
  Col,
  Row,
  Modal,
  Table,
  Button,
  Form,
  Input,
  Space,
  Tooltip,
  Select,
  message,
} from "antd";
import { Icon } from "@iconify/react";
import { updateTaggingDetails } from "../../action/costimizeV2Active";
import { useDispatch } from "react-redux";

const TaggingPopupUpdated = (props) => {
  const [form] = Form.useForm();
  const [applicationdropdown, setApplicationDropDown] = useState([]);
  const [environmentdropdown, setEnvironmentDropDown] = useState([]);
  //remove status
  const [appdetstatus, setApplicationDeleteStatus] = useState(true);
  const [envdetstatus, setEnvironmentDeleteStatus] = useState(true);
  const [buttonloading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    // const app_dropdown = [];
    //application
    const application_list = props.selectedrowvalue?.map((v) => v.application);
    const application_set = new Set([...application_list]);
    const application = [...application_set];
    const app_dropdown = application.map((app_name) => {
      return {
        value: app_name,
        label: app_name,
      };
    });
    setApplicationDropDown(app_dropdown);
    //environment
    const environment_list = props.selectedrowvalue?.map((v) => v.environment);
    const environment_set = new Set([...environment_list]);
    const environment = [...environment_set];
    const env_dropdown = environment.map((env_name) => {
      return {
        value: env_name,
        label: env_name,
      };
    });
    setEnvironmentDropDown(env_dropdown);

    const records = {
      tag_key1: "application",
      tag_key2: "environment",
    };
    form.setFieldsValue(records);
  }, [props]);
  const columns = [
    {
      title: "Resource ID",
      dataIndex: "resource_id",
      align: "center",
      // render: (record) => {
      //   if (record) {
      //     if (record.length > 10) {
      //       return (
      //         <>
      //           <Tooltip placement="bottom" title={record}>
      //             <li className="tagging-popup-service">
      //               <div> {record.substring(0, 10)}...</div>
      //             </li>
      //           </Tooltip>
      //         </>
      //       );
      //     } else {
      //       return (
      //         <>
      //           <li className="tagging-popup-service">
      //             <div>{record}</div>
      //           </li>
      //         </>
      //       );
      //     }
      //   }
      // },
    },
    {
      title: "Services",
      dataIndex: "services",
      align: "center",
    },
    {
      title: "Application",
      dataIndex: "application",
      align: "center",
    },
    {
      title: "Environment",
      dataIndex: "environment",
      align: "center",
    },
  ];

  const handleCancel = () => {
    props.setUpdatePopupStatus(false);
  };
  const onFinish = (values) => {
    setButtonLoading(true);
    const tagging_list = [];
    if (appdetstatus) {
      tagging_list.push({
        tag_key: values.tag_key1,
        tag_value: values.tag_value1,
        action: "tag",
      });
    } else {
      tagging_list.push({
        tag_key: "application",
        tag_value: "undefined",
        action: "untag",
      });
    }
    if (envdetstatus) {
      tagging_list.push({
        tag_key: values.tag_key2,
        tag_value: values.tag_value2,
        action: "tag",
      });
    } else {
      tagging_list.push({
        tag_key: "environment",
        tag_value: "undefined",
        action: "untag",
      });
    }
    // console.log(values.users);
    if (values.users) {
      values.users.map((data) => {
        tagging_list.push({
          tag_key: data.tagging_key,
          tag_value: data.tagging_name,
          action: "tag",
        });
      });
    }
    var resources = [];
    if (props.selectedrowvalue) {
      resources = props.selectedrowvalue.map((datas) => {
        return {
          account_id: datas.account_id,
          ResourceARN: datas.resource_id,
          Service: datas.services,
          tags: tagging_list,
        };
      });
    }
    // console.log(resources);
    // setTimeout(() => {
    //   message.success("Tag Updated");
    //   setButtonLoading(false);
    //   props.setUpdatePopupStatus(false);
    //   props.setUpdateStatus(!props.updatestatus);
    // }, 1000);
    dispatch(updateTaggingDetails({ resources }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        message.success("Tag Updated");
        setButtonLoading(false);
        props.setUpdatePopupStatus(false);
        props.setUpdateStatus(!props.updatestatus);
      })
      .catch((err) => {
        console.log(err.message);
        message.error(err.message);
        setButtonLoading(false);
      });
  };

  return (
    <>
      <Modal
        title={props.title}
        open={props.updatepopupstatus}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center", padding: "20px" }}
        width="90%"
      >
        <Row>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Table
              className="costimize-bootstrap-table"
              columns={columns}
              dataSource={props.selectedrowvalue}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="tagging-update-title"
          >
            <lable>Tag key</lable>
          </Col>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="tagging-update-title"
          >
            <lable>Tag value</lable>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              {appdetstatus && (
                <Row gutter={[16, 16]}>
                  <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                    <Form.Item
                      name="tag_key1"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input placeholder="Tag key" />
                    </Form.Item>
                  </Col>
                  <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                    <Form.Item
                      name="tag_value1"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a Tag Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={applicationdropdown}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Icon
                      style={{
                        fontSize: "29px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      icon="material-symbols:delete"
                      onClick={() => setApplicationDeleteStatus(false)}
                    />
                  </Col>
                </Row>
              )}

              {envdetstatus && (
                <Row gutter={[16, 16]}>
                  <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                    <Form.Item
                      name="tag_key2"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input placeholder="Tag key" />
                    </Form.Item>
                  </Col>
                  <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                    <Form.Item
                      name="tag_value2"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a Tag Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={environmentdropdown}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Icon
                      style={{
                        fontSize: "29px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      icon="material-symbols:delete"
                      onClick={() => setEnvironmentDeleteStatus(false)}
                    />
                  </Col>
                </Row>
              )}
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        // style={{
                        //   display: "flex",
                        //   marginBottom: 8,
                        // }}
                        // align="baseline"
                      >
                        <Row gutter={[16, 16]}>
                          <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                            <Form.Item
                              {...restField}
                              name={[name, "tagging_key"]}
                              rules={[
                                {
                                  required: true,
                                  message: " ",
                                },
                              ]}
                            >
                              <Input placeholder="Tag key" />
                            </Form.Item>
                          </Col>
                          <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                            <Form.Item
                              {...restField}
                              name={[name, "tagging_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: " ",
                                },
                              ]}
                            >
                              <Input placeholder="Tag value" />
                            </Form.Item>
                          </Col>
                          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Icon
                              style={{
                                fontSize: "29px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              icon="material-symbols:delete"
                              onClick={() => remove(name)}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<Icon icon="teenyicons:add-outline" />}
                      >
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default memo(TaggingPopupUpdated);
