import { memo, useEffect, useState } from "react";
import { Col, Row, Modal, Tooltip, Spin, Table } from "antd";
import { Icon } from "@iconify/react";

const RoleDetailsTable = (props) => {
  const [tablerecords, setTableRecords] = useState([]);
  useEffect(() => {
    var records = [];
    console.log(props.records.resources);
    props.records.resources.map((data) => {
      if (data.blocks !== "admin_panel") {
        records.push(data);
      }
    });
    setTableRecords(records);
  }, [props]);
  const columns = [
    {
      title: "Offering",
      dataIndex: "offering",
      key: "offering",
      sorter: (a, b) => {
        return a.offering > b.offering ? -1 : 1;
      },
      align: "center",
      render: (record) => {
        return record.replace("_", " ");
      },
    },
    {
      title: "Pages",
      key: "pages",
      dataIndex: "pages",
      align: "center",
      sorter: (a, b) => {
        return a.pages > b.pages ? -1 : 1;
      },
      render: (record) => {
        return record.replace("_", " ");
      },
    },
    {
      title: "Section Choosen",
      dataIndex: "blocks",
      key: "blocks",
      align: "center",
      sorter: (a, b) => {
        return a.blocks > b.blocks ? -1 : 1;
      },
      render: (record) => {
        return record.replace("_", " ");
      },
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      align: "center",
      render: (record) => {
        if (record === 1) {
          return (
            <>
              <Icon icon="charm:tick" />
            </>
          );
        } else {
          return (
            <>
              <Icon icon="tabler:x" />
            </>
          );
        }
      },
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (record) => {
        if (record === 1) {
          return (
            <>
              <Icon icon="charm:tick" />
            </>
          );
        } else {
          return (
            <>
              <Icon icon="tabler:x" />
            </>
          );
        }
      },
    },
    {
      title: "Write",
      dataIndex: "write",
      key: "write",
      align: "center",
      render: (record) => {
        if (record === 1) {
          return (
            <>
              <Icon icon="charm:tick" />
            </>
          );
        } else {
          return (
            <>
              <Icon icon="tabler:x" />
            </>
          );
        }
      },
    },
  ];

  // const tablerecords = [
  //   {
  //     offering: "costimize",
  //     pages: "Quick glance",
  //     section_choosen: "Budget/Spend",
  //   },
  // ];

  return (
    <>
      <Row gutter={[16, 16]} className="mt-3">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <lable className="role-head-title">{props.records.role_name}</lable>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-3">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table
            className="role-management-table"
            columns={columns}
            dataSource={tablerecords}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(RoleDetailsTable);
