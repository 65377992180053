import React from "react";
import InvoiceTable from './invoicetable';
import "./spp.css";
import Sppclient from "../SppClient/Sppclient";

const Spp = () => {
    const customer = localStorage.getItem(
        "CognitoIdentityServiceProvider.company"
    );
    console.log(customer)
    return (
        <>
            {/* <div className='spp_bg'>
                <div className="spp-invoice">
                    < InvoiceTable />
                </div>
            </div> */}
            <div className="spp_bg">
            <div className="spp-invoice">
                {customer === "Internal1CH" ? (
                    <InvoiceTable />
                ) : customer === "fivestar" ? (
                    <Sppclient />
                ) : (
                    <Sppclient />
                )}
            </div>
        </div>
        </>
    )

}
export default Spp;