import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
const NonProductionWindowChart = (props) => {
  var countfilteredLin1 = props.countsos.filter(function (element) {
    return (
      element.environment == "non-production" &&
      element.os == "windows" &&
      element.patch_status == "compliance"
    );
  }).length;
  var countfilteredLin2 = props.countsos.filter(function (element) {
    return (
      element.environment == "non-production" &&
      element.os == "windows" &&
      element.patch_status == "non-compliance"
    );
  }).length;
  const series = [countfilteredLin1, countfilteredLin2];
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#84AEE7", "#FFEAA0"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val);
      },
    },
    title: {
      show: true,
      text: "Windows",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" />
      </div>
    </>
  );
};

export default NonProductionWindowChart;
