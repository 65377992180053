import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { Col, Row } from "react-bootstrap";
import "./popupdetails.css";
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import {
  getPaginationCount,
  getTagginglist,
} from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Spinner from "react-bootstrap/Spinner";
import { Icon } from "@iconify/react";

const TaggingDetails = (props) => {
  // console.log(props);
  const dispatch = useDispatch();
  const { companyName } = useSelector((state) => state.user);
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [loadingstatus, setLoadingStatus] = useState(false);
  const [loadername, setLoaderName] = useState({
    service_name: "",
    tag_name: "",
  });
  // const [excelrecords, setExcelRecords] = useState([]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheettml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [records, setRecords] = useState({
    tagged: [],
    untagged: [],
    partially_tagged: [],
  });
  useEffect(() => {
    var partially_tagged = props.records.list?.filter(
      (v, index) => v.tagging_details === "partially_tagged"
    );
    var tagged = props.records.list?.filter(
      (v, index) => v.tagging_details === "tagged"
    );
    var untagged = props.records.list?.filter(
      (v, index) => v.tagging_details === "Untagged"
    );

    //short
    partially_tagged = [...partially_tagged].sort((a, b) =>
      a.services > b.services ? -1 : 1
    );
    tagged = [...tagged].sort((a, b) => (a.services > b.services ? -1 : 1));
    untagged = [...untagged].sort((a, b) => (a.services > b.services ? -1 : 1));

    // console.log(+Math.round(partially_tagged.length / 3) + +1);

    setRecords({
      tagged: [...tagged].sort((a, b) =>
        Number(a.count_untagged_resource) > Number(b.count_untagged_resource)
          ? -1
          : 1
      ),
      untagged: [...untagged].sort((a, b) =>
        Number(a.count_untagged_resource) > Number(b.count_untagged_resource)
          ? -1
          : 1
      ),
      partially_tagged: [...partially_tagged].sort((a, b) =>
        Number(a.count_untagged_resource) > Number(b.count_untagged_resource)
          ? -1
          : 1
      ),
    });
  }, [props]);
  const donwloadTagging = (tagging_name, service_name) => {
    console.log("in");
    setLoadingStatus(true);
    setLoaderName({
      service_name: service_name,
      tag_name: tagging_name,
    });
    const date = selectedTopdate;
    dispatch(
      getPaginationCount({ tagging_name, service_name, companyName, date })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        const pagecount = Array.from(
          { length: Math.round(data.newfunctionquery9[0].total_pages) },
          (v, k) => k + 1
        );
        var excelrecords = [];
        const loopResult = pagecount.reduce(async (previous, value) => {
          await previous;
          // Return new promise
          return new Promise((resolve) => {
            const page = value;
            dispatch(
              getTagginglist({
                tagging_name,
                service_name,
                companyName,
                date,
                page,
              })
            )
              .unwrap()
              .then((taggin_data) => {
                const tagging_records = taggin_data.data.newfunctionquery7.map(
                  (data) => {
                    return {
                      ResourceID: data.resourceid,
                      Services: data.services,
                      SubCategory: data.subcategory,
                      Tagging: data.tagging_details,
                      Application: data.application,
                      Environment: data.environment,
                    };
                  }
                );
                var newrecords = excelrecords.concat(tagging_records);
                console.log(newrecords);
                excelrecords = newrecords;
                resolve();
              })
              .catch((err) => {
                console.log(err.message);
              });
          });
        }, Promise.resolve());

        loopResult.then(() => {
          setLoadingStatus(false);
          console.log(excelrecords, service_name, tagging_name);
          exportExcelData(excelrecords, service_name, tagging_name);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const exportExcelData = (excelrecords, service_name, tagging_name) => {
    const fileName = `${tagging_name}_${service_name}`;
    const ws = XLSX.utils.json_to_sheet(excelrecords);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <MDBModal
        show={props.zoominstatus}
        setShow={props.setZoomInStatus}
        tabIndex="-1"
      >
        <MDBModalDialog className="plat-modal-dialog">
          <MDBModalContent>
            <div className="plat-model-fullcover">
              <MDBModalBody className="plat-popup-tagging-main-body">
                <Col>
                  <div>
                    <h1 className="plat-popup-tagging-title">
                      Partially Tagged
                    </h1>
                    <Row>
                      {records.partially_tagged.map((data, index) => {
                        return (
                          <>
                            <Col lg={3} className="plat-popup-tagging-body">
                              <p
                                className="plat-popup-tagging-list"
                                onClick={() =>
                                  donwloadTagging(
                                    "Partially_Tagged",
                                    data.services
                                  )
                                }
                              >
                                {loadingstatus ? (
                                  <>
                                    {loadername.service_name ===
                                      data.services &&
                                    loadername.tag_name ===
                                      "Partially_Tagged" ? (
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        className="tagging_loading_icon"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>
                                    ) : (
                                      <Icon
                                        icon="ic:outline-file-download"
                                        class="tagging_download_icon"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Icon
                                    icon="ic:outline-file-download"
                                    class="tagging_download_icon"
                                  />
                                )}
                                {data.services} ({data.count_untagged_resource})
                              </p>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                  <div>
                    <h1 className="plat-popup-tagging-title">Untagged</h1>
                    <Row>
                      {records.untagged.map((data, index) => {
                        return (
                          <>
                            <Col lg={3} className="plat-popup-tagging-body">
                              <p
                                className="plat-popup-tagging-list"
                                onClick={() =>
                                  donwloadTagging("Untagged", data.services)
                                }
                              >
                                {loadingstatus ? (
                                  <>
                                    {loadername.service_name ===
                                      data.services &&
                                    loadername.tag_name === "Untagged" ? (
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        className="tagging_loading_icon"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>
                                    ) : (
                                      <Icon
                                        icon="ic:outline-file-download"
                                        class="tagging_download_icon"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Icon
                                    icon="ic:outline-file-download"
                                    class="tagging_download_icon"
                                  />
                                )}
                                {data.services} ({data.count_untagged_resource})
                              </p>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                  <div>
                    <h1 className="plat-popup-tagging-title">Tagged</h1>
                    <Row>
                      {records.tagged.map((data, index) => {
                        return (
                          <>
                            <Col lg={3} className="plat-popup-tagging-body">
                              <p
                                className="plat-popup-tagging-list"
                                onClick={() =>
                                  donwloadTagging("Tagged", data.services)
                                }
                              >
                                {loadingstatus ? (
                                  <>
                                    {loadername.service_name ===
                                      data.services &&
                                    loadername.tag_name === "Tagged" ? (
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        className="tagging_loading_icon"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>
                                    ) : (
                                      <Icon
                                        icon="ic:outline-file-download"
                                        class="tagging_download_icon"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Icon
                                    icon="ic:outline-file-download"
                                    class="tagging_download_icon"
                                  />
                                )}
                                {data.services} ({data.count_untagged_resource})
                              </p>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </Col>
              </MDBModalBody>
              <div
                className="plat-model-close-icon"
                onClick={() => props.setZoomInStatus(false)}
              >
                <IoMdClose className="plat-model-top-close-icon" />
              </div>
            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default TaggingDetails;
