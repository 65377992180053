import { memo, useState, useCallback, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown, Spin } from "antd";
import { Icon } from "@iconify/react";
import SpendVSBudgetPopup from "./SpendVSBudgetPopup";
import { useSelector } from "react-redux";
const Loader = () => <div>Loading...</div>;
const SpendVSBudget = (props) => {
  const [open, setOpen] = useState(false);
  const [monthlist, setMonthList] = useState(0);
  const [chartdetails, setChartDetails] = useState({
    label: [],
    awsspendvalue: [],
    azurespendvalue: [],
    awsbudgetvalue: [0, 0, 0],
    azurebudgetvalue: [0, 0, 0],
    monthlist: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [series1, setSeries1] = useState([]);
  const { selectedTopdate } = useSelector((state) => state.constimize);

  useEffect(() => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month_list = [];
    var chart_new_format = [];
    var month_lable_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
      chart_new_format.push(props.awsspend[i]);
      month_lable_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }

    var month_list = [];
    var chart_new_azure = [];
    var month_lable_azure = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
      chart_new_azure.push(props.azurespend[i]);
      month_lable_azure.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }

    setChartDetails({
      label: month_list,
      awsspendvalue: chart_new_format,
      azurespendvalue: chart_new_azure,
      awsbudgetvalue: props.awsbudget,
      azurebudgetvalue: props.azurebudget,
      monthlist: month_lable_list,
    });
  }, [props]);

  useEffect(() => {
    props.setDayChartStatus(false);
  }, [selectedTopdate]);

  // const changeMonth = useCallback(
  //   (month) => {
  //     console.log("in");
  //     setOpen(true);
  //     setMonthList(month);
  //   },
  //   [open]
  // );

  const changeMonth = (month) => {
    // console.log("in");
    setOpen(true);
    setMonthList(month);
  };

  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];
  const series = [
    {
      name: "AWS Budget",
      type: "column",
      data: chartdetails.awsbudgetvalue.map(Number),
    },
    {
      name: "Azure Budget",
      type: "column",
      data: chartdetails.azurebudgetvalue.map(Number),
    },
    {
      name: "AWS Spend",
      type: "line",
      data: chartdetails.awsspendvalue,
    },
    {
      name: "Azure Spend",
      type: "line",
      data: chartdetails.azurespendvalue,
    },
  ];

  // const series = [
  //   {
  //     name: "AWS Budget",
  //     type: "column",
  //     data: chartdetails.awsbudgetvalue.map(Number),
  //   },
  //   {
  //     name: "Azure Budget",
  //     type: "column",
  //     data: chartdetails.azurebudgetvalue.map(Number),
  //   },
  //   {
  //     name: "AWS Spend",
  //     type: "line",
  //     data: chartdetails.awsspendvalue,
  //   },
  //   {
  //     name: "Azure Spend",
  //     type: "line",
  //     data: chartdetails.azurespendvalue,
  //   },
  // ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      events: {
        dataPointSelection: function (event, chartContext, config) {
          console.log(chartdetails.monthlist[config.dataPointIndex]);
          props.setSpendDate(chartdetails.monthlist[config.dataPointIndex]);
          props.setDayChartStatus(true);
        },
      },
    },
    stroke: {
      width: [0],
      curve: "straight",
    },
    tooltip: {
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          if (value >= 1000) {
            return "$" + (value / 1000).toFixed(1) + "k";
          } else {
            return "$" + value;
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },

    labels: chartdetails.label,
    // colors: ["#fcbb29", "#82ccdc"],
    // colors: ["#4cc0c0", "#fe1a67"],
    colors: ["#4cc0c0", "#1c344c", "#e37d05", "#b23cfd"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
    },
    // yaxis: [
    //   {
    //     // max:90000,
    //     title: {
    //       text: "Budget",
    //       style: {
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       },
    //     },
    //     labels: {
    //       formatter: (num) => {
    //         if (num === 0) {
    //           return "0";
    //         } else if (num === Infinity) {
    //           return "0";
    //         } else {
    //           return Math.abs(num) > 999
    //             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    //             : Math.sign(num) * Math.abs(num);
    //         }
    //       },
    //     },
    //   },
    //   {
    //     opposite: true,
    //     title: {
    //       text: "Spend",
    //       style: {
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       },
    //     },
    //     labels: {
    //       formatter: (num) => {
    //         if (num === 0) {
    //           return "0";
    //         } else if (num === Infinity) {
    //           return "0";
    //         } else {
    //           return Math.abs(num) > 999
    //             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    //             : Math.sign(num) * Math.abs(num);
    //         }
    //       },
    //     },
    //   },
    // ],
  };

  return (
    <div style={{ position: "relative" }}>
      {open && (
        <SpendVSBudgetPopup
          open={open}
          setOpen={setOpen}
          monthlist={monthlist}
          setMonthList={setMonthList}
        />
      )}

      <h1 className="spend-chart-title">Budget vs Spend</h1>
      <div>
        {/* <Spin
          spinning={props.azureawsspend}
          wrapperClassName="costimize-loader"
        > */}

        {props.azureawsspend && props.azureawsbudget ? (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={210}
          />
        ) : (
          <Spin />
        )}
      </div>

      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <a onClick={(e) => e.preventDefault()} className="chart-filter">
          <Icon icon="material-symbols:filter-alt-outline-sharp" />
        </a>
      </Dropdown>
    </div>
  );
};

export default memo(SpendVSBudget);
