import React, { memo, useCallback, useState } from "react";
import { Col, Row } from "antd";
import logo from "../../../assets/images/company_logo.png";
import cmacgm_logo from "../../../assets/images/cmacgm_logo_new.png";
import { headercustomerlogo } from "../.././action/header_logo";

import CostimizeFilter from "./CostimizeFilter";
import {
  selectedTopDateMethod,
  setFilterButtonStatus,
} from "../../slice/costimizeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const MainHead = (props) => {
  const [shortmonth, setShortMonth] = useState("current_month");
  const dispatch = useDispatch();
  const [company_logo, setCompanyLogo] = useState();
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [monthlist, setMonthList] = useState({
    last: "",
    current: "",
    custom_month: "",
  });
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const selecteddate = new Date(selectedTopdate);
    const selectedmonth = selecteddate.getMonth() + 1;
    const selectedyear = selecteddate.getFullYear();

    const currentddate = new Date();
    const currentmonth = currentddate.getMonth() + 1;
    const currentyear = currentddate.getFullYear();

    setMonthList({
      last:
        currentddate.getMonth() === 0
          ? `${monthNames[11]} ${currentyear - 1}` // If current month is January, set to December of previous year
          : `${monthNames[currentddate.getMonth() - 1]} ${currentyear}`, // Otherwise, set to last month of current year
      current: `${monthNames[currentddate.getMonth()]} ${currentyear}`,
      custom_month: `${monthNames[selecteddate.getMonth()]} ${selectedyear}`,
    });

    if (selectedmonth === currentmonth && selectedyear === currentyear) {
      setShortMonth("current_month");
    } else if (
      selectedmonth === currentddate.getMonth() &&
      selectedyear === currentyear
    ) {
      setShortMonth("last_month");
    } else {
      setShortMonth("custom_month");
    }
    // const demo_type = localStorage.getItem(
    //   "CognitoIdentityServiceProvider.demo"
    // );
    // if (demo_type == "true") {
    //   setCompanyLogo();
    // } else {
    //   if (company_name === "cmacgm") {
    //     setCompanyLogo(cmacgm_logo);
    //   } else if (company_name === "fivestar") {
    //     setCompanyLogo(logo);
    //   }
    // }
  }, [selectedTopdate]);
  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleMultiMonthChange = useCallback(
    (data) => {
      if (data === "current_month") {
        dispatch(setFilterButtonStatus(false));
        const currentnew = new Date();
        dispatch(
          selectedTopDateMethod(
            `${currentnew.getFullYear()}-${currentnew.getMonth() + 1}-01`
          )
        );
      } else if (data === "last_month") {
        dispatch(setFilterButtonStatus(true));
        const currentnew = new Date();
        const previousMonth = new Date(
          currentnew.getFullYear(),
          currentnew.getMonth() - 1,
          1
        );

        const year =
          previousMonth.getMonth() === 11
            ? currentnew.getFullYear() - 1
            : currentnew.getFullYear();

        dispatch(
          selectedTopDateMethod(
            `${year}-${(previousMonth.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-01`
          )
        );
      }
    },
    [shortmonth]
  );
  return (
    <>
      <div className="costimize-header-body">
        <Row className="mt-2">
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={24}
            className={
              props.type === "slm"
                ? "header-title site-color slm-color"
                : "header-title site-color"
            }
          >
            {props.title}
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
            <div className="header-logo-block">
              {company_logo && (
                <img className="costimize_header_logo" src={company_logo} />
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="header-filter-block">
              <div>
                <ul
                  className={
                    props.type == "slm"
                      ? "header-filter-list slm-ul-li"
                      : "header-filter-list"
                  }
                >
                  <li
                    className={shortmonth === "current_month" && "active"}
                    onClick={() => handleMultiMonthChange("current_month")}
                  >
                    {monthlist.current}
                  </li>
                  {shortmonth === "custom_month" ? (
                    <li className={shortmonth === "custom_month" && "active"}>
                      {monthlist.custom_month}
                    </li>
                  ) : (
                    <li
                      className={shortmonth === "last_month" && "active"}
                      onClick={() => handleMultiMonthChange("last_month")}
                    >
                      {monthlist.last}
                    </li>
                  )}

                  <li>
                    <CostimizeFilter />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(MainHead);
