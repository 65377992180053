import { Icon } from "@iconify/react";
import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterAssetandSpendList } from "../../../action/costimizedashboardAction";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import AssetSpendDBenvironment from "./AssetSpendDBenvironment";
import { capitalizeFirst } from "../../../custom_hook/CustomHook";

function AssetSpendDBMain(props) {
  const [preloader, setPreloader] = useState(true);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [allenvironmentlist, setAllEnvironmentList] = useState([]);

  const {
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    environmentList,
    dummydatastatus,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  const numberFormat = (number) => {
    let returnCount = Math.round(number).toLocaleString();
    if (returnCount == "NaN") {
      returnCount = 0;
    }
    return returnCount;
  };
  const [allenvironmentdetails, setAllEnvironmentDetails] = useState([
    { type: "VM", count: 0, spend: 0 },
    { type: "DB", count: 0, spend: 0 },
    { type: "Others", count: 0, spend: 0 },
  ]);
  const [listenvironmentdetails, setListEnvironmentDetails] = useState([]);



  useEffect(() => {
    setPreloader(true);

    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: "DB",
      db: selecteddb.toString(),
    };


    dispatch(
      filterAssetandSpendList({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery.length > 0) {
          setAllEnvironmentList(data.newfunctionquery);

        }

        //vm

        const allenvironmentvmfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "VM";
          }
        );
        var allenvironmentvm = { type: "VM", count: 0, spend: 0 };
        if (allenvironmentvmfiltered?.length > 0) {
          allenvironmentvm = {
            type: "VM",
            count: allenvironmentvmfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentvmfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }
        //db
        const allenvironmentdbfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "DB";
          }
        );
        var allenvironmentdb = { type: "DB", count: 0, spend: 0 };
        if (allenvironmentdbfiltered?.length > 0) {
          allenvironmentdb = {
            type: "DB",
            count: allenvironmentdbfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentdbfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }
        //others
        const allenvironmentothersfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "Others";
          }
        );
        var allenvironmentothers = { type: "Others", count: 0, spend: 0 };
        if (allenvironmentothersfiltered?.length > 0) {
          allenvironmentothers = {
            type: "Others",
            count: allenvironmentothersfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentothersfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }

        const allenvspend =
          props.type === "db" ? allenvironmentdb : allenvironmentothers;
        setAllEnvironmentDetails(allenvspend);
        var envtemp = [];
        //environment list
        environmentList.map((envdata, index) => {
          const listenvironmentfillter = data.newfunctionquery?.filter(
            (datas) => {
              return (
                datas.environments?.toLowerCase() === envdata?.toLowerCase()
              );
            }
          );
          var allcloudlist = getCloudList(listenvironmentfillter);
          //vm
          const environmentvmfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "VM";
            }
          );
          var environmentvm = { type: "VM", count: 0, spend: 0 };
          if (environmentvmfiltered.length > 0) {
            environmentvm = {
              type: "VM",
              count: environmentvmfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentvmfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }

          //db
          const environmentdbfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "DB";
            }
          );
          var environmentdb = { type: "DB", count: 0, spend: 0 };
          if (environmentdbfiltered.length > 0) {
            environmentdb = {
              type: "DB",
              count: environmentdbfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentdbfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }
          //others
          const environmentothersfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "Others";
            }
          );
          var environmentothers = { type: "Others", count: 0, spend: 0 };
          if (environmentothersfiltered.length > 0) {
            environmentothers = {
              type: "Others",
              count: environmentothersfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentothersfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }
          const envvlaue =
            props.type === "db" ? environmentdb : environmentothers;
          envtemp.push([capitalizeFirst(envdata), envvlaue, [allcloudlist]]);
        });


        setListEnvironmentDetails(envtemp);


        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
  }, [
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    props,
  ]);
  const getCloudList = (list) => {
    var cloudlist = ["aws", "azure", "gcp"];
    var envtemp = [];
    cloudlist.map((envdata, index) => {

      const listenvironmentfillter = list.filter((datas) => {
        return datas.all_cloud?.toLowerCase() === envdata?.toLowerCase();
      });

      var applicationlist = getApplicationList(listenvironmentfillter);

      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([envdata, envvalue, [applicationlist]]);
      }
    });

    return envtemp;
  };
  const getApplicationList = (list) => {

    var cloudlist = applicationList;
    var envtemp = [];
    cloudlist.map((envdata, index) => {
 
      const listenvironmentfillter = list.filter((datas) => {
  
        return datas.applications?.toLowerCase() === envdata?.toLowerCase();
      });
      var servicelist = getServiceList(listenvironmentfillter);
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([capitalizeFirst(envdata), envvalue, [servicelist]]);
      }
    });
    return envtemp.sort((a, b) =>
      a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1
    );
  };

  const getServiceList = (list) => {
    const service_list = list?.map((v) => v.services);
    const service_set = new Set([...service_list]);
    const service = [...service_set];

    var cloudlist = service;
    var envtemp = [];
    cloudlist?.map((envdata, index) => {
      const listenvironmentfillter = list.filter((datas) => {
    
        return datas.services?.toLowerCase() === envdata?.toLowerCase();
      });
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([capitalizeFirst(envdata), envvalue]);
      }
    });
    return envtemp.sort((a, b) =>
      a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1
    );
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <Spin spinning={preloader} wrapperClassName="costimize-loader">
          <ul className="cd-accordion--animated cd-accordion--animated-vs">
            <div>
              <Row
                className={
                  props.classType == "slm"
                    ? "assent-spend-head slm-bg"
                    : "assent-spend-head"
                }
              >
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  Environment
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  Spend
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  Name
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  Size
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  RAM(GIB)
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  Core
                </Col>
              </Row>
            </div>
            <li className="cd-accordion__item cd-accordion__item--has-children ">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name="group-db-1"
                id="group-db-1"
                // defaultChecked
              />
              <label
                className="cd-accordion__label plat-asset-spend-body-vs "
                htmlFor="group-db-1"
                style={{ margin: "10px 0px" }}
                // onClick={() => handleOnClick()}
              >
                <span className="accordion-full-width ">
                  <ToggleButtonV2
                    status={true}
                    newclass="all-environment-icon "
                    onClick={() => setIsRowClicked(!isRowClicked)}
                  >
                    <Row className="plat-table-assets-spend-thead p-2">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-title-vs "
                      >
                        All Environment(
                        {allenvironmentdetails.count})
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        ${numberFormat(allenvironmentdetails.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (allenvironmentdetails.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            allenvironmentdetails.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                    </Row>
                  </ToggleButtonV2>
                  <div className="asset-spend-border-bottom-vs"></div>
                </span>
              </label>
      
                <AssetSpendDBenvironment
                  listenvironmentdetails={listenvironmentdetails}
                />
            
            </li>
          </ul>
        </Spin>
      </div>
    </div>
  );
}

export default AssetSpendDBMain;
