import { Icon } from "@iconify/react";
import { Card, Progress, Spin } from "antd";
import React from "react";

function PeemsStatusCards({ statusCounts, isShowSpinner, type }) {
  // console.log(statusCounts);
  return (
    <>
      <Card>
        <>
          <div className="job-card job-card-initial ">
            <div className="w-100 ">
              {isShowSpinner ? (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-around">
                    <span className="job-status-icon">
                      <Icon icon="pajamas:clear-all" />
                    </span>
                    <span className="job-status-text">Total Jobs</span>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <h3 className="total-job-count">
                      {statusCounts.totalJobs}
                    </h3>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </Card>
      <Card>
        <div className="job-card ">
          <div className="w-100">
            {isShowSpinner ? (
              <div className="d-flex justify-content-center">
                <Spin />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <span className="job-status-icon">
                    <Icon icon="ic:round-done-all" /> &nbsp;Success
                  </span>
                  <h4 className="job-status-count">
                    {statusCounts.successJobs}
                  </h4>
                </div>
                <div className="d-flex justify-content-center mt-2 job-progress">
                  <Progress
                    percent={statusCounts.successJobs}
                    showInfo={false}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      {type != "report" && (
        <Card>
          <div className="job-card ">
            <div className="w-100">
              {isShowSpinner ? (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <span className="job-status-icon">
                      <Icon icon="icon-park-outline:timer" /> &nbsp;Scheduled
                    </span>
                    <h4 className="job-status-count">
                      {statusCounts.scheduleJobs}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-center mt-2 job-progress">
                    <Progress
                      percent={statusCounts.scheduleJobs}
                      showInfo={false}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      )}
      <Card>
        <div className="job-card ">
          <div className="w-100">
            {isShowSpinner ? (
              <div className="d-flex justify-content-center">
                <Spin />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <span className="job-status-icon">
                    <Icon icon="healthicons:no" /> &nbsp;Failed
                  </span>
                  <h4 className="job-status-count">
                    {statusCounts.failureJobs}
                  </h4>
                </div>
                <div className="d-flex justify-content-center mt-2 job-progress">
                  <Progress
                    percent={statusCounts.failureJobs}
                    showInfo={false}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      <Card>
        <div className="job-card ">
          <div className="w-100">
            {isShowSpinner ? (
              <div className="d-flex justify-content-center">
                <Spin />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <span className="job-status-icon">
                    <Icon icon="material-symbols:release-alert-rounded" />{" "}
                    &nbsp;Not Triggered
                  </span>
                  <h4 className="job-status-count">
                    {statusCounts.unTrigerredJobs}
                  </h4>
                </div>
                <div className="d-flex justify-content-center mt-2 job-progress">
                  <Progress
                    percent={statusCounts.unTrigerredJobs}
                    showInfo={false}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </>
  );
}

export default PeemsStatusCards;
