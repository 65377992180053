import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { listServiceSpendbyDate } from "../../action/costimizeV2Active";

const SpendDayServiceChart = (props) => {
  const dispatch = useDispatch();
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const [chart, setChart] = useState({
    value: [],
    categories: [],
  });
  useEffect(() => {
    props.setPreloader(true);
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: props.spenddate,
    };
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );

    dispatch(listServiceSpendbyDate({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data)
        props.setPreloader(false);

        if (data.daywise_service_spend.length > 0) {
          console.log(data.daywise_service_spend);
          var plat_date_list = [];
          const listUserConfigs = data.daywise_service_spend?.map((v) =>
            v.service.toLowerCase()
          );
          const uniqueArr = new Set([...listUserConfigs]);
          const account_list = [...uniqueArr];
          console.log(props.spenddate);
          // const today1 = new Date(props.spenddate);
          // const today = new Date();
          // const lastDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
          // date_lable_list[date_lable_list.length - 1] = lastDate;
          // const currentMonth = today1.getMonth();
          // const currentYear = today1.getFullYear();
          // const currentDateCount = new Date(
          //   currentYear,
          //   currentMonth + 1,
          //   0
          // ).getDate();
          // console.log(currentDateCount)
          const today1 = new Date(props.spenddate);
          const today = new Date();
          console.log(today)
          console.log(today1)
          const lastDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
          const currentMonth = today1.getMonth();
          const currentYear = today1.getFullYear();

          let currentDateCount;
          console.log(currentMonth)
          console.log(currentYear)

          if (today.getMonth() === today1.getMonth()) {
            currentDateCount = today.getDate() - 1;

          } else {
            currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();
          }


          var date_lable_list = [];
          for (let index = 0; index < currentDateCount; index++) {
            date_lable_list.push(
              `${new Date(props.spenddate).getFullYear()}-${new Date(props.spenddate).getMonth() + 1
              }-${+index + +1}`
            );
          }
          // var date_lable_list = [];

          // for (let index = 0; index < currentDateCount; index++) {
          //   date_lable_list.push(
          //     `${new Date(props.spenddate).getFullYear()}-${new Date(props.spenddate).getMonth() + 1
          //     }-${index + 1}`
          //   );
          // }


         
          account_list.map((account_name) => {
            var day_by_spend = [];
            for (let index = 0; index < currentDateCount; index++) {
              const check_date = `${new Date(props.spenddate).getFullYear()}-${new Date(props.spenddate).getMonth() + 1
                }-${+index + +1}`;
              const service_filter = data.daywise_service_spend?.filter(
                (datas_val) =>
                  datas_val.service.toLowerCase() ===
                  account_name.toLowerCase() &&
                  new Date(check_date).getMonth() + 1 ===
                  new Date(datas_val.date).getMonth() + 1 &&
                  new Date(check_date).getDate() ===
                  new Date(datas_val.date).getDate()
              );
              console.log(service_filter)

              // if (account_name == "awslambda") {
              //   const new_check_records = data.daywise_service_spend?.filter(
              //     (datas_val) => {
              //       console.log(datas_val.date);
              //       console.log(new Date(check_date).getDate());
              //       console.log(new Date(datas_val.date).getDate());
              //     }
              //   );
              // }

              var total_spend =
                service_filter.length > 0
                  ? service_filter.reduce((a, v) => (a = +a + +v?.spend), 0)
                  : 0;
              day_by_spend.push(total_spend);
            }
            console.log(day_by_spend)
            var final_value = {
              name: account_name,
              data: day_by_spend,
            };
            console.log(final_value)
            plat_date_list.push(final_value);
          });
          console.log(plat_date_list)
          setChart({
            value: plat_date_list,
            categories: date_lable_list,
          });
        }
      })
      .catch((err) => {
        props.setPreloader(false)
        console.log(err);
      });
  }, [selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    props.spenddate,
    selecteddb,
    selectedinfra,
    selectedos,]);
  function getOrdinalSuffix(day) {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }

  const series = chart.value;
  const options = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          props.resourcesChartDate(chart.categories[config.dataPointIndex]);
        },
      },
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "#0000FF",
      "#000066",
      "#990099",
      "#330033",
      "#FF6633",
      "#339999",
      "#009900",
      "#4E342E",
      "#004D40",
      "#FB8C00",
      "#212121",
      "#00ACC1",
      "#03A9F4",
      "#2196F3",
      "#FFB74D",
      "#37474F",
      "#D35400",
      "#5B2C6F",
      "#873600",
      "#0C7846",
      "#250056",
      "#37223E",
      "#401205",
      "#103E1C",
      "#100347",
      "#4D111B",
      "#4F5A04",
      "#008F4B",
      "#1A603F",
      "#6A5C00",
    ],
    yaxis: {
      labels: {
        formatter: (record) => {
          const num = (Math.round(record * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    // xaxis: {
    //   categories: chart.categories,
    //   ticks: {
    //     maxRotation: 45,
    //     minRotation: 45,
    //   },
    //   labels: {
    //     rotate: -45,
    //     formatter: (num) => {
    //       const date = new Date(num);
    //       const day = date.getDate();
    //       const month = date.toLocaleString("default", { month: "short" });
    //       const isCurrentMonth =
    //         date.getMonth() === new Date(props.spenddate).getMonth() &&
    //         date.getFullYear() === new Date(props.spenddate).getFullYear();

    //       if (isCurrentMonth && day <= new Date().getDate()) {
    //         return `${day}${getOrdinalSuffix(day)}-${month}`;
    //       } else {
    //         return "";
    //       }
    //     },
    //   },
    // },

    // xaxis: {
    //   categories: chart.categories,
    //   labels: {
    //     formatter: (num) => {
    //       const monthNamesShort = [
    //         "Jan",
    //         "Feb",
    //         "Mar",
    //         "Apr",
    //         "May",
    //         "Jun",
    //         "Jul",
    //         "Aug",
    //         "Sep",
    //         "Oct",
    //         "Nov",
    //         "Dec",
    //       ];


    //       const date = new Date(num);
    //       const day = date.getDate();
    //       const month = monthNamesShort[date.getMonth()];
    //       console.log(date)
    //       console.log(date)
    //       console.log(props.spenddate)

    //       const isCurrentMonth =
    //         date.getMonth() === new Date(props.spenddate).getMonth() &&
    //         date.getFullYear() === new Date(props.spenddate).getFullYear();

    //       console.log(isCurrentMonth)
    //       if (isCurrentMonth) {

    //         const today = new Date();
    //         const currentDay = today.getDate();


    //         if (day <= currentDay) {
    //           return `${day}${getOrdinalSuffix(day)} ${month}`;
    //         } else {
    //           return "";
    //         }
    //       } else {

    //         return `${day}${getOrdinalSuffix(day)} ${month}`;
    //       }
    //     },
    //   },
    // },
    xaxis: {
      categories: chart.categories,
      labels: {
        formatter: (num) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          // const current_month_text = `${
          //   monthNamesShort[new Date(num).getMonth()]
          // }-${new Date(num).getDate()}`;
          // return current_month_text;
          const date = new Date(num);
          const day = date.getDate();
          console.log(day)
          console.log(date)
          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${day}${getOrdinalSuffix(day)} ${month}`;

          return current_month_text;

        },
      },
    },
    legend: {
      show: false,
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  );
};
export default memo(SpendDayServiceChart);
