import React from "react";
import { Icon } from "@iconify/react";

const Headerchat = () => {

  
    const handleHeaderButtonClick = () => {
      console.log("Header button clicked!");
     
    };

    return (
      <div className="header">
        {/* <p className="header-text">Conversation with </p> */}
        <button className="header-button" onClick={handleHeaderButtonClick}>
          {/* Click Me */}
          <Icon icon="mingcute:notification-fill" onClick={handleHeaderButtonClick}/>

    
        </button>
      </div>
    );
  };
export default Headerchat  
  