import { Row, Col } from 'react-bootstrap';
import { message, Form, Input, Select, DatePicker, Button, Checkbox, InputNumber } from 'antd';
import DrawerPlat from '../../properties/Drawer/DrawerPlat';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import moment from "moment";
import { updateInstanceRuntime } from "../../action/costimizev2DashboardAction";
import { chartInstanceRuntime } from "../../action/costimizev2DashboardAction";



const AddInstanceRuntime = (props) => {
    const { selectedEnvironment, selectedAccount, selectedCloud, selectedApplication, selectedos, environmentList, applicationList  } = useSelector((state) => state.constimize);
    // const { companyName } = useSelector((state) => state.user);
    var companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
    const [oldrecords, setOldRecords] = useState([]);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [buttonloading, setButtonLoading] = useState(false);
    const [allapplication, setAllapplication] = useState(applicationList.map((list) => {
        return {
            value: list,
            label: list,
        }
    }));

    const [allenvironment, setAllenvironment] = useState(environmentList.map((list) => {
        return {
          value: list,
          label: list,
        }
    }));
    const [hidepage, setHidePage] = useState(false);
    const [monthcount, setMonthCount] = useState('');
    useEffect(() => {
        var records = {
            environment: '',
            application:'',
            actual_runtime: '',
            instance_name:'',
            planned_runtime: '',
            // date: ''
        };
        if(props.instancerecords){
            records = {
                environment: props.instancerecords.environment,
                application: props.instancerecords.application,
                actual_runtime: props.instancerecords.actual_runtime,
                instance_name: props.instancerecords.instance_name,
                planned_runtime: props.instancerecords.planned_runtime,
                month_year: moment(new Date(props.instancerecords.fulldate)),
                planned_runtime_status: true,
            };
        }   
        form.setFieldsValue(records);
        if(props.instancerecords){
            const apivalue = {
                account_name: selectedAccount.toString(),
                application: selectedApplication.toString(),
                cloud: selectedCloud.toString(),
                company_name: companyName,
                environment: selectedEnvironment.toString(),
                month: props.instancerecords.fulldate,
                os: selectedos.toString(),
                resourcename: props.instancerecords.instance_name,
                resourceid: props.instancerecords.resourceid
              }
              dispatch(chartInstanceRuntime({apivalue}))
              .unwrap()
              .then(({ data }) => {
                const short_date = [...data.newfunctionquery14].sort((a, b) => (a.usagestartdate < b.usagestartdate ? -1 : 1));
                const today = new Date(props.instancerecords.fulldate);
                const currentMonth = today.getMonth();
                const currentYear = today.getFullYear();
                const currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();
                var datehtmllist = [];
                for (let index = 0; index < currentDateCount; index++) {
                    const day = +index + +1;
                    var defaultvalue = 0;
                    if(short_date[index]){
                        defaultvalue = short_date[index].planned_runtime;
                    }
                    var dayName =  `day${day}`;
                    const records = {
                        [dayName] : defaultvalue
                    };
                    // console.log(records);
                    form.setFieldsValue(records);
                    datehtmllist.push(<Col lg={2}>
                            <Form.Item
                                name={`day${day}`}
                                label={`Day ${day}`}
                                rules={[
                                    { required: true, message: '' },
                                ]}
                            >
                                <InputNumber 
                                    max={24}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>)
                }
                setMonthCount(datehtmllist);
              })
              .catch(err => {
                  console.log(err.message);
              });
        
            //   console.log(apivalue);
        }
      
    },[props])

    const handleSubmit = (values) => {
        setButtonLoading(true);
        var plan_value_list = [];
        var plat_date_list = [];
        const today = new Date(props.instancerecords.fulldate);
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        const currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();

        for (let index = 0; index < currentDateCount; index++) {
            plat_date_list.push(`${new Date(props.instancerecords.fulldate).getFullYear()}-${new Date(props.instancerecords.fulldate).getMonth() + 1}-${+index + +1}`);
        }
        if(hidepage){
            if(currentDateCount == 28){
                plan_value_list = [values.day1,values.day2,values.day3,values.day4,values.day5,values.day6,values.day7,values.day8,values.day9,values.day10,values.day11,values.day12,values.day13,values.day14,values.day15,values.day16,values.day17,values.day18,values.day19,values.day20,values.day21,values.day22,values.day23,values.day24,values.day25,values.day26,values.day27,values.day28];
            }else if(currentDateCount == 30){
                plan_value_list = [values.day1,values.day2,values.day3,values.day4,values.day5,values.day6,values.day7,values.day8,values.day9,values.day10,values.day11,values.day12,values.day13,values.day14,values.day15,values.day16,values.day17,values.day18,values.day19,values.day20,values.day21,values.day22,values.day23,values.day24,values.day25,values.day26,values.day27,values.day28,values.day29,values.day30];
            }else{
                plan_value_list = [values.day1,values.day2,values.day3,values.day4,values.day5,values.day6,values.day7,values.day8,values.day9,values.day10,values.day11,values.day12,values.day13,values.day14,values.day15,values.day16,values.day17,values.day18,values.day19,values.day20,values.day21,values.day22,values.day23,values.day24,values.day25,values.day26,values.day27,values.day28,values.day29,values.day31];
            }
        }else{
            for (let index = 0; index < currentDateCount; index++) {
                plan_value_list.push(values.planned_runtime);
            }
        }
        const inputs = {
            application : values.application,
            company_name : companyName,
            environment : values.environment,
            planned_runtime : plan_value_list.toString(),
            planning_date : plat_date_list.toString(),
            report_month : props.instancerecords.fulldate,
            resource_id  : props.instancerecords.resourceid,
            resource_name  : props.instancerecords.instance_name,
        }
        // console.log(inputs);
        
        dispatch(updateInstanceRuntime({inputs}))
        .unwrap()
        .then(({ data }) => {
            // console.log(data);
            setButtonLoading(false);
            message.success('Updated Successfully Done');
            props.changeInstancesDrawerStatus(false);
            props.setDatachange(!props.datachange);
            // setTimeout(function() {  props.setDatachange(!props.datachange); }, 3 * 1000 );
           
        })
        .catch(err => {
            console.log(err.message);
            setButtonLoading(false);
            message.error(err);
        });
    }
    const checkPlaanedRuntimeDays = (e) => {
        if(e.target.checked){
            setHidePage(false);
        }else{
            setHidePage(true);
        }
    }
    return(
        <>
            <DrawerPlat 
                drawerstatus={props.instancedrawerstatus}
                changeDrawerStatus={props.changeInstancesDrawerStatus}
                title="Instance"
                // deletestatus={props.deletestatus}
                deletestatus={false}
            >
                <Form 
                    layout="vertical"
                    onFinish={handleSubmit}
                    // initialValues={initialValues}
                    form = {form}
                >
                    <Row>
                        <Col>
                            <Form.Item
                                name="environment"
                                label="Environment"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                    width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={allenvironment}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="application"
                                label="Application"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                    width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={allapplication}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                    name="instance_name"
                                    label="Instance Name"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                        disabled
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="month_year"
                                label="Month / Year"
                                rules={[{ required: true, message: '' }]}
                            >
                                <DatePicker  
                                    style={{
                                    width: "100%",
                                    }}
                                    picker="month" 
                                    defaultValue={moment(new Date())}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {
                            !hidepage &&
                            <Form.Item
                                name="planned_runtime"
                                label="Planned Runtime"
                                rules={[{ required: true, message: '' }]}
                            >
                                <InputNumber 
                                    style={{
                                        width: "100%",
                                    }}
                                    prefix="hr"
                                />
                            </Form.Item>
                        }
                            
                        </Col>
                        <Col>
                            <Form.Item
                                name="actual_runtime"
                                label="Actual Runtime"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input 
                                    style={{
                                        width: "100%",
                                    }}
                                    prefix="hr"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name="planned_runtime_status"
                            >
                                <Checkbox 
                                    onChange={checkPlaanedRuntimeDays}
                                    checked={hidepage ? false : true}
                                >
                                    Planned Runtime Apply For All Date's
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        hidepage &&
                        <>
                            <Row>
                                {monthcount}
                            </Row>
                        </>
                    }
                    <Row>
                        <Form.Item >
                            <Button htmlType="button"  onClick={() => props.setInstanceDrawerStatus(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ background: "#1b5f9d", color: "#fff", float: "right" }} loading = {buttonloading}>
                                Submit
                            </Button>

                        </Form.Item>
                    </Row>  
                </Form>
            </DrawerPlat>
        </>
    )
}

export default AddInstanceRuntime;