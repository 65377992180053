import { memo, useState } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import AssetTrendChart from "./AssetTrendChart";
import DetailsView from "./DetailsView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetSpendVMRecords } from "../../action/costimizeV2Active";
import { numberFormat, capitalizeFirst } from "../../custom_hook/CustomHook";

const AssetSpendList_old = (props) => {
  const [detailsviewstatus, setDetailsViewStatus] = useState(false);
  const dispatch = useDispatch();
  const [environmentdetails, setEnvironmentDetails] = useState({
    title: "All Environment",
    spend: "-",
    name: "-",
    size: "-",
    ram: "-",
    core: "-",
    storage: "-",
    savings_opportunity: "-",
    spend_trend: "",
    count: 0,
  });
  const [allenvironmentlist, setAllEnvironmentList] = useState([]);
  const [popupdetails, setPopupDetails] = useState([]);
  //loader
  const [loader, setLoader] = useState(true);

  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    environmentList,
    applicationList,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {
    // console.log("checking");
    // console.log(props);
    setLoader(true);

    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }

    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    dispatch(getAssetSpendVMRecords({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (data.newfunctionquery30.length > 0) {
          props.setRecords(data.newfunctionquery30);
          var first_month = month_list[0];
          var second_month = month_list[1];
          var thired_month = month_list[2];
          //enviroment list
          const enviroment = data.newfunctionquery30.map((v) =>
            v.environment.toLowerCase()
          );
          var enviromentlist = new Set([...enviroment]);
          enviromentlist = [...enviromentlist];
          //cloud list
          const cloud = data.newfunctionquery30.map((v) =>
            v.cloud.toLowerCase()
          );
          var cloudlist = new Set([...cloud]);
          cloudlist = [...cloudlist];
          //application list
          const application = data.newfunctionquery30.map((v) =>
            v.application.toLowerCase()
          );
          var applicationlist = new Set([...application]);
          applicationlist = [...applicationlist];
          props.setApplicationList(applicationlist);
          const spendtotal = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //ram

          const ram_list = data.newfunctionquery30.map((data) => {
            if (data) {
              const ram_value = data.ram?.split("GiB");
              if (ram_value?.length > 0) {
                return Number(ram_value[0].trim());
              } else {
                return 0;
              }
            }
          });
          const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
          const core = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.core),
            0
          );
          var storage = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.storage),
            0
          );
          if (storage > 1000) {
            storage = storage / 1000;
            storage = `${storage} TB`;
          } else {
            storage = `${storage} GiB`;
          }
          const savings_opportunity = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.savings_opportunity),
            0
          );
          //chart
          const third_month_spend = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.third_month_spend),
            0
          );
          const second_month_spend = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.second_month_spend),
            0
          );
          const first_month_spend = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.first_month_spend),
            0
          );
          const chart = {
            chartlable: [first_month, second_month, thired_month],
            chartvalue: [
              Math.round(third_month_spend),
              Math.round(second_month_spend),
              Math.round(first_month_spend),
            ],
          };
          //environment total
          setEnvironmentDetails({
            title: "All Environment",
            spend: Math.round(spendtotal),
            name: "-",
            size: "-",
            ram: ram === 0 ? "-" : Math.round(ram),
            core: core === 0 ? "-" : Math.round(core),
            storage: storage,
            savings_opportunity: !savings_opportunity
              ? 0
              : Math.round(savings_opportunity),
            spend_trend: chart,
            count: data.newfunctionquery30.length,
          });

          //environment list
          const environment_all_list = [];
          enviromentlist.map((data_map) => {
            const environment_data_list = data.newfunctionquery30?.filter(
              (datas) => {
                return (
                  datas.environment.toLowerCase() === data_map.toLowerCase()
                );
              }
            );
            const cloud_list = getCloudRecordsList(
              environment_data_list,
              first_month,
              second_month,
              thired_month,
              cloudlist,
              applicationlist
            );
            const return_environment = getEnvironmentList(
              data_map,
              environment_data_list,
              first_month,
              second_month,
              thired_month,
              cloud_list
            );
            environment_all_list.push(return_environment);
          });

          setAllEnvironmentList(environment_all_list);
        } else {
          setEnvironmentDetails({
            title: "All Environment",
            spend: 0,
            name: "-",
            size: "-",
            ram: "-",
            core: "-",
            storage: "-",
            savings_opportunity: 0,
            spend_trend: "",
            count: 0,
          });
          setAllEnvironmentList([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  }, [
    companyName,
    selectedTopdate,
    environmentList,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    props,
  ]);
  //cloud
  const getCloudRecordsList = (
    data_list,
    first_month,
    second_month,
    thired_month,
    cloudlist,
    applicationlist
  ) => {
    const cloud_all_list = [];
    cloudlist.map((data_map) => {
      const cloud_data_list = data_list?.filter((datas) => {
        return datas.cloud.toLowerCase() === data_map.toLowerCase();
      });
      const cloud_list = getApplicationRecordsList(
        cloud_data_list,
        first_month,
        second_month,
        thired_month,
        applicationlist
      );
      const return_environment = getEnvironmentList(
        data_map,
        cloud_data_list,
        first_month,
        second_month,
        thired_month,
        cloud_list
      );
      cloud_all_list.push(return_environment);
    });
    return cloud_all_list;
  };
  const getApplicationRecordsList = (
    data_list,
    first_month,
    second_month,
    thired_month,
    applicationlist
  ) => {
    const application_all_list = [];
    applicationlist.map((data_map) => {
      const application_data_list = data_list?.filter((datas) => {
        return datas.application.toLowerCase() === data_map.toLowerCase();
      });
      if (application_data_list.length > 0) {
        const resources_list = getResourcesRecordsList(
          application_data_list,
          first_month,
          second_month,
          thired_month
        );
        const return_application = getEnvironmentList(
          data_map,
          application_data_list,
          first_month,
          second_month,
          thired_month,
          resources_list
        );
        application_all_list.push(return_application);
      }
    });
    return application_all_list;
  };

  const getResourcesRecordsList = (
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    var return_value = [];
    data_list.map((records) => {
      const chart = {
        chartlable: [first_month, second_month, thired_month],
        chartvalue: [
          Math.round(records.third_month_spend),
          Math.round(records.second_month_spend),
          Math.round(records.first_month_spend),
        ],
      };
      var storage_value;
      if (records.storage) {
        if (records.storage > 1000) {
          storage_value = records.storage / 1000;
          storage_value = `${storage_value} TB`;
        } else {
          storage_value = `${records.storage} GiB`;
        }
      } else {
        storage_value = 0;
      }
      //ram
      var ram_records = 0;
      const ram_value = records.ram?.split("GiB");
      if (ram_value?.length > 0) {
        ram_records = Number(ram_value[0].trim());
      }
      const return_data = {
        title: "",
        spend: Math.round(records.spend),
        name: records.resourcename ? records.resourcename : "-",
        size: records.instancetype ? records.instancetype : "-",
        ram: ram_records === "" ? "-" : ram_records,
        core: records.core === "" ? "-" : Number(records.core),
        storage: storage_value,
        savings_opportunity: !records.savings_opportunity
          ? 0
          : Number(records.savings_opportunity),
        spend_trend: chart,
        index: records,
      };
      return_value.push(return_data);
    });
    return return_value;
  };

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month,
    list
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    //ram
    const ram_list = data_list.map((data) => {
      if (data) {
        const ram_value = data.ram?.split("GiB");
        if (ram_value?.length > 0) {
          return Number(ram_value[0].trim());
        } else {
          return 0;
        }
      }
    });

    const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
    const core = data_list.reduce((a, v) => (a = +a + +v.core), 0);
    var storage = data_list.reduce((a, v) => (a = +a + +v.storage), 0);
    if (storage > 1000) {
      storage = storage / 1000;
      storage = `${storage} TB`;
    } else {
      storage = `${storage} GiB`;
    }
    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",
      size: "-",
      ram: ram === 0 ? "-" : Math.round(ram),
      core: core === 0 ? "-" : Math.round(core),
      storage: storage,
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      list: list,
      count: data_list.length,
    };
  };
  const openPopupDetails = (data) => {
    setPopupDetails(data);
    setDetailsViewStatus(true);
  };
  return (
    <>
      {detailsviewstatus && (
        <DetailsView
          detailsviewstatus={detailsviewstatus}
          setDetailsViewStatus={setDetailsViewStatus}
          details={popupdetails}
        />
      )}
      <div>
        <Spin spinning={loader} wrapperClassName="costimize-loader">
          <ul className="cd-accordion--animated cd-accordion--animated-vs">
            <div className="costimize-asset-spend-scroll">
              <Row
                className={
                  props.classType === "slm"
                    ? "assent-spend-head slm-bg"
                    : "assent-spend-head"
                }
              >
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="plat-text-center"
                >
                  Environment
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="plat-text-right"
                >
                  Spend
                </Col>
                <Col
                  xxl={3}
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className="plat-text-center"
                >
                  Name
                </Col>
                {companyName !== "gmmco" && (
                  <>
                    <Col
                      xxl={2}
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="plat-text-center"
                    >
                      Size
                    </Col>

                    <Col
                      xxl={2}
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="plat-text-right"
                    >
                      RAM(GiB)
                    </Col>
                    <Col
                      xxl={2}
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="plat-text-right"
                    >
                      Core
                    </Col>

                    <Col
                      xxl={2}
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="plat-text-right"
                    >
                      Storage
                    </Col>
                  </>
                )}

                <Col
                  xxl={3}
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className="plat-text-right"
                >
                  Savings Opportunity
                </Col>
                {companyName === "gmmco" ? (
                  <>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="plat-text-center"
                    >
                      Spend Trend
                    </Col>
                  </>
                ) : (
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="plat-text-center"
                  >
                    Spend Trend
                  </Col>
                )}
              </Row>
            </div>
            <li className="cd-accordion__item cd-accordion__item--has-children">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name="group-1"
                id="group-1"
                // defaultChecked={props.viewStatus}
              />
              <label
                className="cd-accordion__label plat-asset-spend-body-vs"
                htmlFor="group-1"
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2 status={true} newclass="all-environment-icon">
                    <Row className="plat-table-assets-spend-thead ">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-title-vs"
                      >
                        {capitalizeFirst(environmentdetails.title)}(
                        {environmentdetails.count})
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(environmentdetails.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (environmentdetails.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            environmentdetails.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        -
                      </Col>
                      {companyName !== "gmmco" && (
                        <>
                          <Col
                            xxl={2}
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="asset-spend-values-vs justify-content-center"
                          >
                            -
                          </Col>
                          <Col
                            xxl={2}
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="asset-spend-values-vs plat-text-right"
                          >
                            {environmentdetails.ram}
                          </Col>
                          <Col
                            xxl={2}
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="asset-spend-values-vs plat-text-right"
                          >
                            {environmentdetails.core}
                          </Col>

                          <Col
                            xxl={2}
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="asset-spend-values-vs plat-text-right"
                          >
                            {environmentdetails.storage}
                          </Col>
                        </>
                      )}
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(environmentdetails.savings_opportunity)}
                      </Col>
                      {companyName === "gmmco" ? (
                        <>
                          <Col
                            xxl={12}
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="asset-spend-values-vs plat-chart-center"
                          >
                            <AssetTrendChart
                              records={environmentdetails.spend_trend}
                            />
                          </Col>
                        </>
                      ) : (
                        <Col
                          xxl={4}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                          className="asset-spend-values-vs plat-chart-center"
                        >
                          <AssetTrendChart
                            records={environmentdetails.spend_trend}
                          />
                        </Col>
                      )}
                    </Row>
                  </ToggleButtonV2>
                  <div className="asset-spend-border-bottom-vs"></div>
                </span>
              </label>
              {/* environment list */}
              {allenvironmentlist.map((environmentlist, index) => (
                <>
                  <ul className="cd-accordion__sub cd-accordion__sub--l1">
                    <li className="cd-accordion__item cd-accordion__item--has-children">
                      <input
                        className="cd-accordion__input"
                        type="checkbox"
                        id={"sub-group-" + index}
                        // defaultChecked
                      />
                      <label
                        className="cd-accordion__label"
                        htmlFor={"sub-group-" + index}
                      >
                        <span className="accordion-full-width">
                          <ToggleButtonV2
                            status={true}
                            newclass="environment-list-icon"
                          >
                            <Row className="plat-table-assets-spend-thead">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={4}
                                xs={4}
                                className=" asset-left-title environment-list-title asset-spend-title-vs"
                              >
                                {/* <ToggleButton
                          status={false}
                          newclass="environment-list-icon"
                          clickoption={true}
                        /> */}
                                {capitalizeFirst(environmentlist.title)}(
                                {environmentlist.count})
                              </Col>
                              <Col
                                xxl={2}
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                                className="asset-spend-values-vs plat-text-right"
                              >
                                ${numberFormat(environmentlist.spend)}
                                <Icon
                                  className={
                                    "me-1 " +
                                    (environmentlist.spend > 0
                                      ? "text-danger-icon-custom"
                                      : "text-success-icon-custom")
                                  }
                                  icon={
                                    environmentlist.spend > 0
                                      ? "material-symbols:arrow-drop-up"
                                      : "material-symbols:arrow-drop-down"
                                  }
                                  style={{ fontSize: "20px" }}
                                />
                              </Col>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="asset-spend-values-vs justify-content-center"
                              >
                                {environmentlist.name}
                              </Col>
                              {companyName !== "gmmco" && (
                                <>
                                  <Col
                                    xxl={2}
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    xs={2}
                                    className="asset-spend-values-vs justify-content-center"
                                  >
                                    {environmentlist.size}
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    xs={2}
                                    className="asset-spend-values-vs plat-text-right"
                                  >
                                    {environmentlist.ram}
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    xs={2}
                                    className="asset-spend-values-vs plat-text-right"
                                  >
                                    {environmentlist.core}
                                  </Col>
                                  {/* {companyName === "fivestar" ||
                                (companyName === "cmacgm" && ( */}
                                  <Col
                                    xxl={2}
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={2}
                                    xs={2}
                                    className="asset-spend-values-vs plat-text-right"
                                  >
                                    {environmentlist.storage}
                                  </Col>
                                  {/* ))} */}
                                </>
                              )}
                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="asset-spend-values-vs plat-text-right"
                              >
                                ${" "}
                                {numberFormat(
                                  environmentlist.savings_opportunity
                                )}
                              </Col>
                              {companyName === "gmmco" ? (
                                <>
                                  <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="asset-spend-values-vs plat-chart-center"
                                  >
                                    <AssetTrendChart
                                      records={environmentlist.spend_trend}
                                    />
                                  </Col>
                                </>
                              ) : (
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  className="asset-spend-values-vs plat-chart-center"
                                >
                                  <AssetTrendChart
                                    records={environmentlist.spend_trend}
                                  />
                                </Col>
                              )}
                            </Row>
                            <div className="asset-spend-border-bottom-vs"></div>
                          </ToggleButtonV2>
                        </span>
                      </label>
                      {/* cloud list */}
                      {environmentlist.list.map((cloudlist, indexc) => {
                        const ran_num = Math.random();
                        return (
                          <ul className="cd-accordion__sub cd-accordion__sub--l2">
                            <li className="cd-accordion__item cd-accordion__item--has-children">
                              <input
                                className="cd-accordion__input"
                                type="checkbox"
                                name={"sub-group-level-" + indexc + ran_num}
                                id={"sub-group-level-" + indexc + ran_num}
                                // defaultChecked
                              />
                              <label
                                className="cd-accordion__label"
                                htmlFor={"sub-group-level-" + indexc + ran_num}
                              >
                                <span className="accordion-full-width">
                                  <ToggleButtonV2
                                    status={true}
                                    newclass="cloud-list-icon"
                                  >
                                    <Row className="plat-table-assets-spend-thead">
                                      <Col
                                        xxl={4}
                                        xl={4}
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        className="asset-left-title cloud-list-title asset-spend-title-vs"
                                      >
                                        {cloudlist.title.toLowerCase() ===
                                        "aws" ? (
                                          <>
                                            <Icon
                                              icon="logos:aws"
                                              class="asset-spend-cloud-list-icon"
                                              style={{ marginRight: "4px" }}
                                            />{" "}
                                            AWS({cloudlist.count})
                                          </>
                                        ) : cloudlist.title.toLowerCase() ===
                                          "azure" ? (
                                          <>
                                            <Icon
                                              icon="vscode-icons:file-type-azure"
                                              style={{ marginRight: "4px" }}
                                            />{" "}
                                            Azure({cloudlist.count})
                                          </>
                                        ) : cloudlist.title.toLowerCase() ===
                                          "oracle" ? (
                                          <>
                                            <Icon
                                              icon="simple-icons:oracle"
                                              style={{
                                                color: "#FF0000",
                                                marginRight: "4px",
                                              }}
                                            />{" "}
                                            Oracle({cloudlist.count})
                                          </>
                                        ) : (
                                          <>
                                            <Icon
                                              icon="skill-icons:gcp-light"
                                              style={{ marginRight: "4px" }}
                                            />{" "}
                                            GCP({cloudlist.count})
                                          </>
                                        )}
                                      </Col>
                                      <Col
                                        xxl={2}
                                        xl={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={2}
                                        className="asset-spend-values-vs plat-text-right"
                                      >
                                        ${numberFormat(cloudlist.spend)}
                                        <Icon
                                          className={
                                            "me-1 " +
                                            (cloudlist.spend > 0
                                              ? "text-danger-icon-custom"
                                              : "text-success-icon-custom")
                                          }
                                          icon={
                                            cloudlist.spend > 0
                                              ? "material-symbols:arrow-drop-up"
                                              : "material-symbols:arrow-drop-down"
                                          }
                                          style={{ fontSize: "20px" }}
                                        />
                                      </Col>
                                      <Col
                                        xxl={3}
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                        className="asset-spend-values-vs justify-content-center"
                                      >
                                        {cloudlist.name}
                                      </Col>
                                      {companyName !== "gmmco" && (
                                        <>
                                          <Col
                                            xxl={2}
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={2}
                                            className="asset-spend-values-vs justify-content-center"
                                          >
                                            -
                                          </Col>
                                          <Col
                                            xxl={2}
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={2}
                                            className="asset-spend-values-vs plat-text-right"
                                          >
                                            {cloudlist.ram}
                                          </Col>
                                          <Col
                                            xxl={2}
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={2}
                                            className="asset-spend-values-vs plat-text-right"
                                          >
                                            {cloudlist.core}
                                          </Col>
                                          {/* {companyName === "fivestar" ||
                                      (companyName === "cmacgm" && ( */}
                                          <Col
                                            xxl={2}
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={2}
                                            className="asset-spend-values-vs plat-text-right"
                                          >
                                            {cloudlist.storage}
                                          </Col>
                                          {/* ))} */}
                                        </>
                                      )}
                                      <Col
                                        xxl={3}
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                        className="asset-spend-values-vs plat-text-right"
                                      >
                                        $
                                        {numberFormat(
                                          cloudlist.savings_opportunity
                                        )}
                                      </Col>
                                      {companyName === "gmmco" ? (
                                        <>
                                          <Col
                                            xxl={12}
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="asset-spend-values-vs plat-chart-center"
                                          >
                                            <AssetTrendChart
                                              records={cloudlist.spend_trend}
                                            />
                                          </Col>
                                        </>
                                      ) : (
                                        <Col
                                          xxl={4}
                                          xl={4}
                                          lg={4}
                                          md={4}
                                          sm={4}
                                          xs={4}
                                          className="asset-spend-values-vs plat-chart-center"
                                        >
                                          <AssetTrendChart
                                            records={cloudlist.spend_trend}
                                          />
                                        </Col>
                                      )}
                                    </Row>
                                    <div className="asset-spend-border-bottom-vs"></div>
                                  </ToggleButtonV2>
                                </span>
                              </label>
                              {/* application list */}
                              {cloudlist.list.map((applicationlist, indexa) => {
                                const ran_num_cloud = Math.random();
                                return (
                                  <ul className="cd-accordion__sub cd-accordion__sub--l3">
                                    <li className="cd-accordion__item cd-accordion__item--has-children">
                                      <input
                                        className="cd-accordion__input"
                                        type="checkbox"
                                        name={
                                          "sub-group-level3-" +
                                          index +
                                          "" +
                                          ran_num_cloud
                                        }
                                        id={
                                          "sub-group-level3-" +
                                          index +
                                          "" +
                                          ran_num_cloud
                                        }
                                        // defaultChecked
                                      />
                                      <label
                                        className="cd-accordion__label"
                                        htmlFor={
                                          "sub-group-level3-" +
                                          index +
                                          "" +
                                          ran_num_cloud
                                        }
                                      >
                                        <span className="accordion-full-width">
                                          <ToggleButtonV2
                                            status={true}
                                            newclass="application-list-icon"
                                          >
                                            <Row className="plat-table-assets-spend-thead">
                                              <Col
                                                xxl={4}
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={4}
                                                xs={4}
                                                className="asset-left-title cloud-list-title asset-spend-title-vs"
                                              >
                                                {capitalizeFirst(
                                                  applicationlist.title
                                                )}
                                                ({applicationlist.count})
                                              </Col>
                                              <Col
                                                xxl={2}
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={2}
                                                className="asset-spend-values-vs plat-text-right"
                                              >
                                                $
                                                {numberFormat(
                                                  applicationlist.spend
                                                )}
                                                <Icon
                                                  className={
                                                    "me-1 " +
                                                    (applicationlist.spend > 0
                                                      ? "text-danger-icon-custom"
                                                      : "text-success-icon-custom")
                                                  }
                                                  icon={
                                                    applicationlist.spend > 0
                                                      ? "material-symbols:arrow-drop-up"
                                                      : "material-symbols:arrow-drop-down"
                                                  }
                                                  style={{ fontSize: "20px" }}
                                                />
                                              </Col>
                                              <Col
                                                xxl={3}
                                                xl={3}
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                                className="asset-spend-values-vs justify-content-center"
                                              >
                                                {applicationlist.name}
                                              </Col>
                                              {companyName !== "gmmco" && (
                                                <>
                                                  <Col
                                                    xxl={2}
                                                    xl={2}
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className="asset-spend-values-vs justify-content-center"
                                                  >
                                                    {applicationlist.size}
                                                  </Col>
                                                  <Col
                                                    xxl={2}
                                                    xl={2}
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className="asset-spend-values-vs plat-text-right"
                                                  >
                                                    {applicationlist.ram}
                                                  </Col>
                                                  <Col
                                                    xxl={2}
                                                    xl={2}
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className="asset-spend-values-vs plat-text-right"
                                                  >
                                                    {applicationlist.core}
                                                  </Col>
                                                  {/* {companyName === "fivestar" ||
                                            (companyName === "cmacgm" && ( */}
                                                  <Col
                                                    xxl={2}
                                                    xl={2}
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className="asset-spend-values-vs plat-text-right"
                                                  >
                                                    {applicationlist.storage}
                                                  </Col>
                                                  {/* ))} */}
                                                </>
                                              )}
                                              <Col
                                                xxl={3}
                                                xl={3}
                                                lg={3}
                                                md={3}
                                                sm={3}
                                                xs={3}
                                                className="asset-spend-values-vs plat-text-right"
                                              >
                                                $
                                                {numberFormat(
                                                  applicationlist.savings_opportunity
                                                )}
                                              </Col>
                                              {companyName === "gmmco" ? (
                                                <>
                                                  <Col
                                                    xxl={12}
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    className="asset-spend-values-vs plat-chart-center"
                                                  >
                                                    <AssetTrendChart
                                                      records={
                                                        applicationlist.spend_trend
                                                      }
                                                    />
                                                  </Col>
                                                </>
                                              ) : (
                                                <Col
                                                  xxl={4}
                                                  xl={4}
                                                  lg={4}
                                                  md={4}
                                                  sm={4}
                                                  xs={4}
                                                  className="asset-spend-values-vs plat-chart-center"
                                                >
                                                  <AssetTrendChart
                                                    records={
                                                      applicationlist.spend_trend
                                                    }
                                                  />
                                                </Col>
                                              )}
                                            </Row>
                                            <div className="asset-spend-border-bottom-vs"></div>
                                          </ToggleButtonV2>
                                        </span>
                                      </label>
                                      {/* application list */}
                                      {applicationlist.list.map(
                                        (resourcelist) => (
                                          <ul className="cd-accordion__sub cd-accordion__sub--l3">
                                            <li className="cd-accordion__item cd-accordion__item--has-children">
                                              <a
                                                className="cd-accordion__label cd-accordion__label--icon-img"
                                                href="javascript:void(0)"
                                              >
                                                <span className="accordion-full-width">
                                                  <Row className="plat-table-assets-spend-thead">
                                                    <Col
                                                      xxl={4}
                                                      xl={4}
                                                      lg={4}
                                                      md={4}
                                                      sm={4}
                                                      xs={4}
                                                      className="asset-left-title cloud-list-title asset-spend-title-vs"
                                                    ></Col>
                                                    <Col
                                                      xxl={2}
                                                      xl={2}
                                                      lg={2}
                                                      md={2}
                                                      sm={2}
                                                      xs={2}
                                                      className="asset-spend-values-vs plat-text-right"
                                                    >
                                                      ${resourcelist.spend}
                                                      <Icon
                                                        className={
                                                          "me-1 " +
                                                          (resourcelist.spend >
                                                          0
                                                            ? "text-danger-icon-custom"
                                                            : "text-success-icon-custom")
                                                        }
                                                        icon={
                                                          resourcelist.spend > 0
                                                            ? "material-symbols:arrow-drop-up"
                                                            : "material-symbols:arrow-drop-down"
                                                        }
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      />
                                                    </Col>
                                                    <Col
                                                      xxl={3}
                                                      xl={3}
                                                      lg={3}
                                                      md={3}
                                                      sm={3}
                                                      xs={3}
                                                      className="asset-spend-values-vs justify-content-center"
                                                    >
                                                      {companyName === "apll" ||
                                                      companyName ===
                                                        "cmacgm" ? (
                                                        <span
                                                          onClick={() =>
                                                            openPopupDetails(
                                                              resourcelist.index
                                                            )
                                                          }
                                                        >
                                                          {resourcelist.name
                                                            .length > 10 ? (
                                                            <>
                                                              <Tooltip
                                                                placement="bottom"
                                                                title={
                                                                  resourcelist.name
                                                                }
                                                              >
                                                                {resourcelist.name.substring(
                                                                  0,
                                                                  10
                                                                )}
                                                                ...
                                                              </Tooltip>
                                                            </>
                                                          ) : (
                                                            resourcelist.name
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <span

                                                        // onClick={() =>
                                                        //   openPopupDetails(
                                                        //     resourcelist.index
                                                        //   )
                                                        // }
                                                        >
                                                          {resourcelist.name
                                                            .length > 10 ? (
                                                            <>
                                                              <Tooltip
                                                                placement="bottom"
                                                                title={
                                                                  resourcelist.name
                                                                }
                                                              >
                                                                {resourcelist.name.substring(
                                                                  0,
                                                                  10
                                                                )}
                                                                ...
                                                              </Tooltip>
                                                            </>
                                                          ) : (
                                                            resourcelist.name
                                                          )}
                                                        </span>
                                                      )}
                                                    </Col>
                                                    {companyName !==
                                                      "gmmco" && (
                                                      <>
                                                        <Col
                                                          xxl={2}
                                                          xl={2}
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          xs={2}
                                                          className="asset-spend-values-vs justify-content-center"
                                                        >
                                                          {/* {resourcelist.size} */}
                                                          {resourcelist.size
                                                            .length > 10 ? (
                                                            <>
                                                              <Tooltip
                                                                placement="bottom"
                                                                title={
                                                                  resourcelist.size
                                                                }
                                                              >
                                                                {resourcelist.size.substring(
                                                                  0,
                                                                  10
                                                                )}
                                                                ...
                                                              </Tooltip>
                                                            </>
                                                          ) : (
                                                            resourcelist.size
                                                          )}
                                                        </Col>
                                                        <Col
                                                          xxl={2}
                                                          xl={2}
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          xs={2}
                                                          className="asset-spend-values-vs plat-text-right"
                                                        >
                                                          {resourcelist.ram}
                                                        </Col>
                                                        <Col
                                                          xxl={2}
                                                          xl={2}
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          xs={2}
                                                          className="asset-spend-values-vs plat-text-right"
                                                        >
                                                          {resourcelist.core}
                                                        </Col>
                                                        {/* {companyName === "fivestar" ||
                                                (companyName === "cmacgm" && ( */}
                                                        <Col
                                                          xxl={2}
                                                          xl={2}
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          xs={2}
                                                          className="asset-spend-values-vs plat-text-right"
                                                        >
                                                          {resourcelist.storage}
                                                        </Col>
                                                        {/* ))} */}
                                                      </>
                                                    )}
                                                    <Col
                                                      xxl={3}
                                                      xl={3}
                                                      lg={3}
                                                      md={3}
                                                      sm={3}
                                                      xs={3}
                                                      className="asset-spend-values-vs plat-text-right"
                                                    >
                                                      $
                                                      {numberFormat(
                                                        resourcelist.savings_opportunity
                                                      )}
                                                    </Col>
                                                    {companyName === "gmmco" ? (
                                                      <>
                                                        <Col
                                                          xxl={12}
                                                          xl={12}
                                                          lg={12}
                                                          md={12}
                                                          sm={12}
                                                          xs={12}
                                                          className="asset-spend-values-vs plat-chart-center"
                                                        >
                                                          <AssetTrendChart
                                                            records={
                                                              resourcelist.spend_trend
                                                            }
                                                          />
                                                        </Col>
                                                      </>
                                                    ) : (
                                                      <Col
                                                        xxl={4}
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        xs={4}
                                                        className="asset-spend-values-vs plat-chart-center"
                                                      >
                                                        <AssetTrendChart
                                                          records={
                                                            resourcelist.spend_trend
                                                          }
                                                        />
                                                      </Col>
                                                    )}
                                                  </Row>
                                                  <div className="asset-spend-border-bottom-vs"></div>
                                                </span>
                                              </a>
                                            </li>
                                          </ul>
                                        )
                                      )}
                                    </li>
                                  </ul>
                                );
                              })}
                            </li>
                          </ul>
                        );
                      })}
                    </li>
                    <div>
                      <Row className="assent-spend-footer">
                        <Col span={24}></Col>
                      </Row>
                    </div>
                  </ul>
                </>
              ))}
            </li>
          </ul>
        </Spin>
      </div>
    </>
  );
};

export default memo(AssetSpendList_old);
