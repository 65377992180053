import { createAsyncThunk } from "@reduxjs/toolkit";
import client_new from "../../client/dashboardapplonewClinet";
// import client_new from "../../client/testingappoloClient";
import { useErrorNavigation } from "../../../custom_hook/CustomHook";
import {
  CREATE_IDENTIFIER,
  ESCALATION_DETAILS,
  JOB_CONFIG_IDENTIFIER,
  JOB_CREATE,
  LIST_CHART,
  LIST_CUSTOMER,
  LIST_EVENT,
  LIST_JOB_SCHEDULES,
  LIST_JOB_SCHEDULES_OVERVIEW,
  LIST_JOB_SCHEDULES_REPORT,
  LIST_OVERVIEW,
  LIST_REPORT,
  LIST_RULE,
  UPDATE_IDENTIFIER,
} from "./PeemsGraphQlQueries";
import { useNavigate } from "react-router-dom";

export const listOverview = createAsyncThunk(
  "peems/listOverview",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_overview = await client_new.query({
        query: LIST_OVERVIEW,
        variables: {
          companyName: data.companyName,
        },
      });
      console.log(data);

      return list_overview;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const listJobSchedulesOverview = createAsyncThunk(
  "peems/listJobSchedules",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_job_schedules = await client_new.query({
        query: LIST_JOB_SCHEDULES_OVERVIEW,
        variables: {
          event_identifier: data.value,
          customer_name: data.companyName,
        },
      });
      console.log(data);

      return list_job_schedules;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const listJobSchedulesReport = createAsyncThunk(
  "peems/listJobSchedules",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_job_schedules = await client_new.query({
        query: LIST_JOB_SCHEDULES_REPORT,
        variables: {
          event_identifier: data.value,
          startDate: data.startDate,
          companyName: data.companyName,
        },
      });
      console.log(data);

      return list_job_schedules;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listReport = createAsyncThunk(
  "peems/listreports",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_report = await client_new.query({
        query: LIST_REPORT,
        variables: {
          customer: data.companyName,
          startDate: data.selectedTopdate,
        },
      });
      console.log(data);

      return list_report;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listChart = createAsyncThunk(
  "peems/listreports",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_chart = await client_new.query({
        query: LIST_CHART,
        variables: {
          customer: data.companyName,
          startDate: data.selectedTopdate,
          status: "Completed",
        },
      });
      console.log(data);

      return list_chart;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const escalationDetails = createAsyncThunk(
  "peems/listreports",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const escalation_Details = await client_new.mutate({
        mutation: ESCALATION_DETAILS,
        variables: {
          date: data,
        },
      });
      console.log(data);

      return escalation_Details;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listCustomerIdentifier = createAsyncThunk(
  "peems/listCustomer",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_Customer_Identifier = await client_new.mutate({
        mutation: LIST_CUSTOMER,
        variables: {
          companyName: data.companyName,
        },
      });
      console.log(data);

      return list_Customer_Identifier;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listEventIdentifier = createAsyncThunk(
  "peems/listEvents",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_Event_Identifier = await client_new.mutate({
        mutation: LIST_EVENT,
        variables: {
          companyName: data.companyName,
        },
      });
      console.log(data);

      return list_Event_Identifier;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRuleIdentifier = createAsyncThunk(
  "peems/rules",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const list_Rule_Identifier = await client_new.mutate({
        mutation: LIST_RULE,
        variables: {
          companyName: data.companyName,
        },
      });
      console.log(data);

      return list_Rule_Identifier;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createIdentifier = createAsyncThunk(
  "peems/createIdentifier",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const create_identifier = await client_new.mutate({
        mutation: CREATE_IDENTIFIER,
        variables: {
          rule_id: data.values.rule_id,
          condition: data.values.condition,
          value: data.values.value,
          key: data.values.key,
          companyName: data.companyName,
        },
      });
      console.log(data);

      return create_identifier;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateIdentifier = createAsyncThunk(
  "peems/updateIdentifier",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const update_identifier = await client_new.mutate({
        mutation: UPDATE_IDENTIFIER,
        variables: {
          rule_id: data.values.rule_id,
          condition: data.values.condition,
          value: data.values.value,
          key: data.values.key,
          companyName: data.companyName,
        },
      });
      console.log(data);

      return update_identifier;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const jobConfigIdentifier = createAsyncThunk(
  "peems/jobConfigIdentifier",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const job_config_identifier = await client_new.mutate({
        mutation: JOB_CONFIG_IDENTIFIER,
        variables: {
          companyName: data,
        },
      });
      console.log(data);

      return job_config_identifier;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const jobCreate = createAsyncThunk(
  "peems/jobConfigIdentifier",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const job_Create = await client_new.mutate({
        mutation: JOB_CREATE,
        variables: {
          cron_description: data.values.cron_description,
          job_description: data.values.job_description,
          job_type: data.values.job_type,
          job_name: data.values.job_name,
          jobactual_runtime: data.values.jobactual_runtime,
          jobbuffer_runtime: data.values.jobbuffer_runtime,
          rule_identifier: data.values.rule_identifier,
          customer_identifier: data.values.customer_identifier,
          event_identifier: data.values.event_identifier,
          event_type: "email",
          companyName: data.companyName,
          cron_value: data.values.cron_value,
        },
      });
      console.log(data);

      return job_Create;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
