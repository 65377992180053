import { Avatar, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { IoIosSearch } from "react-icons/io";
import { TbGridDots } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SiteLogo from "../../assets/images/logo.svg";
import "./header.css";
import { setUserLog, setChatbotStatus } from "../slice/userSlice";
import { loginUser } from "../action/userAction";
import { signOut } from "../amplify/auth-config";
import header_logo from "../../assets/images/cams/cams_header_logo.svg";
import costdemaze_white_logo from "../../assets/images/costdemaze_white_logo.png";

function NavHeader(props) {
  const navigate = useNavigate();
  const [platformlogo, setPlatformLogo] = useState(SiteLogo);
  const { userInfo } = useSelector((state) => state.user);
  const [userinfostatus, setUserInfoStatus] = useState(false);
  const [userdetails, setUserDetails] = useState([]);
  const [popoversitem, setPopoverItem] = useState([]);
  const [getofferinglist, setOfferingList] = useState([]);
  const [pagetype, setPageType] = useState(props.type);
  //permission
  const [adminpanelpermission, setAdminPanelPermission] = useState(0);

  const dispatch = useDispatch();
  var path = window.location.pathname;
  const userLogout = () => {
    dispatch(signOut())
      .unwrap()
      .then(() => {
        localStorage.removeItem("_token");
        localStorage.removeItem("CognitoIdentityServiceProvider.auth");
        window.location.href = "/signin";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (
      getofferinglist.indexOf("chatbot") !== -1 &&
      getofferinglist.indexOf(props.type) !== -1
    ) {
      dispatch(setChatbotStatus(true));
    } else {
      dispatch(setChatbotStatus(false));
    }
  }, [props.type]);
  useEffect(() => {
    //useinfo
    var token = localStorage.getItem("_token");
    if (token) {
      setUserInfoStatus(true);
    } else {
      setUserInfoStatus(false);
    }
  }, [window.location.pathname]);

  // useEffect(() => {
  //   console.log(props);
  //   console.log(getofferinglist);

  // }, [getofferinglist]);

  useEffect(() => {
    // permission
    const pagepermission = localStorage.getItem(
      "CognitoIdentityServiceProvider.role"
    )
      ? JSON.parse(localStorage.getItem("CognitoIdentityServiceProvider.role"))
      : "";
    if (pagepermission) {
      const adminpermission = pagepermission?.filter(
        (data) => data.blocks === "admin_panel"
      );
      if (adminpermission) {
        if (adminpermission.length > 0) {
          setAdminPanelPermission(adminpermission[0].access);
        }
      }
    }

    const records = {
      user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
      _token: localStorage.getItem("_token"),
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        // setUserLog(data.);
        const offeringlist = JSON.parse(data.getPlatformUsers.Offerings);
        setOfferingList(offeringlist);
        // console.log(pagetype);
        setUserDetails(data.getPlatformUsers);
        const offering_type = localStorage.getItem(
          "CognitoIdentityServiceProvider.offering"
        );
        var item = [
          {
            key: "1",
            label: "CostDeMaze",
            link: "/costdemaze/quick_glance",
            value: "costdemaze",
          },
          {
            key: "3",
            label: "SLM",
            link: "/slm/dashboard",
            value: "slm",
          },
          {
            key: "4",
            label: "Smart Alert",
            link: "/cams",
            value: "smart_alert",
          },
          {
            key: "6",
            label: "Event Express",
            link: "/peems",
            value: "event_express",
          },
          {
            key: "7",
            label: "Devops",
            link: "/devops-requestor",
            value: "devops",
          },
          {
            key: "5",
            label: "Billing",
            link: "/billing",
            value: "billing",
          },
        ];

        var user_offering = [];
        const companyName = localStorage.getItem(
          "CognitoIdentityServiceProvider.company"
        );
        if (companyName === "Internal1CH") {
          user_offering = [
            "costdemaze",
            "slm",
            "event_express",
            "smart_alert",
            "devops",
            "billing",
          ];
        } else if (
          localStorage.getItem("CognitoIdentityServiceProvider.offering") ===
          "master"
        ) {
          user_offering = [
            "costdemaze",
            "slm",
            "event_express",
            "smart_alert",
            "devops",
            "billing",
          ];
        } else if (
          localStorage.getItem("CognitoIdentityServiceProvider.offering") ===
          "1ch_admin"
        ) {
          user_offering = [
            "costdemaze",
            "slm",
            "event_express",
            "smart_alert",
            "devops",
            "billing",
          ];
        } else {
          const offering_list = JSON.parse(data.getPlatformUsers.Offerings);
          offering_list.push("billing");
          if (offering_list && offering_list.length > 0) {
            user_offering = offering_list;
          }
        }
        var listarray = [];
        item.forEach((itemdata) => {
          if (user_offering.includes(itemdata.value)) {
            listarray.push({
              key: itemdata.key,
              label: itemdata.label,
              onClick: () => navigate(itemdata.link),
            });
            listarray.push({
              type: "divider",
            });
          }
        });

        setPopoverItem(listarray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.pathname]);
  const path_array = path.split("/");
  const customer_image =
    "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img(31).webp";
  return (
    <div className="navheader-position-sticky">
      <Navbar expand="lg" className={props.classHeader} sticky="top">
        <Container fluid className="plat-container">
          {path_array[1] !== "role" && (
            <div>
              <Dropdown
                className=""
                overlayClassName={props.classList}
                placement="bottomRight"
                overlay={<Menu items={popoversitem} />}
              >
                <TbGridDots
                  className="costimize-global-menu-icon"
                  style={{ cursor: "pointer" }}
                />
              </Dropdown>
            </div>
          )}
          <Navbar.Brand
            onClick={() => navigate("/")}
            className="plat-navbar-brand costimize-header-logo"
          >
            {props.type === "costdemaze" ? (
              <>
                <img
                  height={36}
                  width={100}
                  src={costdemaze_white_logo}
                  alt="Capture"
                />
              </>
            ) : (
              <>
                <img height={20} src={header_logo} alt="Capture" /> &nbsp;
                <span className="header-logo-name">
                  CloudShifu &nbsp;{props.headerText}
                </span>
              </>
            )}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="plat-navbar-collapse">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <Form className="d-flex">
              {userinfostatus ? (
                <>
                  {/* <NavDropdown
                    title={<IoIosSearch className="costimize-icon-with-bg" />}
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu costimize-navbar-top-menu"
                  ></NavDropdown> */}
                  {adminpanelpermission &&
                  window.location.pathname !== "/role" ? (
                    <Button
                      variant="outline-success costimize-admin-panel costimize-navbar-top-menu"
                      onClick={() => navigate("/admin-actions")}
                    >
                      Admin Panel
                    </Button>
                  ) : (
                    ""
                  )}
                  <NavDropdown
                    title={
                      <Avatar
                        style={{
                          backgroundColor: "#fff",
                          color: "#433F3F",
                          marginTop: "-1px",
                        }}
                      >
                        {userdetails.first_name && (
                          <>{Array.from(userdetails.first_name)[0]}</>
                        )}
                      </Avatar>
                    }
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu costimize-navbar-top-menu"
                  >
                    {userdetails.first_name ? (
                      <>
                        <label className="menu-topicon-username">
                          {userdetails.first_name}
                        </label>
                        <NavDropdown.Divider />
                      </>
                    ) : (
                      <></>
                    )}

                    {localStorage
                      .getItem("CognitoIdentityServiceProvider.offering")
                      ?.toLowerCase() === "master" ||
                    localStorage
                      .getItem("CognitoIdentityServiceProvider.offering")
                      ?.toLowerCase() === "1ch_admin" ||
                    localStorage
                      .getItem("CognitoIdentityServiceProvider.offering")
                      ?.toLowerCase() === "admin" ? (
                      <>
                        {localStorage
                          .getItem("CognitoIdentityServiceProvider.offering")
                          ?.toLowerCase() === "master" ||
                        (localStorage
                          .getItem("CognitoIdentityServiceProvider.offering")
                          ?.toLowerCase() === "1ch_admin" &&
                          window.location.pathname !== "/role") ? (
                          <>
                            <NavDropdown.Item
                              className="navbar-top-submenu"
                              onClick={() => navigate("/admin-dashboard")}
                            >
                              Dashboard
                            </NavDropdown.Item>
                          </>
                        ) : (
                          " "
                        )}
                        {adminpanelpermission &&
                        window.location.pathname !== "/role" ? (
                          <NavDropdown.Item
                            className="navbar-top-submenu"
                            onClick={() => navigate("/admin-actions")}
                          >
                            Admin Panel
                          </NavDropdown.Item>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <NavDropdown.Item
                      className="navbar-top-submenu"
                      onClick={() => navigate("/role")}
                    >
                      My Role
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="navbar-top-submenu"
                      onClick={userLogout}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <Button
                    variant="outline-success"
                    color="link"
                    className="active-btn ms-2"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </Button>
                  {/* <Button
                    variant="outline-success"
                    color="link"
                    className="active-btn ms-2"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </Button> */}
                </>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavHeader;
