import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import "./devops.css";
import RequestorMain from "./Requestor/RequestorMain";
import ApproverMain from "./Approver/ApproverMain";
import { useDispatch } from "react-redux";
import { setUserRole } from "../slice/devopsSlice";

function DevOpsMain() {
  const [items, setItems] = useState([
    {
      key: "1",
      label: `My Request`,
      children: <RequestorMain />,
    },

    {
      key: "2",
      label: `My Approvals`,
      children: <ApproverMain />,
    },
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    const userRole = localStorage.getItem(
      "CognitoIdentityServiceProvider.offering"
    );

    if (userRole === "master") {
      setItems([
        {
          key: "1",
          label: `My Request`,
          children: <RequestorMain />,
        },
        {
          key: "2",
          label: `My Approvals`,
          children: <ApproverMain />,
        },
      ]);
    } else if (userRole == "1ch_admin") {
      setItems([
        {
          key: "1",
          label: `My Request`,
          children: <RequestorMain />,
        },
      ]);
    } else if (userRole == "1ch_admin") {
    }
    dispatch(setUserRole(userRole));
  }, []);

  return (
    <div>
      <div className="devops-background">
        <Row>
          <Col>
            <div className="header-title-devops">Dashboard</div>
          </Col>
        </Row>

        <Tabs className="cams-tabs" defaultActiveKey="1" items={items} />
      </div>
    </div>
  );
}

export default DevOpsMain;
