import React, { useEffect, useState } from "react";
import { Space, Table, Tag, message } from "antd";
import ReactApexChart from "react-apexcharts";

function MicroChart({ data }) {
  const firstMonthSpend = parseFloat(data.first_month_spend) || 0;
  const secondMonthSpend = parseFloat(data.second_month_spend) || 0;
  const thirdMonthSpend = parseFloat(data.third_month_spend) || 0;

  const chartOptions = {
    chart: {
      type: "line",

      height: 10,
      sparkline: {
        enabled: true,
      },
    },
    series: [
      {
        name: "Spend",
        data: [firstMonthSpend, secondMonthSpend, thirdMonthSpend],
      },
    ],
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
      x: {
        show: false,
      },
    },
    stroke: {
      width: 1,
    },
    markers: {
      size: 3,
      colors: ["#6f42c1"],
    },

    dataLabels: {
      enabled: false,
      style: {
        colors: ["#dc3545"],
      },
    },
  };
  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        height="10"
        width="100"
      />
    </div>
  );
}
function ResourceList(resourceNames) {
  console.log(resourceNames, "resourceName");

  let dataArray = Array.isArray(resourceNames[0])
    ? resourceNames
    : [resourceNames];
  console.log(dataArray, "data");
  // const record=dataArray[0].resourceNames
  const searchValue = dataArray[0].searchValue;
  const record = dataArray[0].resourceNames.map((datas) => {
    console.log(datas);
    return {
      spend: datas.spend,
      environment: datas.environment,
      account_name: datas.account_name,
      cloud: datas.cloud,
      application: datas.application,
      instancetype: datas.instancetype,
      ram: datas.ram,
      searchValue: searchValue,
      core: datas.core,
      storage: datas.storage,
      savings_opportunity: datas.savings_opportunity,
      second_month_spend: datas.second_month_spend,
      third_month_spend: datas.third_month_spend,
      first_month_spend: datas.first_month_spend,
    };
  });
  console.log(record);
  // console.log(searchValue)

  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      align: "center",
    },

    {
      title: "Cloud",
      dataIndex: "cloud",
      key: "cloud",
      align: "center",
    },
    {
      title: "Application Name",
      dataIndex: "application",
      key: "application",
      align: "center",
    },
    {
      title: "Resource Name",
      dataIndex: "searchValue",
      key: "searchValue",
      align: "center",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      align: "center",
      render: (text, record) => {
        if (record.spend && record.spend !== "0") {
          return <span>${Math.round(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    
   
    
    {
      title: "Instance Type",
      dataIndex: "instancetype",
      key: "instancetype",
      render: (text, record, index) => {
        if (record.instancetype && record.instancetype !== "0") {
          return <span>{text}</span>;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Ram",
      dataIndex: "ram",
      key: "ram",
      render: (text, record, index) => {
        if (record.ram && record.ram !== "0") {
          return <span>{text}</span>;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Core",
      dataIndex: "core",
      key: "core",
      render: (text, record, index) => {
        if (record.core && record.core !== "0") {
          return <span>{Math.round(text)}</span>;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      render: (text, record, index) => {
        if (record.storage && record.storage !== "0") {
          return <span>{text}</span>;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Savings  Opportunity",
      dataIndex: "savings_opportunity",
      key: "savings_opportunity",
      render: (text, record, index) => {
        if (record.savings_opportunity && record.savings_opportunity !== "0") {
          return <span>{text}</span>;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Spend Trend",
      dataIndex: "spend",
      key: "spend",
      render: (_, record) => <MicroChart data={record} />,
    },
  ];
  return <Table className="slm" columns={columns} dataSource={record} />;
}
export default ResourceList;
