import { memo, useEffect, useState } from "react";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Form, Input, Select, Button, Row, Col, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "../../custom_hook/CustomHook";
import {
  insertRecommendation,
  updateRecommendation,
  deleteRecommendation,
} from "../../action/costimizeV2Active";

const UnrealizedForm = (props) => {
  const { environmentList, applicationList, serviceList, accountList } =
    useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [allapplication, setAllapplication] = useState([]);
  const [allenvironment, setAllenvironment] = useState([]);
  const [allservice, setAllService] = useState([]);
  const [allaccount, setAllAccount] = useState([]);
  const [buttonloading, setButtonLoading] = useState(false);
  const [deleteid, setDeleteID] = useState("");
  const [form] = Form.useForm();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const changeDrawerStatus = () => {
    props.setDrawerStatus(false);
  };
  useEffect(() => {
    setAllapplication(
      applicationList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllenvironment(
      environmentList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllService(
      serviceList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllAccount(
      accountList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
  }, [applicationList, environmentList, serviceList, accountList]);
  useEffect(() => {
    var records;
    if (props.formType === "add") {
      records = {
        application: "",
        environment: "",
        service: "",
        account: "",
        commitment: "",
        current_configuration: "",
        potential_savings: "",
        recommendation_type: "",
        recommended_configuration: "",
        resource_id: "",
        resource_name: "",
        resource_sub_category: "",
      };
      setDeleteStatus(false);
    } else {
      setDeleteID(props.oldUpdateRecords.resource_id);
      records = {
        application: props.oldUpdateRecords.application,
        environment: props.oldUpdateRecords.environment,
        service: props.oldUpdateRecords.services,
        account: props.oldUpdateRecords.account,
        commitment: props.oldUpdateRecords.commitment,
        current_configuration: props.oldUpdateRecords.current_configuration,
        potential_savings: props.oldUpdateRecords.potential_savings,
        recommendation_type: props.oldUpdateRecords.recommendation_type,
        recommended_configuration:
          props.oldUpdateRecords.recommended_configuration,
        resource_id: props.oldUpdateRecords.resource_id,
        resource_name: props.oldUpdateRecords.resource_name,
        resource_sub_category: props.oldUpdateRecords.resource_sub_category,
        realized_savings: props.oldUpdateRecords.realized_savings,
      };
      console.log(records);
      setDeleteStatus(true);
    }
    form.setFieldsValue(records);
  }, [props]);
  const rowDelete = () => {
    setConfirmLoading(true);
    dispatch(deleteRecommendation({ companyName, deleteid }))
      .unwrap()
      .then(({ data }) => {
        setDrawerDeleteStatus(false);
        setConfirmLoading(false);
        props.setDrawerStatus(false);
        props.setRecordsUpdate(!props.recordsudpate);
        message.success("Deleted");
      })
      .catch((err) => {});
  };
  const handleSubmit = (values) => {
    setButtonLoading(true);
    const inputs = {
      application: values.application,
      environment: values.environment,
      service: values.service,
      account: values.account,
      company_name: companyName,
      commitment: values.commitment,
      current_configuration: values.current_configuration,
      lost_opportunity: 0,
      potential_savings: values.potential_savings,
      realised_savings: 0,
      recommendation_rule: values.recommendation_type,
      recommended_configuration: values.recommended_configuration,
      report_month: `${new Date().getFullYear()}-${new Date().getMonth()}-01`,
      resource_id: values.resource_id,
      resource_name: values.resource_name,
      resource_sub_category: values.resource_sub_category,
    };
    console.log(inputs);
    console.log(props.formType);
    if (props.formType === "add") {
      dispatch(insertRecommendation({ inputs }))
        .unwrap()
        .then((data) => {
          console.log(data);
          message.success("Added Successfully Done");
          props.setDrawerStatus(false);
          setButtonLoading(false);
          props.setRecordsUpdate(!props.recordsudpate);
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err);
          setButtonLoading(false);
        });
    }
    if (props.formType === "update") {
      setButtonLoading(false);
      const inputs = {
        application: values.application,
        environment: values.environment,
        service: values.service,
        account: values.account,
        company_name: companyName,
        commitment: values.commitment,
        current_configuration: values.current_configuration,
        lost_opportunity: 0,
        potential_savings: values.potential_savings,
        realised_savings: values.realized_savings,
        recommendation_rule: values.recommendation_type,
        recommended_configuration: values.recommended_configuration,
        report_month: `${new Date().getFullYear()}-${new Date().getMonth()}-01`,
        resource_id: values.resource_id,
        resource_name: values.resource_name,
        resource_sub_category: values.resource_sub_category,
      };
      dispatch(updateRecommendation({ inputs }))
        .unwrap()
        .then((data) => {
          message.success("Updated Successfully Done");
          props.setDrawerStatus(false);
          setButtonLoading(false);
          props.setRecordsUpdate(!props.recordsudpate);
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err);
          setButtonLoading(false);
        });
    }
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        drawerdeletestatus={drawerdeletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        rowDelete={rowDelete}
        deletestatus={deletestatus}
        title="Savings Model"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="application"
                label="Application"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allapplication}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="environment"
                label="Environment"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allenvironment}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="service"
                label="Service"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allservice}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="account"
                label="Account"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allaccount}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="resource_sub_category"
                label="Resource Sub Category"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Instance",
                      label: "Instance",
                    },
                    {
                      value: "EBS",
                      label: "EBS",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="recommendation_type"
                label="Recommendation Type"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Right Sizing",
                      label: "Right Sizing",
                    },
                    {
                      value: "OS Licensing",
                      label: "OS Licensing",
                    },
                    {
                      value: "DB Licensing",
                      label: "DB Licensing",
                    },
                    {
                      value: "Storage Tiering",
                      label: "Storage Tiering",
                    },
                    {
                      value: "Stop/Start",
                      label: "Stop/Start",
                    },
                    {
                      value: "Savings Plan",
                      label: "Savings Plan",
                    },
                    {
                      value: "Instance Tiering",
                      label: "Instance Tiering",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="resource_id"
                label="Resource ID"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  disabled={deletestatus ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resource_name"
                label="Resource Name"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="current_configuration"
                label="Current Configuration"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="recommended_configuration"
                label="Recommended Configuration"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="commitment"
                label="Commitment"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="potential_savings"
                label="Potential Savings"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  prefix="$"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {deletestatus && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="realized_savings"
                  label="Realized Savings"
                  // rules={[{ required: true, message: '' }]}
                >
                  <Input
                    prefix="$"
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => props.setDrawerStatus(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};

export default memo(UnrealizedForm);
