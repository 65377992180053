import { memo, useEffect, useState } from "react";
import { Modal, Row, Col, message, Spin } from "antd";
import CPUChart from "./CPUChart";
import MemoryChart from "./MemoryChart";
import { Icon } from "@iconify/react";
import { getAssetSpendDetailsView } from "../../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";

const DetailsView = (props) => {
  // console.log(props);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const handleCancel = () => {
    props.setDetailsViewStatus(false);
  };

  const [memorychart, setMemoryChart] = useState({
    memory_max: [],
    memory_min: [],
    memory_avg: [],
  });
  const [cpuchart, setCpuChart] = useState({
    cpu_max: [],
    cpu_min: [],
    cpu_avg: [],
  });
  const [label, setLabel] = useState([]);
  //loader
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setLoader(true);
    // console.log(props.details);
    const resourceid = props.details.resourceid;
    const date = new Date(selectedTopdate);

    // Get the current year and month
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;
    const firstDate = `${year}-${month}-${new Date(
      year,
      month - 1,
      1
    ).getDate()}`;

    dispatch(
      getAssetSpendDetailsView({ resourceid, lastDate, firstDate, companyName })
    )
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery077.length > 0) {
          var memory_max = [];
          var memory_min = [];
          var memory_avg = [];
          var cpu_max = [];
          var cpu_min = [];
          var cpu_avg = [];
          var label = [];
          for (
            let index = 0;
            index < new Date(year, month, 0).getDate();
            index++
          ) {
            const date = +index + +1;
            const records = data.newfunctionquery077?.filter((datas) => {
              if (new Date(datas.date_time).getDate() === date) {
                return datas;
              }
            });
            if (records.length === 0) {
              memory_max.push(0);
              memory_min.push(0);
              memory_avg.push(0);
              cpu_max.push(0);
              cpu_min.push(0);
              cpu_avg.push(0);
            } else {
              memory_max.push(Math.round(Number(records[0].maximum_memory)));
              memory_min.push(Math.round(Number(records[0].minimum_memory)));
              memory_avg.push(Math.round(Number(records[0].average_memory)));
              cpu_max.push(Math.round(Number(records[0].maximum_cpu)));
              cpu_min.push(Math.round(Number(records[0].minimum_cpu)));
              cpu_avg.push(Math.round(Number(records[0].average_cpu)));
            }
            label.push(+index + +1);
          }

          setMemoryChart({
            memory_max: memory_max,
            memory_min: memory_min,
            memory_avg: memory_avg,
          });
          setCpuChart({
            cpu_max: cpu_max,
            cpu_min: cpu_min,
            cpu_avg: cpu_avg,
          });
          setLabel(label);
          setLoader(false);
        } else {
          setLoader(false);
          message.error("Data Not Found");
          props.setDetailsViewStatus(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error("Data Not Found");
        props.setDetailsViewStatus(false);
        console.log(err.message);
      });
  }, [props]);
  return (
    <>
      <Modal
        title={
          props.details.resourcename
            ? props.details.resourcename
            : "Detailed View"
        }
        open={props.detailsviewstatus}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width={900}
      >
        <Spin spinning={loader} wrapperClassName="costimize-loader">
          <Row gutter={[30, 30]}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className="popup-chart-cart">
                <CPUChart label={label} value={cpuchart} />
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className="popup-chart-cart">
                <MemoryChart label={label} value={memorychart} />
              </div>
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              {props.details.recommendation_rule && (
                <>
                  <div className="popup-chart-cart">
                    <h1 className="details-recommendation-title">
                      Recommendations
                    </h1>
                    <table className="recom-table">
                      <tr>
                        <th width="80%"></th>
                        <th width="10%">Yes</th>
                        <th width="10%">No</th>
                      </tr>
                      <tr>
                        {props.details.recommendation_rule && (
                          <>
                            <td
                              width="80%"
                              style={{ textAlign: "left", paddingLeft: "2rem" }}
                            >
                              {props.details.recommendation_rule}
                            </td>
                            <td width="10%">
                              <Icon
                                icon="teenyicons:tick-small-solid"
                                style={{ color: "#1F6D1E", fontSize: "27px" }}
                              />
                            </td>
                            <td width="10%"></td>
                          </>
                        )}
                      </tr>
                      {/* <tr>
                  <td style={{ textAlign: "left", paddingLeft: "2rem" }}>
                    2. Security
                  </td>
                  <td></td>
                  <td></td>
                </tr> */}
                    </table>
                    {/* <div className="details-recommendation-list">
                <div></div>
                <div>
                  <ul>
                    <li>
                      <ul className="details-rec-right">
                        <li>Yes</li>
                        <li>No</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="details-recommendation-list">
                <div>
                  <ul>
                    <li>1. Resize</li>
                    <li>2. Security</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      <ul className="details-rec-right">
                        <li>
                          <Icon
                            icon="teenyicons:tick-small-solid"
                            style={{ color: "#1F6D1E", fontSize: "27px" }}
                          />
                        </li>
                        <li></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div> */}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(DetailsView);
