import React,{useState} from "react";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { Row, Col } from "antd";
import { numberFormat, capitalizeFirst } from "../../../custom_hook/CustomHook";
// import CloudList from "./CloudList";
import AssetCloudDB from "./AssetCloudDB";
import { Icon } from "@iconify/react";

const EnvironmentList = ( props ) => {

    return(
   
  <>
    {props.listenvironmentdetails.map((environmentlist, index) => (
      <>
        <ul className="cd-accordion__sub cd-accordion__sub--l1">
          <li className="cd-accordion__item cd-accordion__item--has-children">
            <input
              className="cd-accordion__input"
              type="checkbox"
              id={`sub-group-db${index}`}
              // defaultChecked
            />
            <label
              className="cd-accordion__label"
              htmlFor={`sub-group-db${index}`}
              // onClick={() => handleOnClick()}
            >
              <span className="accordion-full-width">
                <ToggleButtonV2 status={true} newclass="environment-list-icon">
                  <Row className="plat-table-assets-spend-thead">
                    <Col
                      xxl={4}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className=" asset-left-title environment-list-title asset-spend-title-vs"
                    >
                      {capitalizeFirst(environmentlist[0])}(
                      {environmentlist[1].count})
                    </Col>
                    <Col
                      xxl={4}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="asset-spend-values-vs justify-content-center"
                    >
                      ${numberFormat(environmentlist[1].spend)}
                      <Icon
                        className={`me-1 ${
                          environmentlist[1].spend > 0
                            ? "text-danger-icon-custom"
                            : "text-success-icon-custom"
                        }`}
                        icon={
                          environmentlist[1].spend > 0
                            ? "material-symbols:arrow-drop-up"
                            : "material-symbols:arrow-drop-down"
                        }
                        style={{ fontSize: "20px" }}
                      />
                    </Col>
                    <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                        <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >-</Col>
                  </Row>
                  <div className="asset-spend-border-bottom-vs"></div>
                </ToggleButtonV2>
              </span>
            </label>
       
            <AssetCloudDB cloudList={environmentlist[2][0]} />
          </li>
          <div>
            <Row className="assent-spend-footer">
              <Col span={24}></Col>
            </Row>
          </div>
        </ul>
      </>
    ))}
  </>
    )};

export default EnvironmentList;

