import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { listInvoicedocumet } from "../action/costimizev2DashboardAction";
import { useDispatch } from "react-redux";
import * as inr from "inr-words";
import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

const logo = require("../../assets/images/1CloudHub-Logo-horiz-hires-RGB-copy-1-removebg-preview 1.png");

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  sectionTitle: {
    margin: 10,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  section: {
    margin: 15,
    padding: 10,
    fontSize: 10,
    // lineHeight:2,
  },
  viewer: {
    // width: window.innerWidth, //the pdf viewer will take up all of the width and height
    // height: window.innerHeight,
  },
  tableCell: {
    borderBottomWidth: 1,
    borderTop: 1,
    width: 90,
    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
  },
  tableCell_01: {
    borderBottomWidth: 1,
    width: "15%",
    borderTop: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 122,
  },
  tableCell_02: {
    borderBottomWidth: 1,
    width: "35%",
    borderTop: 1,
    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
    padding: 5,
  },
  tableCell_customer: {
    borderBottomWidth: 1,
    width: "50%",
    borderTop: 1,
    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 122,
    padding: 5,
  },
  tableCell_03: {
    borderBottomWidth: 1,
    width: "25%",
    borderTop: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_03_col: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,
    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_03_col_terms: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_03_col_3: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 60,
  },
  tableCell_04: {
    borderBottomWidth: 1,
    width: "25%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_04_col: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_04_col_4: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 40,
  },
  tableCell_04_subcol_4: {
    // borderBottomWidth: 1,
    width: "100%",
    //borderTop: 1,

    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderBottomColor: 'gray',
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 40,
  },
  tableCell_1: {
    borderBottomWidth: 1,
    width: 30,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_2: {
    borderBottomWidth: 1,
    width: 230,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_2_total: {
    borderBottomWidth: 1,
    width: 230,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "end",
    textAlign: "right",
    height: 24,
    padding: 5,
  },
  tableCell_3: {
    borderBottomWidth: 1,
    width: 60,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_3_total: {
    borderBottomWidth: 1,
    width: 60,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_4: {
    borderBottomWidth: 1,
    width: 30,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_4_total: {
    borderBottomWidth: 1,
    width: 30,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_5: {
    borderBottomWidth: 1,
    width: 80,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCell_last: {
    borderBottomWidth: 1,
    width: 550,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
    textAlign: "Start",
    fontWeight: "bold",
  },
  tableHeader: {
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "black",
    flexDirection: "row",
    alignItems: "center",
    height: 25,
  },

  image: {
    width: "50",
    height: 100,
    marginTop: 500,
    marginLeft: 500,
    // textAlign:'right',
    alignItems: "center",
  },

  text: {
    fontSize: 12,
    fontWeight: "bold", // set fontWeight to 'bold' to make the text bold
  },
  tableCol: {
    borderBottomWidth: 1,

    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
  },
  //  tableCol:{
  //   borderBottomWidth: 1,

  //   borderLeftWidth: 1,
  //   borderRightWidth: 1,
  //   borderBottomColor: 'gray',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   height: 'auto',
  // },
  tableCell_tax_sub: {
    // borderBottomWidth: 1,
    width: 350,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "flex-end",
    // height: 40,
    height: "auto",
    padding: 3,
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCell_tax: {
    borderBottomWidth: 1,
    width: 350,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 40,
    height: "auto",
    padding: 6,
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCell_Int_tax: {
    borderBottomWidth: 1,
    textAlign: "center",
    width: 120,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    // alignItems: 'center',
    // height: '25',
    height: "auto",
    padding: 6,
  },
  tableCell_Int_tax_subCol: {
    borderBottomWidth: 1,
    textAlign: "center",
    width: 60,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    // alignItems: 'center',
    //  height: '40',
    height: "auto",
    padding: 5,
  },
  tableCell_Total_tax: {
    borderBottomWidth: 1,
    width: 90,
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 40,
    height: "auto",
    padding: 5,
  },
  tableCell_Total_tax_sub: {
    // borderBottomWidth: 1,
    width: 90,
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 40,
    height: "auto",
    textAlign: "justify",
    padding: 5,
  },
  tableCol_tax: {
    // borderBottomWidth: 1,

    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    //  height: '40',
    height: "auto",
  },
  tableCell_letter: {
    width: "50%",
    padding: 3,
    height: "auto",
    marginLeft: 10,
  },
  tableCell_letter_left: {
    width: "50%",
    padding: 3,
    height: "auto",
    marginLeft: 10,
  },
  tableCell_letter_bottom: {
    width: "50%",
    height: 60,
    textAlign: "right",
    padding: 3,
    borderLeftWidth: 1,
    borderTop: 1,
  },
  tableCol_bottom: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
  },
});

const SppInvoiceDocument = () => {
  const [month, setMonth] = useState();
  const [clientname, setClientname] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [addresscity, setAddresscity] = useState();
  const [addressstate, setAddressstate] = useState();
  const [addresspincode, setAddresspincode] = useState();
  const [invoiceid, setInvoiceid] = useState();
  const [amount, setAmount] = useState();
  const [usageamount, setUsageamount] = useState();
  const [gstamount, setGstamount] = useState();
  const [totaltax, setTotaltax] = useState();
  const [platform, setPlatform] = useState();
  const [awsdiscount, setAwsdiscount] = useState();
  const [disvalue, setDisvalue] = useState();
  const params = useParams();
  const [totalinwords, setTotalinwords] = useState();
  const [onlyTax, setOnlyTax] = useState();
  const [finaltax, setFinaltax] = useState();
  const [sumFinaltaxwords, setSumFinaltaxwords] = useState();
  const [sumFinalonlytaxwords, setSumFinalonlytaxwords] = useState();
  const [totaltaxinwords, setTotaltaxinwords] = useState();
  const [billing, setBilling] = useState();


  const [pdfrecord, setPdfrecord] = useState([])
  const dispatch = useDispatch();
  useEffect(() => {

    const bill_id = params.id;


    dispatch(listInvoicedocumet({ bill_id }))
      .unwrap()
      .then(({ data }) => {


        const records = data.newfunctionquery31.map((datas) => {
          return {
            billing_currency: datas.spp_billing_currency,
            billing_entity: datas.spp_billing_entity,
            month: datas.spp_month,
            client: datas.spp_client_name,
            resell_partner: datas.spp_resell_partner,
            cloud: datas.spp_cloud,
            cloud_invoice_no: datas.spp_cloud_invoice_no,
            usage: datas.spp_usage,
            discount: datas.spp_discount,
            platform_cost: datas.spp_platform_cost,
            total_invoice_amount: datas.spp_total_invoice_amount,
            total_usage_amount: datas.spp_total_usage_amount,
            och_tax: datas.spp_tax,
            invoice_no: datas.spp_invoice_no,
            status: datas.spp_status,
            bill_id: datas.spp_bill_id,
            spp_address_1: datas.spp_address_1,
            spp_address_2: datas.spp_address_2,
            spp_city: datas.spp_city,
            spp_state: datas.spp_state,
            spp_pincode: datas.spp_pincode,
            action: "",
            type: "data",
          };
        });
        console.log(records);
        setPdfrecord(records)

      })
      .catch((err) => {
        console.log(err.message);

      });

  }, []);
 
  useEffect(() => {
    if (pdfrecord.length > 0) {
      const object = pdfrecord[0];
  
      setBilling(object.billing_entity);
      setMonth(object.month);
      setInvoiceid(object.invoice_no);
      setClientname(object.client);
      setAddress1(object.spp_address_1);
      setAddress2(object.spp_address_2);
      setAddresscity(object.spp_city);
      setAddressstate(object.spp_state);
      setAddresspincode(object.spp_pincode);
      setPlatform(object.platform_cost);
      setAwsdiscount(object.discount);
      setUsageamount(object.usage)

      const discountAmount = object.usage * (1 - object.discount / 100);
   
      const disSubvalue = parseFloat(object.usage) - parseFloat(discountAmount);
 
      const aftdisSub = parseFloat(discountAmount) + parseFloat(object.platform_cost);
      const disAmout = object.usage * object.discount / 100;
      setDisvalue(disAmout);
      const gstPercent = parseInt(object.och_tax.split("-").slice(-1)[0]);
 
      setGstamount(gstPercent);
      const taxSubvalue = aftdisSub * gstPercent / 100;
  
      setOnlyTax(taxSubvalue);
   
      const finaloutput = parseFloat(taxSubvalue) + parseFloat(aftdisSub);
      setFinaltax(finaloutput)
      const sumFinaltax = inr(finaltax);
      const capitalizedWordsA = sumFinaltax.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      setSumFinaltaxwords(capitalizedWordsA);
      const sumFinaltaxonly = inr(taxSubvalue);
      const capitalizedWordsB = sumFinaltaxonly.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      setSumFinalonlytaxwords(capitalizedWordsB);
      const discountValue = discountAmount * (gstPercent / 100);
      setAmount(discountValue.toFixed(2));
   
      const num = discountValue.toFixed(2);

      const sumtaxInWords = inr(discountValue);
 
      const capitalizedWords1 = sumtaxInWords.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

      
      setTotaltaxinwords(capitalizedWords1);
      const totaltaxvalue = parseFloat(object.usage) + parseFloat(discountValue.toFixed(2));
      
      setTotaltax(totaltaxvalue);
      const textedit = parseFloat(totaltaxvalue)
      const sumInWords = inr(textedit);
     
  
      const capitalizedWords = sumInWords.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    
 
      setTotalinwords(capitalizedWords);
     
    }
  }, [pdfrecord]);
 
 
  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "1000px" }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.sectionTitle}>
              <Text>Tax Invoice </Text>
            </View>
            <View style={styles.section}>
              <Text>IRN:</Text>
              <Text>Ack No:</Text>
              <Text>Date:</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_01}>
                  <Image src={logo} style={styles.image} />
                </Text>
                {billing == '1CH' && (
                  <Text style={styles.tableCell_02}>
                    <Text style={{ fontWeight: "bold" }}>
                      1CloudHub Pte Limited{"\n"}
                    </Text>
                    #02-26,{"\n"}
                    OxleyTower,{"\n"}
                    138 Robinson Road, {"\n"}
                    Singapore-068906{"\n"}
                    {"\n"}
                    {"\n"}
                    {"\n"}
                    {"\n"}
                    {"\n"}



                    
                  </Text>
                )}
                {billing == 'OCH' && (
                  <Text style={styles.tableCell_02}>
                    <Text style={{ fontWeight: "bold" }}>
                      OCH Digitech Private Limited
                    </Text>
                    Plot No 12,A (SP)1st Floor,{"\n"}
                    Kamak Tower,{"\n"}
                    Thiruvika Industrial Estate,{"\n"}
                    Gunidy,Chennai {"\n"}
                    {"\n"}
                 
                    {"\n"}
                    {"\n"}
                    {"\n"}
                    {"\n"}
                    
                  </Text>
                )}
                <View
                  style={[styles.tableCell_03, { flexDirection: "column" }]}
                >
                  <View style={styles.tableCell_03_col}>
                    <Text>Invoice No:{invoiceid}
                      
                    </Text>
                  </View>
                  <View style={styles.tableCell_03_col}>
                    <Text>Delivery Note:</Text>
                  </View>
                  <View style={styles.tableCell_03_col}>
                    <Text>Reference No.& Date:</Text>
                  </View>
                  <View style={styles.tableCell_03_col}>
                    <Text>Buyer's Order No.</Text>
                  </View>
                </View>
                <View
                  style={[styles.tableCell_04, { flexDirection: "column" }]}
                >
                  <View style={styles.tableCell_04_col}>
                    <Text>Dated:{month}
                
                    </Text>
                  </View>
                  <View style={styles.tableCell_04_col}>
                    <Text>Mode/Terms of Payment</Text>
                  </View>
                  <View style={styles.tableCell_04_col}>
                    <Text>Other References</Text>
                  </View>
                  <View style={styles.tableCell_04_col}>
                    <Text>Dated:</Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_customer}>
                  Buyer(Bill to){"\n"}
                  {clientname}{"\n"}
                  {address1}{"\n"}
                  {address2}{"\n"}
                  {addresscity}{"\n"}
                  {addressstate}{"\n"}
                  {addresspincode}{"\n"}


                  
                </Text>
                <View
                  style={[styles.tableCell_03, { flexDirection: "column" }]}
                >
                  <View style={styles.tableCell_03_col_3}>
                    <Text>Dispatch Doc No.</Text>
                  </View>
                  <View style={styles.tableCell_03_col_3}>
                    <Text>Dispatch Through</Text>
                  </View>
                  <View style={styles.tableCell_03_col_terms}>
                    <Text>Terms of Delivery</Text>
                  </View>
                </View>
                <View
                  style={[styles.tableCell_04, { flexDirection: "column" }]}
                >
                  <View style={styles.tableCell_04_col_4}>
                    <Text>Delivery Note Date</Text>
                  </View>
                  <View style={styles.tableCell_04_col_4}>
                    <Text>Destination</Text>
                  </View>
                  <View style={styles.tableCell_04_subcol_4}>
                    <Text></Text>
                  </View>
                  <View style={styles.tableCell_04_subcol_4}>
                    <Text></Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableHeader}>
                <Text style={styles.tableCell_1}>SI.No</Text>
                <Text style={styles.tableCell_2}>Particulars</Text>
                <Text style={styles.tableCell_3}>HSN/SAC</Text>
                <Text style={styles.tableCell_3}>Quantity</Text>
                <Text style={styles.tableCell_3}>Rate</Text>
                <Text style={styles.tableCell_4}>per</Text>
                <Text style={styles.tableCell_5}>Amount</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_1}>1</Text>
                <Text style={styles.tableCell_2}>AWS Consumption</Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_4}></Text>
                <Text style={styles.tableCell_5}>
                  {usageamount}
                  
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_1}>2</Text>
                <Text style={styles.tableCell_2}> Tax(AWS Consumption with discount + Platform Cost)</Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}>
                  {gstamount}
                 
                </Text>
                <Text style={styles.tableCell_4}>%</Text>
                <Text style={styles.tableCell_5}>
                
                  {onlyTax}


                </Text>
              </View>
              {Number(platform) !== 0.0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell_1}>3</Text>
                  <Text style={styles.tableCell_2}>Platform cost</Text>
                  <Text style={styles.tableCell_3}></Text>
                  <Text style={styles.tableCell_3}></Text>
                  <Text style={styles.tableCell_3}></Text>
                  <Text style={styles.tableCell_4}></Text>
                  <Text style={styles.tableCell_5}>{platform}</Text>
                </View>
              )}
              {Number(awsdiscount) !== 0.0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell_1}>4</Text>
                  <Text style={styles.tableCell_2}>Discount</Text>
                  <Text style={styles.tableCell_3}></Text>
                  <Text style={styles.tableCell_3}></Text>
                  <Text style={styles.tableCell_3}>{awsdiscount}</Text>
                  <Text style={styles.tableCell_4}>%</Text>
                  <Text style={styles.tableCell_5}>{disvalue}</Text>
                </View>
              )}
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_1}></Text>
                <Text style={styles.tableCell_2_total}>Total</Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_4}></Text>
                <Text style={styles.tableCell_5}>
                  {finaltax}
                  
                </Text>
              </View>
              {Number(platform) == 0.0 && (
                <View style={styles.tableCol}>
                
                  <Text style={styles.tableCell_last}>
                    {totalinwords}
                  </Text>
                </View>
              )}
              {Number(platform) !== 0.0 && (
                <View style={styles.tableCol}>
                 
                  <Text style={styles.tableCell_last}>
                    {sumFinaltaxwords}
                  </Text>
                </View>
              )}
              <View style={styles.tableCol_tax}>
                <Text style={styles.tableCell_tax_sub}>Taxable value</Text>
                <Text style={styles.tableCell_Int_tax}>Integrated Tax</Text>
                <Text style={styles.tableCell_Total_tax_sub}>
                  Total Tax Amonut
                </Text>
              </View>
              <View style={styles.tableCol_tax}>
                <Text style={styles.tableCell_tax}></Text>
                <Text style={styles.tableCell_Int_tax_subCol}>Rate</Text>
                <Text style={styles.tableCell_Int_tax_subCol}> Amount</Text>
                <Text style={styles.tableCell_Total_tax}></Text>
              </View>
              {Number(platform) !== 0.0 && (
                <View style={styles.tableCol_tax}>
                  <Text style={styles.tableCell_tax}>
                    {usageamount}
                  
                  </Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>
                    {gstamount}
            
                  </Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>
                    {" "}
                    {onlyTax}
                   
                  </Text>
                  <Text style={styles.tableCell_Total_tax}>
                    {" "}
                    {onlyTax}
       
                  </Text>
                </View>
              )}
              {Number(platform) !== 0.0 && (
                <View style={styles.tableCol_tax}>
                  <Text style={styles.tableCell_tax}>
                    Total:{usageamount}
                 
                  </Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>{gstamount}</Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>
                    {onlyTax}
           
                  </Text>
                  <Text style={styles.tableCell_Total_tax}>
                    {" "}
                    {onlyTax}
                    
                  </Text>
                </View>
              )}
              {Number(platform) == 0.0 && (

                <View style={styles.tableCol_tax}>
                  <Text style={styles.tableCell_tax}>
                    {usageamount}
              
                  </Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>
                    {gstamount}
              
                  </Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>
                    {" "}
                    {amount}
                 
                  </Text>
                  <Text style={styles.tableCell_Total_tax}>
                    {" "}
                    {amount}
               
                  </Text>
                </View>
              )}
              {Number(platform) == 0.0 && (
                <View style={styles.tableCol_tax}>
                  <Text style={styles.tableCell_tax}>
                    Total:{usageamount}
                    
                  </Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>{gstamount}</Text>
                  <Text style={styles.tableCell_Int_tax_subCol}>
                    {amount}
                 
                  </Text>
                  <Text style={styles.tableCell_Total_tax}>
                    {" "}
                    {amount}
            
                  </Text>
                </View>
              )}
              {Number(platform) !== 0.0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell_last}>{sumFinalonlytaxwords}</Text>
                </View>
              )}
              {Number(platform) == 0.0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell_last}>{totaltaxinwords}</Text>
                </View>
              )}
              <View style={styles.tableCol_bottom}>
                <Text style={styles.tableCell_letter_left}>
                  Company's PAN : AAGCB8921N{" "}
                </Text>
                <Text style={styles.tableCell_letter}>
                  Company's Bank Details {"\n"}
                  Bank Name : HDFC Bank {"\n"}
                  A/c No. : 50200021801161 {"\n"}
                  Branch & IFS Code : Valasaravakkam & HDFC0000024
                </Text>
              </View>

              <View style={styles.tableCol}>
                <Text style={styles.tableCell_letter}>
                  Declaration{"\n"}
                  We declare that this invoice shows the actul price of the
                  goods described and that all particulars are true and {
                    "\n"
                  }{" "}
                  correct.
                </Text>
                <Text style={styles.tableCell_letter_bottom}>
                  for OCH Digitech Private Limited{"\n"}
                  {"\n"}
                  {"\n"}
                  Authorised Signatory
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};


export default SppInvoiceDocument;
