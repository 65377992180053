import React, { useState } from "react";
import { Card } from "antd";
import { Row, Col } from "antd";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import NonProductionLinuxChart from "./NonproductionLinuxChart";
import NonProductionWindowChart from "./NonproductionWindowChart";
import PatchManagementtable from "./PatchManagementtable";
import { Icon } from "@iconify/react";

const NonProductionCard = (props) => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [productionFilter, setProductionFilter] = useState(false);
  const [nonProductionFilter, setNonProductionFilter] = useState(false);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const handleIconClick = () => {
    setDrawerStatus(true);
    setProductionFilter(false);
    setNonProductionFilter(true);
  };
  return (
    <>
      <h6> Non Production </h6>
      <Card>
        <>
          <DrawerPlat
            drawerstatus={drawerStatus}
            changeDrawerStatus={changeDrawerStatus}
            deletestatus={deletestatus}
            title="Patch Management"
          >
            <div>
              {" "}
              <PatchManagementtable
                refreshData={props.refreshData}
                productionFilter={productionFilter}
                nonProductionFilter={nonProductionFilter}
              />{" "}
            </div>
          </DrawerPlat>
          <div id="chart">
            <Icon
              icon="material-symbols:edit-note-sharp"
              onClick={handleIconClick}
              //   style={{ float: "right" }}
            />

            <Row>
              <Col xxl={12} xl={12} lg={12} md={24} xs={24}>
                <NonProductionWindowChart countsos={props.countsos} />
              </Col>
              <Col xxl={12} xl={12} lg={12} md={24} xs={24}>
                <NonProductionLinuxChart countsos={props.countsos} />
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <span className="">
              <span className="compliance-dot"></span>Compliance
            </span>
            &nbsp;
            <span>
              {" "}
              <span className="non-compliance-dot"></span>Non Compliance
            </span>
          </div>
        </>
      </Card>
    </>
  );
};

export default NonProductionCard;
