import React, { useState, useEffect } from "react";
import { Dropdown, Space, Table, Col, Row, Menu, Button, message } from "antd";
import { DatePicker } from "antd";
import Invoiceform from "./invoiceform";
import { useDispatch, useSelector } from "react-redux";
import "./spp.css";
import { Icon } from "@iconify/react";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import { listInvoicetable } from "../action/costimizev2DashboardAction";
import { Page, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { Spin } from "antd";
import InvoicePdfDownload from "./InvoicePdfDownload";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
dayjs.extend(customParseFormat);
const { Column, ColumnGroup } = Table;
const { Search } = Input;
const InvoiceTable = () => {
  const navigate = useNavigate();
  const [updatestatus, setUpdateStatus] = useState(true);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [tablerecordsfilter, setTableRecordsfilter] = useState([]);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [oldrecords, setOldRecords] = useState();
  const [preloader, setPreloader] = useState(true);
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("YYYY-MM")
  );
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const monthFormat = "YYYY-MM";
  const dispatch = useDispatch();
  useEffect(() => {
    const date = `${selectedMonth}-01`;

    dispatch(listInvoicetable({ date }))
      .unwrap()
      .then(({ data }) => {
        setPreloader(true);

        const records = data.newfunctionquery33.map((datas) => {
          return {
            billing_currency: datas.spp_billing_currency,
            billing_entity: datas.spp_billing_entity,
            month: datas.spp_month,
            client: datas.spp_client_name,
            resell_partner: datas.spp_resell_partner,
            cloud: datas.spp_cloud,
            cloud_invoice_no: datas.spp_cloud_invoice_no,
            usage: datas.spp_usage,
            discount: datas.spp_discount,
            platform_cost: datas.spp_platform_cost,
            total_invoice_amount: datas.spp_total_invoice_amount,
            total_usage_amount: datas.spp_total_usage_amount,
            och_tax: datas.spp_tax,
            invoice_no: datas.spp_invoice_no,
            status: datas.spp_status,
            bill_id: datas.spp_bill_id,
            spp_address_1: datas.spp_address_1,
            spp_address_2: datas.spp_address_2,
            spp_city: datas.spp_city,
            spp_state: datas.spp_state,
            spp_pincode: datas.spp_pincode,
            country: datas.spp_country,
            action: "",
            type: "data",
          };
        });

        setTableRecords(records);
        setTableRecordsfilter(records);

        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
    setTableRecords([]);
  }, [updatestatus, companyName, selectedTopdate, selectedMonth]);

  const onChange = (date, dateString) => {
    if (dateString) {
      setSelectedMonth(dateString);
    } else {
      setSelectedMonth(moment(new Date()).format("YYYY-MM"));
    }
    setPreloader(true);
  };
  const renderIcon = (record, index) => {
    const menu = (
      <Menu>
        {index.discount === null ? (
          <Menu.Item key="1" onClick={() => showEditPopup(record, index)}>
            <span>
              <Icon icon="material-symbols:edit-outline" />
              Edit
            </span>
          </Menu.Item>
        ) : (
          <>
            <Menu.Item key="1" onClick={() => showEditPopup(record, index)}>
              <span>
                <Icon icon="material-symbols:edit-outline" />
                Edit
              </span>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => viewPage(index)}>
              <span>
                <Icon icon="ic:outline-remove-red-eye" />
                View
              </span>
            </Menu.Item>

            <Menu.Item key="3">
              <span>
                <Icon icon="ic:baseline-download-for-offline" />
                <PDFDownloadLink
                  document={<InvoicePdfDownload data={index} />}
                  fileName={`Invoice_${Math.random()
                    .toString(16)
                    .slice(2)}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <span style={{ color: "#fff" }}>Loading...</span>
                    ) : (
                      <span style={{ color: "#fff" }}>Download</span>
                    )
                  }
                </PDFDownloadLink>
              </span>
            </Menu.Item>
          </>
        )}
      </Menu>
    );
    return (
      <Dropdown
        className="tab-btn"
        overlay={menu}
        placement="bottomRight"
        overlayClassName="drop-down-list"
      >
        <a onClick={(e) => e.preventDefault()}>
          <Icon icon="mi:options-horizontal" />
        </a>
      </Dropdown>
    );
  };

  const viewPage = (data) => {
    const id = data.bill_id;
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const url = baseUrl + "/invoice/" + id;
    window.open(url, "_blank");
  };
  const searchMenuList = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText) {
      const applist = tablerecordsfilter.filter((record) =>
        record.client.toLowerCase().includes(searchText)
      );

      if (applist.length > 0) {
        setTableRecords(applist);
      } else {
        setTableRecords([]);
      }
    } else {
      setTableRecords(tablerecordsfilter);
    }
  };
  const handleAddClick = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
  };
  const showEditPopup = (records, data) => {
    setDrawerStatus(true);
    setOldRecords(data);
    setDeleteStatus(true);
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  return (
    <>
      <Invoiceform
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        setUpdateStatus={setUpdateStatus}
        updatestatus={updatestatus}
        setDrawerStatus={setDrawerStatus}
        dataSource={tablerecords}
        oldrecords={oldrecords}
        deletestatus={deletestatus}
      />
      <Row>
        <Col>
          <div className="header-title-spp">Billing Service</div>
        </Col>
      </Row>
      <Row>
        <Col span={18} offset={6} className="date-picker-container ">
          <Input
            placeholder="input search text"
            className="search-text"
            type="text"
            onKeyUp={(e) => {
              searchMenuList(e);
            }}
          />
          <DatePicker
            defaultValue={dayjs(selectedMonth, monthFormat)}
            format={monthFormat}
            onChange={onChange}
            className="date-picker"
            picker="month"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div onClick={() => handleAddClick()}>
            <button className="table-add-button float-right">
              <Icon icon="material-symbols:add-circle-outline" />
              Add
            </button>
          </div>
        </Col>
      </Row>
      <Row className="spp">
        <Spin spinning={preloader} wrapperClassName="spp-loader">
          <Table className="spp_Table" dataSource={tablerecords}>
            <Column
              title="Invoice Date"
              dataIndex="month"
              key="month"
              fixed="left"
              render={(month) => {
                const formattedDate = new Date(month).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                  }
                );
                return <span>{formattedDate}</span>;
              }}
            />

            <Column
              title="Billing Entity"
              dataIndex="billing_entity"
              key="billing_entity"
            />
            <Column
              title="Billing Currency"
              dataIndex="billing_currency"
              key="billing_currency"
            />
            <Column title="Client Name" dataIndex="client" key="client" />
            <Column
              title="Resell Partner"
              dataIndex="resell_partner"
              key="resell_partner"
              render={(text) => (
                <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
              )}
            />
            <Column
              title="Cloud"
              dataIndex="cloud"
              key="cloud"
              render={(text) => <span>{text.toUpperCase()}</span>}
            />
            <Column
              title="Cloud Invoice No."
              dataIndex="cloud_invoice_no"
              key="cloud_invoice_no"
              render={(text) => (
                <div style={{ textAlign: "right" }}>{text}</div>
              )}
            />
            <Column
              title="Usage"
              dataIndex="usage"
              key="usage"
              render={(text) => (
                <div style={{ textAlign: "right" }}>{text}</div>
              )}
            />
            <Column
              title="Discount"
              dataIndex="discount"
              key="discount"
              render={(text) => (
                <div style={{ textAlign: "right" }}>{text}</div>
              )}
            />
            <Column
              title="Platform Cost"
              dataIndex="platform_cost"
              key="platform_cost"
              render={(text) => (
                <div style={{ textAlign: "right" }}>{text}</div>
              )}
            />
            <Column
              title="Total Usage Amount"
              dataIndex="total_usage_amount"
              key="total_usage_amount"
              render={(text, record) => {
                const currencySymbol =
                  record.billing_entity === "1CH" ? "$" : "₹";
                return (
                  <div style={{ textAlign: "right" }}>
                    {currencySymbol} {text}
                  </div>
                );
              }}
            />
            <Column title="Tax" dataIndex="och_tax" key="och_tax" />
            <Column
              title="Total Invoice Amount"
              dataIndex="total_invoice_amount"
              key="total_invoice_amount"
              render={(text, record) => {
                const currencySymbol =
                  record.billing_entity === "1CH" ? "$" : "₹";
                return (
                  <div style={{ textAlign: "right" }}>
                    {currencySymbol} {text}
                  </div>
                );
              }}
            />
            <Column
              title="1CH Invoice No."
              dataIndex="invoice_no"
              key="invoice_no"
              render={(text) => (
                <div style={{ textAlign: "right" }}>{text}</div>
              )}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(text) => (
                <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
              )}
            />
            <Column
              title=" "
              dataIndex="action"
              key="action"
              fixed="right"
              render={(record, index) => {
                return renderIcon(record, index);
              }}
            />
          </Table>
        </Spin>
      </Row>
    </>
  );
};

export default InvoiceTable;
