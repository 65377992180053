import React, { memo, useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import budgeticon from "../../../assets/icons/budget_sidebar_icon.svg";
import budgeticon_white from "../../../assets/icons/budget_sidebar_icon_white.svg";
import { checkRolePermission } from "../../custom_hook/CustomHook";
const CostimizeSidebar = () => {
  const { toggleSidebar } = useProSidebar();
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  console.log(companyName);

  const [permission, setPermission] = useState({
    quickglance: checkRolePermission("quick_glance")
      ? checkRolePermission("quick_glance").access
      : 0,
    assetspend: checkRolePermission("asset_spend")
      ? checkRolePermission("asset_spend").access
      : 0,
    savingsmodel: checkRolePermission("savings_model")
      ? checkRolePermission("savings_model").access
      : 0,
    budgetoverview: checkRolePermission("budget_planning")
      ? checkRolePermission("budget_planning").access
      : 0,
    instance: checkRolePermission("instance_runtime")
      ? checkRolePermission("instance_runtime").access
      : 0,
  });

  useEffect(() => {
    if (window.location.pathname === "/costdemaze/savings_model") {
      setSidebarMenu(3);
    } else if (window.location.pathname === "/costdemaze/assent_spend") {
      setSidebarMenu(2);
    } else if (window.location.pathname === "/costdemaze/instance_overview") {
      setSidebarMenu(5);
    } else if (window.location.pathname === "/costdemaze/budget_planning") {
      setSidebarMenu(4);
    } else {
      setSidebarMenu(1);
    }
  }, [window.location.pathname]);

  return (
    <>
      {window.innerWidth > 780 ? (
        <>
          <Sidebar
            defaultCollapsed
            collapsedWidth="64px"
            className="costimize-sidebar"
          >
            <Menu>
              {permission.quickglance ? (
                <Tooltip placement="right" title="Quick Glance">
                  <MenuItem
                    className={sidebarmenu === 1 && "active"}
                    component={<Link to="/costdemaze/quick_glance" />}
                  >
                    <Icon icon="carbon:summary-kpi" />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {permission.assetspend ? (
                <Tooltip placement="right" title="Asset/Spend">
                  <MenuItem
                    className={sidebarmenu === 2 && "active"}
                    component={<Link to="/costdemaze/assent_spend" />}
                  >
                    <Icon icon="fluent-mdl2:fixed-asset-management" />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {permission.savingsmodel ? (
                <Tooltip placement="right" title="Savings Opportunity">
                  <MenuItem
                    className={sidebarmenu === 3 && "active"}
                    component={<Link to="/costdemaze/savings_model" />}
                  >
                    <Icon icon="fluent:savings-24-filled" />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {permission.budgetoverview ? (
                <Tooltip placement="right" title="Budget Planning">
                  <MenuItem
                    className={sidebarmenu === 4 && "active"}
                    component={<Link to="/costdemaze/budget_planning" />}
                  >
                    <img
                      src={sidebarmenu === 4 ? budgeticon : budgeticon_white}
                      className="sidebar-budget-without-white"
                    />
                    {/* <img
                  src={budgeticon_white}
                  className="sidebar-budget-with-white"
                  style={{ display: "none" }}
                /> */}
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {/* {companyName !== "gmmco" ? ( */}
              <>
                {permission.instance ? (
                  <Tooltip placement="right" title="Instance Overview">
                    <MenuItem
                      className={sidebarmenu === 5 && "active"}
                      component={<Link to="/costdemaze/instance_overview" />}
                    >
                      <Icon icon="ic:outline-access-time-filled" />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
              {/* ) : (
                <></>
              )} */}
            </Menu>
          </Sidebar>
        </>
      ) : (
        <>
          <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
            <Sidebar
              breakPoint="always"
              defaultCollapsed
              collapsedWidth="64px"
              className="costimize-sidebar mobile-costimize-sidebar"
            >
              <Menu>
                {permission.quickglance ? (
                  <Tooltip placement="right" title="Quick Glance">
                    <MenuItem
                      className={sidebarmenu === 1 && "active"}
                      component={<Link to="/costdemaze/quick_glance" />}
                    >
                      <Icon icon="carbon:summary-kpi" />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}

                {permission.assetspend ? (
                  <Tooltip placement="right" title="Asset/Spend">
                    <MenuItem
                      className={sidebarmenu === 2 && "active"}
                      component={<Link to="/costdemaze/assent_spend" />}
                    >
                      <Icon icon="fluent-mdl2:fixed-asset-management" />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
                {permission.savingsmodel ? (
                  <Tooltip placement="right" title="Savings Opportunity">
                    <MenuItem
                      className={sidebarmenu === 3 && "active"}
                      component={<Link to="/costdemaze/savings_model" />}
                    >
                      <Icon icon="fluent:savings-24-filled" />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
                {permission.budgetoverview ? (
                  <Tooltip placement="right" title="Budget Planning">
                    <MenuItem
                      className={sidebarmenu === 4 && "active"}
                      component={<Link to="/costdemaze/budget_planning" />}
                    >
                      <img
                        src={sidebarmenu === 4 ? budgeticon : budgeticon_white}
                        className="sidebar-budget-without-white"
                      />
                      {/* <img
                  src={budgeticon_white}
                  className="sidebar-budget-with-white"
                  style={{ display: "none" }}
                /> */}
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
                {companyName !== "gmmco" ? (
                  <>
                    {permission.instance ? (
                      <Tooltip placement="right" title="Instance Overview">
                        <MenuItem
                          className={sidebarmenu === 5 && "active"}
                          component={
                            <Link to="/costdemaze/instance_overview" />
                          }
                        >
                          <Icon icon="ic:outline-access-time-filled" />
                        </MenuItem>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Menu>
            </Sidebar>
            <main className="sidebar-mobile-main">
              <div>
                <Icon
                  className="sidemenu-toggle"
                  icon="ic:round-menu"
                  onClick={() => toggleSidebar()}
                />
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default memo(CostimizeSidebar);
