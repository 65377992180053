import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";
import { filterassetspendrds } from "../../../action/costimizedashboardAction";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { numberFormat } from "../../../custom_hook/CustomHook";

const AssetResourceDB = (application) => {
  const { applicationclicked, isShowApplication, index } = application;
  const [allresourceslist, setAllResorcesList] = useState([]);

  const {
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
    selectedservices,
  } = useSelector((state) => state.constimize);

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: applicationclicked,
      // application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: "DB",
      report_month: selectedTopdate.toString(),
      db: selecteddb.toString(),
      company: companyName,
    };


    dispatch(filterassetspendrds({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "rdsvalue");
        //new insertion of code
        if (data.assetspend_rds_resource.length > 0) {
          const application = data.assetspend_rds_resource?.map(
            (v) => v.resourcename
          );
          var applicationlist = new Set([...application]);
          applicationlist = [...applicationlist];
          const cloud_all_list = [];
          applicationlist.map((data_map) => {
            const cloud_data_list = data.assetspend_rds_resource?.filter(
              (datas) => {
                return (
                  datas.resourcename.toLowerCase() === data_map.toLowerCase()
                );
              }
            );
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list
            );
            cloud_all_list.push(return_environment);
          });
          setAllResorcesList(cloud_all_list);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [applicationclicked]);

  const getEnvironmentList = (enviroment, data_list) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce((a, v) => (a = +a + +v.count), 0);
    const ram_list = data_list.map((data) => {
      if (data) {
        const ram_value = data.ram?.split("GiB");
        if (ram_value?.length > 0) {
          return Number(ram_value[0].trim());
        } else {
          return 0;
        }
      }
    });

    const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
    const core = data_list.reduce((a, v) => (a = +a + +v.core), 0);

    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: data_list[0].resourcename,
      size: data_list[0].instancetype ? data_list[0].instancetype : "-",
      ram: ram === 0 ? "-" : Math.round(ram),
      core: core === 0 ? "-" : Math.round(core),
      count: data_list.length,
    };
  };

  return (
    <>
      {allresourceslist.length > 0
        ? allresourceslist.map((item, index) => (
            <ul className="cd-accordion__sub cd-accordion__sub--l3">
              <li className="cd-accordion__item cd-accordion__item--has-children">
                <input
                  className="cd-accordion__input"
                  type="checkbox"
                  id={`sub-group-db${index}`}
                  // defaultChecked
                />
                <label
                  className="cd-accordion__label"
                  htmlFor={`sub-group-db${index}`}
                >
                  <span className="accordion-full-width">
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-title-vs "
                      ></Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        ${numberFormat(item.spend)}
                        <Icon
                          className={`me-1 ${
                            item.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom"
                          }`}
                          icon={
                            item.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {item.name}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {item.size}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {item.ram}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {item.core}
                      </Col>
                    </Row>
                  </span>
                </label>
              </li>
              <div className="asset-spend-border-bottom-vs"></div>
            </ul>
          ))
        : ""}
    </>
  );
};

export default AssetResourceDB;
