import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Spin, Table } from "antd";
import paginationFactory from "react-bootstrap-table2-paginator";
const AlertDetailsTable = ({ serverData, alertcountstatus }) => {
  const [tableData, setTableData] = useState([]);
  const [totalAlerts, setTotalAlerts] = useState("0");
  const [totalCams, setTotalCams] = useState("0");
  const [totalRecurring, setTotalRecurring] = useState("0");
  useEffect(() => {
    if (serverData.cams_top_servers) {
      setTableData(serverData.cams_top_servers);
      let totalAlertTemp = 0;
      let totalCamsTemp = 0;
      let totalReccTemp = 0;
      serverData.cams_top_servers.forEach((data) => {
        if (data.total_alerts)
          totalAlertTemp = totalAlertTemp + parseInt(data.total_alerts);
        if (data.cams_processed)
          totalCamsTemp = totalCamsTemp + parseInt(data.cams_processed);
        if (data.recurring_tickets)
          totalReccTemp = totalReccTemp + parseInt(data.recurring_tickets);
      });
      setTotalAlerts(totalAlertTemp);
      setTotalCams(totalCamsTemp);
      setTotalRecurring(totalReccTemp);
    }
  }, [serverData]);

  const columns = [
    {
      title: "Server Name",
      dataIndex: "server_name",
      key: "server_name",
      width: 20,
      align: "left",
    },
    {
      title: "Total Alerts",
      dataIndex: "total_alerts",
      key: "total_alerts",
      width: 20,
      align: "right",
    },
    {
      title: "Recurring Tickets",
      dataIndex: "recurring_tickets",
      key: "recurring_tickets",
      width: 20,
      align: "right",
    },
    {
      title: "Tickets Created",
      dataIndex: "cams_processed",
      key: "cams_processed",
      width: 10,
      align: "right",
    },
  ];

  return (
    <>
      {/* <h6 className="alert-detail-header pt-4">Top N alert by Count</h6> */}
      <div className="alert-detail-tables">
        {/* <Spin spinning={alertcountstatus}> */}
        <Table
          className="slm"
          columns={columns}
          dataSource={tableData}
          pagination={{ pageSize: 5 }}
          // scroll={{ x: 500 }}
          loading={{ indicator: <Spin />, spinning: alertcountstatus }}
        />
        {/* </Spin> */}
      </div>
    </>
  );
};

export default AlertDetailsTable;
