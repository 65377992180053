import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustimizeFooter from '../CustimizeFooter';
import OperationTapmenu from '../OperationTapmenu';
import { Tabs } from 'antd';
import ProductionRtoRpo from './ProductionRtoRpo';
import NonProductionRtoRpo from './NonProductionRtoRpo';
import RtoRpoForm from './RtoRpoForm';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listRtoRpo } from "../../action/costimizev2DashboardAction";

const RtoAndRpo = () => {
    const [drawerstatus, setDrawerStatus] = useState(false);
    const [deletestatus, setDeleteStatus] = useState(false);
    const [updatestatus, setUpdateStatus] = useState(true);
    const [oldrecords, setOldRecords] = useState();
    const [tablerecords, setTableRecords] = useState({
        production: [],
        nonproduction: []
    });
    // const { companyName } = useSelector((state) => state.user);
    const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
    const { selectedTopdate } = useSelector((state) => state.constimize);
    const dispatch = useDispatch();


    useEffect(() => {
        const date = selectedTopdate;
        console.log({companyName, date});
        dispatch(listRtoRpo({companyName, date}))
        .unwrap()
        .then(({ data }) => {
            console.log("rto");
            console.log(data);
            const productionlist = data.newfunctionquery19?.filter(datas => {
                return datas.environment === "Production";
            });
            const nonproductionlist = data.newfunctionquery19?.filter(datas => {
                return datas.environment === "Non-Production";
            });
            setTableRecords({
                production: productionlist,
                nonproduction: nonproductionlist
            })
        })
        .catch(err => {
            console.log(err);
        });
    },[updatestatus]);
    const addRtoRpo = (environmenttype) => {
        setDrawerStatus(true);
        setDeleteStatus(false);
        setOldRecords({
            environment: environmenttype,
            application: '',
            instance_name: '',
            rto: '',
            rpo: '',
            key: '',
            date: ''
        });
    }
    const changeDrawerStatus = () => {
        setDrawerStatus(false);
    }
    const editRtoRpo = (data) => {
        setDrawerStatus(true);
        setDeleteStatus(true);
        setOldRecords({
            environment: data.environment,
            application: data.application_name,
            instance_name: data.instance_name,
            rto: data.rto,
            rpo: data.rpo,
            key: data.key,
            date: data.date
        });
    }
    const itemlist = [{
        label: "Production",
        key: 1,
        children: <ProductionRtoRpo
            addRtoRpo = {addRtoRpo}
            editRtoRpo = {editRtoRpo}
            records = {tablerecords.production}
        />,
    },{
        label: "Non-Production",
        key: 2,
        children: <NonProductionRtoRpo 
            addRtoRpo = {addRtoRpo}
            editRtoRpo = {editRtoRpo}
            records = {tablerecords.nonproduction}
        />,
    }
    ];

    
    return(
        <>
            <RtoRpoForm
                drawerstatus = {drawerstatus}
                changeDrawerStatus = {changeDrawerStatus}
                deletestatus = {deletestatus}
                setUpdateStatus = {setUpdateStatus}
                updatestatus = {updatestatus}
                setDrawerStatus= {setDrawerStatus}
                oldrecords = {oldrecords}
            />
            <div className='plat-operation-body'>
                <div className='plat-dashboard-tabs'>
                    <OperationTapmenu 
                        excalname='RTO and RPO' 
                        type="RTO and RPO"
                        title = "RTO and RPO"
                    />
                    <div className='plat-full-asset-spend-cover'>
                        <Row>
                            <Tabs
                                className='operation_compliance'
                                type="card"
                                items={itemlist}
                            />
                        </Row>
                    </div>
                </div>
            </div>
            <CustimizeFooter />
        </>
    )
}

export default RtoAndRpo;