import { Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import HeaderSection from "./HeaderSection";
import "./cams.css";
import SummaryView from "./SummaryView";
import AlertDetails from "./AlertDetails";
import CamsReport from "./CamsReport";
import { useDispatch } from "react-redux";
import { getCustomersReducer } from "../action/graphql/cams/cams_reducers";

function Index() {
  const [defaultCustomer, setDefaultCustomer] = useState("all");
  const name = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const dispatch = useDispatch();
  const [customerOptions, setCustomerOptions] = useState([
    {
      label: "All",
      value: "all",
    },
    {
      label: "CMACGM",
      value: "cmacgm",
    },
    {
      label: "Fivestar",
      value: "Fivestar",
    },
    {
      label: "TVS",
      value: "TVS",
    },
  ]);
  const getCustomersFn = () => {
    dispatch(getCustomersReducer())
      .unwrap()
      .then(({ data }) => {
        let tempArr = [{ label: "All", value: "all" }];
        data.cams_customer &&
          data.cams_customer.forEach((customer) => {
            let temp = {};
            temp.label = customer.customer;
            temp.value = customer.customer;
            tempArr.push(temp);
          });
        setCustomerOptions(tempArr);
      });
  };
  useEffect(() => {
    getCustomersFn();
  }, []);

  useEffect(() => {}, [defaultCustomer]);

  const handleChange = (e) => {
    setDefaultCustomer(e);
  };
  const onChange = (key) => {
  };
  const items = name === "Internal1CH" ?  [
    {
      key: "1",
      label: `Summary view`,
      children: <SummaryView customer={defaultCustomer} />,
    },
    {
      key: "2",
      label: `Alert Details`,
      children: <AlertDetails customer={defaultCustomer} />,
    },
    {
      key: "3",
      label: `Reports`,
      children: <CamsReport customer={defaultCustomer}/>,
    },
  ] : 
  [
    {
      key: "1",
      label: `Summary view`,
      children: <SummaryView customer={defaultCustomer} />,
    },
    {
      key: "2",
      label: `Alert Details`,
      children: <AlertDetails customer={defaultCustomer} />,
    }
  ]
  ;

  return (
    <div className="cams-content">
      <div className="container-fluid">
        <div className="header-filter">
          <HeaderSection title="Smart Alert(SA)" />
          <div className="customer-filter">
            {name === "Internal1CH" && (
              <Select
                className="customer-select"
                defaultValue={defaultCustomer}
                onChange={(e) => handleChange(e)}
                options={customerOptions}
              />
            )}
          </div>
        </div>
        {/* Tabs */}
        <Tabs
          className="cams-tabs"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default Index;
