import { memo, useState } from "react";
import { Col, Row, Card, Spin } from "antd";
import TopApplicationChart from "./TopApplicationChart";
import SavingsCastChart from "./SavingsCastChart";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listRecommendation } from "../../action/costimizeV2Active";
import { numberFormat } from "../../custom_hook/CustomHook";

const CostSavings = () => {
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  //state
  const [toprecords, setTopRecords] = useState({
    potential_savings: 0,
    realised_savings: 0,
    pending_action: "0/0",
    color_code: "#000",
  });
  const [savingslist, setSavingsList] = useState([]);
  //status
  const [lsavigsstatus, setLSavingsStatus] = useState(true);

  useEffect(() => {
    setLSavingsStatus(true);
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: selectedTopdate,
    };

    dispatch(listRecommendation({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        var potential_list = [];
        var realised_list = [];
        setSavingsList(data.newfunctionquery10);
        data.newfunctionquery10.map((map_data) => {
          if (
            map_data.realised_savings === null ||
            Math.round(map_data.realised_savings) === 0
          ) {
            potential_list.push(map_data);
          } else {
            realised_list.push(map_data);
          }
        });
        const pending_action =
          data.newfunctionquery10.length - realised_list.length;
        var pending_percentage =
          (pending_action * 100) / data.newfunctionquery10.length;
        var color_code = "#000";
        if (pending_percentage >= 50) {
          color_code = "#be1e2d";
        } else if (pending_percentage >= 30) {
          color_code = "#FFA500";
        } else {
          color_code = "#188f00";
        }
        setTopRecords({
          potential_savings: Math.round(
            potential_list?.reduce((a, v) => (a = +a + +v.potential_savings), 0)
          ),
          realised_savings: Math.round(
            realised_list?.reduce((a, v) => (a = +a + +v.realised_savings), 0)
          ),
          pending_action: `${
            data.newfunctionquery10.length - realised_list.length
          }/${data.newfunctionquery10.length}`,
          color_code: color_code,
        });
        setLSavingsStatus(false);
      })
      .catch((err) => {
        setLSavingsStatus(false);
      });
  }, [
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    selectedTopdate,
  ]);
  return (
    <>
      <Row className="mt-3">
        <Col>
          <h1 className="site-color block-title">Annual Cost Savings</h1>
        </Col>
      </Row>
      <Row gutter={[50, 16]} className="mt-1">
        <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
          <Row gutter={[50, 16]}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Card className="plat-card-2-1">
                <Spin
                  spinning={lsavigsstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-2-title">Potential Annual Savings</h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-2-value">
                      <Icon
                        icon="guidance:bank"
                        className="quick-glance-icon"
                      />
                      {toprecords.potential_savings === 0
                        ? `$0`
                        : `$${numberFormat(toprecords.potential_savings)}`}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Card className="plat-card-2-1">
                <Spin
                  spinning={lsavigsstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-2-title">Realised Annual Savings</h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-2-value">
                      <Icon
                        icon="fluent:wallet-credit-card-32-filled"
                        className="quick-glance-icon"
                      />
                      {toprecords.realised_savings === 0
                        ? `$0`
                        : `$${numberFormat(toprecords.realised_savings)}`}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="plat-card-2-1">
                <Spin
                  spinning={lsavigsstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div className="d-flex justify-content-center">
                    <Icon
                      icon="ic:baseline-pending-actions"
                      className="pa-quick-glance-icon"
                    />
                    <div>
                      <div>
                        <h1 className="card-2-title">Pending Action</h1>
                      </div>
                      <div className="mt-3">
                        <h1 className="card-2-value">
                          <span style={{ color: toprecords.color_code }}>
                            {toprecords.pending_action}
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
          <Card className="plat-card-2 chart-space">
            <TopApplicationChart />
          </Card>
        </Col> */}
        <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
          <Card className="plat-card-2">
            <SavingsCastChart
              savingslist={savingslist}
              lsavigsstatus={lsavigsstatus}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default memo(CostSavings);
