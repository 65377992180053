import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useSearchParams,
  useQueryParams,
  useNavigate,
} from "react-router-dom";
import {
  listUsersMethod,
  onboardActionMethod,
  profileInfoMethod,
  updateUsersMethod,
  userDetailsUpdateStatus,
} from "./slice/userSlice";
import {
  deleteUser,
  userStatusUpdate,
  usermanagerUserList,
  changeUserPassword,
  editUser,
  loginUser,
  getAccountdetailsNew,
} from "./action/userAction";

import { Icon } from "@iconify/react";
import { capitalizeFirst } from "./custom_hook/CustomHook";
import {
  Row,
  Col,
  Dropdown,
  Button,
  Table,
  Modal,
  Popconfirm,
  message,
  Spin,
} from "antd";
import { Avatar, Menu, Switch } from "antd";
import { checkRolePermission } from "./custom_hook/CustomHook";
import { roleGetByID } from "./action/accountAction";
import UserCreate from "./admin/usermanagment/UserCreate";

function UserManagement() {
  const [permission, setPermission] = useState({
    userwrite: checkRolePermission("user_management")
      ? checkRolePermission("user_management").write
      : 0,
  });
  const user = localStorage.getItem("CognitoIdentityServiceProvider.offering");
  const usercompany =
    localStorage
      .getItem("CognitoIdentityServiceProvider.company")
      .toLowerCase() === "internal1ch"
      ? "1cloudhub"
      : localStorage.getItem("CognitoIdentityServiceProvider.company");
  const customer = user === "master" ? "all" : usercompany;
  const [modelopen, setModelOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company, setCompanyName] = useSearchParams();

  const { userType, listUsers, userdetailsupdate } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [getCompany, setCompany] = useState("");
  const [confirmdialogstatus, setConfirmDialogStatus] = useState(false);
  const [detSelecetedUser, setDetSelecetedUser] = useState(false);
  const [close, setCloseAsset] = useState(false);
  const [userstatus, setUserStatus] = useState(false);
  const [roleid, setroleid] = useState();
  const [tableuser, settableuser] = useState([]);
  const [tableuserfilter, settableuserfilter] = useState([]);

  const [update, setupdate] = useState(false);

  var listUserConfigsItems;

  useEffect(() => {
    dispatch(usermanagerUserList())
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (data.listPlatformUsers) {
          let listUserConfigs = data.listPlatformUsers.items.map((v) => ({
            ...v,
            checked: false,
          }));

          listUserConfigsItems = data.listPlatformUsers.items;
          if (company.get("company")) {
            listUserConfigsItems = data.listPlatformUsers.items.filter(
              (v) => v.company_name.toLowerCase() === company.get("company")
            );
          }
          setList(listUserConfigs);
          dispatch(listUsersMethod(listUserConfigsItems));
          dispatch(userDetailsUpdateStatus(""));
        }
      })
      .catch((err) => {
        // setError(err);
      });
  }, [update]);
  // const customer ="all";
  const event_type = "getUserDetails";
  // useEffect(()=>{
  //   dispatch(getAccountdetailsNew({customer,event_type}))
  //   .unwrap()
  //   .then(({ data }) => {
  //     const record = JSON.parse(data.getAccountDetails);
  //     console.log(data)
  //     console.log(record)
  //     settableuser(record)
  //   })
  //   .catch((err) => {
  //     console.log(err.message);
  //   });

  // },[])
  useEffect(() => {
    dispatch(getAccountdetailsNew({ customer, event_type }))
      .unwrap()
      .then(({ data }) => {
        const parsedData = JSON.parse(data.getAccountDetails);
        settableuser(parsedData);
        settableuserfilter(parsedData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [update]);

  const onDelete = (val) => {
    const user_id = val.user_id;

    dispatch(deleteUser(user_id))
      .unwrap()
      .then(({ data }) => {
        const newUser = tableuser.filter(
          (list) => list.email_id !== detSelecetedUser.email_id
        );
        dispatch(listUsersMethod(newUser));
        setConfirmDialogStatus(false);
        setCloseAsset(true);
        setupdate(!update);
        message.success("User Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const groupCompnay = () => {
    const reducerObject = tableuser.reduce((acc, curr) => {
      console.log(curr?.["company_name"]?.toLowerCase());
      const company_name = curr?.["company_name"]?.toLowerCase();
      if (acc[company_name]) {
        acc[company_name].count += 1;
      } else {
        acc[company_name] = {
          ...curr,
          count: 1,
        };
      }
      return acc;
    }, {});
    const keys = Object.keys(reducerObject);
    const groupedListArray = keys.map((v) => reducerObject[v]);
    return groupedListArray;
  };
  // const filterCompnay = (name) => {
  //   setGetCompany(name);
  //   const filteredList = tableuser.filter(
  //     (v) => v.company_name.toLowerCase() === name.toLowerCase()
  //   );
  //   console.log(filteredList);
  //   settableuserfilter(filteredList)
  //   dispatch(listUsersMethod(filteredList));
  // };
  const filterCompnay = (name) => {
    if (name === "All") {
      setGetCompany("All");
      settableuserfilter(tableuser);
    } else {
      const filteredList = tableuser.filter(
        (v) => v.company_name.toLowerCase() === name.toLowerCase()
      );
      setGetCompany(name);
      settableuserfilter(filteredList);
      dispatch(listUsersMethod(filteredList));
    }
  };

  // const filterCompnay = (name) => {
  //   setCompany(name);
  //   const filterList = tableuser.filter(
  //     (v) => v.company_name.toLowerCase() === name
  //   );
  //   console.log(filterList);
  //   dispatch(listUsersMethod(filterList));
  // };
  const updateUser = (val) => {
    dispatch(onboardActionMethod("update"));

    dispatch(editUser(val.user_id))
      .unwrap()
      .then(({ data }) => {
        dispatch(updateUsersMethod(data.getPlatformUsers));
        navigate("/register-new-customer");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateUserActiveStatus = (e, user_id, email, company_name, role) => {
    var status = "inactive";
    if (e) {
      status = "active";
    }
    dispatch(userStatusUpdate({ user_id, status, email, company_name, role }))
      .unwrap()
      .then(({ data }) => {
        setUserStatus(true);
        setupdate(!update);
        message.success("User Status Updated Successfully Done.");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAll = () => {
    setCompany("All");
    setGetCompany("All");
    settableuserfilter(tableuser);
    dispatch(usermanagerUserList())
      .unwrap()
      .then(({ data }) => {
        if (data.listPlatformUsers) {
          let listUserConfigs = data.listPlatformUsers.items.map((v) => ({
            ...v,
            checked: false,
          }));

          listUserConfigsItems = data.listPlatformUsers.items;

          if (company.get("company")) {
            listUserConfigsItems = data.listPlatformUsers.items.filter(
              (v) => v.company_name.toLowerCase() === company.get("company")
            );
          }
          setList(listUserConfigs);
          dispatch(listUsersMethod(listUserConfigsItems));
          dispatch(userDetailsUpdateStatus(""));
        }
      })
      .catch((err) => {});
  };
  const { Column } = Table;

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const [openModalIndexoff, setOpenModalIndexoff] = useState(null);
  const [getCompany1, setGetCompany] = useState(null);

  const handleMenuClick = ({ key }) => {
    if (key === "All") {
      setCompany(null); // Set company to null to show all items
    } else {
      setCompany(key.toLowerCase()); // Set company to the selected value
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {customer === "all" ? (
        <Menu.Item key="All" onClick={() => getAll()}>
          All
        </Menu.Item>
      ) : null}
      {groupCompnay()
        .map((v) => ({
          ...v,
          company_name: v.company_name.toLowerCase(),
        }))
        .map((v) => (
          <Menu.Item
            key={v.company_name}
            onClick={() => filterCompnay(v.company_name)}
          >
            {v.company_name}
          </Menu.Item>
        ))}
    </Menu>
  );
  const [userrolelist, setUserRoleList] = useState([]);
  const [preloader, setPreloader] = useState(true);

  const getRoleListDetails = (list, company_name) => {
    var setrecords = [];
    list.map((role_id, key) => {
      dispatch(roleGetByID({ role_id, company_name }))
        .unwrap()
        .then(({ data }) => {
          setPreloader(true);
          if (data.usermanagementroledetails) {
            const records = JSON.parse(data.usermanagementroledetails);
            setrecords.push(records.data[0]);
            if (list.length === +key + +1) {
              setUserRoleList(setrecords);
            }
          }
          setPreloader(false);
        })

        .catch((err) => {
          console.log(err.message);
          setPreloader(false);
        });
    });
  };

  //   const [userrolelist, setUserRoleList] = useState([]);
  // const [preloader, setPreloader] = useState(false); // Initialize with false

  // const getRoleListDetails = (list, company_name) => {
  //   setPreloader(true); // Set preloader to true before making API calls

  //   Promise.all(
  //     list.map((role_id) =>
  //       dispatch(roleGetByID({ role_id, company_name }))
  //         .unwrap()
  //         .then(({ data }) => {
  //           if (data.usermanagementroledetails) {
  //             const records = JSON.parse(data.usermanagementroledetails);
  //             return records.data[0];
  //           }
  //           return null;
  //         })
  //         .catch((err) => {
  //           console.log(err.message);
  //           return null;
  //         })
  //     )
  //   )
  //     .then((responses) => {
  //       const filteredResponses = responses.filter((record) => record !== null);
  //       setUserRoleList(filteredResponses);
  //     })
  //     .finally(() => {
  //       setPreloader(false); // Set preloader back to false after all API calls complete
  //     });
  // };

  return (
    <>
      {modelopen && (
        <UserCreate modelopen={modelopen} setModelOpen={setModelOpen} />
      )}
      <div className=" text-box  p-1">
        <Row className="ad-header">
          <h4>User Management</h4>
        </Row>
        <Row className="mb-3 d-flex justify-content-between">
          <div>
            <div className="d-flex">
              <div>
                <h6 className="drop-text">Company</h6>
              </div>
              <div>
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                  <Button className="drop-btn">
                    {user === "master"
                      ? getCompany1
                        ? getCompany1
                        : "ALL"
                      : usercompany}
                    <Icon icon="formkit:caretdown" className="drop-button" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* <div>
            <button
              className="table-add-button"
              onClick={() => setModelOpen(true)}
            >
              Create New Account
            </button>
          </div> */}
        </Row>
        <Row className="testing">
          <Table
            className="user-table"
            dataSource={tableuserfilter}
            // pagination={true}
            footer={() => ""}
          >
            <Column
              title="Name"
              dataIndex="first_name"
              key="user"
              render={(text, record) => (
                <div className="d-inline-flex align-items-center">
                  <Avatar
                    style={{
                      width: "45px",
                      height: "45px",
                      backgroundColor: getRandomColor(),
                      color: "#fff",
                      paddingTop: "6%",
                    }}
                    src={record.avatar}
                  >
                    {record.first_name
                      ? record.first_name.substring(0, 2)?.toUpperCase()
                      : "NA"}
                  </Avatar>

                  <div className="ms-3">
                    <p className="fw-bold mb-1">{record.first_name}</p>
                    <p className="text-muted mb-0">{record.email}</p>
                  </div>
                </div>
              )}
            />
            <Column
              title="Company Name "
              dataIndex="company_name"
              key="company"
              render={(text, record) => (
                <p className="fw-normal ms-3">{record.company_name}</p>
              )}
            />

            <Column
              title="Offering"
              dataIndex="offer"
              key="offer"
              render={(text, record, index) => {
                console.log(record);
                const handleOpenModal = () => {
                  setOpenModalIndexoff(index);
                };

                const handleCloseModal = () => {
                  setOpenModalIndexoff(null);
                };
                var offerings = [];
                var showModal = false;
                var offeringsCount = 0;
                if (record.Offerings !== undefined && record.Offerings !== "") {
                  offerings = Array.isArray(record.Offerings)
                    ? record.Offerings
                    : JSON.parse(record.Offerings);

                  showModal = Array.isArray(offerings) && offerings.length > 1;
                  offeringsCount = Array.isArray(offerings)
                    ? offerings.length
                    : 1;
                }

                return (
                  <>
                    <Button
                      className={
                        Array.isArray(offerings) && offerings.length > 0
                          ? "offering-button"
                          : "roleoff-button"
                      }
                      onClick={showModal ? handleOpenModal : null}
                    >
                      {showModal
                        ? `${offeringsCount} Offering${
                            offeringsCount > 1 ? "s" : ""
                          } Chosen`
                        : (Array.isArray(offerings) && offerings[0]) || ""}
                    </Button>
                    {showModal && (
                      <Modal
                        title={`${offeringsCount} Offering${
                          offeringsCount > 1 ? "s" : ""
                        } Chosen`}
                        open={openModalIndexoff === index}
                        onCancel={handleCloseModal}
                        className="role-table"
                        footer={null}
                      >
                        {Array.isArray(offerings) ? (
                          offerings.map((offering, i) => (
                            <Button key={i} className="role-button">
                              {capitalizeFirst(offering)}
                            </Button>
                          ))
                        ) : (
                          <Button className="role-button">
                            {capitalizeFirst(offerings)}
                          </Button>
                        )}
                      </Modal>
                    )}
                  </>
                );
              }}
            />

            <Column
              title="Role"
              dataIndex="role"
              key="role"
              render={(text, record, index) => {
                const handleOpenModal = () => {
                  setPreloader(true);
                  setRoleAndCompanyAndGetRoleListDetails(
                    record.role,
                    record.company_name
                  );
                  setOpenModalIndex(index);
                };

                const handleCloseModal = () => {
                  setOpenModalIndex(null);
                };

                const setRoleAndCompanyAndGetRoleListDetails = (
                  role,
                  companyName
                ) => {
                  const role_list = JSON.parse(role);
                  getRoleListDetails(role_list, companyName);
                };

                // const roleNames = JSON.parse(record.role).map((role) => role.role_name);
                // console.log(roleNames.length)
                // console.log(userrolelist)

                const roleName = userrolelist
                  .filter((role) => role !== undefined)
                  .map((role) => role.role_name);

                return (
                  <>
                    <Button className="offer-button" onClick={handleOpenModal}>
                      Roles chosen
                      {/* {`${roleNames.length} Roles Chosen`} */}
                    </Button>

                    {roleName.length > 0 && (
                      <Modal
                        title={`${roleName.length} ${
                          roleName.length > 1 ? "Roles" : "Role"
                        } Chosen`}
                        open={openModalIndex === index}
                        onCancel={handleCloseModal}
                        className="role-table"
                        footer={null}
                      >
                        <Spin
                          spinning={preloader}
                          wrapperClassName="spp-loader"
                        >
                          {roleName.map((roleName) => (
                            <Button className="role-button" key={roleName}>
                              {capitalizeFirst(roleName)}
                            </Button>
                          ))}
                        </Spin>
                      </Modal>
                    )}
                  </>
                );
              }}
            />

            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(text, record) => (
                <>
                  {record.status === "active" ? (
                    <Switch
                      className="status-button"
                      defaultChecked={record.status === "active"}
                      onChange={(e) => {
                        updateUserActiveStatus(
                          e,
                          record.user_id,
                          record.email_id,
                          record.company_name,
                          record.role
                        );
                      }}
                    />
                  ) : (
                    <>
                      <p className="status-disable">test</p>
                      <Switch
                        defaultChecked={false}
                        onChange={(e) => {
                          updateUserActiveStatus(
                            e,
                            record.user_id,
                            record.email_id,
                            record.company_name,
                            record.role
                          );
                        }}
                      />
                    </>
                  )}
                </>
              )}
            />

            <Column
              title="Actions"
              key="actions"
              fixed="right"
              render={(text, record) => (
                <>
                  {permission.userwrite ? (
                    <>
                      {" "}
                      <span
                        className="cursor-pointer"
                        onClick={() => updateUser(record)}
                      >
                        <Icon
                          icon="material-symbols:edit"
                          className="edit-button"
                        />
                      </span>
                      <span className="cursor-pointer">
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => {
                            onDelete(record);
                          }}
                        >
                          <Icon
                            icon="material-symbols:delete"
                            className="drawer-delete-icon"
                          />
                        </Popconfirm>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            />
          </Table>
        </Row>
      </div>
    </>
  );
}

export default UserManagement;
