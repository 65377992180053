import React from "react";
import { Row, Col, Card, Table, Form } from "antd";
import SecurityChart from "./SecurityChart";
import SecurityTable from "./SecurityTable";

const Security = () => {
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
          <Card>
            <SecurityChart />
          </Card>
        </Col>
        <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
          <SecurityTable />
        </Col>
      </Row>
    </div>
  );
}

export default Security;
