import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Card, Space, Typography, Layout } from "antd";
import { LIST_USER_INFO } from "./Graphql";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  listUsersMethod,
  onboardActionMethod,
  profileInfoMethod,
  getCompanyName,
  updateUsersMethod,
} from "./slice/userSlice";
import { usermanagerUserList, editUser } from "./action/userAction";

import { capitalizeFirst } from "./custom_hook/CustomHook";
import Header from "./header/Header";

function AdminDashboard() {
  const [basicActive, setBasicActive] = useState("1");
  const [accounttype, setAccountType] = useState([
    "register",
    "active",
    "inactive",
  ]);
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const { userType, listUsers } = useSelector((state) => state.user);
  const [activelist, setActiveList] = useState([]);
  const [inactivelist, setInActiveList] = useState([]);
  const [registerlist, setRegisterList] = useState([]);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  // const handleBasicClick = (tab) => {
  //   // Perform actions specific to the clicked tab
  //   console.log(`Tab ${tab} clicked`);

  //   // Update the active tab state
  //   setBasicActive(tab);
  // };

  useEffect(() => {
    // client
    // .query({
    //   query: LIST_USER_INFO,
    // })
    // .then(({ data }) => {
    dispatch(usermanagerUserList())
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (data.listPlatformUsers) {
          // const listUserConfigs = data.listUserConfigs.items.map(v => v.company_name.toLowerCase());
          // const uniqueArr = new Set([...listUserConfigs]);
          // console.log("fetch");
          // console.log(uniqueArr);
          // dispatch(listUsersMethod([...uniqueArr]));
          dispatch(listUsersMethod(data.listPlatformUsers.items));
        }
      })
      .catch((err) => {
        // setError(err);
      });
    // console.log(listUsers);
  }, []);

  useEffect(() => {
    //active list
    // console.log(listUsers);
    const userlist = listUsers?.filter((datas) => {
      return datas.status === "active";
    });
    const listUserConfigs = userlist.map((v) => v.company_name.toLowerCase());
    const uniqueArr = new Set([...listUserConfigs]);
    var company_name = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    const role = localStorage.getItem(
      "CognitoIdentityServiceProvider.offering"
    );
    if (company_name.toLowerCase() === "internal1ch") {
      company_name = "1CloudHub";
    }
    const company_list = [...uniqueArr].sort();
    if (role === "master") {
      setActiveList(company_list);
    } else {
      var activecmplist = company_list;
      const newAccList = activecmplist?.filter((companyname) => {
        return companyname.toLowerCase() === company_name.toLowerCase();
      });
      setActiveList(newAccList);
    }

    //inactivelist
    const userinactivelist = listUsers?.filter((datas) => {
      return datas.status === "inactive" || datas.status === null;
    });
    const inactive_company_list = userinactivelist.sort();
    if (role === "master") {
      setInActiveList(inactive_company_list);
    } else {
      const newinAccList = inactive_company_list?.filter((companyname) => {
        return companyname === company_name;
      });
      setInActiveList(newinAccList);
    }

    //register
    const userregisterlist = listUsers?.filter((datas) => {
      return datas.status === "register";
    });
    const register_company_list = userregisterlist.sort();
    if (role === "master") {
      setRegisterList(register_company_list);
    } else {
      const newregAccList = register_company_list?.filter((companyname) => {
        return companyname === company_name;
      });
      setRegisterList(newregAccList);
    }
  }, [listUsers]);
  // const viewCard = (name) => {
  //   console.log(name);
  // };
  const renderTiles = (name) => {
    return (
      <Card
        className="admin-card"
        onClick={() => {
          // if (name === "demo") {
          //   localStorage.setItem(
          //     "CognitoIdentityServiceProvider.company",
          //     "fivestar"
          //   );
          //   localStorage.setItem(
          //     "CognitoIdentityServiceProvider.demo",
          //     true
          //   );
          // } else if (name === "demo2") {
          //   localStorage.setItem(
          //     "CognitoIdentityServiceProvider.company",
          //     "cmacgm"
          //   );
          //   localStorage.setItem(
          //     "CognitoIdentityServiceProvider.demo",
          //     true
          //   );
          // } else {
          //   localStorage.setItem(
          //     "CognitoIdentityServiceProvider.company",
          //     name
          //   );
          //   localStorage.setItem(
          //     "CognitoIdentityServiceProvider.demo",
          //     false
          //   );
          // }
          localStorage.setItem("CognitoIdentityServiceProvider.company", name);
          localStorage.setItem("CognitoIdentityServiceProvider.demo", false);
          if (name.toLowerCase() === "1cloudhub") {
            localStorage.setItem(
              "CognitoIdentityServiceProvider.company",
              "Internal1CH"
            );
          }
          // localStorage.setItem('CognitoIdentityServiceProvider.company', name);
          // localStorage.setItem('CognitoIdentityServiceProvider.company', 'fivestar');
          // dispatch(getCompanyName('fivestar'));
          navigateFn("/costdemaze/quick_glance");
          // navigateFn("/card");
        }}
      >
        <Typography> {capitalizeFirst(name)} </Typography>
        <div className="action-btn">
          <Icon
            icon="uil:arrow-circle-right"
            style={{ fontSize: "21px", color: "#1c344c" }}
          />
        </div>
      </Card>
    );
  };

  const renderTilesInactive = (value) => {
    return (
      <Card
        style={{ width: "100%" }}
        className="admin-card"
        onClick={() => {
          dispatch(onboardActionMethod("update"));
          dispatch(editUser(value.user_id))
            .unwrap()
            .then(({ data }) => {
              dispatch(updateUsersMethod(data.getPlatformUsers));
              navigateFn("/register-new-customer");
            })
            .catch((err) => {
              console.log(err);
            });

          // dispatch(updateUsersMethod(data));
          // dispatch(onboardActionMethod("update"));
          // navigateFn("/register-new-customer");
        }}
      >
        <Typography>{value.company_name}</Typography>
        <div className="action-btn">
          <Icon
            icon="uil:arrow-circle-right"
            style={{ fontSize: "21px", color: "#1c344c" }}
          />
        </div>
      </Card>
    );
  };

  const items = [
    {
      key: "1",
      label: `Active Accounts`,
      children: (
        <div>
          {basicActive === "1" && (
            <Row>
              {activelist.map((name) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {renderTiles(name)}
                </Col>
              ))}
            </Row>
          )}
        </div>
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick("1"),
      active: basicActive === "1",
    },

    {
      key: "2",
      label: `InActive Accounts`,
      children: (
        <div>
          {basicActive === "2" && (
            <Row>
              {inactivelist.map((data) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {renderTilesInactive(data)}
                </Col>
              ))}
            </Row>
          )}
        </div>
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick("2"),
      active: basicActive === "2",
    },
    {
      key: "3",
      label: `Registered Account`,
      children: (
        <div>
          {basicActive === "3" && (
            <Row>
              {registerlist.map((data) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {renderTilesInactive(data)}
                </Col>
              ))}
            </Row>
          )}
        </div>
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick("3"),
      active: basicActive === "3",
    },
  ];
  return (
    <>
      <div className=" text-box  p-1">
        <Row className="ad-header">
          <h4>Our Customers</h4>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs
              className="cams-tabs"
              items={items}
              onChange={handleBasicClick}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdminDashboard;
