import { memo, useEffect, useState } from "react";
import { Modal } from "antd";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Spin } from "antd";
import { Icon } from "@iconify/react";
import {
  getSpendDetails,
  listAnnualBudget,
} from "../../action/costimizeV2Active";

const SpendVSBudgetPopup = (props) => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const [awsbudgetdetails, setAWSBudgetDetails] = useState({
    overall_budget: 0,
    last_month_budget: 0,
    month_budget: 0,
    status: "=",
    chartvalueaws: [0, 0, 0],
  });
  const [open, setOpen] = useState(false);

  const [budgetdetails, setBudgetDetails] = useState({
    overall_budget: 0,
    last_month_budget: 0,
    month_budget: 0,
    status: "=",
    chartvalue1: [0, 0, 0],
  });
  const [loader, setLoader] = useState(false);
  const handleCancel = () => {
    props.setOpen(false);
    props.setMonthList(0);
  };
  const [azureawsspend, setAzureAwsSpend] = useState(false);
  const [azureawsbudget, setAzureAwsBudget] = useState(false);

  // const [chartdetails, setChartDetails] = useState({
  //   label: [],
  //   monthlist: [],
  // });
  const changeMonth = (month) => {
    // console.log("in");
    setOpen(true);
    props.setMonthList(month);
  };
  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];
  const [chartdetails, setChartDetails] = useState({
    label: [],
    awsspendvalue: [],
    azurespendvalue: [],
    awsbudgetvalue: [0, 0, 0],
    azurebudgetvalue: [0, 0, 0],
    monthlist: [],
  });
  const [spendlist, setSpendList] = useState([]);
  const [awsspendlist, setAWSSpendList] = useState([]);
  const [azurespendlist, setAzurespendList] = useState([]);
  const [budgetlist, setBudgetList] = useState([]);
  const [chart, setChart] = useState({
    chartoption: {},
    chartvalue: [],
  });
  useEffect(() => {
    setLoader(true);
    // setAzureAwsSpend(true);
    if (props.monthlist > 0) {
      let currentDate = new Date(selectedTopdate);
      let lastThreeMonthsDate = new Date(selectedTopdate);
      lastThreeMonthsDate.setMonth(
        currentDate.getMonth() - (props.monthlist - 1)
      );
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month_list = [];
      var datelist = [];
      for (let i = 0; i < props.monthlist; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        month_list.push(
          `${monthNamesShort[date.getMonth()]}-${(
            "" + date.getFullYear()
          ).substr(2)}`
        );
        datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
      }

      const selectedTopdate_new = datelist.toString();
      var apivalue = {
        environment: selectedEnvironment.toString(),
        account: selectedAccount.toString(),
        cloud: selectedCloud.toString(),
        application: selectedApplication.toString(),
        services: selectedservices.toString(),
        os: selectedos.toString(),
        infra: selectedinfra.toString(),
        db: selecteddb.toString(),
      };
      dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
        .unwrap()
        .then(({ data }) => {
          var spend_list = [];
          var chart_new_format = [];
          var spend_value;
          for (let i = 0; i < props.monthlist; i++) {
            let date = new Date(
              lastThreeMonthsDate.getFullYear(),
              lastThreeMonthsDate.getMonth() + i,
              1
            );

            var awsSpend = data.newfunctionquery3.filter((item) => {
              let spenddate = new Date(item.reportmonth);
              if (
                item.cloud === "AWS" &&
                spenddate.getMonth() === date.getMonth()
              ) {
                return item;
              }
            });
            if (
              date.getMonth() + 1 ===
              new Date(selectedTopdate).getMonth() + 1
            ) {
              if (awsSpend.length > 0) {
                spend_value = awsSpend[0].spend;
              }
            }
            if (awsSpend.length > 0) {
              spend_list.push(Math.round(awsSpend[0].spend));
            } else {
              spend_list.push(0);
            }
          }
          setAWSSpendList(spend_list, "spendlistt");

          // filter for azure

          var aws_spend_list = [];
          var chart_new_format = [];
          var awsspend_value;
          for (let i = 0; i < props.monthlist; i++) {
            let date = new Date(
              lastThreeMonthsDate.getFullYear(),
              lastThreeMonthsDate.getMonth() + i,
              1
            );

            var azureSpend = data.newfunctionquery3.filter((item) => {
              let spenddate = new Date(item.reportmonth);
              if (
                item.cloud === "Azure" &&
                spenddate.getMonth() === date.getMonth()
              ) {
                return item;
              }
            });
            if (
              date.getMonth() + 1 ===
              new Date(selectedTopdate).getMonth() + 1
            ) {
              if (azureSpend.length > 0) {
                awsspend_value = azureSpend[0].spend;
              }
            }
            if (azureSpend.length > 0) {
              aws_spend_list.push(Math.round(azureSpend[0].spend));
            } else {
              aws_spend_list.push(0);
            }
          }
          setAzurespendList(aws_spend_list, "aws_spend_list");
          // setSpendList(spend_list);
          setLoader(false);
          setAzureAwsSpend(true);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [props]);

  useEffect(() => {
    //budget
    const cloud = selectedCloud.toString();
    // console.log({ companyName, cloud });
    dispatch(listAnnualBudget({ companyName, cloud }))
      .unwrap()
      .then(({ data }) => {
        var datevalue = [];
        if (data.list_annual_budget.length > 0) {
          data.list_annual_budget.forEach((data) => {
            if (Number(data.budget_year) == new Date().getFullYear()) {
              for (let i = 0; i < props.monthlist; i++) {
                datevalue.push(getBudgetAllocatedAmount(i, data));
              }
            }
          });

          data.list_annual_budget.forEach((item) => {
            if (
              item.cloud === "AWS" &&
              Number(item.budget_year) === new Date().getFullYear()
            ) {
              var allocated_buget = getBudgetAllocatedAmount(
                new Date().getMonth() + 1,
                item
              );
              var last_month_allocated = getBudgetAllocatedAmount(
                new Date().getMonth(),
                item
              );

              var status_value = "";
              var diff_value = "";
              if (Number(allocated_buget) === Number(last_month_allocated)) {
                status_value = "=";
                diff_value = 0;
              } else {
                if (allocated_buget > last_month_allocated) {
                  status_value = "-";
                  diff_value = Math.round(
                    (allocated_buget * 100) / last_month_allocated - 100
                  );
                } else {
                  status_value = "+";
                  diff_value = Math.round(
                    (allocated_buget * 100) / last_month_allocated - 100
                  );
                }
              }

              const datevalue1 = [];
              for (let i = 0; i < props.monthlist; i++) {
                datevalue1.push(getBudgetAllocatedAmount(i, item));
              }
              setAWSBudgetDetails({
                overall_budget: Number(allocated_buget),
                last_month_budget: Number(last_month_allocated),
                month_budget: Math.abs(diff_value),
                status: status_value,
                chartvalueaws: datevalue1,
              });
            }
            if (
              item.cloud === "Azure" &&
              Number(item.budget_year) === new Date().getFullYear()
            ) {
              var allocated_buget = getBudgetAllocatedAmount(
                new Date().getMonth() + 1,
                item
              );
              var last_month_allocated = getBudgetAllocatedAmount(
                new Date().getMonth(),
                item
              );
              var status_value = "";
              var diff_value = "";
              if (Number(allocated_buget) === Number(last_month_allocated)) {
                status_value = "=";
                diff_value = 0;
              } else {
                if (allocated_buget > last_month_allocated) {
                  status_value = "-";
                  diff_value = Math.round(
                    (allocated_buget * 100) / last_month_allocated - 100
                  );
                } else {
                  status_value = "+";
                  diff_value = Math.round(
                    (allocated_buget * 100) / last_month_allocated - 100
                  );
                }
              }
              const datevalue2 = [];
              for (let i = 0; i < props.monthlist; i++) {
                datevalue2.push(getBudgetAllocatedAmount(i, item));
              }
              setBudgetDetails({
                overall_budget: Number(allocated_buget),
                last_month_budget: Number(last_month_allocated),
                month_budget: Math.abs(diff_value),
                status: status_value,
                chartvalue1: datevalue2,
              });
            }
          });
        } else {
          for (let i = 0; i < props.monthlist; i++) {
            datevalue.push(0);
          }
        }
        setBudgetList(datevalue);
        setAzureAwsBudget(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedCloud, props.monthlist]);

  const series = [
    {
      name: "AWS Budget",
      type: "column",
      data: awsbudgetdetails.chartvalueaws,
    },
    {
      name: "Azure Budget",
      type: "column",
      data: budgetdetails.chartvalue1,
    },
    {
      name: "AWS Spend",
      type: "line",
      data: awsspendlist,
    },
    {
      name: "Azure Spend",
      type: "line",
      data: azurespendlist,
    },
  ];

  const currentDate = new Date();
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const labels = [];
  for (let i = 0; i < props.monthlist; i++) {
    const date = new Date();
    date.setMonth(currentDate.getMonth() - i);
    labels.unshift(
      `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
        2
      )}`
    );
  }

  const options = {
    chart: {
      height: 350,
      type: "line",
    },
    events: {
      dataPointSelection: function (event, chartContext, config) {
        console.log(chartdetails.monthlist[config.dataPointIndex]);
        props.setSpendDate(chartdetails.monthlist[config.dataPointIndex]);
        props.setDayChartStatus(true);
      },
    },
    stroke: {
      width: [0],
      curve: "straight",
    },
    tooltip: {
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          if (value >= 1000) {
            return "$" + (value / 1000).toFixed(1) + "k";
          } else {
            return "$" + value;
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: labels,
    // colors: ["#fcbb29", "#82ccdc"],
    // colors: ["#4cc0c0", "#fe1a67"],
    colors: ["#4cc0c0", "#1c344c", "#e37d05", "#b23cfd"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
    },
    // yaxis: [
    //   {
    //     // max:90000,
    //     title: {
    //       text: "Budget",
    //       style: {
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       },
    //     },
    //     labels: {
    //       formatter: (num) => {
    //         if (num === 0) {
    //           return "0";
    //         } else if (num === Infinity) {
    //           return "0";
    //         } else {
    //           return Math.abs(num) > 999
    //             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    //             : Math.sign(num) * Math.abs(num);
    //         }
    //       },
    //     },
    //   },
    //   {
    //     opposite: true,
    //     title: {
    //       text: "Spend",
    //       style: {
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       },
    //     },
    //     labels: {
    //       formatter: (num) => {
    //         if (num === 0) {
    //           return "0";
    //         } else if (num === Infinity) {
    //           return "0";
    //         } else {
    //           return Math.abs(num) > 999
    //             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    //             : Math.sign(num) * Math.abs(num);
    //         }
    //       },
    //     },
    //   },
    // ],
  };

  const getBudgetAllocatedAmount = (current_month, data) => {
    var allocated_buget = 0;
    if (current_month === 1) {
      allocated_buget = data.budget_jan;
    } else if (current_month === 2) {
      allocated_buget = data.budget_feb;
    } else if (current_month === 3) {
      allocated_buget = data.budget_mar;
    } else if (current_month === 4) {
      allocated_buget = data.budget_apr;
    } else if (current_month === 5) {
      allocated_buget = data.budget_may;
    } else if (current_month === 6) {
      allocated_buget = data.budget_jun;
    } else if (current_month === 7) {
      allocated_buget = data.budget_jul;
    } else if (current_month === 8) {
      allocated_buget = data.budget_aug;
    } else if (current_month === 9) {
      allocated_buget = data.budget_sept;
    } else if (current_month === 10) {
      allocated_buget = data.budget_oct;
    } else if (current_month === 11) {
      allocated_buget = data.budget_nov;
    } else {
      allocated_buget = data.budget_dec;
    }
    return allocated_buget;
  };
  return (
    <>
      <Modal
        title="Budget vs Spend"
        open={props.open}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width="auto"
        maxWidth={800}
      >
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <a onClick={(e) => e.preventDefault()} className="chart-filter2">
            <Icon icon="material-symbols:filter-alt-outline-sharp" />
          </a>
        </Dropdown>
        {console.log(azureawsspend, "azureawsspend")}
        {console.log(azureawsbudget, "azureawsbudget")}
        {azureawsspend && azureawsbudget ? (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={300}
          />
        ) : (
          <Spin />
        )}
      </Modal>
    </>
  );
};

export default memo(SpendVSBudgetPopup);
