// import React from "react";
// import SLMSidebar from "./SLMSidebar";
// import MainHead from "../costimize_v2/layout/MainHead";
// import { Card, Col, Collapse, Row } from "antd";
// import { Icon } from "@iconify/react";
// import ReactApexChart from "react-apexcharts";

// function SLM_Quickglance() {
//   const series = [
//     {
//       name: "STOCK ABC",
//       data: [10, 15, 15, 12, 10, 17, 16],
//     },
//   ];
//   const options = {
//     chart: {
//       type: "area",
//       height: 350,
//       zoom: {
//         enabled: false,
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: "straight",
//     },

//     title: {
//       text: "Incident trends",
//       align: "center",
//     },

//     labels: [
//       "Jan 2023",
//       "feb 2023",
//       "Mar 2023",
//       "Apr 2023",
//       "May 2023",
//       "Jun 2023",
//     ],
//     xaxis: {
//       type: "datetime",
//     },
//     yaxis: {
//       opposite: true,
//     },
//     legend: {
//       horizontalAlign: "left",
//     },
//   };

//   const options1 = {
//     chart: {
//       type: "bar",
//       height: 350,
//       stacked: true,
//       stackType: "100%",
//     },
//     title: {
//       text: "Top 5 Application",
//       align: "center",
//     },
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           legend: {
//             position: "bottom",
//             offsetX: -10,
//             offsetY: 0,
//           },
//         },
//       },
//     ],
//     xaxis: {
//       categories: ["Employment", "Nucleus", "Finance_Prod", "Navision", "Java"],
//       labels: {
//         show: true,
//         rotate: -70,
//         trim: true,
//       },
//     },
//     fill: {
//       opacity: 1,
//     },
//     legend: {
//       show: true,
//       position: "bottom",
//       offsetX: 100,
//       offsetY: 0,
//     },

//     colors: ["#5DB9A7", "#FFE03D"],
//   };

//   const series1 = [
//     {
//       name: "Target",
//       data: [84, 55, 41, 57, 12],
//     },
//     {
//       name: "Availability",
//       data: [40, 51, 16, 57, 92],
//     },
//   ];
//   const series2 = [
//     {
//       name: "STOCK ABC",
//       data: [11, 14, 15, 12, 20, 17],
//     },
//   ];
//   const options2 = {
//     chart: {
//       type: "area",
//       height: 350,
//       zoom: {
//         enabled: false,
//       },
//     },
//     title: {
//       text: "Spend Trend",
//       align: "center",
//     },

//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: "straight",
//     },

//     xaxis: {
//       type: "category",
//       categories: ["Jan", "feb", "Mar", "Apr", "May", "Jun"],
//     },
//     yaxis: {
//       opposite: true,
//       labels: {
//         show: true,
//         align: "rights",
//       },
//     },
//     legend: {
//       horizontalAlign: "left",
//     },
//     colors: ["#58BBD1"],
//   };
//   const { Panel } = Collapse;
//   return (
//     <>
//       {" "}
//       <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
//         <SLMSidebar />
//         <MainHead type="slm" title="Quick Glance" />
//         <div className="costimize-body-screen ">
//           <div className="landscape-perfomance">
//             <h4 className="slm-color">Landscape Perfomance</h4>
//             <div className="mt-5">
//               <Row gutter={[16, 16]}>
//                 <Col xxl={12} xl={10} lg={10} md={12} sm={24} xs={24}>
//                   <Row gutter={[16, 16]}>
//                     <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//                       {" "}
//                       <Card className="plat-card-1">
//                         <div className="d-flex justify-content-center">
//                           <h6 className="card-title mt-2 card-header-color">
//                             No. of assets monitored
//                           </h6>
//                         </div>
//                         <div className="mt-2 mb-1">
//                           <h6 className="card-value-1">
//                             <Icon icon={"mdi:monitor-eye"} /> &nbsp; 560
//                           </h6>
//                         </div>
//                       </Card>
//                     </Col>
//                     <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//                       <Card className="plat-card-1">
//                         <div className="d-flex justify-content-center">
//                           <h6 className="card-title mt-2 card-header-color">
//                             No. of incidents created
//                           </h6>
//                         </div>
//                         <div className="mt-2 mb-1">
//                           <h6 className="card-value-1">
//                             <Icon icon={"ri:instance-fill"} />
//                             &nbsp; 560
//                           </h6>
//                         </div>
//                       </Card>
//                     </Col>
//                   </Row>
//                   <br />
//                   <Row gutter={[16, 16]}>
//                     <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//                       {" "}
//                       <Card className="plat-card-1">
//                         <div className="d-flex justify-content-center">
//                           <h6 className="card-title mt-2 card-header-color">
//                             No. of P1’s
//                           </h6>
//                         </div>
//                         <div className="mt-2 mb-1">
//                           <h6 className="card-value-1">
//                             <Icon icon={"iconoir:priority-up"} />
//                             &nbsp; 560
//                           </h6>
//                         </div>
//                       </Card>
//                     </Col>
//                     <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//                       <Card className="plat-card-1">
//                         <div className="d-flex justify-content-center">
//                           <h6 className="card-title mt-2 card-header-color">
//                             SLA Breaches
//                           </h6>
//                         </div>
//                         <div className="mt-2 mb-1">
//                           <h6 className="card-value-1">
//                             <Icon icon={"ri:split-cells-horizontal"} />
//                             &nbsp; 46%
//                           </h6>
//                         </div>
//                       </Card>
//                     </Col>
//                   </Row>
//                 </Col>
//                 <Col xxl={12} xl={14} lg={14} md={12} sm={24} xs={24}>
//                   <Card className="plat-card-1">
//                     <ReactApexChart
//                       options={options}
//                       series={series}
//                       type="area"
//                       height={195}
//                     />
//                   </Card>
//                 </Col>
//               </Row>
//             </div>
//           </div>
//           <div className="availability mt-5">
//             <h4 className="slm-color">Availability</h4>
//             <div className="mt-3">
//               <Row gutter={[16, 16]}>
//                 <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//                   <Card className="plat-card-1">
//                     <ReactApexChart
//                       options={options1}
//                       series={series1}
//                       type="bar"
//                       height={250}
//                     />
//                   </Card>
//                 </Col>
//                 <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//                   <Card className="plat-card-1">
//                     <ReactApexChart
//                       options={options2}
//                       series={series2}
//                       type="area"
//                       height={250}
//                     />
//                   </Card>
//                 </Col>
//               </Row>
//             </div>
//           </div>
//           <div className="compliance mt-5">
//             <h4 className="slm-color">Compliance</h4>
//             <div className="mt-3">
//               <Row>
//                 <Card className="plat-card-1 ">
//                   <div className="d-flex justify-content-center">
//                     <h6 className="card-title mt-3 card-header-color">
//                       Security Compliance
//                     </h6>
//                   </div>
//                   <div className="mt-2 mb-2">
//                     <h6 className="card-value-1">56%</h6>
//                   </div>
//                 </Card>
//                 <Card className="plat-card-1 me-3 ms-3">
//                   <div className="d-flex justify-content-center">
//                     <h6 className="card-title mt-3 card-header-color">
//                       Patch Compliance
//                     </h6>
//                   </div>
//                   <div className="mt-2 mb-2">
//                     <h6 className="card-value-1">70%</h6>
//                   </div>
//                 </Card>
//                 <Card className="plat-card-1">
//                   <div className="d-flex justify-content-center">
//                     <h6 className="card-title mt-3 card-header-color">
//                       Tagging Compliance
//                     </h6>
//                   </div>
//                   <div className="mb-2 mt-2">
//                     <h6 className="card-value-1">46%</h6>
//                   </div>
//                 </Card>
//               </Row>
//             </div>
//           </div>
//           <div className="compliance mt-5">
//             <div className="mt-3">
//               <Card className="plat-card-1 d-flex">
//                 <Row>
//                   <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
//                     <Collapse defaultActiveKey={["1"]} ghost>
//                       <Panel
//                         className="collapse-header"
//                         header="Key Events"
//                         key="1"
//                       >
//                         <ul className="collapse-ui-list">
//                           <li>
//                             Lorem ipsum dolor sit amet &nbsp;
//                             <Icon icon={"ri:external-link-fill"} />
//                           </li>
//                           <li>
//                             Sit deleniti eveniet eum repudiandae blanditiis et
//                             earum omnis qui fuga veritatis in molestias
//                             fuga.&nbsp;
//                             <Icon icon={"ri:external-link-fill"} />
//                           </li>
//                           <li>
//                             Aut aperiam eius et possimus reiciendis et labore
//                             nisi et modi corporis ut inventore rerum.&nbsp;
//                             <Icon icon={"ri:external-link-fill"} />
//                           </li>
//                           <li>
//                             Qui officia tempore est optio iure in consequatur
//                             doloremque quo tempore maiores sed rerum dignissimos
//                             eum labore sunt hic aperiam sunt&nbsp;
//                             <Icon icon={"ri:external-link-fill"} />
//                           </li>
//                         </ul>
//                       </Panel>
//                     </Collapse>
//                   </Col>
//                   <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
//                     <button className="table-add-button float-right">
//                       <Icon icon="material-symbols:add-circle-outline" />
//                       &nbsp; Add
//                     </button>
//                     <button className="table-add-button float-right">
//                       <Icon icon="material-symbols:edit" />
//                       &nbsp; Edit
//                     </button>
//                   </Col>
//                 </Row>
//               </Card>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default SLM_Quickglance;
import React from "react";
import SLMSidebar from "./SLMSidebar";
import MainHead from "../costimize_v2/layout/MainHead";
import { Card, Col, Collapse, Row } from "antd";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import AvailabiltyChart  from "./AvailabilityChart";
import SpendtrendChart from "./SpendtrendChart";

function SLM_Quickglance() {
  const series = [
    {
      name: "STOCK ABC",
      data: [10, 15, 15, 12, 10, 17, 16],
    },
  ];
  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    title: {
      text: "Incident trends",
      align: "center",
    },

    labels: [
      "Jan 2023",
      "feb 2023",
      "Mar 2023",
      "Apr 2023",
      "May 2023",
      "Jun 2023",
    ],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  // const options1 = {
  //   chart: {
  //     type: "bar",
  //     height: 350,
  //     stacked: true,
  //     stackType: "100%",
  //   },
  //   title: {
  //     text: "Top 5 Application",
  //     align: "center",
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         legend: {
  //           position: "bottom",
  //           offsetX: -10,
  //           offsetY: 0,
  //         },
  //       },
  //     },
  //   ],
  //   xaxis: {
  //     categories: ["Employment", "Nucleus", "Finance_Prod", "Navision", "Java"],
  //     labels: {
  //       show: true,
  //       rotate: -70,
  //       trim: true,
  //     },
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   legend: {
  //     show: true,
  //     position: "bottom",
  //     offsetX: 100,
  //     offsetY: 0,
  //   },

  //   colors: ["#5DB9A7", "#FFE03D"],
  // };

  // const series1 = [
  //   {
  //     name: "Target",
  //     data: [84, 55, 41, 57, 12],
  //   },
  //   {
  //     name: "Availability",
  //     data: [40, 51, 16, 57, 92],
  //   },
  // ];
  const series2 = [
    {
      name: "STOCK ABC",
      data: [11, 14, 15, 12, 20, 17],
    },
  ];
  const options2 = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "Spend Trend",
      align: "center",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    xaxis: {
      type: "category",
      categories: ["Jan", "feb", "Mar", "Apr", "May", "Jun"],
    },
    yaxis: {
      opposite: true,
      labels: {
        show: true,
        align: "rights",
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    colors: ["#58BBD1"],
  };
  const { Panel } = Collapse;
  return (
    <>
      {" "}
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <MainHead type="slm" title="Quick Glance" />
        <div className="costimize-body-screen ">
          <div className="landscape-perfomance">
            <h4 className="slm-color">Landscape Perfomance</h4>
            <div className="mt-5">
              <Row gutter={[16, 16]}>
                <Col xxl={12} xl={10} lg={10} md={12} sm={24} xs={24}>
                  <Row gutter={[16, 16]}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      {" "}
                      <Card className="plat-card-1">
                        <div className="d-flex justify-content-center">
                          <h6 className="card-title mt-2 card-header-color">
                            No. of assets monitored
                          </h6>
                        </div>
                        <div className="mt-2 mb-1">
                          <h6 className="card-value-1">
                            <Icon icon={"mdi:monitor-eye"} /> &nbsp; 560
                          </h6>
                        </div>
                      </Card>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Card className="plat-card-1">
                        <div className="d-flex justify-content-center">
                          <h6 className="card-title mt-2 card-header-color">
                            No. of incidents created
                          </h6>
                        </div>
                        <div className="mt-2 mb-1">
                          <h6 className="card-value-1">
                            <Icon icon={"ri:instance-fill"} />
                            &nbsp; 560
                          </h6>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      {" "}
                      <Card className="plat-card-1">
                        <div className="d-flex justify-content-center">
                          <h6 className="card-title mt-2 card-header-color">
                            No. of P1’s
                          </h6>
                        </div>
                        <div className="mt-2 mb-1">
                          <h6 className="card-value-1">
                            <Icon icon={"iconoir:priority-up"} />
                            &nbsp; 560
                          </h6>
                        </div>
                      </Card>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Card className="plat-card-1">
                        <div className="d-flex justify-content-center">
                          <h6 className="card-title mt-2 card-header-color">
                            SLA Breaches
                          </h6>
                        </div>
                        <div className="mt-2 mb-1">
                          <h6 className="card-value-1">
                            <Icon icon={"ri:split-cells-horizontal"} />
                            &nbsp; 46%
                          </h6>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xxl={12} xl={14} lg={14} md={12} sm={24} xs={24}>
                  <Card className="plat-card-1">
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="area"
                      height={195}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className="availability mt-5">
            <h4 className="slm-color">Availability</h4>
            <div className="mt-3">
              <Row gutter={[16, 16]}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Card className="plat-card-1">
                    {/* <ReactApexChart
                      options={options1}
                      series={series1}
                      type="bar"
                      height={250}
                    /> */}
                    <AvailabiltyChart/>
                  </Card>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Card className="plat-card-1">
                    {/* <ReactApexChart
                      options={options2}
                      series={series2}
                      type="area"
                      height={250}
                    /> */}
                    <SpendtrendChart/>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className="compliance mt-5">
            <h4 className="slm-color">Compliance</h4>
            <div className="mt-3">
              <Row>
                <Card className="plat-card-1 ">
                  <div className="d-flex justify-content-center">
                    <h6 className="card-title mt-3 card-header-color">
                      Security Compliance
                    </h6>
                  </div>
                  <div className="mt-2 mb-2">
                    <h6 className="card-value-1">56%</h6>
                  </div>
                </Card>
                <Card className="plat-card-1 me-3 ms-3">
                  <div className="d-flex justify-content-center">
                    <h6 className="card-title mt-3 card-header-color">
                      Patch Compliance
                    </h6>
                  </div>
                  <div className="mt-2 mb-2">
                    <h6 className="card-value-1">70%</h6>
                  </div>
                </Card>
                <Card className="plat-card-1">
                  <div className="d-flex justify-content-center">
                    <h6 className="card-title mt-3 card-header-color">
                      Tagging Compliance
                    </h6>
                  </div>
                  <div className="mb-2 mt-2">
                    <h6 className="card-value-1">46%</h6>
                  </div>
                </Card>
              </Row>
            </div>
          </div>
          <div className="compliance mt-5">
            <div className="mt-3">
              <Card className="plat-card-1 d-flex">
                <Row>
                  <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                    <Collapse defaultActiveKey={["1"]} ghost>
                      <Panel
                        className="collapse-header"
                        header="Key Events"
                        key="1"
                      >
                        <ul className="collapse-ui-list">
                          <li>
                            Lorem ipsum dolor sit amet &nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                          <li>
                            Sit deleniti eveniet eum repudiandae blanditiis et
                            earum omnis qui fuga veritatis in molestias
                            fuga.&nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                          <li>
                            Aut aperiam eius et possimus reiciendis et labore
                            nisi et modi corporis ut inventore rerum.&nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                          <li>
                            Qui officia tempore est optio iure in consequatur
                            doloremque quo tempore maiores sed rerum dignissimos
                            eum labore sunt hic aperiam sunt&nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                        </ul>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
                    <button className="table-add-button float-right">
                      <Icon icon="material-symbols:add-circle-outline" />
                      &nbsp; Add
                    </button>
                    <button className="table-add-button float-right">
                      <Icon icon="material-symbols:edit" />
                      &nbsp; Edit
                    </button>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SLM_Quickglance;
