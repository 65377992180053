import "./newsavingsmodel.css";
import QuickView from "../QuickView";
import Tapmenu from "../Tapmenu";
import AllListSavingsModel from "./AllListSavingsModel";
import { useState, useEffect } from "react";
import AddTableRecords from "./AddTableRecords";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import {
  message,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Switch,
  Card,
} from "antd";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SavingsTracking from "./SavingsTracking";
import CustimizeFooter from "../CustimizeFooter";
import OsDbOptimization from "./OsDbOptimization";
import DbLicenceOptimization from "./DbLicenceOptimization";
import { disabledDate, capitalizeFirst } from "../../custom_hook/CustomHook";
import {
  insertRecommendation,
  listRecommendation,
  updateRecommendation,
  deleteRecommendation,
} from "../../action/costimizev2DashboardAction";
import { MDBIcon } from "mdb-react-ui-kit";
import SavingsModelChart from "./SavingsModelChart";
import RealizedTable from "./RealizedTable";

import { numberFormat } from "../../custom_hook/CustomHook";
import RealizedChart from "./RealizedChart";
import { Icon } from "@iconify/react";

const { TextArea } = Input;

const SavingsModel = () => {
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [savingstrackingstatus, setSavingsTrackingStatus] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const {
    environmentList,
    applicationList,
    serviceList,
    accountList,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const { userInfo } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [deletestatus, setDeleteStatus] = useState(false);
  const [drawertype, setDrawerType] = useState();
  const [formType, setFormType] = useState("");
  const [oldUpdateRecords, setOldUpdateRecords] = useState({});
  const [identifiedSavingsAmount, setIdentifiedSavingsAmount] = useState();
  const [buttonloading, setButtonLoading] = useState(false);
  const [savingstabledataupdate, setsavingstabledataupdate] = useState(true);
  const [tablerecords, setTableRecords] = useState([]);
  const [recordsudpate, setRecordsUpdate] = useState(false);
  const [deleteid, setDeleteID] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [toprecords, setTopRecords] = useState({
    potential_savings: 0,
    realised_savings: 0,
    pending_action: "0/0",
    color_code: "#000",
  });

  const [allapplication, setAllapplication] = useState(
    applicationList.map((list) => {
      return {
        value: list,
        label: capitalizeFirst(list),
      };
    })
  );
  const [allenvironment, setAllenvironment] = useState(
    environmentList.map((list) => {
      return {
        value: list,
        label: capitalizeFirst(list),
      };
    })
  );
  const [allservice, setAllService] = useState(
    serviceList.map((list) => {
      return {
        value: list,
        label: capitalizeFirst(list),
      };
    })
  );
  const [allaccount, setAllAccount] = useState(
    accountList.map((list) => {
      return {
        value: list,
        label: capitalizeFirst(list),
      };
    })
  );

  useEffect(() => {
    setAllapplication(
      applicationList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllService(
      serviceList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllAccount(
      accountList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllenvironment(
      environmentList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
  }, [applicationList, environmentList, serviceList, accountList]);
  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: selectedTopdate,
    };
    dispatch(listRecommendation({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        setTableRecords(data.newfunctionquery10);
        var potential_list = [];
        var realised_list = [];
        data.newfunctionquery10.map((map_data) => {
          if (
            map_data.realised_savings === null ||
            Math.round(map_data.realised_savings) === 0
          ) {
            potential_list.push(map_data);
          } else {
            realised_list.push(map_data);
          }
        });
        const pending_action =
          data.newfunctionquery10.length - realised_list.length;
        var pending_percentage =
          (pending_action * 100) / data.newfunctionquery10.length;
        var color_code = "#000";
        if (pending_percentage >= 50) {
          color_code = "#be1e2d";
        } else if (pending_percentage >= 30) {
          color_code = "#FFA500";
        } else {
          color_code = "#188f00";
        }

        setTopRecords({
          potential_savings: potential_list?.reduce(
            (a, v) => (a = +a + +v.potential_savings),
            0
          ),
          realised_savings: realised_list?.reduce(
            (a, v) => (a = +a + +v.realised_savings),
            0
          ),
          pending_action: `${
            data.newfunctionquery10.length - realised_list.length
          }/${data.newfunctionquery10.length}`,
          color_code: color_code,
        });
      })
      .catch((err) => {});
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    companyName,
    selecteddb,
    selectedinfra,
    selectedos,
    recordsudpate,
  ]);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const showEditRow = (data) => {
    setDeleteStatus(true);
    setDrawerStatus(true);
    setFormType("update");
    setDeleteID(data.resource_id);
    const records = {
      application: data.application,
      environment: data.environment,
      service: data.services,
      account: data.account,
      commitment: data.commitment,
      current_configuration: data.current_configuration,
      potential_savings: data.potential_savings,
      recommendation_type: data.recommendation_type,
      recommended_configuration: data.recommended_configuration,
      resource_id: data.resource_id,
      resource_name: data.resource_name,
      resource_sub_category: data.resource_sub_category,
      realized_savings: data.realized_savings,
    };
    form.setFieldsValue(records);
    setOldUpdateRecords(records);
  };

  const showAddRow = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setFormType("add");
    const records = {
      application: "",
      environment: "",
      service: "",
      account: "",
      commitment: "",
      current_configuration: "",
      potential_savings: "",
      recommendation_type: "",
      recommended_configuration: "",
      resource_id: "",
      resource_name: "",
      resource_sub_category: "",
    };
    form.setFieldsValue(records);
    setOldUpdateRecords({});
  };
  const handleSubmit = (values) => {
    setButtonLoading(true);
    const inputs = {
      application: values.application,
      environment: values.environment,
      service: values.service,
      account: values.account,
      company_name: companyName,
      commitment: values.commitment,
      current_configuration: values.current_configuration,
      lost_opportunity: 0,
      potential_savings: values.potential_savings,
      realised_savings: 0,
      recommendation_rule: values.recommendation_type,
      recommended_configuration: values.recommended_configuration,
      report_month: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`,
      resource_id: values.resource_id,
      resource_name: values.resource_name,
      resource_sub_category: values.resource_sub_category,
    };
    if (formType === "add") {
      // const tracking_content = `Savings created by ${userInfo.first_name} ${userInfo.last_name}`;
      dispatch(insertRecommendation({ inputs }))
        .unwrap()
        .then((data) => {
          // console.log(data);
          message.success("Added Successfully Done");
          setDrawerStatus(false);
          setButtonLoading(false);
          setRecordsUpdate(!recordsudpate);
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err);
          setButtonLoading(false);
        });
    }
    if (formType === "update") {
      setButtonLoading(false);
      const inputs = {
        application: values.application,
        environment: values.environment,
        service: values.service,
        account: values.account,
        company_name: companyName,
        commitment: values.commitment,
        current_configuration: values.current_configuration,
        lost_opportunity: 0,
        potential_savings: values.potential_savings,
        realised_savings: values.realized_savings,
        recommendation_rule: values.recommendation_type,
        recommended_configuration: values.recommended_configuration,
        report_month: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`,
        resource_id: values.resource_id,
        resource_name: values.resource_name,
        resource_sub_category: values.resource_sub_category,
      };
      dispatch(updateRecommendation({ inputs }))
        .unwrap()
        .then((data) => {
          console.log(data);
          message.success("Updated Successfully Done");
          setDrawerStatus(false);
          setButtonLoading(false);
          setRecordsUpdate(!recordsudpate);
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err);
          setButtonLoading(false);
        });

      // tracking
      // const new_date = {
      //     application: values.application,
      //     environment: values.environment,
      //     service: values.service,
      //     account: values.account,
      //     identified: values.identified,
      //     month_year: values.month_year,
      //     eligibility: values.eligibility,
      //     potential: values.potential,
      //     potential_month_year: values.potential_month_year,
      //     implemented: values.implemented,
      //     realised: values.realised,
      //     realised_month_year: values.realised_month_year,
      //     status: values.status,
      //     instance_name: values.instance_name,
      //     details: values.details
      // }
      // var changesvalues = [];
      // Object.keys(oldUpdateRecords).filter(k => {
      //     if(oldUpdateRecords[k] !== new_date[k]){
      //         changesvalues.push({
      //             name: k,
      //             oldvalue: oldUpdateRecords[k],
      //             newvalue: new_date[k]
      //         })

      //     }
      // });
      // const tracking_content = changevaluescheck(changesvalues);
      // console.log(tracking_content);
    }
  };

  const rowDelete = () => {
    setConfirmLoading(true);
    dispatch(deleteRecommendation({ companyName, deleteid }))
      .unwrap()
      .then(({ data }) => {
        setDrawerDeleteStatus(false);
        setConfirmLoading(false);
        setDrawerStatus(false);
        setRecordsUpdate(!recordsudpate);
        message.success("Deleted");
      })
      .catch((err) => {});

    // setTimeout(() => {
    //   setDrawerDeleteStatus(false);
    //   setConfirmLoading(false);
    //   message.success('Deleted');
    // }, 1000);
  };

  function changevaluescheck(changesvalues) {
    if (changesvalues.length > 0) {
      var status = false;
      var content = "";
      changesvalues.map((data, key) => {
        if (data.name === "status") {
          content += "Status Changed " + data.oldvalue + " to " + data.newvalue;
          status = true;
        }
        if (data.name === "details") {
          content +=
            "Details Changed " + data.oldvalue + " to " + data.newvalue;
          status = true;
        }
        // console.log(changesvalues.length);
        // if(Number(++key) !== Number(changesvalues.length)){
        if (status) {
          content += ", ";
        }
        // }
      });
      content += ` by ${userInfo.first_name} ${userInfo.last_name}`;
      return content;
    }
  }
  const checkIdentifiedValue = (e) => {
    setIdentifiedSavingsAmount(e.target.value);
  };
  const checkEligibilityStatus = (e) => {
    if (e) {
      form.setFieldsValue({
        potential: identifiedSavingsAmount,
      });
    } else {
      form.setFieldsValue({
        potential: 0,
      });
    }
  };

  return (
    <>
      <SavingsTracking
        savingstrackingstatus={savingstrackingstatus}
        setSavingsTrackingStatus={setSavingsTrackingStatus}
      />
      <div className="plat-dashboard-body">
        <div className="plat-dashboard-quickview">
          <QuickView />
        </div>

        <div className="plat-dashboard-tabs">
          <Tapmenu
            excalname="savings models"
            type="savingsmodel"
            osstatus={false}
            dbstatus={false}
            title="Savings Models"
          />
          <div className="plat-full-asset-spend-cover">
            <div className="plat-saving-table-body">
              <DrawerPlat
                drawerstatus={drawerstatus}
                changeDrawerStatus={changeDrawerStatus}
                drawerdeletestatus={drawerdeletestatus}
                setDrawerDeleteStatus={setDrawerDeleteStatus}
                confirmLoading={confirmLoading}
                setConfirmLoading={setConfirmLoading}
                rowDelete={rowDelete}
                deletestatus={deletestatus}
                title="Savings Model"
              >
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  // initialValues={initialValues}
                  form={form}
                >
                  <Row>
                    <Col>
                      <Form.Item
                        name="application"
                        label="Application"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={allapplication}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="environment"
                        label="Environment"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={allenvironment}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        name="service"
                        label="Service"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={allservice}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="account"
                        label="Account"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={allaccount}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row className="identified-background"></Row> */}
                  <Row>
                    <Col>
                      <Form.Item
                        name="resource_sub_category"
                        label="Resource Sub Category"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Instance",
                              label: "Instance",
                            },
                            {
                              value: "EBS",
                              label: "EBS",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="recommendation_type"
                        label="Recommendation Type"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Right Sizing",
                              label: "Right Sizing",
                            },
                            {
                              value: "OS Licensing",
                              label: "OS Licensing",
                            },
                            {
                              value: "DB Licensing",
                              label: "DB Licensing",
                            },
                            {
                              value: "Storage Tiering",
                              label: "Storage Tiering",
                            },
                            {
                              value: "Stop/Start",
                              label: "Stop/Start",
                            },
                            {
                              value: "Savings Plan",
                              label: "Savings Plan",
                            },
                            {
                              value: "Instance Tiering",
                              label: "Instance Tiering",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                                    <Col>
                                        <Form.Item
                                            label="Eligibility"
                                            name="eligibility"
                                            valuePropName="checked"
                                        >
                                            <Switch 
                                                checkedChildren="Yes" 
                                                unCheckedChildren="No" 
                                                onChange={checkEligibilityStatus}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                  <Row>
                    <Col>
                      <Form.Item
                        name="resource_id"
                        label="Resource ID"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          disabled={deletestatus ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="resource_name"
                        label="Resource Name"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                                    <Col>
                                        <Form.Item
                                            name="implemented"
                                            label="Implemented"
                                            valuePropName="checked"
                                        >
                                             <Switch checkedChildren="Yes" unCheckedChildren="No" />
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                  <Row>
                    <Col>
                      <Form.Item
                        name="current_configuration"
                        label="Current Configuration"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="recommended_configuration"
                        label="Recommended Configuration"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        name="commitment"
                        label="Commitment"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="potential_savings"
                        label="Potential Savings"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Input
                          prefix="$"
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {deletestatus && (
                    <Row>
                      <Col>
                        <Form.Item
                          name="realized_savings"
                          label="Realized Savings"
                          // rules={[{ required: true, message: '' }]}
                        >
                          <Input
                            prefix="$"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {/* <Row>
                                    <Col>
                                        <Form.Item
                                            name="status"
                                            label="Status"
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Select
                                                showSearch
                                                style={{
                                                width: "100%",
                                                }}
                                                placeholder="Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={[
                                                {
                                                    value: "identified",
                                                    label: "Identified",
                                                },
                                                {
                                                    value: "validate",
                                                    label: "Validate",
                                                },
                                                {
                                                    value: "complated",
                                                    label: "Completed",
                                                },
                                                {
                                                    value: "not_executable",
                                                    label: "Not Executable ",
                                                }
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Item
                                                name="instance_name"
                                                label="Instance Name"
                                                rules={[{ required: true, message: '' }]}
                                        >
                                            <Select
                                                showSearch
                                                style={{
                                                width: "100%",
                                                }}
                                                placeholder="Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={[
                                                {
                                                    value: "fsg_uat_employee_portal_job_schedular_mumbai_az1",
                                                    label: "fsg_uat_employee_portal_job_schedular_mumbai_az1",
                                                },
                                                {
                                                    value: "fsg_prod_dedupe_app_mumbai_az1",
                                                    label: "fsg_prod_dedupe_app_mumbai_az1",
                                                },
                                                {
                                                    value: "fsg_uat_credence_App_Server-20-12-2022",
                                                    label: "fsg_uat_credence_App_Server-20-12-2022",
                                                },
                                                {
                                                    value: "fsg_prod_employee_portal_db_mumbai_az1",
                                                    label: "fsg_prod_employee_portal_db_mumbai_az1",
                                                }
                                                ]}
                                            />
                                        </Form.Item>     
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>   
                                        <Form.Item
                                            name="details"
                                            label="Details"
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <TextArea 
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                  {/* {
                                    deletestatus && 
                                    <Row>
                                        <Form.Item >
                                            <Button type="dashed" htmlType="button" onClick={() => setSavingsTrackingStatus(true)}>
                                                View Tracking
                                            </Button>
                                        </Form.Item>
                                    </Row>
                                } */}

                  <Row>
                    <Form.Item>
                      <Button
                        htmlType="button"
                        onClick={() => setDrawerStatus(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          background: "#1b5f9d",
                          color: "#fff",
                          float: "right",
                        }}
                        loading={buttonloading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </DrawerPlat>
              {/* <div onClick={() => addRecordsStatus(true)}> */}
              {/* <div onClick={() => {
                                setDrawerStatus(true)
                            }}>
                                <Icon icon="material-symbols:add-box-outline"  class="plat-saving-add-icon"/>
                            </div> */}
              <Row>
                {/* <Col xl={2} lg={2} className="budget-projected-col"> */}
                <Col xl={2} lg={2} md={4} sm={12}>
                  <Card className="budget-projected-box">
                    <h1 className="budget-allocated-content">
                      Potential Savings
                    </h1>
                    <h1 className="budget-box-cneter-icon">
                      <Icon icon="mdi:wallet" />
                    </h1>
                    <h1 className="budget-allocated-value">
                      ${numberFormat(toprecords.potential_savings)}
                    </h1>
                  </Card>
                </Col>
                {/* <Col xl={2} lg={2} className="budget-projected-col"> */}
                <Col xl={2} lg={2} md={4} sm={12}>
                  <Card className="budget-projected-box">
                    <h1 className="budget-allocated-content">
                      Realised Savings
                    </h1>
                    <h1 className="budget-box-cneter-icon">
                      <Icon icon="fluent:wallet-credit-card-24-filled" />
                    </h1>
                    <h1 className="budget-allocated-value">
                      ${numberFormat(toprecords.realised_savings)}{" "}
                    </h1>
                  </Card>
                </Col>
                <Col xl={2} lg={2} md={4} sm={12}>
                  <Card className="budget-projected-box">
                    <h1 className="budget-allocated-content">Pending Action</h1>
                    <h1 className="budget-box-cneter-icon">
                      <Icon icon="mdi:warning-decagram-outline" />
                    </h1>
                    <h1
                      className="budget-allocated-value"
                      style={{ color: toprecords.color_code }}
                    >
                      {toprecords.pending_action}{" "}
                    </h1>
                  </Card>
                </Col>
                <Col xl={3} lg={3} md={12} sm={12}>
                  <RealizedChart records={tablerecords} />
                </Col>
                <Col xl={3} lg={3} md={12} sm={12}>
                  <SavingsModelChart />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AllListSavingsModel
                    showEditRow={showEditRow}
                    showAddRow={showAddRow}
                    records={tablerecords}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <RealizedTable
                    showEditRow={showEditRow}
                    showAddRow={showAddRow}
                    records={tablerecords}
                  />
                </Col>
              </Row>
              {/* {
                                addrecordsstatus ?
                                    <>
                                        <div onClick={() => addRecordsStatus(false)} style={{  marginRight: "80px" }}>
                                            <Icon  icon="material-symbols:arrow-back" class="plat-saving-add-icon"/>
                                        </div><br></br>
                                        <AddTableRecords />
                                    </>
                                    
                                :
                                    <>
                                        <div onClick={() => addRecordsStatus(true)}>
                                            <Icon icon="material-symbols:edit" class="plat-saving-add-icon"/>
                                        </div>
                                        <AllListSavingsModel />
                                    </>
                            } */}
            </div>
          </div>
        </div>
      </div>
      <CustimizeFooter />
    </>
  );
};

export default SavingsModel;
