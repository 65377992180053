import { memo, useState, useCallback, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getSpendDetails} from "../action/costimizeV2Active";

const SpendtrendChart = (props) => {
  // console.log(props);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [monthlist, setMonthList] = useState(0);
  const [spendtrend,setspendtrend]=useState([])
  const [chartdetails, setChartDetails] = useState({
    label: [],
    spendvalue: [],
    budgetvalue: [0, 0, 0],
    monthlist: [],
  });
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  useEffect(()=>{
   
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 5);
    const datelist = [];
    for (let i = 0; i < 6; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
    }
    const selectedTopdate_new = datelist.toString();
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };
    dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
    .unwrap()
    .then(({ data }) => {
      
      console.log(data);
      setspendtrend(data.newfunctionquery3)

      var spend_list = [];
      var spend_value = 0;
      for (let i = 0; i < 6; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        var spend = data.newfunctionquery3.filter((item) => {
          let spenddate = new Date(item.reportmonth);
          if (spenddate.getMonth() + 1 === date.getMonth() + 1) {
            return item;
          }
        });
        if (
          date.getMonth() + 1 ===
          new Date(selectedTopdate).getMonth() + 1
        ) {
          if (spend.length > 0) {
            spend_value = spend[0].spend;
          }
        }
        if (spend.length > 0) {
          spend_list.push(Math.round(spend[0].spend));
        } else {
          spend_list.push(0);
        }
      }
    
      setChartDetails((prevChartDetails) => ({
        ...prevChartDetails,
        spendvalue: spend_list,
      }));
    
    
    })
    .catch((err) => {
    
    });
  },[])


useEffect(() => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    console.log(currentDate)
    console.log(lastThreeMonthsDate)
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 5);
    console.log(lastThreeMonthsDate)
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month_list = [];
    var chart_new_format = [];
    var month_lable_list = [];
    for (let i = 0; i < 6; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
      const spend = spendtrend.find((item) => {
        let spenddate = new Date(item.reportmonth);
        return spenddate.getMonth() + 1 === date.getMonth() + 1;
      });
      if (spend) {
        chart_new_format.push(Math.round(spend.spend));
      } else {
        chart_new_format.push(0);
      }
      month_lable_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    setChartDetails({
      label: month_list,
      spendvalue: chart_new_format,
    //   budgetvalue: [0, 0, 0], 
      monthlist: month_lable_list,
    });
  

  
  }, [spendtrend, selectedTopdate]);
  


  const changeMonth = (month) => {
    // console.log("in");
    setOpen(true);
    setMonthList(month);
  };

  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];

  const series = [
    {
      name: "Spend",
       type: "area",
      data: chartdetails.spendvalue,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      events: {
        dataPointSelection: function (event, chartContext, config) {
     
        },
      },
    },
    stroke: {
   
    curve: "straight",
    },
    tooltip: {
      enabled: true,
      intersect: false,
      shared: true,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: chartdetails.label,
    
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
    },
    yaxis: [
    //   {
    //     title: {
    //       text: "Budget",
    //       style: {
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       },
    //     },
    //     labels: {
    //       formatter: (num) => {
    //         if (num === 0) {
    //           return "0";
    //         } else if (num === Infinity) {
    //           return "0";
    //         } else {
    //           return Math.abs(num) > 999
    //             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    //             : Math.sign(num) * Math.abs(num);
    //         }
    //       },
    //     },
    //   },
      {
        opposite: true,
        title: {
          text: "Spend",
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        labels: {
          formatter: (num) => {
            if (num === 0) {
              return "0";
            } else if (num === Infinity) {
              return "0";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          },
        },
      },
    ],
    colors: ["#58BBD1"],
  };

  return (
    <div style={{ position: "relative" }}>
    

      <h1 className="spend-chart-title"> Spend Trends</h1>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={210}
      />
   
    </div>
  );
};

export default memo(SpendtrendChart);
