import { Table } from "antd";
//import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";
//import DatePicker from "react-datepicker";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import {
  message,
  Form,
  Input,
  Select,
  Button,
  Switch,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import {
  getActionItemTableData,
  addActionItem,
  updateActionItem,
  deleteActionItem,
} from "../../../action/graphql/slm/SLM_reducers";
import { disabledDate } from "../../../custom_hook/CustomHook";
// import { disabledDate } from "../../custom_hook/CustomHook";
import ActionItemChart from "./actionItemChart";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Icon } from "@iconify/react";

const { TextArea } = Input;

const ActionItems = () => {
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [oldRecords, setOldRecords] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [categoryCount, setCategoryCount] = useState({});
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  //const [startDate, setStartDate] = useState(new Date());
  const [updatestatus, setUpdateStatus] = useState(true);
  // const showEditRow = (data) => {
  //   setDrawerStatus(true);
  // };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const dispatch = useDispatch();
  const { selectedTopdate } = useSelector((state) => state.constimize);

  // const companyName = "fivestar";

  useEffect(() => {
    dispatch(
      getActionItemTableData({ companyName, selectedTopdate: selectedTopdate })
    )
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery20.map((item) => {
          let formatDate = new Date(item.date);
          const options = {
            year: "numeric",
            day: "numeric",
            month: "short",
          };
          let formattedDate_new = formatDate.toLocaleDateString(
            "en-US",
            options
          );
          return {
            date: formattedDate_new,
            points_discussed: item.points_discussed,
            owner: item.owner,
            remarks: item.remarks,
            status: item.status,
            category: item.category,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => handleEditClick(item)}
              />
            ),
            type: "data",
          };
        });
        setTableData(records);
        setCategoryCount(records);
        console.log(records);
        // setServiceRequestTableData(records);
      })
      .catch((err) => console.log(err));
  }, [selectedTopdate, updatestatus]);
  const [form] = Form.useForm();
  const monthFormat = "YYYY-MM-01";

  const [actionItemId, setActionItemId] = useState();
  const handleEditClick = (data) => {
    console.log("data", data);
    setActionItemId(data.action_item_id);
    setDeleteStatus(true);
    setDrawerStatus(true);
    setOldRecords(data);

    const records = {
      //date: moment(data.date, "YYYY-MM-DD"),
      //date:data.date,
      date: moment(data.date, "YYYY-MM-DD"),
      category: data.category,
      owner: data.owner,
      points_discussed: data.points_discussed,
      remarks: data.remarks,
      status: data.status,
    };
    form.setFieldsValue(records);
  };

  const handleAddClick = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
    const records = {
      //date: moment(data.date, "YYYY-MM-DD"),
      date: "",
      category: "",
      owner: "",
      points_discussed: "",
      remarks: "",
      status: "",
    };
    form.setFieldsValue(records);

    // setOldRecords({
    //   date: "",
    //   category: "",
    //   owner: "",
    //   points_discussed: "",
    //   remarks: "",
    //   status: "",
    // });
  };
  //   useEffect(() => {
  //     var records = {
  //         next_milestone: "",
  //         report_date: "",
  //         project_target_date: "",
  //         start_date: "",
  //         progress: "",
  //         project_name: "",
  //         project_phase: "",
  //         status: ""
  //     }

  //     if (props.oldrecords) {
  //         records = {
  //             next_milestone: props.oldrecords.next_milestone,
  //             progress: props.oldrecords.progress,
  //             project_name: props.oldrecords.project_name,
  //             project_phase: props.oldrecords.project_phase,
  //             start_date: props.oldrecords.start_date,
  //             project_target_date: props.oldrecords.project_target_date,
  //             report_date: props.oldrecords.report_date,
  //             status: props.oldrecords.status,
  //         }
  //     }
  //     form.setFieldsValue(records);
  // }, [props])

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "plat-table-date",
      fixed: "left",
    },
    {
      title: "Points Discussed",
      dataIndex: "points_discussed",
      key: "points_discussed",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const tableProps = {};

  const handleSubmit = (value) => {
    const input = {
      category: value.category,
      company_name: companyName,
      owner: value.owner,
      points_discussed: value.points_discussed,
      status: value.status,
      remarks: value.remarks,
      usagedate: value.date,
      report_month: selectedTopdate,
    };
    if (deletestatus) {
      dispatch(updateActionItem({ input, action_item_id: actionItemId }))
        .unwrap()
        .then(({ data }) => {
          setDeleteStatus(false);
          setUpdateStatus(!updatestatus);
          console.log(updatestatus);
          message.success("Record updated");
          setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(addActionItem({ input }))
        .unwrap()
        .then(({ data }) => {
          setUpdateStatus(!updatestatus);
          console.log(data);
          message.success("Record added");
          setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const rowDelete = () => {
    dispatch(deleteActionItem({ action_item_id: actionItemId, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        //getActionItemsData();
        setDrawerStatus(false);
        setUpdateStatus(!updatestatus);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleFileChange = () => {
    console.log("uploaded");
  };

  const handleFileSubmit = () => {
    console.log("submit");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uploadClick = () => {
    setIsModalOpen(true);
  };
  const items = [
    {
      key: "1",
      label: "Upload",
      onClick: () => uploadClick(),
    },
    {
      key: "2",
      label: <CSVLink data={tableData}>Download </CSVLink>,
    },
  ];

  return (
    <>
      <DrawerPlat
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title="Action Items"
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={initialValues}
          form={form}
        >
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {/* <Form.Item name="date" label="Date">
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  disabledDate={disabledDate}
                  // selected={selectedDate}
                />
              </Form.Item> */}
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  format={monthFormat}
                  picker="month"
                  //  selected={startDate}
                  //  onChange={(date) => setStartDate(date)}
                />
              </Form.Item>
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {/* <Form.Item name="category" label="Category">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item> */}
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "operations",
                      label: "Operations",
                    },
                    {
                      value: "cost savings",
                      label: "Cost Saving",
                    },
                    {
                      value: "security",
                      label: "Security",
                    },
                    {
                      value: "budgeting",
                      label: "Budgeting",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {/* <Form.Item name="points_discussed" label="Points Discussed">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item> */}
              <Form.Item
                name="points_discussed"
                label="Points Discussed"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {/* <Form.Item name="owner" label="Owner">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item> */}
              <Form.Item
                name="owner"
                label="Owner"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              {/* <Form.Item name="status" label="Status">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "completed",
                      label: "Completed",
                    },
                    {
                      value: "in_progress",
                      label: "In Progress",
                    },
                  ]}
                />
              </Form.Item> */}
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "completed",
                      label: "Completed",
                    },
                    {
                      value: "pending",
                      label: "Pending",
                    },
                    {
                      value: "in-progress",
                      label: "In-Progress",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div className="">
        <ActionItemChart categoryCount={categoryCount} />
        <Row>
          <Col xxl={23} xl={23} lg={23} md={23} sm={24} xs={24}>
            <h6 className="m-3"> Action Items </h6>
            <div onClick={handleAddClick}>
              <button className="table-add-button float-right">
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            </div>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <Button className="table-add-button float-right">
                <Icon icon="vscode-icons:file-type-excel" />
              </Button>
            </Dropdown>
            <Modal
              title="Choose File Type"
              open={isModalOpen}
              footer={null}
              onCancel={handleCancel}
            >
              <input type="file" id="fileInput" onChange={handleFileChange} />
              <div className="p-1">
                <button
                  className="btn tagging-upload-button"
                  onClick={handleFileSubmit}
                >
                  Upload
                </button>
              </div>
            </Modal>
            <div className="table-planned-actual">
              <Table
                {...tableProps}
                className="savings_new_table "
                style={{ overflowX: "scroll" }}
                columns={columns}
                dataSource={tableData}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActionItems;
