import React from "react";
import { useState } from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { TbGridDots } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../amplify/auth-config";
import { loginUser } from "../action/userAction";
import { useEffect } from "react";
import { Avatar } from "antd";
import { IoIosSearch } from "react-icons/io";
import cams_header_logo from "../../assets/images/cams/cams_header_logo.svg";

function CamsHeader() {
  const [userinfostatus, setUserInfoStatus] = useState(false);
  const [userdetails, setUserDetails] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogout = () => {
    dispatch(signOut())
      .unwrap()
      .then(() => {
        localStorage.removeItem("_token");
        localStorage.removeItem("CognitoIdentityServiceProvider.auth");
        window.location.href = "/signin";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    //useinfo
    var token = localStorage.getItem("_token");
    if (token) {
      setUserInfoStatus(true);
    } else {
      setUserInfoStatus(false);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const records = {
      user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
      _token: localStorage.getItem("_token"),
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        setUserDetails(data.getPlatformUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.pathname]);
  return (
    <>
      <Navbar expand="lg" className="cams_header" sticky="top">
        <Container fluid className="plat-container">
          <div>
            <TbGridDots className="costimize-global-menu-icon" />
          </div>
          <Navbar.Brand
            onClick={() => navigate("/")}
            className="plat-navbar-brand costimize-header-logo"
          >
            <img src={cams_header_logo} alt="Capture" />
            <span className="header-logo-name">CloudShifu CAMS</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="plat-navbar-collapse">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <Form className="d-flex">
              {userinfostatus ? (
                <>
                  {/* <NavDropdown
                    title={<IoIosSearch className="costimize-icon-with-bg" />}
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu costimize-navbar-top-menu"
                  ></NavDropdown> */}
                  {userdetails.role !== "user" && (
                    <Button
                      variant="outline-success costimize-admin-panel costimize-navbar-top-menu"
                      onClick={() => navigate("/admin-actions")}
                    >
                      Admin Panel
                    </Button>
                  )}
                  <NavDropdown
                    title={
                      <Avatar
                        style={{
                          backgroundColor: "#fff",
                          color: "#433F3F",
                          marginTop: "-1px",
                        }}
                      >
                        {userdetails.first_name && (
                          <>{Array.from(userdetails.first_name)[0]}</>
                        )}
                      </Avatar>
                    }
                    id="navbarScrollingDropdown"
                    className="navbar-top-menu costimize-navbar-top-menu"
                  >
                    {userdetails.first_name ? (
                      <>
                        <label className="menu-topicon-username">
                          {userdetails.first_name}
                        </label>
                        <NavDropdown.Divider />
                      </>
                    ) : (
                      <></>
                    )}

                    {userdetails.role === "master" ||
                    userdetails.role === "admin" ? (
                      <>
                        {userdetails.role === "master" && (
                          <>
                            <NavDropdown.Item
                              className="navbar-top-submenu"
                              onClick={() => navigate("/admin-dashboard")}
                            >
                              Dashboard
                            </NavDropdown.Item>
                          </>
                        )}
                        <NavDropdown.Item
                          className="navbar-top-submenu"
                          onClick={() => navigate("/admin-actions")}
                        >
                          Admin Panel
                        </NavDropdown.Item>
                      </>
                    ) : (
                      <></>
                    )}
                    <NavDropdown.Item
                      className="navbar-top-submenu"
                      onClick={userLogout}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <Button
                    variant="outline-success"
                    color="link"
                    className="active-btn ms-2"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </Button>
                  <Button
                    variant="outline-success"
                    color="link"
                    className="active-btn ms-2"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default CamsHeader;
