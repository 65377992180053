import { gql } from "@apollo/client";

const CHART_BOT_RESPONSE = gql`
  query MyQuery($message: String!, $companyName: String!) {
    getChat(query: $message, schema: $companyName)
  }
`;

const REQUEST_CHATBOT_CREDIT = gql`
  query MyQuery($userId: String!)
  {
    chat_bot_credit_request(event_type: "credit_request", user_id: $userId)
  }
`;

export { CHART_BOT_RESPONSE,REQUEST_CHATBOT_CREDIT };
