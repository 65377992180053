import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getRandomColor } from '../../custom_hook/CustomHook';
import { listRealizationChart } from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

var colors = [
    '#F4D772',
    '#BB3D9F',
    '#5228AC'
  ]


const SavingsModelChart = () => {
    // const [colorlist, setColorList] = useState([]);
    const { selectedTopdate, selectedEnvironment, selectedAccount, selectedCloud, selectedApplication,  selectedservices, selectedos, selectedinfra,selecteddb  } = useSelector((state) => state.constimize);
    const { companyName } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [tablerecords, setTableRecords] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);


    useEffect(() => {
        const monthlist = [];
        for (let index = 0; index < 12; index++) {
            monthlist.push(`${new Date().getFullYear()}-${+index + +1}-01`);
        }
        const apivalue = {
            account_name: selectedAccount.toString(),
            application: selectedApplication.toString(),
            cloud: selectedCloud.toString(),
            company_name: companyName,
            environment: selectedEnvironment.toString(),
            month: monthlist.toString(),
            os: selectedos.toString(),
            db: selecteddb.toString(),
            infra: selectedinfra.toString(),
            service: selectedservices.toString(),
        }
        dispatch(listRealizationChart({apivalue}))
        .unwrap()
        .then(({ data }) => {
            var chartlist = [];
            for (let index = 0; index < 12; index++) {
                const datalist = data.newfunctionquery11.filter((v) =>  {
                    if(`${new Date(v.report_month).getFullYear()}-${new Date(v.report_month).getMonth() + 1}-01` === `${new Date().getFullYear()}-${+index + +1}-01`){
                        return v;
                    }
                });
                if(datalist.length > 0){
                    chartlist.push(Math.round(datalist[0].realised_savings));
                }else{
                    chartlist.push(0);
                }
            }
            setTableRecords(chartlist);
        })
        .catch(err => {
            console.log(err.message);
        });
    },[selectedAccount, selectedApplication, selectedCloud, selectedEnvironment, selectedTopdate, selectedos, selecteddb, selectedinfra, selectedservices]);

    const options = {
        chart: {
            type: 'area',
            height: 160,
            // offsetY: 5,
            toolbar: {
                show: false,
            }
        },
        stroke: {
            show: false,
            curve: 'smooth',
            // lineCap: 'butt',
            // colors: ["#000","#000"],
            // width: 1,
            // dashArray: 0,      
        },
        // markers: {
        //     show: false,
        //     size: [3,3,3],
        //     strokeColors: '#fff',
        //     strokeWidth: 0,
        //     strokeOpacity: 0,
        //     strokeDashArray: 0,
        //     fillOpacity: 0,
        //     discrete: [],
        //     shape: "circle",
        //     radius: 2,
        //     offsetX: 0,
        //     offsetY: 0,
        //     onClick: undefined,
        //     onDblClick: undefined,
        //     showNullDataPoints: true,
        //     hover: {
        //     size: undefined,
        //     sizeOffset: 3
        //     }
        // },
        grid:{
            show: false
        },
        colors: colors,
        xaxis: {
        //   categories: spendtrandschartmonth,
          categories: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
          
          axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: false,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0
            },
            labels: {
              offsetX: 0,
              offsetY: -6,
              style: {
                  fontSize: '12px',
                  fontWeight: 400,
              }
          },
        //   title: {
        //         text: "Month",
        //         rotate: -180,
        //         offsetX: 0,
        //         offsetY: -10,
        //         style: {
        //             color: undefined,
        //             fontSize: '12px',
        //             fontWeight: 500,
        //         },
        //     }
        },
        yaxis: {
            // tickAmount: 2,
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
            title: {
              text: "$",
              rotate: -180,
              offsetX: 7,
              offsetY: 0,
              style: {
                  color: undefined,
                  fontSize: '12px',
                  fontWeight: 500,
              },
          },
          labels: {
              style: {
                  fontSize: '8px',
                  fontWeight: 400,
              },
              formatter: (num) => { 
                return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
               },
          },
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'Savings Realization',
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  '500',
              color:  '#000'
            },
        }
      };
      const series =  [{
        name: "Realized Savings",
        // data: [250,210,200,290,240,270,290,220,200,230,260,290]
        data: tablerecords
      }];


    return(
        <>
            <ReactApexChart options={options} series={series} type="area" height={220} className="trendchartv2" />
            {/* <FaRegCalendarAlt className='quick-glance-spend-chart-cal' /> */}
        </>
    )
}

export default SavingsModelChart;