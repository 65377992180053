import React from "react";
import SLMSidebar from "../../SLMSidebar";
import MainHead from "../../../costimize_v2/layout/MainHead";
import ActionItems from "./ActionItems";
import ProjectStatus from "../ProjectStatus/projectstatus";
import KeyActivities from "./keyActivities";
import { Tabs } from "antd";

function SLM_ActivityTracker() {
  const items = [
    {
      key: "1",
      label: `Action Items`,
      children: <ActionItems />,
    },
    {
      key: "2",
      label: `Project Status`,
      children: <ProjectStatus />,
    },
    {
      key: "3",
      label: `Key Activities`,
      children: <KeyActivities />,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <MainHead type="slm" title="Activity Tracker" />
        {/* <div className="costimize-body-screen d-flex justify-content-between">
          <div className="icons"> */}
        <div className="costimize-body-screen ">
          <Tabs className="cams-tabs mt-4" defaultActiveKey="1" items={items} />
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
}

export default SLM_ActivityTracker;
