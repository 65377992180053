import ReactApexChart from "react-apexcharts";
import React ,{useState,useEffect}from "react";
import moment from "moment";
import { Card } from "antd";

const ProjectStatusChart = ({daysDiffrence}) => {
  console.log(daysDiffrence);
  const [series, setSeries] = useState([]);


    const options = {
        chart: {
          height: 350,
          type: 'rangeBar',
          toolbar: {
                show: false,
            },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        grid: {
          borderColor: "#C9CED2",
          xaxis: {
              lines: {
                  show: false
              }
          },
          yaxis : {
            lines : {
              show : true
            }
          },
      },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            var a = moment(val[0])
            var b = moment(val[1])
            var diff = b.diff(a, 'days')
            return diff + (diff > 1 ? ' days' : ' day')
          }
        },
        title: {
            text: `Project Status`,
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 10,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              color:  '#595959'
            },
        },
        fill: {
          // type: 'gradient',
          // gradient: {
          //   shade: 'light',
          //   type: 'vertical',
          //   shadeIntensity: 0.25,
          //   color :" #FF7E6C",
          //   gradientToColors: undefined,
          //   inverseColors: true,
          //   opacityFrom: 1,
          //   opacityTo: 1,
          //   stops: [50, 0, 100, 100]
          // }
          colors: ["#FF7E6C"]
        },
        xaxis: {
          type: 'datetime',
          position: 'top',
        
        },
        legend: {
          position: 'top'
        }
      };
      // const series = [
      //   {
      //     data: [
      //       {
      //         x: 'CKYC Migration',
      //         y: [
      //           new Date('2023-01-25').getTime(),
      //           new Date('2023-02-25').getTime()
      //         ]
      //       },
      //       {
      //         x: 'CKYC Migration ',
      //         y: [
      //           new Date('2023-01-25').getTime(),
      //           new Date('2023-02-25').getTime()
      //         ]
      //       },
      //       {
      //         x: 'IpV6 Enablement',
      //         y: [
      //           new Date('2023-01-15').getTime(),
      //           new Date('2023-03-15').getTime()
      //         ]
      //       },
      //       {
      //           x: 'DataVault',
      //           y: [
      //             new Date('2023-01-18').getTime(),
      //             new Date('2023-02-23').getTime()
      //           ]
      //         }
      //     ]
      //   },
      // ]; 
    
      // const series = [
      //   {
      //     data: [
      //       {
      //         x: 'CKYC Migration',
      //         y: [
      //           new Date('2023-01-25').getTime(),
      //           new Date('2023-02-25').getTime()
      //         ]
      //       },
      //       {
      //         x: 'CKYC Migration ',
      //         y: [
      //           new Date('2023-01-25').getTime(),
      //           new Date('2023-02-25').getTime()
      //         ]
      //       },
      //       {
      //         x: 'IpV6 Enablement',
      //         y: [
      //           new Date('2023-01-15').getTime(),
      //           new Date('2023-03-15').getTime()
      //         ]
      //       },
      //       {
      //           x: 'DataVault',
      //           y: [
      //             new Date('2023-01-18').getTime(),
      //             new Date('2023-02-23').getTime()
      //           ]
      //         }
      //     ]
      //   },
      // ]; 
    //   useEffect(() => {
    //     if (Array.isArray(daysDiffrence)) {
    //     daysDiffrence.forEach(diff => {
    //     const { project_name, start_date, project_target_date } = diff;
      
      
    //     const newData = {
    //       x: project_name,
    //       y: [
    //         start_date, project_target_date
    //         // new Date(start_date).getTime(),
    //         // new Date(project_target_date).getTime()
    //       ]
    //     };
      
    //  console.log(newData)
      
    //   });
    // }else {
    //     console.log(' not an array');
    //   }
    
    // },[daysDiffrence]);
      
    useEffect(() => {
      if (Array.isArray(daysDiffrence)) {
        const newSeries = daysDiffrence.map(diff => {
          const { project_name, start_date, project_target_date } = diff;
    
          return {
            x: project_name,
            y: [
              new Date(start_date).getTime(),
              new Date(project_target_date).getTime()
            ]
          };
        });
    
        setSeries([{ data: newSeries }]);
      } else {
        console.log('Not an array');
      }
    }, [daysDiffrence]);
    
    return(
        <>
        {/* <Card> */}
        <ReactApexChart options={options} series={series} type="rangeBar" height={200}/>
        {/* </Card> */}
        </>
    )
}

export default ProjectStatusChart;